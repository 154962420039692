:root {
  --cache-version: 1;
}

:root {
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-6: 6px;
  --spacing-8: 8px;
  --spacing-10: 10px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-44: 44px;
  --spacing-48: 48px;
  --spacing-64: 64px;
  --spacing-72: 72px;
  --spacing-80: 80px;
  --spacing-88: 88px;
  --spacing-96: 96px;
  --shadow-s: 1px 3px 3px #1919191a, 1px 2px 2px #19191912;
  --shadow-m: 0 2px 4px -2px #19191912, 0 0 8px 2px #1919191a;
  --shadow-l: 12px 16px 16px -4px #19191914, 4px 6px 6px -2px #1919190a;
  --shadow-xl: 20px 24px 24px -4px #19191914, 8px 8px 8px -4px #1919190a;
  --shadow-xxl: 24px 48px 48px -12px #1919192e;
  --shadow-xxxl: 32px 64px 64px -12px #19191924;
  --shadow-newsletter-in: inset 4px 4px 4px #00000026;
  --shadow-newsletter-drop: 0 -4px 4px #00000026;
  --avatar-size-2xs: 16px;
  --avatar-size-xs: 24px;
  --avatar-size-sm: 32px;
  --avatar-size-md: 40px;
  --avatar-size-lg: 48px;
  --avatar-size-xl: 60px;
  --avatar-size-2xl: 72px;
  --avatar-size-3xl: 88px;
  --avatar-size-4xl: 112px;
  --avatar-icon-size-2xs: 8px;
  --avatar-icon-size-xs: 12px;
  --avatar-icon-size-sm: 16px;
  --avatar-icon-size-md: 20px;
  --avatar-icon-size-lg: 24px;
  --avatar-icon-size-xl: 28px;
  --avatar-icon-size-2xl: 32px;
  --avatar-icon-size-3xl: 36px;
  --avatar-icon-size-4xl: 60px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  overflow-wrap: break-word;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

picture {
  line-height: 0;
  display: block;
}

.wrapper--main img {
  width: 100%;
  height: auto;
  display: inline-block;
}

.flex-line-break {
  flex: 1 0 100%;
  height: 0;
}

.landscape-16by9-wrapper {
  aspect-ratio: 16 / 9;
  line-height: 0;
  position: relative;
  container-type: inline-size;
}

.piano-top-uniweb {
  grid-column: lend / rend;
}

.piano-top-uniweb:empty, .piano-hp-dalsi-clanky-uniweb:empty {
  display: none;
}

.space-below-header:has(.piano-top-uniweb:empty) {
  display: none;
}

.space-below-header:has(.section):not(:empty) {
  display: grid;
}

.piano-uniweb--hp, .piano-uniweb--hp-odjinud {
  grid-column: 1 / 3;
  display: contents;
}

.piano-uniweb--hp:empty, .piano-uniweb--hp-odjinud:empty {
  display: none;
}

.hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hideScrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.hidden {
  display: none !important;
}

.print-only {
  display: none !important;
}

.image__placeholder {
  background: url("/dist/assets/default/image-placeholder.svg") center / cover no-repeat;
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1 1 0;
}

.flex-column {
  flex-direction: column;
}

a {
  scroll-margin-top: var(--spacing-64);
}

[class$="line-limit-5"], [class$="line-limit-4"], [class$="line-limit-3"], [class$="line-limit-2"] {
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

[class$="line-limit-2"] {
  -webkit-line-clamp: 2;
}

[class$="line-limit-3"] {
  -webkit-line-clamp: 3;
}

[class$="line-limit-4"] {
  -webkit-line-clamp: 4;
}

[class$="line-limit-5"] {
  -webkit-line-clamp: 5;
}

.word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
}

@media (min-width: 768px) and (max-width: 1079px), (min-width: 1080px) {
  .hide-on-tablet-desktop {
    display: none;
  }
}

@keyframes pulse-animation-on-black-bg {
  0% {
    box-shadow: 0 0 #fff3;
  }

  100% {
    box-shadow: 0 0 0 20px #fff0;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

:root {
  --inter: "Inter", "Arial", "Helvetica", sans-serif;
  --font-size-base: 16;
  --font-size-10: calc(10rem / var(--font-size-base));
  --font-size-11: calc(11rem / var(--font-size-base));
  --font-size-12: calc(12rem / var(--font-size-base));
  --font-size-13: calc(13rem / var(--font-size-base));
  --font-size-14: calc(14rem / var(--font-size-base));
  --font-size-15: calc(15rem / var(--font-size-base));
  --font-size-16: calc(16rem / var(--font-size-base));
  --font-size-18: calc(18rem / var(--font-size-base));
  --font-size-19: calc(19rem / var(--font-size-base));
  --font-size-20: calc(20rem / var(--font-size-base));
  --font-size-22: calc(22rem / var(--font-size-base));
  --font-size-24: calc(24rem / var(--font-size-base));
  --font-size-25: calc(25rem / var(--font-size-base));
  --font-size-26: calc(26rem / var(--font-size-base));
  --font-size-28: calc(28rem / var(--font-size-base));
  --font-size-30: calc(30rem / var(--font-size-base));
  --font-size-32: calc(32rem / var(--font-size-base));
  --font-size-34: calc(34rem / var(--font-size-base));
  --font-size-36: calc(36rem / var(--font-size-base));
  --font-size-42: calc(42rem / var(--font-size-base));
  --font-size-44: calc(44rem / var(--font-size-base));
  --font-size-46: calc(46rem / var(--font-size-base));
  --font-size-48: calc(48rem / var(--font-size-base));
  --font-mobile-h1: var(--font-size-36) / 1.1 var(--inter);
  --font-mobile-h2: var(--font-size-32) / 1.125 var(--inter);
  --font-mobile-h3: var(--font-size-26) / 1.23 var(--inter);
  --font-mobile-h4: var(--font-size-22) / 1.27 var(--inter);
  --font-mobile-h5: var(--font-size-18) / 1.3 var(--inter);
  --font-mobile-body1: var(--font-size-16) / 1.75 var(--inter);
  --font-mobile-body1-bold: 700 var(--font-size-16) / 1.75 var(--inter);
  --font-mobile-body2: var(--font-size-18) / 1.7 var(--inter);
  --font-mobile-body2-bold: 700 var(--font-size-18) / 1.7 var(--inter);
  --font-mobile-inter-title: var(--font-size-32) / 1.125 var(--inter);
  --font-mobile-section-title: var(--font-size-32) / 1.125 var(--inter);
  --font-mobile-meta-bold: 700 var(--font-size-12) / 1.6 var(--inter);
  --font-mobile-meta-regular: var(--font-size-12) / 1.6 var(--inter);
  --font-mobile-button-large: var(--font-size-16) / 1.75 var(--inter);
  --font-mobile-button-medium: var(--font-size-16) / 1.5 var(--inter);
  --font-mobile-button-small: var(--font-size-14) / 1.42 var(--inter);
  --font-mobile-menu-item: var(--font-size-16) / 1.25 var(--inter);
  --font-mobile-helper-text: var(--font-size-10) / 1.2 var(--arial);
  --font-desktop-h1: var(--font-size-48) / 1.083 var(--inter);
  --font-desktop-h2: var(--font-size-32) / 1.125 var(--inter);
  --font-desktop-h3: var(--font-size-26) / 1.23 var(--inter);
  --font-desktop-h4: var(--font-size-22) / 1.27 var(--inter);
  --font-desktop-h5: var(--font-size-14) / 1.14 var(--inter);
  --font-desktop-body1: var(--font-size-16) / 1.75 var(--inter);
  --font-desktop-body1-bold: 700 var(--font-size-16) / 1.75 var(--inter);
  --font-desktop-body2: var(--font-size-14) / 1.4 var(--inter);
  --font-desktop-body2-bold: 700 var(--font-size-14) / 1.4 var(--inter);
  --font-desktop-inter-title: var(--font-size-32) / 1.125 var(--inter);
  --font-desktop-section-title: var(--font-size-32) / 1.125 var(--inter);
  --font-desktop-meta-bold: 700 var(--font-size-12) / 1.6 var(--inter);
  --font-desktop-meta-regular: var(--font-size-12) / 1.666 var(--inter);
  --font-desktop-button-large: var(--font-size-18) / 1.55 var(--inter);
  --font-desktop-button-medium: var(--font-size-16) / 1.5 var(--inter);
  --font-desktop-button-small: var(--font-size-14) / 1.42 var(--inter);
  --font-desktop-menu-item: var(--font-size-16) / 1.25 var(--inter);
  --font-desktop-helper-text: var(--font-size-10) / 1.2 var(--arial);
}

html, body, .font-body {
  font: var(--font-mobile-body1);
  color: var(--text-primary);
}

h1, .heading-1 {
  font: var(--font-mobile-h1);
}

h2, .heading-2 {
  font: var(--font-mobile-h2);
}

h3, .heading-3 {
  font: var(--font-mobile-h3);
}

h4, .heading-4 {
  font: var(--font-mobile-h4);
}

h5, .heading-5 {
  font: var(--font-mobile-h5);
}

.button {
  text-decoration: none;
}

em, em strong {
  font-style: italic;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

ul ul {
  margin: 0 0 0 var(--spacing-24);
  list-style-type: circle;
}

ol {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: decimal;
}

ol ol {
  margin: 0 0 0 var(--spacing-24);
}

hr {
  border: 0;
  border-top: 1px solid var(--other-divider);
}

blockquote:not(.tiktok-embed) {
  background-color: var(--other-divider);
  border-left: 5px solid var(--other-backdrop-overlay);
  padding: var(--spacing-20);
}

ul, ul ul {
  margin: 0;
  list-style-type: none;
}

.flash-message__list {
  transform: translate(-50%, calc(-1 * var(--spacing-32)));
  z-index: 1000;
  pointer-events: none;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 50%;
}

.flash-message {
  pointer-events: all;
  padding: var(--spacing-24);
  background-color: var(--info-alert-background);
  color: var(--info-alert-content);
  margin-top: var(--spacing-16);
  visibility: hidden;
  opacity: 0;
  border-radius: 12px;
  transition: opacity .25s ease-in, visibility 0s ease-in .25s;
  position: relative;
  box-shadow: 0 4px 8px -2px #1919191a, 0 2px 4px -2px #19191912;
}

.flash-message--visible {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s ease-in, visibility 0s ease-in .25s;
}

.flash-message a {
  text-underline-offset: var(--spacing-4);
  text-decoration: underline;
}

.flash-message a:hover {
  text-decoration: none;
}

.flash-message--info {
  background-color: var(--info-alert-background);
  color: var(--info-alert-content);
}

.flash-message--info svg path {
  fill: var(--info-alert-content);
}

.flash-message--info a, .flash-message--info a:hover {
  color: var(--info-alert-content);
}

.flash-message--error {
  background-color: var(--error-alert-background);
  color: var(--error-alert-content);
}

.flash-message--error svg path {
  fill: var(--error-alert-content);
}

.flash-message--error a, .flash-message--error a:hover {
  color: var(--error-alert-content);
}

.flash-message--warning {
  background-color: var(--warning-alert-background);
  color: var(--warning-alert-content);
}

.flash-message--warning svg path {
  fill: var(--warning-alert-content);
}

.flash-message--warning a, .flash-message--warning a:hover {
  color: var(--warning-alert-content);
}

.flash-message--success {
  background-color: var(--success-alert-background);
  color: var(--success-alert-content);
}

.flash-message--success svg path {
  fill: var(--success-alert-content);
}

.flash-message--success a, .flash-message--success a:hover {
  color: var(--success-alert-content);
}

.flash-message__icon {
  float: left;
  margin-right: var(--spacing-16);
}

.flash-message__text {
  width: 92%;
  display: inline-block;
}

.flash-message__close {
  cursor: pointer;
  top: var(--spacing-24);
  right: var(--spacing-24);
  font-weight: bold;
  transition: all .3s;
  position: absolute;
}

.flash-message__close svg {
  width: 24px;
  height: 24px;
}

.flash-message__close:active, .flash-message__close:focus {
  transform: rotate(45deg);
}

@media print {
  @page {
    margin: 2cm;
  }

  body {
    color: #000 !important;
    background: #fff !important;
  }

  header {
    color: #000 !important;
    box-shadow: none !important;
    background: #fff !important;
    border-bottom: 1px solid #ccc !important;
    position: relative !important;
  }

  header .header {
    box-shadow: none !important;
  }

  .header-logo {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .container--main > .is-pinned {
    position: relative !important;
  }

  footer {
    color: #000 !important;
    box-shadow: none !important;
    background: #fff !important;
    border-top: 1px solid #ccc !important;
  }

  .container {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .grid-113, .grid-233 {
    grid-template-columns: [lend] 1fr[ldivider] 1fr[rend] !important;
  }

  h1 {
    font-size: 24pt;
  }

  h2, h3, h4 {
    font-size: 14pt;
  }

  a, blockquote {
    page-break-inside: avoid;
  }

  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  table, pre {
    page-break-inside: avoid;
  }

  ul, ol, dl {
    page-break-before: avoid;
  }

  a:link, a:visited, a {
    text-align: left;
    background: none;
    font-weight: bold;
    text-decoration: underline;
    color: #000 !important;
  }

  .article-detail__content-wrapper a[href^="http"]:after {
    content: " <" attr(href) "> ";
  }

  .article-detail__content-wrapper a[href^="#"]:after {
    content: "";
  }

  embed, iframe, .vp-player {
    position: absolute;
    height: 0 !important;
    overflow: hidden !important;
  }

  p, address, li, dt, dd, blockquote {
    font-size: 100%;
  }

  code, pre {
    font-family: Courier New, Courier, mono, serif;
  }

  ul, ol {
    margin-bottom: 20pt;
    margin-left: 18pt;
    list-style: square;
  }

  li {
    line-height: 1.3;
  }

  .small-gallery, .article-detail__top-img, .article-gallery-strip {
    max-width: 12cm;
    margin: 0 auto .2cm !important;
  }

  .article-gallery-strip {
    background: #fff !important;
    margin-bottom: 1cm !important;
    padding: 0 !important;
  }

  .article-image {
    max-width: 12cm;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .footer__copyright {
    color: #000 !important;
  }

  .article-detail__content-wrapper > p, .article-detail__content-wrapper > h2, .article-detail__content-wrapper > h3, .article-detail__content-wrapper > h4, .article-detail__content-wrapper > h5, .article-detail__perex {
    max-width: none !important;
  }

  .accordion__question {
    padding-top: 0 !important;
  }

  .accordion__answer {
    padding-bottom: .85cm;
    display: block !important;
  }

  .subscription {
    background: #fff !important;
    padding: 0 !important;
  }

  .no-print, .cnc-ads, .cnc-ad-overlay, [class*="ad-list-"], [class*="ad-position-"], .piano-top-uniweb, .button, .breadcrumb, .header-socials, .header-controls, .header-desktop-navigation, .header-static, .header-sensor, .footer__categories, .footer__contacts, .footer__copyright-link, .article-detail__discussion, .article-gallery-strip__item--photo-count, .article-detail__social-share-buttons, .article-detail-tags, .article-detail__top-info-icon-wrapper, .article-card, .article-detail__top-gjvp-video, .article-video, .landscape-16by9-wrapper, .glide__arrows, .author-card__arrow-wrapper, .subscription *, .list-articles-wrapper--vertical, .list-articles-wrapper--horizontal, .list-article-comments, .list-article-author, .megamenu, .megamenu-backdrop, .promobox, .wiki-search, .premium-extras, .accordion__icon, .pagination, .topic, aside, [id="piano_clanek_pod_uniweb"], [id="piano-zamek-za-registraci-custom"] {
    display: none !important;
  }

  .print-only {
    display: initial !important;
  }
}

:root {
  --white: #fff;
  --grey-50: #f8f8f8;
  --grey-100: #f2f2f2;
  --grey-200: #e7e7e7;
  --grey-300: #d7d7d7;
  --grey-400: #b3b3b3;
  --grey-500: #939393;
  --grey-600: #6b6b6b;
  --grey-700: #575757;
  --grey-800: #393939;
  --grey-900: #191919;
  --black: #000;
  --pink-light: #ffeef1;
  --pink: #fce5e9;
  --pink-alt: #fbe0e5;
  --pink-dark: #e4294a;
  --red-light: #ff272f;
  --red: #df0028;
  --red-dark: #bc0012;
  --red-dark-alt: #bb0525;
  --error-alert-background: var(--pink-light);
  --error-alert-content: #d32e2f;
  --error-contrast: var(--white);
  --error-dark: #b71a1c;
  --error-light: #ef5250;
  --error-main: #d32e2f;
  --error-states-contained-hover-background: #d32e2f;
  --error-states-outlined-hover-background: #fce5e9;
  --error-states-outlined-resting-border: #da4f50;
  --warning-alert-background: #fff8e9;
  --warning-alert-content: #d67300;
  --warning-contrast: var(--grey-900);
  --warning-dark: #f9a825;
  --warning-light: #fdd835;
  --warning-main: #fbc02d;
  --warning-states-contained-hover-background: #d2a229;
  --warning-states-outlined-hover-background: #feecc0;
  --warning-states-outlined-resting-border: #fcca4f;
  --info-alert-background: #eff8ff;
  --info-alert-content: #2979ff;
  --info-contrast: var(--white);
  --info-dark: #004599;
  --info-light: #2196f3;
  --info-main: #1764c0;
  --info-states-contained-hover-background: #004599;
  --info-states-outlined-hover-background: #e3f2fd;
  --info-states-outlined-resting-border: #1565c0;
  --success-alert-background: #f4f8f5;
  --success-alert-content: #388e3b;
  --success-contrast: var(--white);
  --success-dark: #1b5e1f;
  --success-light: #4caf50;
  --success-main: #388e3b;
  --success-states-contained-hover-background: #2f6b31;
  --success-states-outlined-hover-background: #dbebdc;
  --success-states-outlined-resting-border: #58a05a;
  --green: green;
  --orange: #f66533;
  --primary-main: var(--red);
  --primary-light: var(--red-light);
  --primary-dark: var(--red-dark);
  --primary-contrast: var(--white);
  --primary-contained-hover-background: var(--red-dark-alt);
  --primary-outlined-hover-background: var(--pink);
  --primary-outlined-resting-border: var(--pink-dark);
  --secondary-main: var(--grey-500);
  --secondary-light: var(--grey-300);
  --secondary-dark: var(--grey-600);
  --secondary-contrast: var(--white);
  --secondary-contained-hover-background: var(--grey-700);
  --secondary-outlined-hover-background: var(--grey-200);
  --secondary-outlined-resting-border: var(--grey-400);
  --text-primary: var(--grey-900);
  --text-secondary: var(--grey-600);
  --text-disabled: var(--grey-400);
  --action-active: var(--grey-600);
  --action-hover: var(--grey-50);
  --action-selected: var(--pink-alt);
  --action-disabled: var(--grey-400);
  --action-disabled-background: var(--grey-100);
  --action-focus: var(--grey-200);
  --background-paper: var(--grey-900);
  --background-paper-contrast: var(--white);
  --background-default: var(--white);
  --other-divider: var(--grey-200);
  --other-outline-border: var(--grey-300);
  --other-filled-background: var(--grey-200);
  --other-backdrop-overlay: var(--grey-600);
  --other-common-white: var(--white);
  --other-common-black: var(--grey-900);
  --other-premium-brand: var(--orange);
  --surface-neutral-darker: var(--grey-900);
  --surface-default-disabled: var(--action-disabled-background);
  --digiarena-50: #f5f8f7;
  --digiarena-100: #ddeae8;
  --digiarena-200: #bad5d3;
  --digiarena-300: #90b8b6;
  --digiarena-400: #699897;
  --digiarena-500: #558787;
  --digiarena-600: #3d6464;
  --digiarena-700: #345051;
  --digiarena-800: #283839;
  --digiarena-900: #132020;
  --reflex-red-50: #fff0f3;
  --reflex-red-100: #ffdde3;
  --reflex-red-200: #ffc1cc;
  --reflex-red-300: #ff95a8;
  --reflex-red-400: #ff5977;
  --reflex-red-500: #ff264d;
  --reflex-red-600: #fc0632;
  --reflex-red-700: #df0028;
  --reflex-red-800: #af0523;
  --reflex-red-900: #900c24;
  --reflex-red-950: #50000e;
  --reflex-gray-50: #f6f6f6;
  --reflex-gray-100: #e7e7e7;
  --reflex-gray-200: #d1d1d1;
  --reflex-gray-300: #b0b0b0;
  --reflex-gray-400: #888;
  --reflex-gray-500: #6d6d6d;
  --reflex-gray-600: #5d5d5d;
  --reflex-gray-700: #4f4f4f;
  --reflex-gray-800: #454545;
  --reflex-gray-900: #3d3d3d;
  --reflex-gray-950: #191919;
  --zeny-gray-50: #f5f5f6;
  --zeny-gray-100: #e5e5e8;
  --zeny-gray-200: #cfced3;
  --zeny-gray-300: #acacb4;
  --zeny-gray-400: #83838d;
  --zeny-gray-500: #686872;
  --zeny-gray-600: #595961;
  --zeny-gray-700: #4e4d54;
  --zeny-gray-800: #444248;
  --zeny-gray-900: #3c3a3f;
  --zeny-gray-950: #252428;
  --zeny-salmon-50: #fff4f1;
  --zeny-salmon-100: #ffe8e1;
  --zeny-salmon-200: #ffd4c7;
  --zeny-salmon-300: #ffb6a0;
  --zeny-salmon-400: #ff9e81;
  --zeny-salmon-500: #f8663b;
  --zeny-salmon-600: #e54b1d;
  --zeny-salmon-700: #c13c14;
  --zeny-salmon-800: #a03414;
  --zeny-salmon-900: #843118;
  --zeny-salmon-950: #481607;
  --moje-psychologie-purple-50: #f8f7fb;
  --moje-psychologie-purple-100: #f2f0f7;
  --moje-psychologie-purple-200: #e7e4f0;
  --moje-psychologie-purple-300: #d4cee4;
  --moje-psychologie-purple-400: #bdb1d4;
  --moje-psychologie-purple-500: #b1a0ca;
  --moje-psychologie-purple-600: #9277b0;
  --moje-psychologie-purple-700: #80649d;
  --moje-psychologie-purple-800: #6b5483;
  --moje-psychologie-purple-900: #59466c;
  --moje-psychologie-purple-950: #3a2d48;
  --moje-psychologie-nepal-50: #f4f7fa;
  --moje-psychologie-nepal-100: #e6edf3;
  --moje-psychologie-nepal-200: #d3dfea;
  --moje-psychologie-nepal-300: #b5cadb;
  --moje-psychologie-nepal-400: #95b1ca;
  --moje-psychologie-nepal-500: #7796ba;
  --moje-psychologie-nepal-600: #6581ab;
  --moje-psychologie-nepal-700: #59709c;
  --moje-psychologie-nepal-800: #4d5d80;
  --moje-psychologie-nepal-900: #414e67;
  --moje-psychologie-nepal-950: #2b3240;
  --moje-psychologie-sundown-50: #fcf4f4;
  --moje-psychologie-sundown-100: #f8e8e8;
  --moje-psychologie-sundown-200: #f3d5d5;
  --moje-psychologie-sundown-300: #e7abac;
  --moje-psychologie-sundown-400: #dd8c8d;
  --moje-psychologie-sundown-500: #cc6769;
  --moje-psychologie-sundown-600: #b74b4d;
  --moje-psychologie-sundown-700: #993c3e;
  --moje-psychologie-sundown-800: #7f3536;
  --moje-psychologie-sundown-900: #6b3132;
  --moje-psychologie-sundown-950: #391617;
  --e15-red-50: #fff1f0;
  --e15-red-100: #ffe9e7;
  --e15-red-200: #fdafa7;
  --e15-red-300: #ffaaa0;
  --e15-red-400: #ff7869;
  --e15-red-500: #f93822;
  --e15-red-600: #e7301b;
  --e15-red-700: #c32412;
  --e15-red-800: #a12213;
  --e15-red-900: #852217;
  --e15-red-950: #490d06;
  --e15-blue-50: #eef9ff;
  --e15-blue-100: #dcf3ff;
  --e15-blue-200: #b2eaff;
  --e15-blue-300: #6ddaff;
  --e15-blue-400: #00b2ff;
  --e15-blue-500: #008edf;
  --e15-blue-600: #0071b4;
  --e15-blue-700: #006094;
  --e15-blue-800: #00517e;
  --e15-blue-900: #003251;
  --e15-blue-950: #00111d;
  --auto-red-50: #fff1f2;
  --auto-red-100: #ffdfe1;
  --auto-red-200: #ffc5c8;
  --auto-red-300: #ff9ca1;
  --auto-red-400: #ff636a;
  --auto-red-500: #ff323c;
  --auto-red-600: #df0f19;
  --auto-red-700: #c90c15;
  --auto-red-800: #a60e15;
  --auto-red-900: #891319;
  --auto-red-950: #4b0407;
  --auto-gray-1-50: #f7f7f7;
  --auto-gray-1-100: #ededec;
  --auto-gray-1-200: #dfdfde;
  --auto-gray-1-300: #c6c6c5;
  --auto-gray-1-400: #aeaeac;
  --auto-gray-1-500: #999998;
  --auto-gray-1-600: #898987;
  --auto-gray-1-700: #7b7b7a;
  --auto-gray-1-800: #676766;
  --auto-gray-1-900: #545454;
  --auto-gray-1-950: #363635;
  --auto-gray-2-50: #f8f8f7;
  --auto-gray-2-100: #efefef;
  --auto-gray-2-200: #e3e3e2;
  --auto-gray-2-300: #bebebb;
  --auto-gray-2-400: #9a9a96;
  --auto-gray-2-500: #7e7e79;
  --auto-gray-2-600: #666663;
  --auto-gray-2-700: #535351;
  --auto-gray-2-800: #474745;
  --auto-gray-2-900: #3e3e3c;
  --auto-gray-2-950: #292928;
  --lide-a-zeme-blue-50: #effaff;
  --lide-a-zeme-blue-100: #def3ff;
  --lide-a-zeme-blue-200: #b6eaff;
  --lide-a-zeme-blue-300: #75dbff;
  --lide-a-zeme-blue-400: #2ccaff;
  --lide-a-zeme-blue-500: #00aeef;
  --lide-a-zeme-blue-600: #0090d4;
  --lide-a-zeme-blue-700: #0073ab;
  --lide-a-zeme-blue-800: #00608d;
  --lide-a-zeme-blue-900: #065074;
  --lide-a-zeme-blue-950: #04334d;
  --lide-a-zeme-yellow-50: #ffffea;
  --lide-a-zeme-yellow-100: #fffbc5;
  --lide-a-zeme-yellow-200: #fff885;
  --lide-a-zeme-yellow-300: #ffee46;
  --lide-a-zeme-yellow-400: #ffdf1b;
  --lide-a-zeme-yellow-500: #f7ba00;
  --lide-a-zeme-yellow-600: #e29400;
  --lide-a-zeme-yellow-700: #bb6902;
  --lide-a-zeme-yellow-800: #985108;
  --lide-a-zeme-yellow-900: #7c420b;
  --lide-a-zeme-yellow-950: #482200;
  --blesk-red-50: #fff1f1;
  --blesk-red-100: #ffdfe0;
  --blesk-red-200: #ffc5c7;
  --blesk-red-300: #ff9da0;
  --blesk-red-400: #ff656a;
  --blesk-red-500: #fe353c;
  --blesk-red-600: #ed1c23;
  --blesk-red-700: #c70e14;
  --blesk-red-800: #a41015;
  --blesk-red-900: #881418;
  --blesk-red-950: #4a0507;
  --blesk-gray-50: #e8e9ea;
  --blesk-gray-100: #d0d3d5;
  --blesk-gray-200: #b9bcc0;
  --blesk-gray-300: #a2a6ab;
  --blesk-gray-400: #8b9096;
  --blesk-gray-500: #737a81;
  --blesk-gray-600: #5c646c;
  --blesk-gray-700: #454d57;
  --blesk-gray-800: #2d3742;
  --blesk-gray-900: #16212d;
  --blesk-gray-950: #0f171f;
  --isport-iron-50: #f8fafc;
  --isport-iron-100: #f1f5f9;
  --isport-iron-200: #d6dae1;
  --isport-iron-300: #b3bbc6;
  --isport-iron-400: #929eaf;
  --isport-iron-500: #7d899c;
  --isport-iron-600: #687588;
  --isport-iron-700: #586479;
  --isport-iron-800: #52596b;
  --isport-iron-900: #3f425f;
  --isport-iron-950: #0f1120;
  --isport-spring-50: #effef6;
  --isport-spring-100: #d9ffec;
  --isport-spring-200: #b6fcda;
  --isport-spring-300: #7ef7bd;
  --isport-spring-400: #30e890;
  --isport-spring-500: #15d279;
  --isport-spring-600: #0ba85e;
  --isport-spring-700: #0d884e;
  --isport-spring-800: #106b41;
  --isport-spring-900: #0f5837;
  --isport-spring-950: #02311c;
  --isport-aquamarine-50: #edfefe;
  --isport-aquamarine-100: #d2fbfa;
  --isport-aquamarine-200: #aaf6f7;
  --isport-aquamarine-300: #70ecf0;
  --isport-aquamarine-400: #4ddfe6;
  --isport-aquamarine-500: #12bdc8;
  --isport-aquamarine-600: #18a3aa;
  --isport-aquamarine-700: #167a88;
  --isport-aquamarine-800: #1b626f;
  --isport-aquamarine-900: #1b525e;
  --isport-aquamarine-950: #0c3640;
  --isport-brick-50: #fff4ed;
  --isport-brick-100: #ffe6d4;
  --isport-brick-200: #ffcaa8;
  --isport-brick-300: #ffa472;
  --isport-brick-400: #fe7439;
  --isport-brick-500: #fd4506;
  --isport-brick-600: #ee3308;
  --isport-brick-700: #c52309;
  --isport-brick-800: #9c1d10;
  --isport-brick-900: #7e1b10;
  --isport-brick-950: #440906;
  --isport-flame-50: #fef2f2;
  --isport-flame-100: #fee2e3;
  --isport-flame-200: #fecacc;
  --isport-flame-300: #fda4a7;
  --isport-flame-400: #f97075;
  --isport-flame-500: #ef2f36;
  --isport-flame-600: #de242b;
  --isport-flame-700: #ba1b21;
  --isport-flame-800: #9a1a1f;
  --isport-flame-900: #801c20;
  --isport-flame-950: #46090b;
  --isport-iron-950-alfa-50: #0f112000;
  --isport-iron-950-alfa-100: #0f11200d;
  --isport-iron-950-alfa-200: #0f11201a;
  --isport-iron-950-alfa-300: #0f112033;
  --isport-iron-950-alfa-400: #0f11204d;
  --isport-iron-950-alfa-500: #0f112066;
  --isport-iron-950-alfa-600: #0f112080;
  --isport-iron-950-alfa-700: #0f112099;
  --isport-iron-950-alfa-800: #0f1120b2;
  --isport-iron-950-alfa-900: #0f1120cc;
  --isport-iron-950-alfa-950: #0f1120e5;
  --isport-iron-950-alfa-1000: #0f1120;
  --isport-lime: #30e890;
  --isport-azure: #4ddfe6;
  --tipsport-tip-blue-50: #eefaff;
  --tipsport-tip-blue-100: #f1f5fd;
  --tipsport-tip-blue-200: #c5daf8;
  --tipsport-tip-blue-300: #8fdeff;
  --tipsport-tip-blue-400: #5ac8ff;
  --tipsport-tip-blue-500: #33acfe;
  --tipsport-tip-blue-600: #1d8ff3;
  --tipsport-tip-blue-700: #167be8;
  --tipsport-tip-blue-800: #185fb5;
  --tipsport-tip-blue-900: #1a518e;
  --tipsport-tip-blue-950: #143357;
  --tipsport-tip-orange-50: #fff9ed;
  --tipsport-tip-orange-100: #fff2d4;
  --tipsport-tip-orange-200: #ffe1a8;
  --tipsport-tip-orange-300: #ffca70;
  --tipsport-tip-orange-400: #ffa737;
  --tipsport-tip-orange-500: #ff8e13;
  --tipsport-tip-orange-600: #f07006;
  --tipsport-tip-orange-700: #c75407;
  --tipsport-tip-orange-800: #9e420e;
  --tipsport-tip-orange-900: #7f380f;
  --tipsport-tip-orange-950: #451a05;
  --tipsport-gray-50: #e8e9ea;
  --tipsport-gray-100: #d0d3d5;
  --tipsport-gray-200: #b9bcc0;
  --tipsport-gray-300: #a2a6ab;
  --tipsport-gray-400: #8b9096;
  --tipsport-gray-500: #737a81;
  --tipsport-gray-600: #5c646c;
  --tipsport-gray-700: #454d57;
  --tipsport-gray-800: #2d3742;
  --tipsport-gray-900: #16212d;
  --tipsport-gray-950: #0f171f;
  --poggers-radical-red-50: #fff1f3;
  --poggers-radical-red-100: #fee5e7;
  --poggers-radical-red-200: #fdced6;
  --poggers-radical-red-300: #fba6b4;
  --poggers-radical-red-400: #f8748d;
  --poggers-radical-red-500: #ef355e;
  --poggers-radical-red-600: #dd2153;
  --poggers-radical-red-700: #ba1646;
  --poggers-radical-red-800: #9c1541;
  --poggers-radical-red-900: #85163d;
  --poggers-radical-red-950: #4a071d;
  --poggers-tangaroa-50: #eef6ff;
  --poggers-tangaroa-100: #dfeeff;
  --poggers-tangaroa-200: #c6ddff;
  --poggers-tangaroa-300: #a3c7fe;
  --poggers-tangaroa-400: #7ea5fb;
  --poggers-tangaroa-500: #6083f4;
  --poggers-tangaroa-600: #425ce9;
  --poggers-tangaroa-700: #3449ce;
  --poggers-tangaroa-800: #2d3fa6;
  --poggers-tangaroa-900: #2c3c83;
  --poggers-tangaroa-950: #141a3b;
  --poggers-tangaroa-950-50: #141a3b80;
  --poggers-finn-50: #fbf4fa;
  --poggers-finn-100: #f8ebf5;
  --poggers-finn-200: #f3d7ee;
  --poggers-finn-300: #eab7e0;
  --poggers-finn-400: #dd89ca;
  --poggers-finn-500: #cf65b3;
  --poggers-finn-600: #bb4798;
  --poggers-finn-700: #a0367c;
  --poggers-finn-800: #852f67;
  --poggers-finn-900: #582245;
  --poggers-finn-950: #431433;
  --poggers-revolver-50: #f2f0fd;
  --poggers-revolver-100: #e8e4fb;
  --poggers-revolver-200: #d5cdf8;
  --poggers-revolver-300: #bdaff2;
  --poggers-revolver-400: #ab8fea;
  --poggers-revolver-500: #9b73e1;
  --poggers-revolver-600: #8f58d3;
  --poggers-revolver-700: #7d49b9;
  --poggers-revolver-800: #663d96;
  --poggers-revolver-900: #533877;
  --poggers-revolver-950: #2c1d3e;
  --blesk-mobil-red-50: #fff0f1;
  --blesk-mobil-red-100: #ffdee0;
  --blesk-mobil-red-200: #ffc3c7;
  --blesk-mobil-red-300: #ff999f;
  --blesk-mobil-red-400: #ff5e67;
  --blesk-mobil-red-500: #ff2b37;
  --blesk-mobil-red-600: #f60c1a;
  --blesk-mobil-red-700: #e30613;
  --blesk-mobil-red-800: #ab0913;
  --blesk-mobil-red-900: #8d0f16;
  --blesk-mobil-red-950: #4d0206;
  --blesk-mobil-gray-50: #f6f6f5;
  --blesk-mobil-gray-100: #e7e7e6;
  --blesk-mobil-gray-200: #d1d1d0;
  --blesk-mobil-gray-300: #b1b0af;
  --blesk-mobil-gray-400: #898887;
  --blesk-mobil-gray-500: #6e6d6c;
  --blesk-mobil-gray-600: #5e5d5c;
  --blesk-mobil-gray-700: #50504e;
  --blesk-mobil-gray-800: #464644;
  --blesk-mobil-gray-900: #403f3e;
  --blesk-mobil-gray-950: #262626;
  --blesk-mobil-yellow-50: #fdffe7;
  --blesk-mobil-yellow-100: #f8ffc1;
  --blesk-mobil-yellow-200: #f6ff86;
  --blesk-mobil-yellow-300: #f9ff41;
  --blesk-mobil-yellow-400: #fffb0d;
  --blesk-mobil-yellow-500: #ffed00;
  --blesk-mobil-yellow-600: #e5b909;
  --blesk-mobil-yellow-700: #b66c05;
  --blesk-mobil-yellow-800: #89630a;
  --blesk-mobil-yellow-900: #74500f;
  --blesk-mobil-yellow-950: #442b04;
  --maminka-pink-50: #fce8ee;
  --maminka-pink-100: #f7bbcc;
  --maminka-pink-200: #f18daa;
  --maminka-pink-300: #eb6088;
  --maminka-pink-400: #e63366;
  --maminka-pink-500: #cc194d;
  --maminka-pink-600: #9f143c;
  --maminka-pink-700: #720e2b;
  --maminka-pink-800: #44081a;
  --maminka-pink-900: #2d0611;
  --maminka-pink-950: #170309;
  --maminka-gray-50: #f2f2f2;
  --maminka-gray-100: #d9d9d9;
  --maminka-gray-200: #bfbfbf;
  --maminka-gray-300: #a6a6a6;
  --maminka-gray-400: #8c8c8c;
  --maminka-gray-500: #737373;
  --maminka-gray-600: #595959;
  --maminka-gray-700: #404040;
  --maminka-gray-800: #262626;
  --maminka-gray-900: #1a1a1a;
  --maminka-gray-950: #0d0d0d;
  --color-yellow-50: #fff9eb;
  --color-yellow-100: #fdeec8;
  --color-yellow-200: #fcd98b;
  --color-yellow-300: #fac04f;
  --color-yellow-400: #f9a825;
  --color-yellow-500: #f3850d;
  --color-yellow-600: #d76208;
  --color-yellow-700: #b2420b;
  --color-yellow-800: #91330f;
  --color-yellow-900: #772a10;
  --color-yellow-950: #441304;
  --color-white-0: #fffffe00;
  --color-white-50: #fffffe0d;
  --color-white-100: #fffffe1a;
  --color-white-200: #fffffe33;
  --color-white-300: #fffffe4d;
  --color-white-400: #fffffe66;
  --color-white-500: #fffffe80;
  --color-white-600: #fffffe99;
  --color-white-700: #fffffeb2;
  --color-white-800: #fffffecc;
  --color-white-900: #fffffee5;
  --color-white-950: #fffffef2;
  --color-white-1000: #fffffe;
  --color-orange-50: #fef6ee;
  --color-orange-100: #fdebd7;
  --color-orange-200: #fad2ae;
  --color-orange-300: #f7b27a;
  --color-orange-400: #f38844;
  --color-orange-500: #ef6820;
  --color-orange-600: #e04f16;
  --color-orange-700: #ba3a14;
  --color-orange-800: #942f18;
  --color-orange-900: #772917;
  --color-orange-950: #40120a;
  --color-red-50: #fdf3f3;
  --color-red-100: #fde3e3;
  --color-red-200: #fbcdcd;
  --color-red-300: #f8a9a9;
  --color-red-400: #f17879;
  --color-red-500: #e74c4d;
  --color-red-600: #d32e2f;
  --color-red-700: #b12425;
  --color-red-800: #932122;
  --color-red-900: #7a2223;
  --color-red-950: #420d0d;
  --color-pink-50: #fdf2fa;
  --color-pink-100: #fce7f7;
  --color-pink-200: #fccef2;
  --color-pink-300: #faa7e5;
  --color-pink-400: #f670d2;
  --color-pink-500: #ee46bc;
  --color-pink-600: #dd259d;
  --color-pink-700: #c01680;
  --color-pink-800: #9f156a;
  --color-pink-900: #84175a;
  --color-pink-950: #510634;
  --color-purple-50: #faf3ff;
  --color-purple-100: #f3e4ff;
  --color-purple-200: #e9ceff;
  --color-purple-300: #d8a7ff;
  --color-purple-400: #c06fff;
  --color-purple-500: #a938ff;
  --color-purple-600: #9513ff;
  --color-purple-700: #8003ed;
  --color-purple-800: #6d09c0;
  --color-purple-900: #640aab;
  --color-purple-950: #3d0075;
  --color-violet-50: #f4f3ff;
  --color-violet-100: #ebe9fe;
  --color-violet-200: #d9d5ff;
  --color-violet-300: #bbb4fe;
  --color-violet-400: #9a8afb;
  --color-violet-500: #7a5af8;
  --color-violet-600: #6838ef;
  --color-violet-700: #5926db;
  --color-violet-800: #4b1fb8;
  --color-violet-900: #3f1b97;
  --color-violet-950: #250f66;
  --color-blue-50: #eefaff;
  --color-blue-100: #daf3ff;
  --color-blue-200: #bdebff;
  --color-blue-300: #8fe0ff;
  --color-blue-400: #5accff;
  --color-blue-500: #34b1fd;
  --color-blue-600: #2196f3;
  --color-blue-700: #167bdf;
  --color-blue-800: #1863b5;
  --color-blue-900: #1a548e;
  --color-blue-950: #153456;
  --color-cyan-50: #ecfdff;
  --color-cyan-100: #cff8fe;
  --color-cyan-200: #a5f0fc;
  --color-cyan-300: #67e2f9;
  --color-cyan-400: #22cbee;
  --color-cyan-500: #06aed4;
  --color-cyan-600: #088ab2;
  --color-cyan-700: #0e6f90;
  --color-cyan-800: #155a75;
  --color-cyan-900: #164b63;
  --color-cyan-950: #083144;
  --color-teal-50: #effefd;
  --color-teal-100: #c7fffa;
  --color-teal-200: #90fff5;
  --color-teal-300: #51f7ef;
  --color-teal-400: #1de4e0;
  --color-teal-500: #04c8c7;
  --color-teal-600: #00a8ab;
  --color-teal-700: #057d80;
  --color-teal-800: #0a6165;
  --color-teal-900: #0d5154;
  --color-teal-950: #002f33;
  --color-lime-50: #f3fde8;
  --color-lime-100: #e3facd;
  --color-lime-200: #c9f6a0;
  --color-lime-300: #a7ed69;
  --color-lime-400: #86e03b;
  --color-lime-500: #66c61c;
  --color-lime-600: #4c9e12;
  --color-lime-700: #3c7813;
  --color-lime-800: #325f15;
  --color-lime-900: #2c5116;
  --color-lime-950: #132d06;
  --color-green-50: #f3faf3;
  --color-green-100: #e3f5e3;
  --color-green-200: #c8eac9;
  --color-green-300: #9dd89e;
  --color-green-400: #6abe6d;
  --color-green-500: #45a248;
  --color-green-600: #388e3b;
  --color-green-700: #2c692f;
  --color-green-800: #275429;
  --color-green-900: #224524;
  --color-green-950: #0e2510;
  --color-gray-50: #f6f6f6;
  --color-gray-100: #e7e7e7;
  --color-gray-200: #d1d1d1;
  --color-gray-300: #b0b0b0;
  --color-gray-400: #939393;
  --color-gray-500: #6d6d6d;
  --color-gray-600: #5d5d5d;
  --color-gray-700: #4f4f4f;
  --color-gray-800: #454545;
  --color-gray-900: #3d3d3d;
  --color-gray-950: #262626;
  --color-black-50: #0000010d;
  --color-black-100: #0000011a;
  --color-black-200: #00000133;
  --color-black-300: #0000014d;
  --color-black-400: #00000166;
  --color-black-500: #00000180;
  --color-black-600: #00000199;
  --color-black-700: #000001b2;
  --color-black-800: #000001cc;
  --color-black-900: #000001e5;
  --color-black-950: #000001f2;
  --color-black-1000: #000001;
  --color-orange-02-50: #fff4ed;
  --color-orange-02-100: #ffe6d4;
  --color-orange-02-200: #ffc8a8;
  --color-orange-02-300: #ffa271;
  --color-orange-02-400: #ff692e;
  --color-orange-02-500: #fe4a11;
  --color-orange-02-600: #ef3007;
  --color-orange-02-700: #c61f08;
  --color-orange-02-800: #9d1b0f;
  --color-orange-02-900: #7e1a10;
  --color-orange-02-950: #440906;
  --color-red-02-50: #fff1f0;
  --color-red-02-100: #ffdfdd;
  --color-red-02-200: #ffc5c1;
  --color-red-02-300: #ff9d96;
  --color-red-02-400: #ff655a;
  --color-red-02-500: #ff3527;
  --color-red-02-600: #f61404;
  --color-red-02-700: #d40f01;
  --color-red-02-800: #ae1106;
  --color-red-02-900: #90150c;
  --color-red-02-950: #4f0500;
  --color-green-02-50: #f3ffe6;
  --color-green-02-100: #e3fdca;
  --color-green-02-200: #c9fc9a;
  --color-green-02-300: #a6f660;
  --color-green-02-400: #84eb30;
  --color-green-02-500: #65d211;
  --color-green-02-600: #4ba709;
  --color-green-02-700: #3a7f0c;
  --color-green-02-800: #316410;
  --color-green-02-900: #2b5512;
  --color-green-02-950: #132f04;
  --number-scale-0: 0;
  --number-scale-2: 2px;
  --number-scale-4: 4px;
  --number-scale-6: 6px;
  --number-scale-8: 8px;
  --number-scale-10: 10px;
  --number-scale-12: 12px;
  --number-scale-16: 16px;
  --number-scale-20: 20px;
  --number-scale-24: 24px;
  --number-scale-32: 32px;
  --number-scale-40: 40px;
  --number-scale-48: 48px;
  --number-scale-64: 64px;
  --number-scale-72: 72px;
  --number-scale-96: 96px;
  --number-scale-112: 112px;
  --number-scale-9999: 9999px;
  --font-family-roboto: roboto;
  --text-decoration-none: none;
  --text-decoration-underline: underline;
}

@font-face {
  font-family: Heroic Condensed;
  font-style: normal;
  src: url("/dist/assets/default/fonts/heroic-condensed-medium.otf") format("opentype");
}

@font-face {
  font-family: Manrope Regular;
  font-style: normal;
  src: url("/dist/assets/default/fonts/manrope-regular.woff2") format("woff2");
}

@font-face {
  font-family: Manrope Medium;
  font-style: normal;
  src: url("/dist/assets/default/fonts/manrope-medium.woff2") format("woff2");
}

@font-face {
  font-family: Manrope SemiBold;
  font-style: normal;
  src: url("/dist/assets/default/fonts/manrope-semibold.woff2") format("woff2");
}

@font-face {
  font-family: Manrope Bold;
  font-style: normal;
  src: url("/dist/assets/default/fonts/manrope-bold.woff2") format("woff2");
}

@font-face {
  font-family: Oswald Medium;
  font-style: normal;
  src: url("/dist/assets/default/fonts/oswald-medium.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Regular;
  font-style: normal;
  src: url("/dist/assets/default/fonts/roboto-regular.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Bold;
  font-style: normal;
  src: url("/dist/assets/default/fonts/roboto-bold.woff2") format("woff2");
}

:root {
  --oswald-medium: "Oswald Medium", "Arial", "Helvetica", sans-serif;
  --roboto-regular: "Roboto Regular", "Arial", "Helvetica", sans-serif;
  --roboto-bold: "Roboto Bold", "Arial", "Helvetica", sans-serif;
  --heading-3xl-font-family: var(--oswald-medium);
  --heading-2xl-font-family: var(--oswald-medium);
  --heading-xl-font-family: var(--oswald-medium);
  --heading-lg-font-family: var(--oswald-medium);
  --heading-md-font-family: var(--oswald-medium);
  --heading-sm-font-family: var(--oswald-medium);
  --heading-xs-font-family: var(--oswald-medium);
  --heading-2xs-font-family: var(--oswald-medium);
  --inter-title-font-family: var(--oswald-medium);
  --section-title-font-family: var(--oswald-medium);
  --subtitle-lg-font-family: var(--roboto-regular);
  --subtitle-lg-bold-font-family: var(--roboto-bold);
  --subtitle-md-font-family: var(--roboto-regular);
  --subtitle-md-bold-font-family: var(--roboto-bold);
  --body-lg-font-family: var(--roboto-regular);
  --body-lg-bold-font-family: var(--roboto-bold);
  --body-md-font-family: var(--roboto-regular);
  --body-md-bold-font-family: var(--roboto-bold);
  --body-sm-font-family: var(--roboto-regular);
  --body-sm-bold-font-family: var(--roboto-bold);
  --body-xs-font-family: var(--roboto-regular);
  --body-xs-bold-font-family: var(--roboto-bold);
  --button-lg-font-family: var(--oswald-medium);
  --button-md-font-family: var(--oswald-medium);
  --button-sm-font-family: var(--oswald-medium);
  --menu-item-font-family: var(--oswald-medium);
  --label-font-family: var(--roboto-regular);
  --helper-font-family: var(--roboto-regular);
  --font-mobile-h1: var(--typography-mobile-heading-3xl-font-size) / var(--typography-mobile-heading-3xl-line-height) var(--heading-3xl-font-family);
  --font-mobile-h2: var(--typography-mobile-heading-xl-font-size) / var(--typography-mobile-heading-xl-line-height) var(--heading-xl-font-family);
  --font-mobile-h3: var(--typography-mobile-heading-lg-font-size) / var(--typography-mobile-heading-lg-line-height) var(--heading-lg-font-family);
  --font-mobile-h4: var(--typography-mobile-heading-md-font-size) / var(--typography-mobile-heading-md-line-height) var(--heading-md-font-family);
  --font-mobile-h5: var(--typography-mobile-heading-sm-font-size) / var(--typography-mobile-heading-sm-line-height) var(--heading-sm-font-family);
  --font-mobile-inter-title: var(--typography-mobile-inter-title-font-size) / var(--typography-mobile-inter-title-line-height) var(--inter-title-font-family);
  --font-mobile-section-title: var(--typography-mobile-section-title-font-size) / var(--typography-mobile-section-title-line-height) var(--section-title-font-family);
  --font-mobile-subtitle1: var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  --font-mobile-subtitle1-bold: var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  --font-mobile-subtitle2: var(--typography-mobile-subtitle-md-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-font-family);
  --font-mobile-subtitle2-bold: var(--typography-mobile-subtitle-md-bold-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  --font-mobile-body1: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-font-family);
  --font-mobile-body1-bold: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-bold-font-family);
  --font-mobile-body2: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-font-family);
  --font-mobile-body2-bold: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-bold-font-family);
  --font-mobile-meta-regular: var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-font-family);
  --font-mobile-meta-bold: var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-bold-font-family);
  --font-mobile-button-large: var(--typography-mobile-button-lg-font-size) / var(--typography-mobile-button-lg-line-height) var(--button-lg-font-family);
  --font-mobile-button-medium: var(--typography-mobile-button-md-font-size) / var(--typography-mobile-button-md-line-height) var(--button-md-font-family);
  --font-mobile-button-small: var(--typography-mobile-button-sm-font-size) / var(--typography-mobile-button-sm-line-height) var(--button-sm-font-family);
  --font-mobile-menu-item: var(--typography-mobile-menu-item-font-size) / var(--typography-mobile-menu-item-line-height) var(--menu-item-font-family);
  --font-mobile-label: var(--typography-mobile-label-font-size) / var(--typography-mobile-label-line-height) var(--label-font-family);
  --font-mobile-helper-text: var(--typography-mobile-helper-text-font-size) / var(--typography-mobile-helper-text-line-height) var(--helper-text-font-family);
  --font-desktop-h1: var(--typography-desktop-heading-3xl-font-size) / var(--typography-desktop-heading-3xl-line-height) var(--heading-3xl-font-family);
  --font-desktop-h2: var(--typography-desktop-heading-xl-font-size) / var(--typography-desktop-heading-xl-line-height) var(--heading-xl-font-family);
  --font-desktop-h3: var(--typography-desktop-heading-lg-font-size) / var(--typography-desktop-heading-lg-line-height) var(--heading-lg-font-family);
  --font-desktop-h4: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
  --font-desktop-h5: var(--typography-desktop-heading-sm-font-size) / var(--typography-desktop-heading-sm-line-height) var(--heading-sm-font-family);
  --font-desktop-inter-title: var(--typography-desktop-inter-title-font-size) / var(--typography-desktop-inter-title-line-height) var(--inter-title-font-family);
  --font-desktop-section-title: var(--typography-desktop-section-title-font-size) / var(--typography-desktop-section-title-line-height) var(--section-title-font-family);
  --font-desktop-subtitle1: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  --font-desktop-subtitle1-bold: var(--typography-desktop-subtitle-lg-font-size) / var(--typography-desktop-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  --font-desktop-subtitle2: var(--typography-desktop-subtitle-md-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-font-family);
  --font-desktop-subtitle2-bold: var(--typography-desktop-subtitle-md-bold-font-size) / var(--typography-desktop-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  --font-desktop-body1: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-font-family);
  --font-desktop-body1-bold: var(--typography-desktop-body-lg-font-size) / var(--typography-desktop-body-lg-line-height) var(--body-lg-bold-font-family);
  --font-desktop-body2: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
  --font-desktop-body2-bold: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-bold-font-family);
  --font-desktop-meta-regular: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-font-family);
  --font-desktop-meta-bold: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  --font-desktop-button-large: var(--typography-desktop-button-lg-font-size) / var(--typography-desktop-button-lg-line-height) var(--button-lg-font-family);
  --font-desktop-button-medium: var(--typography-desktop-button-md-font-size) / var(--typography-desktop-button-md-line-height) var(--button-md-font-family);
  --font-desktop-button-small: var(--typography-desktop-button-sm-font-size) / var(--typography-desktop-button-sm-line-height) var(--button-sm-font-family);
  --font-desktop-menu-item: var(--typography-desktop-menu-item-font-size) / var(--typography-desktop-menu-item-line-height) var(--menu-item-font-family);
  --font-desktop-label: var(--typography-desktop-label-font-size) / var(--typography-desktop-label-line-height) var(--label-font-family);
  --font-desktop-helper-text: var(--typography-desktop-helper-text-font-size) / var(--typography-desktop-helper-text-line-height) var(--helper-text-font-family);
}

:root {
  --yellows-100: #fdf1cc;
  --yellows-200: #fce399;
  --yellows-300: #fad666;
  --yellows-400: #f9c833;
  --yellows-500: #f7ba00;
  --yellows-600: #c69500;
  --yellows-700: #947000;
  --yellows-800: #634a00;
  --yellows-900: #312500;
  --green: green;
  --blue-100: #cceffc;
  --blue-200: #99dff9;
  --blue-300: #66cef5;
  --blue-400: #33bef2;
  --blue-500: #00aeef;
  --blue-600: #008bbf;
  --blue-700: #00688f;
  --blue-800: #004660;
  --blue-900: #002330;
  --orange: #f66533;
  --primary-main: var(--blue-500);
  --primary-light: var(--blue-400);
  --primary-dark: var(--blue-700);
  --primary-contrast: var(--white);
  --primary-contained-hover-background: var(--blue-600);
  --primary-outlined-hover-background: var(--blue-100);
  --primary-outlined-resting-border: var(--blue-300);
  --secondary-main: var(--yellows-500);
  --secondary-light: var(--yellows-400);
  --secondary-dark: var(--yellows-700);
  --secondary-contrast: var(--white);
  --secondary-contained-hover-background: var(--yellows-600);
  --secondary-outlined-hover-background: var(--yellows-100);
  --secondary-outlined-resting-border: var(--yellows-300);
  --action-selected: var(--blue-100);
  --action-focus: var(--blue-100);
  --color-surface-article-card-background: var(--color-surface-gradients-white-0);
  --color-surface-article-card-desktop-xl-v01-title-default: var(--color-white-0);
  --color-surface-article-card-desktop-xl-v01-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-xl-v02-title-default: var(--color-white-0);
  --color-surface-article-card-desktop-xl-v02-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-2xl-v01-elements-default: var(--color-white-0);
  --color-surface-article-card-desktop-2xl-v01-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-lg-v01-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-md-v01-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-md-v02-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-md-other-v01-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-md-other-v02-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-sm-v01-card-default: var(--color-white-0);
  --color-surface-article-card-desktop-xs-v01-card-default: var(--color-white-0);
  --color-surface-article-card-mobile-lg-v1-card-default: var(--color-white-0);
  --color-surface-article-card-mobile-xl-v01-card-default: var(--color-white-0);
  --color-surface-article-card-mobile-xl-v02-card-default: var(--color-white-0);
  --color-surface-article-card-mobile-md-other-v01-card-default: var(--color-white-0);
  --color-surface-article-card-mobile-md-other-v02-card-default: var(--color-white-0);
  --color-surface-article-card-mobile-md-v01-card-default: var(--color-white-0);
  --color-surface-article-card-xs-v01-card-default: var(--color-white-0);
  --color-surface-application-card-background: var(--color-white-1000);
  --color-surface-button-primary-solid-inverted-contained-default: var(--themes-action-inverted-main);
  --color-surface-button-primary-solid-inverted-contained-hover: var(--themes-action-inverted-dark);
  --color-surface-button-primary-solid-inverted-contained-focus: var(--themes-action-inverted-dark);
  --color-surface-button-primary-solid-inverted-contained-active: var(--themes-action-inverted-darker);
  --color-surface-button-primary-solid-inverted-contained-disabled: var(--themes-action-inverted-light);
  --color-surface-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-inverted-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-inverted-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-normal-contained-default: var(--themes-action-primary-main);
  --color-surface-button-primary-solid-normal-contained-hover: var(--themes-action-primary-dark);
  --color-surface-button-primary-solid-normal-contained-focus: var(--themes-action-primary-dark);
  --color-surface-button-primary-solid-normal-contained-active: var(--themes-action-primary-light);
  --color-surface-button-primary-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-primary-solid-normal-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-normal-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-normal-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-solid-normal-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-inverted-contained-default: var(--themes-action-inverted-main);
  --color-surface-button-primary-gradient-inverted-contained-hover: var(--themes-action-inverted-dark);
  --color-surface-button-primary-gradient-inverted-contained-focus: var(--themes-action-inverted-dark);
  --color-surface-button-primary-gradient-inverted-contained-active: var(--themes-action-inverted-darker);
  --color-surface-button-primary-gradient-inverted-contained-disabled: var(--themes-action-inverted-light);
  --color-surface-button-primary-gradient-inverted-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-inverted-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-inverted-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-normal-contained-default: var(--themes-action-primary-main);
  --color-surface-button-primary-gradient-normal-contained-hover: var(--themes-action-primary-dark);
  --color-surface-button-primary-gradient-normal-contained-focus: var(--themes-action-primary-dark);
  --color-surface-button-primary-gradient-normal-contained-active: var(--themes-action-primary-light);
  --color-surface-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-primary-gradient-normal-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-normal-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-normal-outlined-default: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-normal-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-primary-gradient-normal-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-secondary-solid-normal-contained-default: var(--themes-action-secondary-main);
  --color-surface-button-secondary-solid-normal-contained-hover: var(--themes-action-secondary-dark);
  --color-surface-button-secondary-solid-normal-contained-focus: var(--themes-action-secondary-dark);
  --color-surface-button-secondary-solid-normal-contained-active: var(--themes-action-secondary-light);
  --color-surface-button-secondary-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-subtle);
  --color-surface-button-secondary-solid-normal-outlined-focus: var(--themes-action-secondary-subtle);
  --color-surface-button-secondary-solid-normal-text-hover: var(--themes-action-secondary-subtle);
  --color-surface-button-secondary-solid-normal-text-focus: var(--themes-action-secondary-subtle);
  --color-surface-button-info-contained-default: var(--themes-info-main);
  --color-surface-button-info-contained-hover: var(--themes-info-dark);
  --color-surface-button-info-contained-focus: var(--themes-info-dark);
  --color-surface-button-info-contained-active: var(--themes-info-light);
  --color-surface-button-info-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-info-outlined-hover: var(--themes-info-subtle);
  --color-surface-button-info-outlined-focus: var(--themes-info-subtle);
  --color-surface-button-info-text-hover: var(--themes-info-subtle);
  --color-surface-button-info-text-focus: var(--themes-info-subtle);
  --color-surface-button-error-contained-default: var(--themes-error-main);
  --color-surface-button-error-contained-hover: var(--themes-error-dark);
  --color-surface-button-error-contained-focus: var(--themes-error-dark);
  --color-surface-button-error-contained-active: var(--themes-error-light);
  --color-surface-button-error-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-error-outlined-hover: var(--themes-error-subtle);
  --color-surface-button-error-outlined-focus: var(--themes-error-subtle);
  --color-surface-button-error-text-hover: var(--themes-error-subtle);
  --color-surface-button-error-text-focus: var(--themes-error-subtle);
  --color-surface-button-social-login-default: var(--color-white-1000);
  --color-surface-button-social-login-hover: var(--themes-neutral-subtle);
  --color-surface-button-social-login-focus: var(--color-white-1000);
  --color-surface-button-social-login-active: var(--themes-brand-primary-subtle);
  --color-surface-button-social-login-disabled: var(--themes-disabled-subtle);
  --color-surface-content-top-background: var(--color-white-0);
  --color-surface-content-top-body-background: var(--color-white-1000);
  --color-surface-play-button-default: var(--themes-action-primary-main);
  --color-surface-play-button-hover: var(--themes-action-primary-dark);
  --color-surface-play-button-focus: var(--themes-action-primary-dark);
  --color-surface-play-button-active: var(--themes-action-primary-light);
  --color-surface-play-button-disabled: var(--themes-disabled-lighter);
  --color-surface-badge-success: var(--themes-success-main);
  --color-surface-badge-warning: var(--themes-warning-main);
  --color-surface-badge-error: var(--themes-error-main);
  --color-surface-badge-info: var(--themes-info-main);
  --color-surface-badge-disabled: var(--themes-disabled-subtle);
  --color-surface-badge-secondary: var(--themes-brand-secondary-main);
  --color-surface-badge-primary: var(--themes-brand-primary-main);
  --color-surface-badgepill-soft-success: var(--themes-success-subtle);
  --color-surface-badgepill-soft-warning: var(--themes-warning-subtle);
  --color-surface-badgepill-soft-error: var(--themes-error-subtle);
  --color-surface-badgepill-soft-info: var(--themes-info-subtle);
  --color-surface-badgepill-soft-disabled: var(--themes-disabled-subtle);
  --color-surface-badgepill-soft-secondary: var(--themes-brand-secondary-subtle);
  --color-surface-badgepill-soft-primary: var(--themes-brand-primary-subtle);
  --color-surface-badgepill-solid-success: var(--themes-success-light);
  --color-surface-badgepill-solid-warning: var(--themes-warning-light);
  --color-surface-badgepill-solid-error: var(--themes-error-light);
  --color-surface-badgepill-solid-info: var(--themes-info-light);
  --color-surface-badgepill-solid-disabled: var(--themes-disabled-light);
  --color-surface-badgepill-solid-secondary: var(--themes-brand-secondary-main);
  --color-surface-badgepill-solid-primary: var(--themes-brand-primary-main);
  --color-surface-chip-inverted-contained-default: var(--themes-action-inverted-subtle);
  --color-surface-chip-inverted-contained-hover: var(--themes-action-inverted-lighter);
  --color-surface-chip-inverted-contained-focus: var(--themes-action-inverted-dark);
  --color-surface-chip-inverted-contained-active: var(--themes-action-inverted-dark);
  --color-surface-chip-inverted-contained-disabled: var(--themes-action-inverted-subtle);
  --color-surface-chip-primary-contained-default: var(--themes-neutral-subtle);
  --color-surface-chip-primary-contained-hover: var(--themes-action-primary-subtle);
  --color-surface-chip-primary-contained-focus: var(--themes-action-primary-subtle);
  --color-surface-chip-primary-contained-active: var(--themes-action-primary-subtle);
  --color-surface-chip-primary-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-chip-primary-outlined-default: var(--color-white-1000);
  --color-surface-chip-primary-outlined-hover: var(--color-white-1000);
  --color-surface-chip-primary-outlined-focus: var(--themes-action-primary-main);
  --color-surface-chip-primary-outlined-active: var(--themes-action-primary-main);
  --color-surface-chip-primary-outlined-disabled: var(--color-white-1000);
  --color-surface-chip-secondary-contained-default: var(--themes-neutral-subtle);
  --color-surface-chip-secondary-contained-hover: var(--themes-neutral-subtle);
  --color-surface-chip-secondary-contained-focus: var(--themes-action-secondary-main);
  --color-surface-chip-secondary-contained-active: var(--themes-action-secondary-main);
  --color-surface-chip-secondary-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-chip-secondary-outlined-default: var(--color-white-1000);
  --color-surface-chip-secondary-outlined-hover: var(--color-white-1000);
  --color-surface-chip-secondary-outlined-focus: var(--themes-action-secondary-darker);
  --color-surface-chip-secondary-outlined-active: var(--themes-action-secondary-darker);
  --color-surface-chip-secondary-outlined-disabled: var(--color-white-1000);
  --color-surface-default-page: var(--color-white-1000);
  --color-surface-default-subtle: var(--themes-neutral-subtle);
  --color-surface-default-homepage: var(--color-white-0);
  --color-surface-default-inverted: var(--themes-neutral-strong);
  --color-surface-default-content-top: var(--themes-neutral-strong);
  --color-surface-default-color: #fff;
  --color-surface-gallery-arrows-default: var(--color-black-500);
  --color-surface-gallery-arrows-hover: var(--color-black-950);
  --color-surface-gradients-white-0: var(--color-white-0);
  --color-surface-gradients-gray: var(--themes-neutral-light);
  --color-surface-header-default: var(--color-white-1000);
  --color-surface-header-inverted: var(--color-white-1000);
  --color-surface-icon-button-brand-solid-normal-contained-default: var(--themes-action-primary-main);
  --color-surface-icon-button-brand-solid-normal-contained-hover: var(--themes-action-primary-dark);
  --color-surface-icon-button-brand-solid-normal-contained-focus: var(--themes-action-primary-dark);
  --color-surface-icon-button-brand-solid-normal-contained-active: var(--themes-action-primary-light);
  --color-surface-icon-button-brand-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-icon-button-brand-solid-normal-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-normal-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-normal-text-hover: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-normal-text-focus: var(--themes-action-primary-subtle);
  --color-surface-icon-button-brand-solid-inverted-contained-default: var(--themes-action-inverted-main);
  --color-surface-icon-button-brand-solid-inverted-contained-hover: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-contained-focus: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-contained-active: var(--themes-action-inverted-darker);
  --color-surface-icon-button-brand-solid-inverted-contained-disabled: var(--themes-action-inverted-light);
  --color-surface-icon-button-brand-solid-inverted-outlined-hover: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-outlined-focus: var(--themes-action-inverted-dark);
  --color-surface-icon-button-brand-solid-inverted-outlined-active: var(--themes-action-inverted-darker);
  --color-surface-icon-button-brand-solid-inverted-text-hover: var(--themes-action-inverted-subtle);
  --color-surface-icon-button-brand-solid-inverted-text-focus: var(--themes-action-inverted-subtle);
  --color-surface-icon-button-brand-solid-inverted-text-active: var(--themes-action-inverted-lighter);
  --color-surface-icon-button-secondary-solid-normal-contained-default: var(--themes-action-secondary-darker);
  --color-surface-icon-button-secondary-solid-normal-contained-hover: var(--themes-action-secondary-strong);
  --color-surface-icon-button-secondary-solid-normal-contained-focus: var(--themes-action-secondary-strong);
  --color-surface-icon-button-secondary-solid-normal-contained-active: var(--themes-action-secondary-light);
  --color-surface-icon-button-secondary-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-icon-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-secondary-solid-normal-outlined-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-secondary-solid-normal-text-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-secondary-solid-normal-text-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-solid-normal-contained-default: var(--themes-action-secondary-main);
  --color-surface-icon-button-primary-solid-normal-contained-hover: var(--themes-action-secondary-dark);
  --color-surface-icon-button-primary-solid-normal-contained-focus: var(--themes-action-secondary-dark);
  --color-surface-icon-button-primary-solid-normal-contained-active: var(--themes-action-secondary-light);
  --color-surface-icon-button-primary-solid-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-icon-button-primary-solid-normal-outlined-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-solid-normal-outlined-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-solid-normal-text-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-solid-normal-text-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-solid-inverted-contained-default: var(--themes-action-secondary-on-secondary);
  --color-surface-icon-button-primary-solid-inverted-contained-hover: var(--themes-action-primary-main);
  --color-surface-icon-button-primary-solid-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-surface-icon-button-primary-solid-inverted-contained-active: var(--themes-action-primary-dark);
  --color-surface-icon-button-primary-solid-inverted-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-icon-button-primary-gradient-normal-contained-default: var(--themes-action-secondary-main);
  --color-surface-icon-button-primary-gradient-normal-contained-hover: var(--themes-action-secondary-dark);
  --color-surface-icon-button-primary-gradient-normal-contained-focus: var(--themes-action-secondary-dark);
  --color-surface-icon-button-primary-gradient-normal-contained-active: var(--themes-action-secondary-light);
  --color-surface-icon-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-icon-button-primary-gradient-normal-outlined-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-gradient-normal-outlined-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-gradient-normal-text-hover: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-gradient-normal-text-focus: var(--themes-action-secondary-subtle);
  --color-surface-icon-button-primary-gradient-inverted-contained-default: var(--themes-action-secondary-on-secondary);
  --color-surface-icon-button-primary-gradient-inverted-contained-hover: var(--themes-action-secondary-dark);
  --color-surface-icon-button-primary-gradient-inverted-contained-focus: var(--themes-action-secondary-dark);
  --color-surface-icon-button-primary-gradient-inverted-contained-active: var(--themes-action-secondary-light);
  --color-surface-icon-button-primary-gradient-inverted-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-pagination-default: var(--color-white-1000);
  --color-surface-pagination-hover: var(--themes-neutral-subtle);
  --color-surface-pagination-active: var(--color-white-1000);
  --color-surface-pagination-focus: var(--themes-action-primary-subtle);
  --color-surface-pagination-disabled: var(--color-white-1000);
  --color-surface-premium-card-normal-normal: var(--themes-neutral-subtle);
  --color-surface-premium-card-inverted-best: var(--themes-neutral-strong);
  --color-surface-premium-card-inverted-vip: var(--color-violet-950);
  --color-surface-premium-card-dark: var(--themes-neutral-strong);
  --color-surface-premium-card-subtle: var(--themes-neutral-subtle);
  --color-surface-tag-solid-normal-default: var(--color-white-1000);
  --color-surface-tag-solid-normal-hover: var(--color-white-1000);
  --color-surface-tag-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-tag-solid-normal-active: var(--themes-action-primary-main);
  --color-surface-tag-solid-normal-disabled: var(--themes-disabled-subtle);
  --color-surface-tag-solid-inverted-default: var(--color-white-1000);
  --color-surface-tag-solid-inverted-hover: var(--color-white-1000);
  --color-surface-tag-solid-inverted-focus: var(--themes-action-primary-subtle);
  --color-surface-tag-solid-inverted-active: var(--themes-action-primary-main);
  --color-surface-tag-solid-inverted-disabled: var(--themes-disabled-subtle);
  --color-surface-tag-gradient-normal-default: var(--color-white-1000);
  --color-surface-tag-gradient-normal-hover: var(--color-white-1000);
  --color-surface-tag-gradient-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-tag-gradient-normal-active: var(--themes-action-primary-main);
  --color-surface-tag-gradient-normal-disabled: var(--themes-disabled-subtle);
  --color-surface-tag-gradient-inverted-default: var(--color-white-1000);
  --color-surface-tag-gradient-inverted-hover: var(--color-white-1000);
  --color-surface-tag-gradient-inverted-focus: var(--themes-action-primary-subtle);
  --color-surface-tag-gradient-inverted-active: var(--themes-action-primary-main);
  --color-surface-tag-gradient-inverted-disabled: var(--themes-disabled-subtle);
  --color-surface-textlink-background: var(--color-surface-gradients-white-0);
  --color-surface-textlink-contained-gradient-default: var(--color-white-0);
  --color-surface-textlink-contained-gradient-hover: var(--color-white-0);
  --color-surface-textlink-contained-gradient-active: var(--color-white-0);
  --color-surface-textlink-contained-gradient-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-contained-gradient-disabled: var(--color-white-0);
  --color-surface-textlink-contained-solid-default: var(--color-white-0);
  --color-surface-textlink-contained-solid-hover: var(--color-white-0);
  --color-surface-textlink-contained-solid-active: var(--color-white-0);
  --color-surface-textlink-contained-solid-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-contained-solid-disabled: var(--color-white-0);
  --color-surface-textlink-category-gradient-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-category-solid-hover: var(--color-white-0);
  --color-surface-textlink-category-solid-active: var(--color-white-0);
  --color-surface-textlink-category-solid-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-category-solid-disabled: var(--color-white-0);
  --color-surface-textlink-menu-item-gradient-default: var(--color-white-0);
  --color-surface-textlink-menu-item-gradient-hover: var(--color-white-0);
  --color-surface-textlink-menu-item-gradient-active: var(--color-white-0);
  --color-surface-textlink-menu-item-gradient-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-menu-item-gradient-disabled: var(--color-white-0);
  --color-surface-textlink-menu-item-solid-default: var(--color-white-0);
  --color-surface-textlink-menu-item-solid-hover: var(--color-white-0);
  --color-surface-textlink-menu-item-solid-active: var(--color-white-0);
  --color-surface-textlink-menu-item-solid-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-menu-item-solid-disabled: var(--color-white-0);
  --color-surface-textlink-stand-alone-solid-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-megamenu-solid-default: var(--color-white-0);
  --color-surface-textlink-megamenu-solid-hover: var(--color-white-0);
  --color-surface-textlink-megamenu-solid-active: var(--color-white-0);
  --color-surface-textlink-megamenu-solid-focus: var(--color-surface-action-primary-subtle);
  --color-surface-textlink-megamenu-solid-disabled: var(--color-white-0);
  --color-surface-video-strip-inverted-background: var(--themes-neutral-strong);
  --color-surface-video-strip-normal-background: var(--themes-neutral-subtle);
  --color-surface-topics-background-normal: var(--themes-neutral-subtle);
  --color-surface-ads-background: #f6f6f6;
  --color-surface-avatar-background: var(--themes-neutral-subtle);
  --color-surface-avatar-photo-background: var(--color-white-1000);
  --color-surface-info-wrapper-background: var(--color-black-700);
  --color-surface-stepper-finished: var(--themes-brand-primary-main);
  --color-surface-stepper-hover: var(--themes-brand-primary-darker);
  --color-surface-stepper-active: var(--color-white-1000);
  --color-surface-stepper-inactive: var(--color-white-1000);
  --color-surface-video-box-inverted-background: var(--color-surface-video-strip-inverted-background);
  --color-surface-video-box-normal-background: var(--color-surface-video-strip-normal-background);
  --color-surface-comments-box-background: var(--themes-accent-subtle);
  --color-surface-comments-card-background: var(--themes-accent-subtle);
  --color-surface-label-link-gradient-contained-default: var(--themes-action-primary-main);
  --color-surface-label-link-gradient-contained-hover: var(--themes-action-primary-light);
  --color-surface-label-link-gradient-contained-active: var(--themes-action-primary-dark);
  --color-surface-label-link-gradient-contained-focus: var(--themes-action-primary-subtle);
  --color-surface-label-link-gradient-contained-disabled: var(--themes-neutral-subtle);
  --color-surface-label-link-gradient-text-focus: var(--themes-action-primary-subtle);
  --color-surface-label-link-solid-contained-default: var(--themes-action-primary-main);
  --color-surface-label-link-solid-contained-hover: var(--themes-action-primary-light);
  --color-surface-label-link-solid-contained-active: var(--themes-action-primary-dark);
  --color-surface-label-link-solid-contained-focus: var(--themes-action-primary-subtle);
  --color-surface-label-link-solid-contained-disabled: var(--themes-neutral-subtle);
  --color-surface-label-link-solid-text-focus: var(--themes-action-primary-subtle);
  --color-surface-megamenu-link-title-focus: var(--themes-action-primary-subtle);
  --color-surface-quotation-box-default: var(--color-white-1000);
  --color-surface-vote-strip-default: var(--themes-neutral-subtle);
  --color-surface-vote-strip-hover: var(--color-white-1000);
  --color-surface-vote-strip-answer: var(--themes-brand-primary-less-subtle);
  --color-surface-survey-background: var(--themes-brand-primary-less-subtle);
  --color-surface-text-link-stand-alone-primary-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-stand-alone-primary-solid-inverted-focus: var(--themes-action-primary-dark);
  --color-surface-text-link-stand-alone-primary-gradient-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-stand-alone-primary-gradient-inverted-focus: var(--themes-action-primary-dark);
  --color-surface-text-link-stand-alone-secondary-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-stand-alone-secondary-solid-inverted-focus: var(--themes-action-primary-dark);
  --color-surface-text-link-stand-alone-secondary-gradient-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-stand-alone-secondary-gradient-inverted-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-primary-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-primary-solid-inverted-focus: var(--themes-action-primary-dark);
  --color-surface-text-link-hyperlink-primary-gradient-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-primary-gradient-inverted-focus: var(--themes-action-primary-dark);
  --color-surface-text-link-hyperlink-secondary-solid-normal-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-secondary-solid-inverted-focus: var(--themes-action-primary-dark);
  --color-surface-text-link-hyperlink-secondary-gradient-solid-focus: var(--themes-action-primary-subtle);
  --color-surface-text-link-hyperlink-secondary-gradient-inverted-focus: var(--themes-action-primary-subtle);
  --color-surface-dropdown-default: var(--color-white-1000);
  --color-surface-dropdown-hover: var(--themes-neutral-subtle);
  --color-surface-dropdown-active: var(--themes-brand-primary-subtle);
  --color-surface-dropdown-focus: var(--themes-brand-primary-subtle);
  --color-surface-dropdown-disabled: var(--themes-disabled-subtle);
  --color-surface-megamenu-default: var(--color-white-1000);
  --color-surface-search-field-normal-default: var(--color-surface-default-subtle);
  --color-surface-search-field-normal-hover: var(--color-surface-default-subtle);
  --color-surface-search-field-normal-focus: var(--color-surface-default-contrast);
  --color-surface-search-field-normal-filed: var(--color-surface-default-contrast);
  --color-surface-search-field-inverted-default: var(--color-surface-default-strong);
  --color-surface-search-field-inverted-hover: var(--color-surface-default-strong);
  --color-surface-search-field-inverted-focus: var(--color-surface-default-contrast);
  --color-surface-search-field-inverted-filled: var(--color-surface-default-subtle);
  --color-surface-footer-normal-background: var(--color-surface-default-strong);
  --color-surface-footer-inverted-background: var(--themes-neutral-subtle);
  --color-surface-table-data-cell-subtle: var(--themes-neutral-subtle);
  --color-surface-table-header-cell-default: var(--themes-brand-primary-less-subtle);
  --color-surface-table-subheader-cell-default: var(--themes-brand-primary-subtle);
  --color-surface-gallery-strip-default: var(--color-surface-default-strong);
  --color-surface-author-card-list-background: var(--themes-neutral-subtle);
  --color-surface-modal-default: var(--color-white-1000);
  --color-surface-content-box-default: var(--themes-neutral-subtle);
  --color-surface-registration-lock-background: var(--color-surface-brand-primary-subtle);
  --color-surface-registration-lock-lock-top: var(--color-surface-brand-primary-strong);
  --color-surface-registration-lock-lock-background: var(--color-icon-default-main);
  --color-surface-registration-lock-lock-background-2: var(--color-surface-brand-primary-dark);
  --color-surface-registration-default-light: var(--color-white-1000);
  --color-surface-registration-default-subtle: var(--themes-neutral-subtle);
  --color-surface-button-betting-primary-normal-contained-default: var(--themes-action-primary-main);
  --color-surface-button-betting-primary-normal-contained-hover: var(--themes-action-primary-dark);
  --color-surface-button-betting-primary-normal-contained-focus: var(--themes-action-primary-dark);
  --color-surface-button-betting-primary-normal-contained-active: var(--themes-action-primary-light);
  --color-surface-button-betting-primary-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-betting-primary-normal-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-outlined-default: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-outlined-disabled: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-text-default: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-text-disabled: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-normal-text-active: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-contained-default: var(--themes-action-primary-main);
  --color-surface-button-betting-primary-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-surface-button-betting-primary-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-surface-button-betting-primary-inverted-contained-active: var(--themes-action-primary-light);
  --color-surface-button-betting-primary-inverted-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-betting-primary-inverted-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-outlined-disabled: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-text-default: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-text-disabled: var(--themes-action-primary-subtle);
  --color-surface-button-betting-primary-inverted-text-active: var(--themes-action-primary-subtle);
  --color-surface-button-betting-secondary-normal-contained-default: var(--themes-action-primary-main);
  --color-surface-button-betting-secondary-normal-contained-hover: var(--themes-action-primary-dark);
  --color-surface-button-betting-secondary-normal-contained-focus: var(--themes-action-primary-dark);
  --color-surface-button-betting-secondary-normal-contained-active: var(--themes-action-primary-light);
  --color-surface-button-betting-secondary-normal-contained-disabled: var(--themes-disabled-subtle);
  --color-surface-button-betting-secondary-normal-outlined-hover: var(--themes-action-primary-subtle);
  --color-surface-button-betting-secondary-normal-outlined-focus: var(--themes-action-primary-subtle);
  --color-surface-button-betting-secondary-normal-outlined-default: var(--themes-action-primary-subtle);
  --color-surface-button-betting-secondary-normal-outlined-disabled: var(--themes-action-primary-subtle);
  --color-surface-button-betting-secondary-normal-text-hover: var(--themes-action-primary-subtle);
  --color-surface-button-betting-secondary-normal-text-focus: var(--themes-action-primary-subtle);
  --color-surface-button-betting-secondary-normal-text-default: var(--themes-action-primary-subtle);
  --color-surface-results-default: var(--color-white-1000);
  --color-surface-results-hover: var(--themes-neutral-lighter);
  --color-surface-title-section-normal: var(--color-white-1000);
  --color-surface-title-section-inverted: var(--color-white-1000);
  --color-surface-rank-circle: var(--color-white-1000);
  --color-surface-rank-square: var(--color-white-1000);
  --color-surface-rank-hexagon: var(--color-white-1000);
  --color-surface-result-cells-row: var(--color-white-1000);
  --color-surface-result-cells-subheader: var(--color-white-1000);
  --color-surface-goal-cells-row: var(--color-white-1000);
  --color-surface-goal-cells-subheader: var(--color-white-1000);
  --color-surface-sticky-down-background: var(--themes-neutral-subtle);
  --color-surface-discussion-box-background: var(--themes-neutral-strong);
  --color-surface-discussion-card-background: var(--color-white-1000);
  --color-surface-result-row-default: var(--color-white-1000);
  --color-surface-result-row-hover: var(--color-white-1000);
  --color-surface-premium-box-background: var(--themes-neutral-strong);
  --color-surface-newsletter-box-background: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-default: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-inverted-default: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-inverted-hover: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-inverted-focus: var(--color-white-1000);
  --color-surface-checkbox-default-unchecked-inverted-disabled: var(--color-white-1000);
  --color-surface-checkbox-default-checked-normal-default: var(--themes-brand-primary-main);
  --color-surface-checkbox-default-checked-normal-hover: var(--themes-brand-primary-main);
  --color-surface-checkbox-default-checked-normal-focus: var(--themes-brand-primary-main);
  --color-surface-checkbox-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-checkbox-default-checked-inverted-default: var(--themes-brand-primary-less-subtle);
  --color-surface-checkbox-default-checked-inverted-hover: var(--themes-brand-primary-less-subtle);
  --color-surface-checkbox-default-checked-inverted-focus: var(--themes-brand-primary-less-subtle);
  --color-surface-checkbox-default-checked-inverted-disabled: var(--themes-disabled-main);
  --color-surface-checkbox-error-unchecked-normal-default: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-inverted-default: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-inverted-hover: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-inverted-focus: var(--color-white-1000);
  --color-surface-checkbox-error-unchecked-inverted-disabled: var(--color-white-1000);
  --color-surface-checkbox-error-checked-normal-default: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-normal-hover: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-normal-focus: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-checkbox-error-checked-inverted-default: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-inverted-hover: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-inverted-focus: var(--themes-error-dark);
  --color-surface-checkbox-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-surface-commentary-box-background: var(--color-white-1000);
  --color-surface-commentary-box-subheader: var(--color-green-02-50);
  --color-surface-filter-normal-background: var(--color-white-1000);
  --color-surface-filter-inverted-background: var(--themes-neutral-strong);
  --color-surface-top-event-default: var(--color-white-1000);
  --color-surface-input-normal-default: var(--color-white-1000);
  --color-surface-input-normal-hover: var(--color-white-1000);
  --color-surface-input-normal-focus: var(--color-white-1000);
  --color-surface-input-normal-active: var(--color-white-1000);
  --color-surface-input-normal-error: var(--color-white-1000);
  --color-surface-input-normal-disabled: var(--color-white-1000);
  --color-surface-input-inverted-default: var(--color-white-50);
  --color-surface-input-inverted-hover: var(--color-white-50);
  --color-surface-input-inverted-focus: var(--color-white-50);
  --color-surface-input-inverted-active: var(--color-white-50);
  --color-surface-input-inverted-error: var(--color-white-50);
  --color-surface-input-inverted-disabled: var(--color-white-50);
  --color-surface-scrollbar-background: var(--themes-neutral-subtle);
  --color-surface-scrollbar-scrollbar: var(--themes-neutral-light);
  --color-surface-text-area-normal-default: var(--color-white-1000);
  --color-surface-text-area-normal-hover: var(--color-white-1000);
  --color-surface-text-area-normal-focus: var(--color-white-1000);
  --color-surface-text-area-normal-active: var(--color-white-1000);
  --color-surface-text-area-normal-error: var(--color-white-1000);
  --color-surface-text-area-normal-disabled: var(--color-white-1000);
  --color-surface-text-area-inverted-default: var(--color-white-1000);
  --color-surface-text-area-inverted-hover: var(--color-white-1000);
  --color-surface-text-area-inverted-focus: var(--color-white-1000);
  --color-surface-text-area-inverted-active: var(--color-white-1000);
  --color-surface-text-area-inverted-error: var(--color-white-1000);
  --color-surface-text-area-inverted-disabled: var(--color-white-1000);
  --color-surface-tipsport-bet-background: var(--color-white-1000);
  --color-surface-card-percent-progressbar-empty: var(--color-white-1000);
  --color-surface-card-percent-progressbar-fill: var(--color-white-1000);
  --color-surface-card-percent-card-default: var(--color-white-1000);
  --color-surface-card-percent-card-hover: var(--color-white-800);
  --color-surface-card-ticket-card: var(--color-white-1000);
  --color-surface-card-ticket-background: var(--color-white-1000);
  --color-surface-ticketarena-header: var(--color-white-1000);
  --color-surface-ticketarena-background: var(--color-white-1000);
  --color-surface-flash-messages-info-normal-background: var(--themes-info-subtle);
  --color-surface-flash-messages-info-inverted-background: var(--themes-info-main);
  --color-surface-flash-messages-alert-normal-background: var(--themes-error-subtle);
  --color-surface-flash-messages-alert-inverted-background: var(--themes-error-main);
  --color-surface-flash-messages-warning-normal-background: var(--themes-warning-subtle);
  --color-surface-flash-messages-warning-inverted-background: var(--themes-warning-light);
  --color-surface-flash-messages-success-normal-background: var(--themes-success-subtle);
  --color-surface-flash-messages-success-inverted-background: var(--themes-success-main);
  --color-surface-info-bar-video-background: var(--themes-neutral-strong);
  --color-surface-lineup-background: var(--color-white-1000);
  --color-surface-lineup-playground: var(--color-white-1000);
  --color-surface-lineup-player: var(--color-white-1000);
  --color-surface-lineup-shadow: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-normal-default: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-default: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-hover: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-focus: var(--color-white-1000);
  --color-surface-radiobutton-default-unchecked-inverted-disabled: var(--color-white-1000);
  --color-surface-radiobutton-default-checked-normal-default: var(--themes-brand-primary-main);
  --color-surface-radiobutton-default-checked-normal-hover: var(--themes-brand-primary-main);
  --color-surface-radiobutton-default-checked-normal-focus: var(--themes-brand-primary-main);
  --color-surface-radiobutton-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-radiobutton-default-checked-inverted-default: var(--themes-brand-primary-less-subtle);
  --color-surface-radiobutton-default-checked-inverted-hover: var(--themes-brand-primary-less-subtle);
  --color-surface-radiobutton-default-checked-inverted-focus: var(--themes-brand-primary-less-subtle);
  --color-surface-radiobutton-default-checked-inverted-disabled: var(--themes-disabled-main);
  --color-surface-radiobutton-error-unchecked-normal-default: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-normal-hover: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-normal-focus: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-normal-disabled: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-default: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-hover: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-focus: var(--color-white-1000);
  --color-surface-radiobutton-error-unchecked-inverted-disabled: var(--color-white-1000);
  --color-surface-radiobutton-error-checked-normal-default: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-normal-hover: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-normal-focus: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-surface-radiobutton-error-checked-inverted-default: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-inverted-hover: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-inverted-focus: var(--themes-error-dark);
  --color-surface-radiobutton-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-surface-stats-background: var(--color-white-1000);
  --color-surface-stats-better: var(--color-white-1000);
  --color-surface-stats-worse: var(--color-white-1000);
  --color-surface-tipsport-table-header: var(--color-white-1000);
  --color-surface-tipsport-table-content: var(--color-white-1000);
  --color-surface-tipsport-table-background: var(--color-white-1000);
  --color-surface-results-card-default: var(--color-white-1000);
  --color-surface-results-card-hover: var(--color-white-800);
  --color-surface-schedule-default: var(--color-white-1000);
  --color-surface-schedule-hover: var(--themes-neutral-lighter);
  --color-surface-result-module-card-default: var(--color-white-1000);
  --color-surface-result-module-card-hover: var(--themes-neutral-lighter);
  --color-surface-result-module-default: var(--color-white-1000);
  --color-surface-team-card-default: var(--color-white-1000);
  --color-surface-team-card-hover: var(--color-white-800);
  --color-surface-videoplayer-background: var(--reflex-gray-950);
  --color-surface-tooltip-background: var(--themes-neutral-strong);
  --color-surface-funds-card-default: var(--color-white-1000);
  --color-surface-funds-card-hover: var(--color-white-800);
  --color-surface-segmented-controls-default: var(--color-white-1000);
  --color-surface-segmented-controls-hover: var(--themes-neutral-subtle);
  --color-surface-segmented-controls-focus: var(--themes-neutral-subtle);
  --color-surface-segmented-controls-active: var(--themes-brand-primary-main);
  --color-surface-segmented-controls-disbled: var(--color-white-1000);
  --color-text-video-card-dark-title: var(--themes-neutral-subtle);
  --color-text-video-card-light-title: var(--themes-neutral-strong);
  --color-text-video-box-dark-title: var(--color-text-video-card-dark-title);
  --color-text-video-box-light-title: var(--color-text-video-card-light-title);
  --color-text-button-primary-solid-inverted-contained-default: var(--themes-action-primary-main);
  --color-text-button-primary-solid-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-text-button-primary-solid-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-text-button-primary-solid-inverted-contained-active: var(--themes-action-primary-dark);
  --color-text-button-primary-solid-inverted-contained-disabled: var(--themes-action-inverted-darker);
  --color-text-button-primary-solid-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-text-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-text-button-primary-solid-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-text-button-primary-solid-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-text-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-text-button-primary-solid-inverted-text-default: var(--themes-action-primary-subtle);
  --color-text-button-primary-solid-inverted-text-hover: var(--themes-action-primary-main);
  --color-text-button-primary-solid-inverted-text-focus: var(--themes-action-primary-light);
  --color-text-button-primary-solid-inverted-text-active: var(--themes-action-primary-subtle);
  --color-text-button-primary-solid-inverted-text-disabled: var(--themes-disabled-main);
  --color-text-button-primary-solid-normal-contained-default: var(--themes-action-primary-on-primary);
  --color-text-button-primary-solid-normal-contained-hover: var(--themes-action-primary-on-primary);
  --color-text-button-primary-solid-normal-contained-focus: var(--themes-action-primary-on-primary);
  --color-text-button-primary-solid-normal-contained-active: var(--themes-action-primary-on-primary);
  --color-text-button-primary-solid-normal-contained-disabled: var(--themes-disabled-light);
  --color-text-button-primary-solid-normal-outlined-default: var(--themes-action-primary-main);
  --color-text-button-primary-solid-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-text-button-primary-solid-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-text-button-primary-solid-normal-outlined-active: var(--themes-action-primary-light);
  --color-text-button-primary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-primary-solid-normal-text-default: var(--themes-action-primary-main);
  --color-text-button-primary-solid-normal-text-hover: var(--themes-action-primary-dark);
  --color-text-button-primary-solid-normal-text-focus: var(--themes-action-primary-dark);
  --color-text-button-primary-solid-normal-text-active: var(--themes-action-primary-light);
  --color-text-button-primary-solid-normal-text-disabled: var(--themes-disabled-main);
  --color-text-button-primary-gradient-inverted-contained-default: var(--themes-action-primary-main);
  --color-text-button-primary-gradient-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-text-button-primary-gradient-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-text-button-primary-gradient-inverted-contained-active: var(--themes-action-primary-dark);
  --color-text-button-primary-gradient-inverted-contained-disabled: var(--themes-action-inverted-darker);
  --color-text-button-primary-gradient-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-text-button-primary-gradient-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-text-button-primary-gradient-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-text-button-primary-gradient-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-text-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-text-button-primary-gradient-inverted-text-default: var(--themes-action-primary-subtle);
  --color-text-button-primary-gradient-inverted-text-hover: var(--themes-action-primary-main);
  --color-text-button-primary-gradient-inverted-text-focus: var(--themes-action-primary-light);
  --color-text-button-primary-gradient-inverted-text-active: var(--themes-action-primary-subtle);
  --color-text-button-primary-gradient-inverted-text-disabled: var(--themes-disabled-main);
  --color-text-button-primary-gradient-normal-contained-default: var(--themes-action-primary-on-primary);
  --color-text-button-primary-gradient-normal-contained-hover: var(--themes-action-primary-on-primary);
  --color-text-button-primary-gradient-normal-contained-focus: var(--themes-action-primary-on-primary);
  --color-text-button-primary-gradient-normal-contained-active: var(--themes-action-primary-on-primary);
  --color-text-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-light);
  --color-text-button-primary-gradient-normal-outlined-default: var(--themes-action-primary-main);
  --color-text-button-primary-gradient-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-text-button-primary-gradient-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-text-button-primary-gradient-normal-outlined-active: var(--themes-action-primary-light);
  --color-text-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-primary-gradient-normal-text-default: var(--themes-action-primary-main);
  --color-text-button-primary-gradient-normal-text-hover: var(--themes-action-primary-dark);
  --color-text-button-primary-gradient-normal-text-focus: var(--themes-action-primary-dark);
  --color-text-button-primary-gradient-normal-text-active: var(--themes-action-primary-light);
  --color-text-button-primary-gradient-normal-text-disabled: var(--themes-disabled-light);
  --color-text-button-secondary-contained-default: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-button-secondary-contained-disabled: var(--themes-disabled-light);
  --color-text-button-secondary-outlined-default: var(--themes-action-secondary-main);
  --color-text-button-secondary-outlined-hover: var(--themes-action-secondary-dark);
  --color-text-button-secondary-outlined-focus: var(--themes-action-secondary-dark);
  --color-text-button-secondary-outlined-active: var(--themes-action-secondary-light);
  --color-text-button-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-secondary-text-default: var(--themes-action-secondary-main);
  --color-text-button-secondary-text-hover: var(--themes-action-secondary-dark);
  --color-text-button-secondary-text-focus: var(--themes-action-secondary-dark);
  --color-text-button-secondary-text-active: var(--themes-action-secondary-light);
  --color-text-button-secondary-text-disabled: var(--themes-disabled-light);
  --color-text-button-info-contained-default: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-button-info-contained-disabled: var(--themes-disabled-light);
  --color-text-button-info-outlined-default: var(--themes-info-main);
  --color-text-button-info-outlined-hover: var(--themes-info-dark);
  --color-text-button-info-outlined-focus: var(--themes-info-dark);
  --color-text-button-info-outlined-active: var(--themes-info-light);
  --color-text-button-info-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-info-text-default: var(--themes-info-main);
  --color-text-button-info-text-hover: var(--themes-info-dark);
  --color-text-button-info-text-focus: var(--themes-info-dark);
  --color-text-button-info-text-active: var(--themes-info-light);
  --color-text-button-info-text-disabled: var(--themes-disabled-light);
  --color-text-button-error-contained-default: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-button-error-contained-disabled: var(--themes-disabled-light);
  --color-text-button-error-outlined-default: var(--themes-error-main);
  --color-text-button-error-outlined-hover: var(--themes-error-dark);
  --color-text-button-error-outlined-focus: var(--themes-error-dark);
  --color-text-button-error-outlined-active: var(--themes-error-light);
  --color-text-button-error-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-error-text-default: var(--themes-error-main);
  --color-text-button-error-text-hover: var(--themes-error-dark);
  --color-text-button-error-text-focus: var(--themes-error-dark);
  --color-text-button-error-text-active: var(--themes-error-light);
  --color-text-button-error-text-disabled: var(--themes-disabled-light);
  --color-text-button-social-login-default: var(--themes-neutral-strong);
  --color-text-button-social-login-hover: var(--themes-neutral-strong);
  --color-text-button-social-login-focus: var(--themes-neutral-strong);
  --color-text-button-social-login-active: var(--themes-neutral-strong);
  --color-text-button-social-login-disabled: var(--themes-disabled-light);
  --color-text-default-title: var(--themes-neutral-strong);
  --color-text-default-subtitle: var(--themes-neutral-darker);
  --color-text-default-perex: var(--themes-neutral-strong);
  --color-text-default-body: var(--themes-neutral-strong);
  --color-text-default-meta: var(--themes-neutral-dark);
  --color-text-default-light: var(--themes-neutral-light);
  --color-text-default-inverted: var(--color-white-1000);
  --color-text-article-card-title: var(--color-text-default-title);
  --color-text-article-card-meta: var(--color-text-default-meta);
  --color-text-article-card-perex: var(--color-text-default-body);
  --color-text-article-title-default: var(--color-text-default-title);
  --color-text-article-title-hover: var(--color-text-action-primary-main);
  --color-text-accordion-body-default: var(--themes-neutral-darker);
  --color-text-accordion-title-default: var(--themes-neutral-strong);
  --color-text-accordion-title-hover: var(--themes-action-primary-main);
  --color-text-badge-success: var(--themes-success-on-success);
  --color-text-badge-warning: var(--themes-warning-on-warning);
  --color-text-badge-error: var(--themes-error-on-error);
  --color-text-badge-info: var(--themes-info-on-info);
  --color-text-badge-disabled: var(--themes-disabled-light);
  --color-text-badge-secondary: var(--themes-brand-secondary-on-secondary);
  --color-text-badge-primary: var(--themes-brand-primary-on-primary);
  --color-text-badgepill-soft-success: var(--themes-success-main);
  --color-text-badgepill-soft-warning: var(--themes-warning-dark);
  --color-text-badgepill-soft-error: var(--themes-error-dark);
  --color-text-badgepill-soft-info: var(--themes-info-dark);
  --color-text-badgepill-soft-disabled: var(--themes-disabled-light);
  --color-text-badgepill-soft-secondary: var(--themes-brand-secondary-dark);
  --color-text-badgepill-soft-primary: var(--themes-brand-primary-main);
  --color-text-badgepill-solid-success: var(--themes-success-on-success);
  --color-text-badgepill-solid-warning: var(--themes-warning-on-warning);
  --color-text-badgepill-solid-error: var(--themes-error-on-error);
  --color-text-badgepill-solid-info: var(--themes-info-on-info);
  --color-text-badgepill-solid-disabled: var(--themes-disabled-subtle);
  --color-text-badgepill-solid-secondary: var(--themes-brand-secondary-on-secondary);
  --color-text-badgepill-solid-primary: var(--themes-brand-primary-on-primary);
  --color-text-badgepill-outlined-success: var(--themes-success-main);
  --color-text-badgepill-outlined-warning: var(--themes-warning-dark);
  --color-text-badgepill-outlined-error: var(--themes-error-dark);
  --color-text-badgepill-outlined-info: var(--themes-info-dark);
  --color-text-badgepill-outlined-disabled: var(--themes-disabled-light);
  --color-text-badgepill-outlined-secondary: var(--themes-brand-secondary-dark);
  --color-text-badgepill-outlined-primary: var(--themes-brand-primary-main);
  --color-text-chip-inverted-contained-default: var(--themes-action-inverted-main);
  --color-text-chip-inverted-contained-hover: var(--themes-action-inverted-main);
  --color-text-chip-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-text-chip-inverted-contained-active: var(--themes-action-primary-dark);
  --color-text-chip-inverted-contained-disabled: var(--themes-action-inverted-light);
  --color-text-chip-primary-contained-default: var(--themes-neutral-darker);
  --color-text-chip-primary-contained-hover: var(--themes-neutral-dark);
  --color-text-chip-primary-contained-focus: var(--themes-action-primary-main);
  --color-text-chip-primary-contained-active: var(--themes-action-primary-main);
  --color-text-chip-primary-contained-disabled: var(--themes-disabled-light);
  --color-text-chip-primary-outlined-default: var(--themes-neutral-darker);
  --color-text-chip-primary-outlined-hover: var(--themes-action-primary-main);
  --color-text-chip-primary-outlined-focus: var(--themes-action-primary-on-primary);
  --color-text-chip-primary-outlined-active: var(--themes-action-primary-on-primary);
  --color-text-chip-primary-outlined-disabled: var(--themes-disabled-light);
  --color-text-chip-secondary-contained-default: var(--themes-neutral-darker);
  --color-text-chip-secondary-contained-hover: var(--themes-neutral-dark);
  --color-text-chip-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-contained-disabled: var(--themes-disabled-light);
  --color-text-chip-secondary-outlined-default: var(--themes-neutral-darker);
  --color-text-chip-secondary-outlined-hover: var(--themes-neutral-dark);
  --color-text-chip-secondary-outlined-focus: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-outlined-active: var(--themes-action-secondary-on-secondary);
  --color-text-chip-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-text-divider-label: var(--themes-neutral-main);
  --color-text-link-primary-default: var(--themes-action-primary-main);
  --color-text-link-primary-hover: var(--themes-action-primary-dark);
  --color-text-link-neutral-default: var(--themes-neutral-strong);
  --color-text-link-neutral-hover: var(--themes-neutral-dark);
  --color-text-list-default: var(--themes-neutral-strong);
  --color-text-list-inverted: var(--color-white-1000);
  --color-text-list-positive: var(--themes-neutral-strong);
  --color-text-list-negative: var(--themes-neutral-strong);
  --color-text-pagination-default: var(--themes-neutral-strong);
  --color-text-pagination-hover: var(--themes-neutral-strong);
  --color-text-pagination-active: var(--themes-action-primary-main);
  --color-text-pagination-focus: var(--themes-action-primary-light);
  --color-text-pagination-disabled: var(--themes-neutral-lighter);
  --color-text-premium-card-normal-title: var(--themes-neutral-strong);
  --color-text-premium-card-normal-body: var(--themes-neutral-strong);
  --color-text-premium-card-normal-subtitle: var(--themes-neutral-dark);
  --color-text-premium-card-inverted-title: var(--themes-neutral-subtle);
  --color-text-premium-card-inverted-body: var(--themes-neutral-subtle);
  --color-text-premium-card-inverted-subtitle: var(--themes-neutral-light);
  --color-text-tag-solid-normal-default: var(--themes-neutral-dark);
  --color-text-tag-solid-normal-hover: var(--themes-action-primary-main);
  --color-text-tag-solid-normal-focus: var(--themes-action-primary-main);
  --color-text-tag-solid-normal-active: var(--themes-action-primary-on-primary);
  --color-text-tag-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-tag-solid-inverted-default: var(--themes-neutral-dark);
  --color-text-tag-solid-inverted-hover: var(--themes-action-primary-main);
  --color-text-tag-solid-inverted-focus: var(--themes-action-primary-main);
  --color-text-tag-solid-inverted-active: var(--themes-action-primary-on-primary);
  --color-text-tag-solid-inverted-disabled: var(--themes-disabled-main);
  --color-text-tag-gradient-normal-default: var(--themes-neutral-dark);
  --color-text-tag-gradient-normal-hover: var(--themes-action-primary-main);
  --color-text-tag-gradient-normal-focus: var(--themes-action-primary-main);
  --color-text-tag-gradient-normal-active: var(--themes-action-primary-on-primary);
  --color-text-tag-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-tag-gradient-inverted-default: var(--themes-neutral-dark);
  --color-text-tag-gradient-inverted-hover: var(--themes-action-primary-main);
  --color-text-tag-gradient-inverted-focus: var(--themes-action-primary-main);
  --color-text-tag-gradient-inverted-active: var(--themes-action-primary-on-primary);
  --color-text-tag-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-textlink-menu-item-default: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-hover: var(--themes-action-primary-main);
  --color-text-textlink-menu-item-active: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-menu-item-gradient-default: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-gradient-hover: var(--themes-action-primary-main);
  --color-text-textlink-menu-item-gradient-active: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-gradient-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-gradient-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-menu-item-solid-default: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-solid-hover: var(--themes-action-primary-main);
  --color-text-textlink-menu-item-solid-active: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-solid-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-menu-item-solid-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-category-default: var(--color-text-action-primary-light);
  --color-text-textlink-category-hover: var(--color-text-action-primary-dark);
  --color-text-textlink-category-active: var(--color-text-action-secondary-strong);
  --color-text-textlink-category-focus: var(--color-text-action-secondary-strong);
  --color-text-textlink-category-disabled: var(--themes-disabled-light);
  --color-text-textlink-category-gradient-default: var(--color-text-action-primary-light);
  --color-text-textlink-category-gradient-hover: var(--color-text-action-primary-dark);
  --color-text-textlink-category-gradient-active: var(--color-text-action-secondary-strong);
  --color-text-textlink-category-gradient-focus: var(--color-text-action-secondary-strong);
  --color-text-textlink-category-gradient-disabled: var(--themes-disabled-light);
  --color-text-textlink-category-solid-default: var(--color-text-action-primary-light);
  --color-text-textlink-category-solid-hover: var(--color-text-action-primary-dark);
  --color-text-textlink-category-solid-active: var(--color-text-action-secondary-strong);
  --color-text-textlink-category-solid-focus: var(--color-text-action-secondary-strong);
  --color-text-textlink-category-solid-disabled: var(--themes-disabled-light);
  --color-text-textlink-contained-default: var(--color-text-action-primary-light);
  --color-text-textlink-contained-hover: var(--color-text-action-primary-dark);
  --color-text-textlink-contained-active: var(--color-text-action-secondary-strong);
  --color-text-textlink-contained-focus: var(--color-text-action-secondary-strong);
  --color-text-textlink-contained-disabled: var(--themes-disabled-light);
  --color-text-textlink-contained-gradient-default: var(--color-text-action-primary-light);
  --color-text-textlink-contained-gradient-hover: var(--color-text-action-primary-dark);
  --color-text-textlink-contained-gradient-active: var(--color-text-action-secondary-strong);
  --color-text-textlink-contained-gradient-focus: var(--color-text-action-secondary-strong);
  --color-text-textlink-contained-gradient-disabled: var(--themes-disabled-light);
  --color-text-textlink-contained-solid-default: var(--color-text-action-primary-light);
  --color-text-textlink-contained-solid-hover: var(--color-text-action-primary-dark);
  --color-text-textlink-contained-solid-active: var(--color-text-action-secondary-strong);
  --color-text-textlink-contained-solid-focus: var(--color-text-action-secondary-strong);
  --color-text-textlink-contained-solid-disabled: var(--themes-disabled-light);
  --color-text-textlink-megamenu-default: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-hover: var(--themes-action-primary-main);
  --color-text-textlink-megamenu-active: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-megamenu-gradient-default: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-gradient-hover: var(--themes-action-primary-main);
  --color-text-textlink-megamenu-gradient-active: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-gradient-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-gradient-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-megamenu-solid-default: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-solid-hover: var(--themes-action-primary-main);
  --color-text-textlink-megamenu-solid-active: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-solid-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-megamenu-solid-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-stand-alone-default: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-hover: var(--themes-action-primary-main);
  --color-text-textlink-stand-alone-active: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-stand-alone-gradient-default: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-gradient-hover: var(--themes-action-primary-main);
  --color-text-textlink-stand-alone-gradient-active: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-gradient-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-gradient-disabled: var(--themes-action-secondary-lighter);
  --color-text-textlink-stand-alone-solid-default: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-solid-hover: var(--themes-action-primary-main);
  --color-text-textlink-stand-alone-solid-active: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-solid-focus: var(--themes-action-secondary-strong);
  --color-text-textlink-stand-alone-solid-disabled: var(--themes-action-secondary-lighter);
  --color-text-comments-card-name: var(--themes-brand-primary-main);
  --color-text-comments-card-body: var(--themes-neutral-strong);
  --color-text-comments-box-title: var(--themes-neutral-strong);
  --color-text-comments-box-body: var(--color-white-1000);
  --color-text-text-link-default: var(--themes-neutral-strong);
  --color-text-text-link-hover: var(--themes-action-primary-main);
  --color-text-text-link-focus: var(--themes-neutral-strong);
  --color-text-text-link-disabled: var(--themes-neutral-light);
  --color-text-text-link-stand-alone-primary-solid-normal-default: var(--themes-action-primary-main);
  --color-text-text-link-stand-alone-primary-solid-normal-hover: var(--themes-action-primary-dark);
  --color-text-text-link-stand-alone-primary-solid-normal-focus: var(--themes-action-primary-main);
  --color-text-text-link-stand-alone-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-primary-solid-inverted-default: var(--color-white-1000);
  --color-text-text-link-stand-alone-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-text-text-link-stand-alone-primary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-stand-alone-primary-solid-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-primary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-secondary-solid-normal-default: var(--themes-neutral-strong);
  --color-text-text-link-stand-alone-secondary-solid-normal-hover: var(--themes-action-primary-main);
  --color-text-text-link-stand-alone-secondary-solid-normal-focus: var(--themes-neutral-strong);
  --color-text-text-link-stand-alone-secondary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-secondary-solid-inverted-default: var(--color-white-1000);
  --color-text-text-link-stand-alone-secondary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-text-text-link-stand-alone-secondary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-stand-alone-secondary-solid-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-secondary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-stand-alone-secondary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-primary-solid-normal-default: var(--themes-action-primary-main);
  --color-text-text-link-hyperlink-primary-solid-normal-hover: var(--themes-action-primary-dark);
  --color-text-text-link-hyperlink-primary-solid-normal-focus: var(--themes-action-primary-main);
  --color-text-text-link-hyperlink-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-primary-solid-inverted-default: var(--color-white-1000);
  --color-text-text-link-hyperlink-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-text-text-link-hyperlink-primary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-hyperlink-primary-solid-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-primary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-secondary-solid-normal-default: var(--themes-neutral-strong);
  --color-text-text-link-hyperlink-secondary-solid-normal-hover: var(--themes-action-primary-main);
  --color-text-text-link-hyperlink-secondary-solid-normal-focus: var(--themes-neutral-strong);
  --color-text-text-link-hyperlink-secondary-solid-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-secondary-solid-inverted-default: var(--color-white-1000);
  --color-text-text-link-hyperlink-secondary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-text-text-link-hyperlink-secondary-solid-inverted-focus: var(--color-white-1000);
  --color-text-text-link-hyperlink-secondary-solid-inverted-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-secondary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-text-text-link-hyperlink-secondary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-text-label-link-solid-text-default: var(--themes-action-primary-main);
  --color-text-label-link-solid-text-hover: var(--themes-action-primary-dark);
  --color-text-label-link-solid-text-active: var(--themes-neutral-strong);
  --color-text-label-link-solid-text-focus: var(--themes-neutral-strong);
  --color-text-label-link-solid-text-disabled: var(--themes-neutral-light);
  --color-text-label-link-solid-contained-default: var(--themes-action-primary-subtle);
  --color-text-label-link-solid-contained-hover: var(--themes-action-primary-subtle);
  --color-text-label-link-solid-contained-active: var(--themes-neutral-lighter);
  --color-text-label-link-solid-contained-focus: var(--themes-action-primary-main);
  --color-text-label-link-solid-contained-disabled: var(--themes-neutral-light);
  --color-text-label-link-gradient-text-default: var(--themes-action-primary-main);
  --color-text-label-link-gradient-text-hover: var(--themes-action-primary-dark);
  --color-text-label-link-gradient-text-active: var(--themes-neutral-strong);
  --color-text-label-link-gradient-text-focus: var(--themes-neutral-strong);
  --color-text-label-link-gradient-text-disabled: var(--themes-neutral-light);
  --color-text-label-link-gradient-contained-default: var(--themes-action-primary-subtle);
  --color-text-label-link-gradient-contained-hover: var(--themes-action-primary-subtle);
  --color-text-label-link-gradient-contained-active: var(--themes-neutral-lighter);
  --color-text-label-link-gradient-contained-focus: var(--themes-action-primary-main);
  --color-text-label-link-gradient-contained-disabled: var(--themes-neutral-light);
  --color-text-category-link-gradient-default: var(--themes-action-primary-light);
  --color-text-megamenu-link-title-default: var(--themes-neutral-strong);
  --color-text-megamenu-link-title-hover: var(--themes-action-primary-main);
  --color-text-megamenu-link-title-active: var(--themes-action-primary-dark);
  --color-text-megamenu-link-title-focus: var(--themes-neutral-strong);
  --color-text-megamenu-link-text-link-default: var(--themes-neutral-strong);
  --color-text-megamenu-link-text-link-hover: var(--themes-action-primary-main);
  --color-text-megamenu-link-text-link-active: var(--themes-action-primary-dark);
  --color-text-megamenu-link-text-link-focus: var(--themes-neutral-strong);
  --color-text-header-link-solid-normal-default: var(--themes-neutral-strong);
  --color-text-header-link-solid-normal-hover: var(--themes-action-primary-light);
  --color-text-header-link-solid-normal-selected: var(--themes-neutral-strong);
  --color-text-header-link-solid-normal-focus: var(--themes-neutral-strong);
  --color-text-header-link-solid-inverted-default: var(--themes-neutral-lighter);
  --color-text-header-link-solid-inverted-hover: var(--themes-action-primary-light);
  --color-text-header-link-solid-inverted-active: var(--themes-neutral-strong);
  --color-text-header-link-solid-inverted-subtle: var(--color-gray-50);
  --color-text-header-link-solid-inverted-focus: var(--themes-neutral-strong);
  --color-text-header-link-gradient-normal-default: var(--themes-neutral-strong);
  --color-text-header-link-gradient-normal-hover: var(--themes-action-primary-light);
  --color-text-header-link-gradient-normal-selected: var(--themes-neutral-strong);
  --color-text-header-link-gradient-normal-active: var(--themes-neutral-strong);
  --color-text-header-link-gradient-inverted-default: var(--themes-neutral-lighter);
  --color-text-header-link-gradient-inverted-hover: var(--themes-action-primary-light);
  --color-text-header-link-gradient-inverted-active: var(--themes-neutral-strong);
  --color-text-header-link-gradient-inverted-focus: var(--themes-neutral-strong);
  --color-text-quotation-box-heading: var(--themes-neutral-strong);
  --color-text-dropdown-default: var(--themes-neutral-strong);
  --color-text-dropdown-disabled: var(--themes-disabled-main);
  --color-text-search-field-default-default: var(--color-text-default-placeholder);
  --color-text-search-field-default-hover: var(--color-text-default-helper);
  --color-text-search-field-default-focus: var(--color-text-default-title);
  --color-text-search-field-default-filled: var(--color-text-default-title);
  --color-text-search-field-inverted-default: var(--color-text-default-placeholder);
  --color-text-search-field-inverted-hover: var(--color-text-tag-solid-normal-disabled);
  --color-text-search-field-inverted-focus: var(--color-text-default-title);
  --color-text-search-field-inverted-filled: var(--color-text-default-title);
  --color-text-table-header-cells-heading: var(--themes-neutral-strong);
  --color-text-table-subheader-cells-subheading: var(--themes-neutral-strong);
  --color-text-table-data-cells-body: var(--themes-neutral-strong);
  --color-text-table-data-cells-meta: var(--themes-neutral-dark);
  --color-text-breadcrumb-normal-default: var(--themes-neutral-dark);
  --color-text-breadcrumb-normal-active: var(--themes-neutral-strong);
  --color-text-breadcrumb-inverted-default: var(--themes-neutral-dark);
  --color-text-breadcrumb-inverted-active: var(--color-white-1000);
  --color-text-author-card-list-subtitle: var(--themes-neutral-strong);
  --color-text-author-card-list-body: var(--themes-neutral-strong);
  --color-text-author-card-detail-subtitle: var(--themes-neutral-dark);
  --color-text-author-card-detail-body: var(--themes-neutral-strong);
  --color-text-modal-title: var(--themes-neutral-strong);
  --color-text-modal-body: var(--themes-neutral-strong);
  --color-text-content-box-title: var(--themes-neutral-strong);
  --color-text-content-box-body: var(--themes-neutral-strong);
  --color-text-registration-title: var(--themes-neutral-strong);
  --color-text-registration-body: var(--themes-neutral-strong);
  --color-text-application-card-title: var(--themes-neutral-strong);
  --color-text-application-card-body: var(--themes-neutral-strong);
  --color-text-avatar-name: var(--themes-neutral-dark);
  --color-text-button-betting-primary-normal-contained-default: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-normal-contained-hover: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-normal-contained-focus: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-normal-contained-active: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-normal-contained-disabled: var(--themes-disabled-light);
  --color-text-button-betting-primary-normal-outlined-default: var(--themes-action-primary-main);
  --color-text-button-betting-primary-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-normal-outlined-active: var(--themes-action-primary-light);
  --color-text-button-betting-primary-normal-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-betting-primary-normal-text-default: var(--themes-action-primary-main);
  --color-text-button-betting-primary-normal-text-hover: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-normal-text-focus: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-normal-text-active: var(--themes-action-primary-light);
  --color-text-button-betting-primary-normal-text-disabled: var(--themes-disabled-light);
  --color-text-button-betting-primary-inverted-contained-default: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-inverted-contained-hover: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-inverted-contained-focus: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-inverted-contained-active: var(--themes-action-primary-on-primary);
  --color-text-button-betting-primary-inverted-contained-disabled: var(--themes-disabled-light);
  --color-text-button-betting-primary-inverted-outlined-default: var(--themes-action-primary-main);
  --color-text-button-betting-primary-inverted-outlined-hover: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-inverted-outlined-focus: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-inverted-outlined-active: var(--themes-action-primary-light);
  --color-text-button-betting-primary-inverted-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-betting-primary-inverted-text-default: var(--themes-action-primary-main);
  --color-text-button-betting-primary-inverted-text-hover: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-inverted-text-focus: var(--themes-action-primary-dark);
  --color-text-button-betting-primary-inverted-text-active: var(--themes-action-primary-light);
  --color-text-button-betting-primary-inverted-text-disabled: var(--themes-disabled-light);
  --color-text-button-betting-secondary-normal-contained-default: var(--themes-action-primary-main);
  --color-text-button-betting-secondary-normal-contained-hover: var(--themes-action-primary-dark);
  --color-text-button-betting-secondary-normal-contained-focus: var(--themes-action-primary-dark);
  --color-text-button-betting-secondary-normal-contained-active: var(--themes-action-primary-light);
  --color-text-button-betting-secondary-normal-contained-disabled: var(--themes-disabled-light);
  --color-text-button-betting-secondary-normal-outlined-default: var(--themes-action-primary-main);
  --color-text-button-betting-secondary-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-text-button-betting-secondary-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-text-button-betting-secondary-normal-outlined-active: var(--themes-action-primary-light);
  --color-text-button-betting-secondary-normal-outlined-disabled: var(--themes-disabled-light);
  --color-text-button-betting-secondary-normal-text-default: var(--themes-action-primary-main);
  --color-text-button-betting-secondary-normal-text-hover: var(--themes-action-primary-dark);
  --color-text-button-betting-secondary-normal-text-focus: var(--themes-action-primary-dark);
  --color-text-button-betting-secondary-normal-text-active: var(--themes-action-primary-light);
  --color-text-button-betting-secondary-normal-text-disabled: var(--themes-disabled-light);
  --color-text-results-live: var(--themes-success-main);
  --color-text-results-result: var(--themes-error-main);
  --color-text-results-body: var(--themes-neutral-strong);
  --color-text-results-meta: var(--themes-neutral-dark);
  --color-text-rank-circle: var(--themes-neutral-dark);
  --color-text-rank-square: var(--themes-neutral-dark);
  --color-text-rank-hexagon: var(--themes-neutral-dark);
  --color-text-result-cells-body: var(--themes-neutral-dark);
  --color-text-result-cells-points: var(--themes-neutral-dark);
  --color-text-result-cells-subheader: var(--themes-neutral-dark);
  --color-text-goal-cells-body: var(--themes-neutral-dark);
  --color-text-goal-cells-subheader: var(--themes-neutral-dark);
  --color-text-footer-normal-title: var(--themes-neutral-strong);
  --color-text-footer-normal-body: var(--themes-neutral-strong);
  --color-text-footer-inverted-title: var(--color-white-1000);
  --color-text-footer-inverted-body: var(--color-white-1000);
  --color-text-sticky-down-title: var(--themes-neutral-strong);
  --color-text-sticky-down-body: var(--themes-neutral-strong);
  --color-text-discussion-strip-title: var(--themes-neutral-strong);
  --color-text-discussion-strip-body: var(--themes-neutral-strong);
  --color-text-discussion-strip-meta: var(--themes-neutral-dark);
  --color-text-discussion-box-title: var(--color-white-1000);
  --color-text-error-title: var(--themes-brand-primary-main);
  --color-text-error-subtitle: var(--themes-neutral-strong);
  --color-text-error-body: var(--themes-neutral-darker);
  --color-text-article-meta-normal-data: var(--color-text-default-meta);
  --color-text-article-meta-inverted-data: var(--color-text-default-meta);
  --color-text-premium-box-title: var(--color-white-1000);
  --color-text-premium-box-body: var(--color-white-1000);
  --color-text-newsletter-box-title: var(--themes-brand-primary-main);
  --color-text-newsletter-box-body: var(--themes-neutral-strong);
  --color-text-newsletter-box-meta: var(--themes-neutral-dark);
  --color-text-checkbox-default-unchecked-normal-default: var(--themes-neutral-strong);
  --color-text-checkbox-default-unchecked-normal-hover: var(--themes-neutral-strong);
  --color-text-checkbox-default-unchecked-normal-focus: var(--themes-neutral-strong);
  --color-text-checkbox-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-unchecked-normal-error: var(--themes-error-main);
  --color-text-checkbox-default-unchecked-inverted-default: var(--color-white-1000);
  --color-text-checkbox-default-unchecked-inverted-hover: var(--color-white-1000);
  --color-text-checkbox-default-unchecked-inverted-focus: var(--color-white-1000);
  --color-text-checkbox-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-unchecked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-default-checked-normal-default: var(--themes-neutral-strong);
  --color-text-checkbox-default-checked-normal-hover: var(--themes-neutral-strong);
  --color-text-checkbox-default-checked-normal-focus: var(--themes-neutral-strong);
  --color-text-checkbox-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-checked-normal-error: var(--themes-error-main);
  --color-text-checkbox-default-checked-inverted-default: var(--color-white-1000);
  --color-text-checkbox-default-checked-inverted-hover: var(--color-white-1000);
  --color-text-checkbox-default-checked-inverted-focus: var(--color-white-1000);
  --color-text-checkbox-default-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-checked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-default-all-normal-default: var(--themes-neutral-strong);
  --color-text-checkbox-default-all-normal-hover: var(--themes-neutral-strong);
  --color-text-checkbox-default-all-normal-focus: var(--themes-neutral-strong);
  --color-text-checkbox-default-all-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-all-normal-error: var(--themes-error-main);
  --color-text-checkbox-default-all-inverted-default: var(--color-white-1000);
  --color-text-checkbox-default-all-inverted-hover: var(--color-white-1000);
  --color-text-checkbox-default-all-inverted-focus: var(--color-white-1000);
  --color-text-checkbox-default-all-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-default-all-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-default: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-hover: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-focus: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-unchecked-normal-error: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-default: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-hover: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-focus: var(--themes-error-main);
  --color-text-checkbox-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-unchecked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-checked-normal-default: var(--themes-error-dark);
  --color-text-checkbox-error-checked-normal-hover: var(--themes-error-dark);
  --color-text-checkbox-error-checked-normal-focus: var(--themes-error-dark);
  --color-text-checkbox-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-checked-normal-error: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-default: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-hover: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-focus: var(--themes-error-main);
  --color-text-checkbox-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-checked-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-all-normal-default: var(--themes-error-dark);
  --color-text-checkbox-error-all-normal-hover: var(--themes-error-dark);
  --color-text-checkbox-error-all-normal-focus: var(--themes-error-dark);
  --color-text-checkbox-error-all-normal-disabled: var(--themes-disabled-main);
  --color-text-checkbox-error-all-normal-error: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-default: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-hover: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-focus: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-error: var(--themes-error-main);
  --color-text-checkbox-error-all-inverted-disabled: var(--themes-disabled-main);
  --color-text-commentary-box-title: var(--themes-neutral-strong);
  --color-text-commentary-box-body: var(--themes-neutral-strong);
  --color-text-commentary-box-meta: var(--themes-neutral-dark);
  --color-text-top-event-live: var(--themes-neutral-subtle);
  --color-text-top-event-result: var(--themes-neutral-subtle);
  --color-text-top-event-body: var(--themes-neutral-subtle);
  --color-text-top-event-meta: var(--themes-neutral-subtle);
  --color-text-top-event-info: var(--themes-neutral-subtle);
  --color-text-top-match-end: var(--themes-neutral-subtle);
  --color-text-tab-normal-title: var(--color-text-default-title);
  --color-text-tab-inverted-title: var(--color-text-default-contrast);
  --color-text-tab-solid-normal-default: var(--color-text-default-title);
  --color-text-tab-solid-normal-hover: var(--themes-brand-primary-main);
  --color-text-tab-solid-inverted-default: var(--color-text-default-contrast);
  --color-text-tab-solid-inverted-hover: var(--themes-brand-primary-main);
  --color-text-input-normal-label-default: var(--themes-neutral-strong);
  --color-text-input-normal-label-hover: var(--themes-neutral-strong);
  --color-text-input-normal-label-focus: var(--themes-neutral-strong);
  --color-text-input-normal-label-active: var(--themes-neutral-strong);
  --color-text-input-normal-label-error: var(--themes-error-main);
  --color-text-input-normal-label-disabled: var(--themes-disabled-light);
  --color-text-input-normal-prefix-default: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-hover: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-focus: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-active: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-error: var(--themes-neutral-dark);
  --color-text-input-normal-prefix-disabled: var(--themes-disabled-light);
  --color-text-input-normal-value-default: var(--themes-neutral-main);
  --color-text-input-normal-value-hover: var(--themes-neutral-dark);
  --color-text-input-normal-value-focus: var(--themes-neutral-strong);
  --color-text-input-normal-value-active: var(--themes-neutral-strong);
  --color-text-input-normal-value-error: var(--themes-neutral-strong);
  --color-text-input-normal-value-disabled: var(--themes-disabled-light);
  --color-text-input-normal-sufix-default: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-hover: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-focus: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-active: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-error: var(--themes-neutral-dark);
  --color-text-input-normal-sufix-disabled: var(--themes-disabled-light);
  --color-text-input-normal-description-default: var(--themes-neutral-dark);
  --color-text-input-normal-description-hover: var(--themes-neutral-dark);
  --color-text-input-normal-description-focus: var(--themes-neutral-dark);
  --color-text-input-normal-description-active: var(--themes-neutral-dark);
  --color-text-input-normal-description-error: var(--themes-error-main);
  --color-text-input-normal-description-disabled: var(--themes-disabled-light);
  --color-text-input-inverted-label-default: var(--themes-neutral-light);
  --color-text-input-inverted-label-hover: var(--themes-neutral-light);
  --color-text-input-inverted-label-focus: var(--themes-neutral-light);
  --color-text-input-inverted-label-active: var(--themes-neutral-light);
  --color-text-input-inverted-label-error: var(--themes-error-light);
  --color-text-input-inverted-label-disabled: var(--themes-disabled-darker);
  --color-text-input-inverted-prefix-default: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-hover: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-focus: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-active: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-error: var(--themes-neutral-dark);
  --color-text-input-inverted-prefix-disabled: var(--themes-disabled-darker);
  --color-text-input-inverted-value-default: var(--themes-neutral-main);
  --color-text-input-inverted-value-hover: var(--themes-neutral-dark);
  --color-text-input-inverted-value-focus: var(--color-white-1000);
  --color-text-input-inverted-value-active: var(--color-white-1000);
  --color-text-input-inverted-value-error: var(--color-white-1000);
  --color-text-input-inverted-value-disabled: var(--themes-disabled-darker);
  --color-text-input-inverted-sufix-default: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-hover: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-focus: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-active: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-error: var(--themes-neutral-dark);
  --color-text-input-inverted-sufix-disabled: var(--themes-disabled-darker);
  --color-text-input-inverted-description-default: var(--themes-neutral-light);
  --color-text-input-inverted-description-hover: var(--themes-neutral-light);
  --color-text-input-inverted-description-focus: var(--themes-neutral-light);
  --color-text-input-inverted-description-active: var(--themes-neutral-light);
  --color-text-input-inverted-description-error: var(--themes-error-light);
  --color-text-input-inverted-description-disabled: var(--themes-disabled-darker);
  --color-text-text-area-normal-label-default: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-hover: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-focus: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-active: var(--themes-neutral-strong);
  --color-text-text-area-normal-label-error: var(--themes-error-main);
  --color-text-text-area-normal-label-disabled: var(--themes-disabled-light);
  --color-text-text-area-normal-value-default: var(--themes-neutral-light);
  --color-text-text-area-normal-value-hover: var(--themes-neutral-dark);
  --color-text-text-area-normal-value-focus: var(--themes-neutral-strong);
  --color-text-text-area-normal-value-active: var(--themes-neutral-strong);
  --color-text-text-area-normal-value-error: var(--themes-neutral-strong);
  --color-text-text-area-normal-value-disabled: var(--themes-disabled-light);
  --color-text-text-area-normal-info-default: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-hover: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-focus: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-active: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-error: var(--themes-neutral-dark);
  --color-text-text-area-normal-info-disabled: var(--themes-disabled-light);
  --color-text-text-area-normal-description-default: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-hover: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-focus: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-active: var(--themes-neutral-dark);
  --color-text-text-area-normal-description-error: var(--themes-error-main);
  --color-text-text-area-normal-description-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-label-default: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-hover: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-focus: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-active: var(--themes-neutral-light);
  --color-text-text-area-inverted-label-error: var(--themes-error-main);
  --color-text-text-area-inverted-label-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-value-default: var(--themes-neutral-light);
  --color-text-text-area-inverted-value-hover: var(--themes-neutral-dark);
  --color-text-text-area-inverted-value-focus: var(--themes-neutral-strong);
  --color-text-text-area-inverted-value-active: var(--themes-neutral-strong);
  --color-text-text-area-inverted-value-error: var(--themes-neutral-strong);
  --color-text-text-area-inverted-value-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-info-default: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-hover: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-focus: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-active: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-error: var(--themes-neutral-dark);
  --color-text-text-area-inverted-info-disabled: var(--themes-disabled-light);
  --color-text-text-area-inverted-description-default: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-hover: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-focus: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-active: var(--themes-neutral-light);
  --color-text-text-area-inverted-description-error: var(--themes-error-main);
  --color-text-text-area-inverted-description-disabled: var(--themes-disabled-light);
  --color-text-tipsport-bet-text: var(--color-text-default-meta);
  --color-text-card-percent-text: var(--themes-neutral-strong);
  --color-text-card-ticket-text: var(--color-text-default-meta);
  --color-text-card-ticket-description: var(--color-text-default-meta);
  --color-text-ticketarena-title: var(--color-text-default-meta);
  --color-text-ticketarena-description: var(--color-text-default-meta);
  --color-text-flash-messages-info-normal-title: var(--themes-info-main);
  --color-text-flash-messages-info-inverted-title: var(--color-white-1000);
  --color-text-flash-messages-alert-normal-title: var(--themes-error-main);
  --color-text-flash-messages-alert-inverted-title: var(--color-white-1000);
  --color-text-flash-messages-warning-normal-title: var(--themes-warning-dark);
  --color-text-flash-messages-warning-inverted-title: var(--themes-neutral-strong);
  --color-text-flash-messages-success-normal-title: var(--themes-success-main);
  --color-text-flash-messages-success-inverted-title: var(--color-white-1000);
  --color-text-info-bar-video-text: var(--color-white-1000);
  --color-text-lineup-body: var(--themes-neutral-subtle);
  --color-text-lineup-meta: var(--themes-neutral-subtle);
  --color-text-radiobutton-default-unchecked-normal-default: var(--themes-neutral-strong);
  --color-text-radiobutton-default-unchecked-normal-hover: var(--themes-neutral-strong);
  --color-text-radiobutton-default-unchecked-normal-focus: var(--themes-neutral-strong);
  --color-text-radiobutton-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-default-unchecked-inverted-default: var(--color-white-1000);
  --color-text-radiobutton-default-unchecked-inverted-hover: var(--color-white-1000);
  --color-text-radiobutton-default-unchecked-inverted-focus: var(--color-white-1000);
  --color-text-radiobutton-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-default-checked-normal-default: var(--themes-neutral-strong);
  --color-text-radiobutton-default-checked-normal-hover: var(--themes-neutral-strong);
  --color-text-radiobutton-default-checked-normal-focus: var(--themes-neutral-strong);
  --color-text-radiobutton-default-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-default-checked-inverted-default: var(--color-white-1000);
  --color-text-radiobutton-default-checked-inverted-hover: var(--color-white-1000);
  --color-text-radiobutton-default-checked-inverted-focus: var(--color-white-1000);
  --color-text-radiobutton-default-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-unchecked-normal-default: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-normal-hover: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-normal-focus: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-unchecked-inverted-default: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-inverted-hover: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-inverted-focus: var(--themes-error-main);
  --color-text-radiobutton-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-checked-normal-default: var(--themes-error-dark);
  --color-text-radiobutton-error-checked-normal-hover: var(--themes-error-dark);
  --color-text-radiobutton-error-checked-normal-focus: var(--themes-error-dark);
  --color-text-radiobutton-error-checked-normal-disabled: var(--themes-disabled-main);
  --color-text-radiobutton-error-checked-inverted-default: var(--themes-error-main);
  --color-text-radiobutton-error-checked-inverted-hover: var(--themes-error-main);
  --color-text-radiobutton-error-checked-inverted-focus: var(--themes-error-main);
  --color-text-radiobutton-error-checked-inverted-disabled: var(--themes-disabled-main);
  --color-text-stats-body: var(--themes-neutral-subtle);
  --color-text-stats-meta: var(--themes-neutral-subtle);
  --color-text-tipsport-table-body: var(--color-text-default-meta);
  --color-text-tipsport-table-description: var(--color-text-default-meta);
  --color-text-info-wrapper-default: var(--color-white-1000);
  --color-text-results-card-live: var(--themes-neutral-subtle);
  --color-text-results-card-result: var(--themes-neutral-subtle);
  --color-text-results-card-body: var(--themes-neutral-subtle);
  --color-text-results-card-meta: var(--themes-neutral-subtle);
  --color-text-results-card-info: var(--themes-neutral-subtle);
  --color-text-vote-strip-body: var(--themes-neutral-strong);
  --color-text-survey-title: var(--themes-neutral-strong);
  --color-text-survey-body: var(--themes-neutral-strong);
  --color-text-survey-meta: var(--themes-neutral-dark);
  --color-text-survey-confirm: var(--themes-brand-primary-main);
  --color-text-schedule-live: var(--themes-success-main);
  --color-text-schedule-result: var(--themes-error-main);
  --color-text-schedule-body: var(--themes-neutral-strong);
  --color-text-schedule-meta: var(--themes-neutral-dark);
  --color-text-result-module-card-live: var(--themes-success-main);
  --color-text-result-module-card-result: var(--themes-error-main);
  --color-text-result-module-card-body: var(--themes-neutral-strong);
  --color-text-result-module-card-meta: var(--themes-neutral-dark);
  --color-text-online-box-body: var(--themes-neutral-strong);
  --color-text-title-section-normal-title: var(--themes-neutral-dark);
  --color-text-title-section-inverted-title: var(--themes-neutral-dark);
  --color-text-team-card-body: var(--themes-neutral-subtle);
  --color-text-videoplayer-body: var(--color-white-1000);
  --color-text-tooltip-body: var(--color-white-1000);
  --color-text-funds-card-title: var(--color-text-default-title);
  --color-text-funds-card-meta: var(--color-text-default-meta);
  --color-text-funds-card-percentages: var(--themes-success-main);
  --color-text-segmented-controls-default: var(--themes-neutral-strong);
  --color-text-segmented-controls-hover: var(--themes-neutral-strong);
  --color-text-segmented-controls-focus: var(--themes-neutral-strong);
  --color-text-segmented-controls-active: var(--themes-brand-primary-subtle);
  --color-text-segmented-controls-disabled: var(--themes-disabled-light);
  --color-text-stepper-inactive: var(--themes-disabled-main);
  --color-text-stepper-label: var(--themes-neutral-strong);
  --color-border-textlink-active-divider: var(--themes-action-secondary-strong);
  --color-border-textlink-active-category-focus: var(--color-white-0);
  --color-border-premium-card-normal: var(--themes-neutral-subtle);
  --color-border-button-primary-solid-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-border-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-border-button-primary-solid-inverted-outlined-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-border-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-primary-solid-inverted-contained-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-inverted-text-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-normal-contained-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-normal-outlined-default: var(--themes-action-primary-main);
  --color-border-button-primary-solid-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-border-button-primary-solid-normal-outlined-focus: var(--themes-focus-main);
  --color-border-button-primary-solid-normal-outlined-active: var(--themes-action-primary-light);
  --color-border-button-primary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-primary-solid-normal-text-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-border-button-primary-gradient-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-border-button-primary-gradient-inverted-outlined-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-border-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-primary-gradient-inverted-contained-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-inverted-text-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-normal-contained-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-normal-outlined-default: var(--themes-action-primary-main);
  --color-border-button-primary-gradient-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-border-button-primary-gradient-normal-outlined-focus: var(--themes-focus-main);
  --color-border-button-primary-gradient-normal-outlined-active: var(--themes-action-primary-light);
  --color-border-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-primary-gradient-normal-text-focus: var(--themes-focus-main);
  --color-border-button-secondary-contained-focus: var(--themes-focus-main);
  --color-border-button-secondary-outlined-default: var(--themes-action-secondary-main);
  --color-border-button-secondary-outlined-hover: var(--themes-action-secondary-dark);
  --color-border-button-secondary-outlined-focus: var(--themes-focus-main);
  --color-border-button-secondary-outlined-active: var(--themes-action-secondary-light);
  --color-border-button-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-secondary-text-focus: var(--themes-focus-main);
  --color-border-button-info-outlined-default: var(--themes-info-main);
  --color-border-button-info-outlined-hover: var(--themes-info-dark);
  --color-border-button-info-outlined-focus: var(--themes-focus-main);
  --color-border-button-info-outlined-active: var(--themes-info-light);
  --color-border-button-info-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-info-contained-focus: var(--themes-focus-main);
  --color-border-button-info-text-focus: var(--themes-focus-main);
  --color-border-button-error-outlined-default: var(--themes-error-main);
  --color-border-button-error-outlined-hover: var(--themes-error-dark);
  --color-border-button-error-outlined-focus: var(--themes-focus-main);
  --color-border-button-error-outlined-active: var(--themes-error-light);
  --color-border-button-error-outlined-disabled: var(--themes-disabled-light);
  --color-border-button-error-contained-focus: var(--themes-focus-main);
  --color-border-button-error-text-focus: var(--themes-focus-main);
  --color-border-button-social-login-default: var(--themes-neutral-lighter);
  --color-border-button-social-login-hover: var(--themes-neutral-lighter);
  --color-border-button-social-login-focus: var(--themes-focus-main);
  --color-border-button-social-login-active: var(--themes-error-light);
  --color-border-button-social-login-disabled: var(--themes-disabled-light);
  --color-border-chip-inverted-contained-focus: var(--themes-focus-main);
  --color-border-chip-primary-outlined-default: var(--themes-neutral-main);
  --color-border-chip-primary-outlined-hover: var(--themes-action-primary-light);
  --color-border-chip-primary-outlined-focus: var(--themes-focus-main);
  --color-border-chip-primary-outlined-active: var(--themes-action-primary-light);
  --color-border-chip-primary-outlined-disabled: var(--themes-disabled-light);
  --color-border-chip-primary-contained-focus: var(--themes-focus-main);
  --color-border-chip-secondary-outlined-default: var(--themes-neutral-main);
  --color-border-chip-secondary-outlined-hover: var(--themes-neutral-main);
  --color-border-chip-secondary-outlined-focus: var(--themes-focus-main);
  --color-border-chip-secondary-outlined-active: var(--themes-neutral-main);
  --color-border-chip-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-border-chip-secondary-contained-focus: var(--themes-focus-main);
  --color-border-divider-default: var(--themes-neutral-lighter);
  --color-border-divider-label: var(--themes-neutral-lighter);
  --color-border-divider-chip: var(--themes-neutral-lighter);
  --color-border-divider-dark: var(--themes-neutral-strong);
  --color-border-divider-section: var(--themes-brand-primary-main);
  --color-border-divider-footer: var(--themes-action-primary-main);
  --color-border-icon-button-brand-solid-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-normal-outlined-default: var(--themes-action-primary-main);
  --color-border-icon-button-brand-solid-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-border-icon-button-brand-solid-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-normal-outlined-active: var(--themes-action-primary-light);
  --color-border-icon-button-brand-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-brand-solid-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-inverted-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-brand-solid-inverted-outlined-default: var(--themes-action-inverted-strong);
  --color-border-icon-button-brand-solid-inverted-outlined-hover: var(--themes-action-primary-dark);
  --color-border-icon-button-brand-solid-inverted-outlined-focus: var(--themes-focus-light);
  --color-border-icon-button-brand-solid-inverted-outlined-active: var(--themes-action-primary-light);
  --color-border-icon-button-brand-solid-inverted-outlined-disabled: var(--themes-action-inverted-darker);
  --color-border-icon-button-brand-solid-inverted-text-focus: var(--themes-focus-light);
  --color-border-icon-button-secondary-solid-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-secondary-solid-normal-outlined-default: var(--themes-action-secondary-darker);
  --color-border-icon-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-strong);
  --color-border-icon-button-secondary-solid-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-secondary-solid-normal-outlined-active: var(--themes-action-secondary-light);
  --color-border-icon-button-secondary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-secondary-solid-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-normal-outlined-default: var(--themes-action-secondary-main);
  --color-border-icon-button-primary-solid-normal-outlined-hover: var(--themes-action-secondary-dark);
  --color-border-icon-button-primary-solid-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-normal-outlined-active: var(--themes-action-secondary-light);
  --color-border-icon-button-primary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-primary-solid-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-inverted-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-solid-inverted-outlined-default: var(--themes-action-secondary-on-secondary);
  --color-border-icon-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-main);
  --color-border-icon-button-primary-solid-inverted-outlined-focus: var(--themes-focus-light);
  --color-border-icon-button-primary-solid-inverted-outlined-active: var(--themes-action-primary-dark);
  --color-border-icon-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-primary-solid-inverted-text-focus: var(--themes-focus-light);
  --color-border-icon-button-primary-gradient-normal-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-normal-outlined-default: var(--themes-action-secondary-main);
  --color-border-icon-button-primary-gradient-normal-outlined-hover: var(--themes-action-secondary-dark);
  --color-border-icon-button-primary-gradient-normal-outlined-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-normal-outlined-active: var(--themes-action-secondary-light);
  --color-border-icon-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-primary-gradient-normal-text-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-inverted-contained-focus: var(--themes-focus-main);
  --color-border-icon-button-primary-gradient-inverted-outlined-default: var(--themes-action-secondary-on-secondary);
  --color-border-icon-button-primary-gradient-inverted-outlined-hover: var(--themes-action-primary-main);
  --color-border-icon-button-primary-gradient-inverted-outlined-focus: var(--themes-focus-light);
  --color-border-icon-button-primary-gradient-inverted-outlined-active: var(--themes-action-primary-dark);
  --color-border-icon-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-light);
  --color-border-icon-button-primary-gradient-inverted-text-focus: var(--themes-focus-light);
  --color-border-pagination-default: var(--themes-neutral-light);
  --color-border-pagination-hover: var(--themes-neutral-light);
  --color-border-pagination-active: var(--themes-action-primary-main);
  --color-border-pagination-focus: var(--themes-focus-main);
  --color-border-pagination-disabled: var(--themes-neutral-lighter);
  --color-border-tag-solid-normal-default: var(--themes-neutral-light);
  --color-border-tag-solid-normal-hover: var(--themes-action-primary-main);
  --color-border-tag-solid-normal-focus: var(--themes-action-primary-main);
  --color-border-tag-solid-normal-active: var(--themes-action-primary-main);
  --color-border-tag-solid-normal-disabled: var(--themes-disabled-light);
  --color-border-tag-solid-inverted-default: var(--themes-neutral-light);
  --color-border-tag-solid-inverted-hover: var(--themes-action-primary-main);
  --color-border-tag-solid-inverted-focus: var(--themes-action-primary-main);
  --color-border-tag-solid-inverted-disabled: var(--themes-disabled-light);
  --color-border-tag-gradient-normal-default: var(--themes-neutral-light);
  --color-border-tag-gradient-normal-hover: var(--themes-action-primary-main);
  --color-border-tag-gradient-normal-focus: var(--themes-action-primary-main);
  --color-border-tag-gradient-normal-active: var(--themes-action-primary-main);
  --color-border-tag-gradient-normal-disabled: var(--themes-disabled-light);
  --color-border-tag-gradient-inverted-default: var(--themes-neutral-light);
  --color-border-tag-gradient-inverted-hover: var(--themes-action-primary-main);
  --color-border-tag-gradient-inverted-focus: var(--themes-action-primary-main);
  --color-border-tag-gradient-inverted-disabled: var(--themes-disabled-light);
  --color-border-badge-default: var(--color-white-1000);
  --color-border-badgepill-outlined-success: var(--themes-success-light);
  --color-border-badgepill-outlined-warning: var(--themes-warning-light);
  --color-border-badgepill-outlined-error: var(--themes-error-light);
  --color-border-badgepill-outlined-info: var(--themes-info-light);
  --color-border-badgepill-outlined-disabled: var(--themes-disabled-light);
  --color-border-badgepill-outlined-secondary: var(--themes-brand-secondary-main);
  --color-border-badgepill-outlined-primary: var(--themes-brand-primary-main);
  --color-border-default-lighter: var(--themes-neutral-subtle);
  --color-border-default-subtle: var(--themes-neutral-subtle);
  --color-border-default-section: var(--themes-brand-primary-light);
  --color-border-default-dark: var(--themes-neutral-main);
  --color-border-header-link-solid-normal-active: var(--themes-neutral-strong);
  --color-border-header-link-solid-normal-focus: var(--themes-neutral-strong);
  --color-border-header-link-solid-inverted-active: var(--themes-neutral-strong);
  --color-border-header-link-solid-inverted-focus: var(--themes-neutral-strong);
  --color-border-avatar-default: var(--color-white-1000);
  --color-border-vote-strip-default: var(--themes-brand-primary-main);
  --color-border-vote-strip-hover: var(--themes-brand-primary-main);
  --color-border-vote-strip-focus: var(--themes-focus-main);
  --color-border-dropdown-focus: var(--themes-focus-main);
  --color-border-search-field-normal-default: var(--themes-neutral-light);
  --color-border-search-field-normal-hover: var(--themes-neutral-dark);
  --color-border-search-field-normal-focus: var(--themes-action-primary-main);
  --color-border-search-field-normal-filled: var(--themes-neutral-light);
  --color-border-search-field-inverted-default: var(--themes-neutral-dark);
  --color-border-search-field-inverted-hover: var(--themes-action-primary-light);
  --color-border-search-field-inverted-focus: var(--themes-action-primary-main);
  --color-border-search-field-inverted-filled: var(--themes-neutral-main);
  --color-border-modal-default: var(--themes-neutral-lighter);
  --color-border-registration-subtle: var(--themes-neutral-subtle);
  --color-border-registration-lighter: var(--themes-neutral-lighter);
  --color-border-button-betting-primary-normal-contained-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-normal-outlined-default: var(--themes-action-primary-subtle);
  --color-border-button-betting-primary-normal-outlined-hover: var(--themes-action-primary-lighter);
  --color-border-button-betting-primary-normal-outlined-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-normal-outlined-active: var(--themes-action-primary-subtle);
  --color-border-button-betting-primary-normal-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-betting-primary-normal-text-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-inverted-contained-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-border-button-betting-primary-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-border-button-betting-primary-inverted-outlined-focus: var(--themes-focus-main);
  --color-border-button-betting-primary-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-border-button-betting-primary-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-betting-primary-inverted-text-focus: var(--themes-focus-main);
  --color-border-button-betting-secondary-contained-focus: var(--themes-focus-main);
  --color-border-button-betting-secondary-outlined-default: var(--themes-action-primary-subtle);
  --color-border-button-betting-secondary-outlined-hover: var(--themes-action-primary-lighter);
  --color-border-button-betting-secondary-outlined-focus: var(--themes-focus-main);
  --color-border-button-betting-secondary-outlined-active: var(--themes-action-primary-subtle);
  --color-border-button-betting-secondary-outlined-disabled: var(--themes-disabled-main);
  --color-border-button-betting-secondary-text-focus: var(--themes-focus-main);
  --color-border-results-default: var(--themes-neutral-lighter);
  --color-border-title-section-default: var(--themes-neutral-lighter);
  --color-border-result-cells-default: var(--themes-neutral-lighter);
  --color-border-goal-cells-default: var(--themes-neutral-lighter);
  --color-border-discussion-card-default: var(--themes-neutral-lighter);
  --color-border-newsletter-box-default: var(--themes-neutral-lighter);
  --color-border-checkbox-default-unchecked-normal-default: var(--themes-neutral-darker);
  --color-border-checkbox-default-unchecked-normal-hover: var(--themes-neutral-lighter);
  --color-border-checkbox-default-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-checkbox-default-unchecked-inverted-default: var(--themes-neutral-light);
  --color-border-checkbox-default-unchecked-inverted-hover: var(--themes-neutral-lighter);
  --color-border-checkbox-default-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-checkbox-default-checked-normal-hover: var(--themes-neutral-lighter);
  --color-border-checkbox-default-checked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-default-checked-inverted-hover: var(--themes-neutral-lighter);
  --color-border-checkbox-default-checked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-default-all-normal-hover: var(--themes-neutral-lighter);
  --color-border-checkbox-default-all-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-default-all-inverted-hover: var(--themes-neutral-lighter);
  --color-border-checkbox-default-all-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-error-unchecked-normal-default: var(--themes-error-main);
  --color-border-checkbox-error-unchecked-normal-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-checkbox-error-unchecked-inverted-default: var(--themes-error-main);
  --color-border-checkbox-error-unchecked-inverted-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-checkbox-error-checked-normal-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-checked-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-error-checked-inverted-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-checked-inverted-focus: var(--themes-focus-main);
  --color-border-checkbox-error-all-normal-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-all-normal-focus: var(--themes-focus-main);
  --color-border-checkbox-error-all-inverted-hover: var(--themes-error-lighter);
  --color-border-checkbox-error-all-inverted-focus: var(--themes-focus-main);
  --color-border-commentary-box-default: var(--themes-neutral-lighter);
  --color-border-commentary-box-goal: var(--themes-neutral-lighter);
  --color-border-input-normal-default: var(--themes-neutral-main);
  --color-border-input-normal-hover: var(--themes-neutral-dark);
  --color-border-input-normal-focus: var(--themes-focus-main);
  --color-border-input-normal-active: var(--themes-neutral-dark);
  --color-border-input-normal-error: var(--themes-error-main);
  --color-border-input-normal-disabled: var(--themes-disabled-light);
  --color-border-input-inverted-default: var(--themes-neutral-main);
  --color-border-input-inverted-hover: var(--themes-neutral-lighter);
  --color-border-input-inverted-focus: var(--themes-focus-light);
  --color-border-input-inverted-active: var(--themes-neutral-lighter);
  --color-border-input-inverted-error: var(--themes-error-light);
  --color-border-input-inverted-disabled: var(--themes-disabled-darker);
  --color-border-text-area-normal-default: var(--themes-neutral-light);
  --color-border-text-area-normal-hover: var(--themes-neutral-dark);
  --color-border-text-area-normal-focus: var(--themes-focus-main);
  --color-border-text-area-normal-active: var(--themes-neutral-dark);
  --color-border-text-area-normal-error: var(--themes-error-main);
  --color-border-text-area-normal-disabled: var(--themes-disabled-light);
  --color-border-text-area-inverted-default: var(--themes-neutral-main);
  --color-border-text-area-inverted-hover: var(--themes-neutral-lighter);
  --color-border-text-area-inverted-focus: var(--themes-focus-main);
  --color-border-text-area-inverted-active: var(--themes-neutral-lighter);
  --color-border-text-area-inverted-error: var(--themes-error-main);
  --color-border-text-area-inverted-disabled: var(--themes-disabled-light);
  --color-border-lineup-playground: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-unchecked-normal-default: var(--themes-neutral-darker);
  --color-border-radiobutton-default-unchecked-normal-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-default-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-default-unchecked-inverted-default: var(--themes-neutral-light);
  --color-border-radiobutton-default-unchecked-inverted-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-radiobutton-default-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-default-checked-normal-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-checked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-default-checked-inverted-hover: var(--themes-neutral-lighter);
  --color-border-radiobutton-default-checked-inverted-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-unchecked-normal-default: var(--themes-error-main);
  --color-border-radiobutton-error-unchecked-normal-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-unchecked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-unchecked-normal-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-error-unchecked-inverted-default: var(--themes-error-main);
  --color-border-radiobutton-error-unchecked-inverted-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-unchecked-inverted-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-unchecked-inverted-disabled: var(--themes-disabled-main);
  --color-border-radiobutton-error-checked-normal-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-checked-normal-focus: var(--themes-focus-main);
  --color-border-radiobutton-error-checked-inverted-hover: var(--themes-error-lighter);
  --color-border-radiobutton-error-checked-inverted-focus: var(--themes-focus-main);
  --color-border-ads-default: #d0d0d0;
  --color-border-tab-normal-default: var(--themes-neutral-lighter);
  --color-border-tab-inverted-default: var(--themes-neutral-lighter);
  --color-border-tab-solid-inverted-default: var(--themes-neutral-lighter);
  --color-border-tab-solid-inverted-hover: var(--themes-brand-primary-main);
  --color-border-tab-solid-normal-default: var(--themes-neutral-lighter);
  --color-border-tab-solid-normal-hover: var(--themes-brand-primary-main);
  --color-border-result-module-card-default: var(--themes-neutral-lighter);
  --color-border-result-module-default: var(--themes-neutral-lighter);
  --color-border-segmented-controls-default: var(--themes-neutral-light);
  --color-border-segmented-controls-focus: var(--themes-focus-main);
  --color-border-stepper-active: var(--themes-brand-primary-main);
  --color-border-stepper-hover: var(--themes-brand-primary-darker);
  --color-border-stepper-inactive: var(--themes-disabled-main);
  --color-border-spinner-background: var(--themes-brand-primary-less-subtle);
  --color-border-spinner-spinner: var(--themes-brand-primary-main);
  --color-icon-flash-messages-info-normal-icon: var(--themes-info-main);
  --color-icon-flash-messages-info-inverted-icon: var(--color-white-1000);
  --color-icon-flash-messages-alert-normal-icon: var(--themes-error-main);
  --color-icon-flash-messages-alert-inverted-icon: var(--color-white-1000);
  --color-icon-flash-messages-warning-normal-icon: var(--themes-warning-dark);
  --color-icon-flash-messages-warning-inverted-icon: var(--themes-neutral-strong);
  --color-icon-flash-messages-success-normal-icon: var(--themes-success-main);
  --color-icon-flash-messages-success-inverted-icon: var(--color-white-1000);
  --color-icon-avatar-default: var(--themes-neutral-dark);
  --color-icon-gallery-arrows-default: var(--themes-neutral-subtle);
  --color-icon-textlink-menu-item-default: #fff;
  --color-icon-textlink-menu-item-hover: #fff;
  --color-icon-textlink-menu-item-active: #fff;
  --color-icon-textlink-menu-item-focus: #fff;
  --color-icon-textlink-menu-item-disabled: #fff;
  --color-icon-textlink-menu-item-solid-default: #fff;
  --color-icon-textlink-menu-item-solid-hover: #fff;
  --color-icon-textlink-menu-item-solid-active: #fff;
  --color-icon-textlink-menu-item-solid-focus: #fff;
  --color-icon-textlink-menu-item-solid-disabled: #fff;
  --color-icon-textlink-menu-item-ctagory-default: #fff;
  --color-icon-textlink-menu-item-ctagory-hover: #fff;
  --color-icon-textlink-menu-item-ctagory-active: #fff;
  --color-icon-textlink-menu-item-ctagory-focus: #fff;
  --color-icon-textlink-menu-item-ctagory-disabled: #fff;
  --color-icon-textlink-category-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-category-solid-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-category-solid-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-category-solid-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-category-solid-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-category-solid-disabled: var(--themes-disabled-light);
  --color-icon-textlink-category-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-category-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-category-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-category-disabled: var(--themes-disabled-light);
  --color-icon-textlink-category-gradient-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-category-gradient-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-category-gradient-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-category-gradient-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-category-gradient-disabled: var(--themes-disabled-light);
  --color-icon-textlink-contained-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-contained-solid-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-contained-solid-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-contained-solid-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-contained-solid-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-contained-solid-disabled: var(--themes-disabled-light);
  --color-icon-textlink-contained-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-contained-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-contained-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-contained-disabled: var(--themes-disabled-light);
  --color-icon-textlink-contained-gradient-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-contained-gradient-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-contained-gradient-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-contained-gradient-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-contained-gradient-disabled: var(--themes-disabled-light);
  --color-icon-textlink-stand-alone-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-stand-alone-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-stand-alone-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-stand-alone-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-stand-alone-disabled: var(--themes-disabled-light);
  --color-icon-textlink-megamenu-default: var(--color-icon-action-primary-light);
  --color-icon-textlink-megamenu-hover: var(--color-icon-action-primary-dark);
  --color-icon-textlink-megamenu-active: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-megamenu-focus: var(--color-icon-action-secondary-strong);
  --color-icon-textlink-megamenu-disabled: var(--themes-disabled-light);
  --color-icon-list-default: var(--themes-neutral-strong);
  --color-icon-list-brand: var(--themes-brand-primary-light);
  --color-icon-list-inverted: var(--color-white-1000);
  --color-icon-list-positive: var(--color-surface-succes-light);
  --color-icon-list-negative: var(--color-surface-error-main);
  --color-icon-button-primary-solid-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-icon-button-primary-solid-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-solid-inverted-contained-default: var(--themes-action-primary-main);
  --color-icon-button-primary-solid-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-inverted-contained-active: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-inverted-contained-disabled: var(--themes-action-inverted-darker);
  --color-icon-button-primary-solid-inverted-text-default: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-text-hover: var(--themes-action-primary-main);
  --color-icon-button-primary-solid-inverted-text-focus: var(--themes-action-primary-light);
  --color-icon-button-primary-solid-inverted-text-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-solid-inverted-text-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-solid-normal-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-solid-normal-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-solid-normal-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-solid-normal-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-solid-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-primary-solid-normal-outlined-default: var(--themes-action-primary-main);
  --color-icon-button-primary-solid-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-normal-outlined-active: var(--themes-action-primary-light);
  --color-icon-button-primary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-primary-solid-normal-text-default: var(--themes-action-primary-main);
  --color-icon-button-primary-solid-normal-text-hover: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-normal-text-focus: var(--themes-action-primary-dark);
  --color-icon-button-primary-solid-normal-text-active: var(--themes-action-primary-light);
  --color-icon-button-primary-solid-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-button-primary-gradient-inverted-contained-default: var(--themes-action-primary-main);
  --color-icon-button-primary-gradient-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-icon-button-primary-gradient-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-icon-button-primary-gradient-inverted-contained-active: var(--themes-action-primary-dark);
  --color-icon-button-primary-gradient-inverted-contained-disabled: var(--themes-action-inverted-darker);
  --color-icon-button-primary-gradient-inverted-outlined-default: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-outlined-hover: var(--themes-action-primary-lighter);
  --color-icon-button-primary-gradient-inverted-outlined-focus: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-outlined-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-gradient-inverted-text-default: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-text-hover: var(--themes-action-primary-main);
  --color-icon-button-primary-gradient-inverted-text-focus: var(--themes-action-primary-light);
  --color-icon-button-primary-gradient-inverted-text-active: var(--themes-action-primary-subtle);
  --color-icon-button-primary-gradient-inverted-text-disabled: var(--themes-disabled-main);
  --color-icon-button-primary-gradient-normal-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-gradient-normal-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-gradient-normal-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-gradient-normal-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-primary-gradient-normal-outlined-default: var(--themes-action-primary-main);
  --color-icon-button-primary-gradient-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-icon-button-primary-gradient-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-button-primary-gradient-normal-outlined-active: var(--themes-action-primary-light);
  --color-icon-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-primary-gradient-normal-text-default: var(--themes-action-primary-main);
  --color-icon-button-primary-gradient-normal-text-hover: var(--themes-action-primary-dark);
  --color-icon-button-primary-gradient-normal-text-focus: var(--themes-action-primary-dark);
  --color-icon-button-primary-gradient-normal-text-active: var(--themes-action-primary-light);
  --color-icon-button-primary-gradient-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-button-secondary-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-button-secondary-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-secondary-outlined-default: var(--themes-action-secondary-main);
  --color-icon-button-secondary-outlined-hover: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-outlined-focus: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-outlined-active: var(--themes-action-secondary-light);
  --color-icon-button-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-secondary-text-default: var(--themes-action-secondary-main);
  --color-icon-button-secondary-text-hover: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-text-focus: var(--themes-action-secondary-dark);
  --color-icon-button-secondary-text-active: var(--themes-action-secondary-light);
  --color-icon-button-secondary-text-disabled: var(--themes-disabled-light);
  --color-icon-button-info-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-button-info-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-info-outlined-default: var(--themes-info-main);
  --color-icon-button-info-outlined-hover: var(--themes-info-dark);
  --color-icon-button-info-outlined-focus: var(--themes-info-dark);
  --color-icon-button-info-outlined-active: var(--themes-info-light);
  --color-icon-button-info-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-info-text-default: var(--themes-info-main);
  --color-icon-button-info-text-hover: var(--themes-info-dark);
  --color-icon-button-info-text-focus: var(--themes-info-dark);
  --color-icon-button-info-text-active: var(--themes-info-light);
  --color-icon-button-info-text-disabled: var(--themes-disabled-light);
  --color-icon-button-error-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-button-error-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-error-outlined-default: var(--themes-error-main);
  --color-icon-button-error-outlined-hover: var(--themes-error-dark);
  --color-icon-button-error-outlined-focus: var(--themes-error-dark);
  --color-icon-button-error-outlined-active: var(--themes-error-light);
  --color-icon-button-error-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-error-text-default: var(--themes-error-main);
  --color-icon-button-error-text-hover: var(--themes-error-dark);
  --color-icon-button-error-text-focus: var(--themes-error-dark);
  --color-icon-button-error-text-active: var(--themes-error-light);
  --color-icon-button-error-text-disabled: var(--themes-disabled-light);
  --color-icon-button-social-login-disabled: var(--themes-disabled-light);
  --color-icon-chip-inverted-contained-default: var(--themes-action-inverted-darker);
  --color-icon-chip-inverted-contained-hover: var(--themes-action-inverted-darker);
  --color-icon-chip-inverted-contained-focus: var(--themes-action-primary-main);
  --color-icon-chip-inverted-contained-active: var(--themes-action-primary-main);
  --color-icon-chip-inverted-contained-disabled: var(--themes-action-inverted-light);
  --color-icon-chip-primary-contained-default: var(--themes-neutral-darker);
  --color-icon-chip-primary-contained-hover: var(--themes-neutral-dark);
  --color-icon-chip-primary-contained-focus: var(--themes-action-primary-main);
  --color-icon-chip-primary-contained-active: var(--themes-action-primary-main);
  --color-icon-chip-primary-contained-disabled: var(--themes-disabled-light);
  --color-icon-chip-primary-outlined-default: var(--themes-neutral-darker);
  --color-icon-chip-primary-outlined-hover: var(--themes-action-primary-main);
  --color-icon-chip-primary-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-chip-primary-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-chip-primary-outlined-disabled: var(--themes-disabled-light);
  --color-icon-chip-secondary-contained-default: var(--themes-neutral-darker);
  --color-icon-chip-secondary-contained-hover: var(--themes-neutral-dark);
  --color-icon-chip-secondary-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-contained-disabled: var(--themes-disabled-light);
  --color-icon-chip-secondary-outlined-default: var(--themes-neutral-darker);
  --color-icon-chip-secondary-outlined-hover: var(--themes-neutral-dark);
  --color-icon-chip-secondary-outlined-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-outlined-active: var(--themes-action-secondary-on-secondary);
  --color-icon-chip-secondary-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-normal-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-icon-button-brand-solid-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-normal-outlined-default: var(--themes-action-primary-main);
  --color-icon-icon-button-brand-solid-normal-outlined-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-outlined-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-normal-text-default: var(--themes-action-primary-main);
  --color-icon-icon-button-brand-solid-normal-text-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-text-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-normal-text-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-brand-solid-inverted-contained-default: var(--themes-action-primary-main);
  --color-icon-icon-button-brand-solid-inverted-contained-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-contained-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-contained-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-inverted-contained-disabled: var(--themes-action-inverted-darker);
  --color-icon-icon-button-brand-solid-inverted-outlined-default: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-outlined-hover: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-brand-solid-inverted-outlined-active: var(--themes-action-primary-light);
  --color-icon-icon-button-brand-solid-inverted-outlined-disabled: var(--themes-action-inverted-darker);
  --color-icon-icon-button-brand-solid-inverted-text-default: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-hover: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-focus: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-active: var(--themes-action-inverted-strong);
  --color-icon-icon-button-brand-solid-inverted-text-disabled: var(--themes-action-inverted-darker);
  --color-icon-icon-button-secondary-solid-normal-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-secondary-solid-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-secondary-solid-normal-outlined-default: var(--themes-action-secondary-darker);
  --color-icon-icon-button-secondary-solid-normal-outlined-hover: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-outlined-focus: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-outlined-active: var(--themes-action-secondary-light);
  --color-icon-icon-button-secondary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-secondary-solid-normal-text-default: var(--themes-action-secondary-darker);
  --color-icon-icon-button-secondary-solid-normal-text-hover: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-text-focus: var(--themes-action-secondary-strong);
  --color-icon-icon-button-secondary-solid-normal-text-active: var(--themes-action-secondary-light);
  --color-icon-icon-button-secondary-solid-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-normal-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-solid-normal-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-solid-normal-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-solid-normal-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-solid-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-normal-outlined-default: var(--themes-action-secondary-strong);
  --color-icon-icon-button-primary-solid-normal-outlined-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-solid-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-normal-outlined-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-normal-text-default: var(--themes-action-secondary-strong);
  --color-icon-icon-button-primary-solid-normal-text-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-solid-normal-text-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-normal-text-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-inverted-contained-default: var(--themes-action-secondary-strong);
  --color-icon-icon-button-primary-solid-inverted-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-solid-inverted-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-solid-inverted-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-solid-inverted-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-inverted-outlined-default: var(--themes-action-secondary-subtle);
  --color-icon-icon-button-primary-solid-inverted-outlined-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-solid-inverted-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-inverted-outlined-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-inverted-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-solid-inverted-text-default: var(--themes-action-secondary-subtle);
  --color-icon-icon-button-primary-solid-inverted-text-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-solid-inverted-text-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-inverted-text-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-solid-inverted-text-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-gradient-normal-contained-default: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-gradient-normal-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-gradient-normal-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-gradient-normal-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-gradient-normal-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-gradient-normal-outlined-default: var(--themes-action-secondary-strong);
  --color-icon-icon-button-primary-gradient-normal-outlined-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-gradient-normal-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-normal-outlined-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-normal-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-gradient-normal-text-default: var(--themes-action-secondary-strong);
  --color-icon-icon-button-primary-gradient-normal-text-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-gradient-normal-text-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-normal-text-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-normal-text-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-gradient-inverted-contained-default: var(--themes-action-secondary-strong);
  --color-icon-icon-button-primary-gradient-inverted-contained-hover: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-gradient-inverted-contained-focus: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-gradient-inverted-contained-active: var(--themes-action-secondary-on-secondary);
  --color-icon-icon-button-primary-gradient-inverted-contained-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-gradient-inverted-outlined-default: var(--themes-action-secondary-subtle);
  --color-icon-icon-button-primary-gradient-inverted-outlined-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-gradient-inverted-outlined-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-inverted-outlined-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-inverted-outlined-disabled: var(--themes-disabled-light);
  --color-icon-icon-button-primary-gradient-inverted-text-default: var(--themes-action-secondary-subtle);
  --color-icon-icon-button-primary-gradient-inverted-text-hover: var(--themes-action-primary-main);
  --color-icon-icon-button-primary-gradient-inverted-text-focus: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-inverted-text-active: var(--themes-action-primary-dark);
  --color-icon-icon-button-primary-gradient-inverted-text-disabled: var(--themes-disabled-light);
  --color-icon-badge-success: var(--themes-success-on-success);
  --color-icon-badge-warning: var(--themes-warning-on-warning);
  --color-icon-badge-error: var(--themes-error-on-error);
  --color-icon-badge-info: var(--themes-info-on-info);
  --color-icon-badge-disabled: var(--themes-disabled-light);
  --color-icon-badge-secondary: var(--themes-brand-secondary-on-secondary);
  --color-icon-badge-primary: var(--themes-brand-primary-on-primary);
  --color-icon-badgepill-soft-success: var(--themes-success-main);
  --color-icon-badgepill-soft-warning: var(--themes-warning-dark);
  --color-icon-badgepill-soft-error: var(--themes-error-dark);
  --color-icon-badgepill-soft-info: var(--themes-info-dark);
  --color-icon-badgepill-soft-disabled: var(--themes-disabled-light);
  --color-icon-badgepill-soft-secondary: var(--themes-brand-secondary-dark);
  --color-icon-badgepill-soft-primary: var(--themes-brand-primary-main);
  --color-icon-badgepill-solid-success: var(--themes-success-on-success);
  --color-icon-badgepill-solid-warning: var(--themes-warning-on-warning);
  --color-icon-badgepill-solid-error: var(--themes-error-on-error);
  --color-icon-badgepill-solid-info: var(--themes-info-on-info);
  --color-icon-badgepill-solid-disabled: var(--themes-disabled-subtle);
  --color-icon-badgepill-solid-secondary: var(--themes-brand-secondary-on-secondary);
  --color-icon-badgepill-solid-primary: var(--themes-brand-primary-on-primary);
  --color-icon-badgepill-outlined-success: var(--themes-success-main);
  --color-icon-badgepill-outlined-warning: var(--themes-warning-dark);
  --color-icon-badgepill-outlined-error: var(--themes-error-dark);
  --color-icon-badgepill-outlined-info: var(--themes-info-dark);
  --color-icon-badgepill-outlined-disabled: var(--themes-disabled-light);
  --color-icon-badgepill-outlined-secondary: var(--themes-brand-secondary-dark);
  --color-icon-badgepill-outlined-primary: var(--themes-brand-primary-main);
  --color-icon-notification-default: var(--themes-neutral-dark);
  --color-icon-accordion-default: var(--themes-neutral-strong);
  --color-icon-accordion-hover: var(--themes-action-primary-main);
  --color-icon-play-button-default: var(--color-white-1000);
  --color-icon-category-link-solid-default: var(--themes-action-primary-main);
  --color-icon-category-link-solid-hover: var(--themes-action-primary-dark);
  --color-icon-category-link-solid-active: var(--themes-neutral-strong);
  --color-icon-category-link-solid-focus: var(--themes-neutral-strong);
  --color-icon-category-link-solid-disabled: var(--themes-neutral-light);
  --color-icon-category-link-gradient-default: var(--themes-action-primary-main);
  --color-icon-category-link-gradient-hover: var(--themes-action-primary-dark);
  --color-icon-category-link-gradient-active: var(--themes-neutral-strong);
  --color-icon-category-link-gradient-focus: var(--themes-neutral-strong);
  --color-icon-category-link-gradient-disabled: var(--themes-neutral-light);
  --color-icon-megamenu-link-default: var(--themes-neutral-strong);
  --color-icon-megamenu-link-title-default: var(--themes-neutral-strong);
  --color-icon-megamenu-link-title-hover: var(--themes-action-primary-main);
  --color-icon-megamenu-link-title-active: var(--themes-action-primary-dark);
  --color-icon-megamenu-link-title-focus: var(--themes-neutral-strong);
  --color-icon-megamenu-link-title-disabled: var(--themes-neutral-light);
  --color-icon-megamenu-link-hover: var(--themes-action-primary-main);
  --color-icon-megamenu-link-active: var(--themes-action-primary-dark);
  --color-icon-megamenu-link-focus: var(--themes-neutral-strong);
  --color-icon-megamenu-link-disabled: var(--themes-neutral-light);
  --color-icon-megamenu-link-text-link-default: var(--themes-neutral-strong);
  --color-icon-megamenu-link-text-link-hover: var(--themes-action-primary-main);
  --color-icon-megamenu-link-text-link-active: var(--themes-action-primary-dark);
  --color-icon-megamenu-link-text-link-focus: var(--themes-neutral-strong);
  --color-icon-megamenu-link-text-link-disabled: var(--themes-neutral-light);
  --color-icon-header-link-default: var(--themes-neutral-strong);
  --color-icon-header-link-hover: var(--themes-action-primary-light);
  --color-icon-header-link-active: var(--themes-neutral-strong);
  --color-icon-header-link-focus: var(--themes-neutral-strong);
  --color-icon-header-link-disabled: var(--themes-neutral-light);
  --color-icon-quotation-box-default: var(--themes-neutral-strong);
  --color-icon-text-link-default: var(--themes-neutral-strong);
  --color-icon-text-link-hover: var(--themes-action-primary-main);
  --color-icon-text-link-visited: var(--themes-action-primary-dark);
  --color-icon-text-link-focus: var(--themes-neutral-strong);
  --color-icon-text-link-disabled: var(--themes-neutral-light);
  --color-icon-text-link-stand-alone-primary-solid-normal-default: var(--themes-action-primary-main);
  --color-icon-text-link-stand-alone-primary-solid-normal-hover: var(--themes-action-primary-dark);
  --color-icon-text-link-stand-alone-primary-solid-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-primary-solid-normal-focus: var(--themes-action-primary-main);
  --color-icon-text-link-stand-alone-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-primary-solid-inverted-default: var(--color-white-1000);
  --color-icon-text-link-stand-alone-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-icon-text-link-stand-alone-primary-solid-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-primary-solid-inverted-focus: var(--color-white-1000);
  --color-icon-text-link-stand-alone-primary-solid-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-primary-gradient-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-primary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-primary-gradient-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-secondary-solid-default: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-solid-hover: var(--themes-action-primary-main);
  --color-icon-text-link-stand-alone-secondary-solid-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-secondary-solid-focus: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-solid-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-default: var(--color-white-1000);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-hover: var(--themes-neutral-subtle);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-focus: var(--color-white-1000);
  --color-icon-text-link-stand-alone-secondary-inverted-solid-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-secondary-inverted-gradient-disabled: var(--themes-disabled-main);
  --color-icon-text-link-stand-alone-secondary-inverted-gradient-visited: var(--themes-focus-light);
  --color-icon-text-link-stand-alone-secondary-gradient-default: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-gradient-hover: var(--themes-action-primary-main);
  --color-icon-text-link-stand-alone-secondary-gradient-visited: var(--themes-focus-main);
  --color-icon-text-link-stand-alone-secondary-gradient-focus: var(--themes-neutral-strong);
  --color-icon-text-link-stand-alone-secondary-gradient-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-solid-inverted-default: var(--color-white-1000);
  --color-icon-text-link-hyperlink-primary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-icon-text-link-hyperlink-primary-solid-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-hyperlink-primary-solid-inverted-focus: var(--color-white-1000);
  --color-icon-text-link-hyperlink-primary-solid-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-solid-normal-default: var(--themes-action-primary-main);
  --color-icon-text-link-hyperlink-primary-solid-normal-hover: var(--themes-action-primary-dark);
  --color-icon-text-link-hyperlink-primary-solid-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-primary-solid-normal-focus: var(--themes-action-primary-main);
  --color-icon-text-link-hyperlink-primary-solid-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-gradient-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-hyperlink-primary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-primary-gradient-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-secondary-solid-normal-default: var(--themes-neutral-strong);
  --color-icon-text-link-hyperlink-secondary-solid-normal-hover: var(--themes-action-primary-main);
  --color-icon-text-link-hyperlink-secondary-solid-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-secondary-solid-normal-focus: var(--themes-neutral-strong);
  --color-icon-text-link-hyperlink-secondary-solid-normal-disabled: var(--themes-neutral-light);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-default: var(--color-white-1000);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-hover: var(--themes-neutral-subtle);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-visited: var(--themes-focus-light);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-focus: var(--color-white-1000);
  --color-icon-text-link-hyperlink-secondary-solid-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-secondary-gradient-normal-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-secondary-gradient-normal-visited: var(--themes-focus-main);
  --color-icon-text-link-hyperlink-secondary-gradient-inverted-disabled: var(--themes-disabled-main);
  --color-icon-text-link-hyperlink-secondary-gradient-inverted-visited: var(--themes-focus-light);
  --color-icon-dropdown-default: var(--themes-neutral-strong);
  --color-icon-dropdown-disabled: var(--themes-disabled-main);
  --color-icon-search-field-normal-search: var(--themes-action-secondary-dark);
  --color-icon-search-field-normal-closed: var(--themes-action-secondary-main);
  --color-icon-search-field-inverted-search: var(--themes-action-secondary-dark);
  --color-icon-search-field-inverted-closed: var(--themes-action-secondary-light);
  --color-icon-default-default: var(--themes-neutral-strong);
  --color-icon-default-light: var(--themes-neutral-main);
  --color-icon-default-inverted: var(--themes-neutral-light);
  --color-icon-breadcrumb-normal-chevron: var(--themes-neutral-main);
  --color-icon-breadcrumb-inverted-chevron: var(--themes-neutral-dark);
  --color-icon-application-card-default: var(--themes-neutral-strong);
  --color-icon-button-betting-primary-normal-left-icon-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-left-icon-outlined-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-outlined-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-left-icon-text-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-text-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-text-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-text-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-left-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-right-icon-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-right-icon-outlined-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-outlined-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-normal-right-icon-text-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-text-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-text-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-text-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-normal-right-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-left-icon-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-left-icon-text-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-text-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-text-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-text-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-left-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-right-icon-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-primary-inverted-right-icon-text-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-text-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-text-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-text-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-primary-inverted-right-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-left-icon-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-left-icon-outlined-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-outlined-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-left-icon-text-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-text-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-text-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-text-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-left-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-right-icon-contained-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-contained-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-contained-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-contained-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-contained-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-right-icon-outlined-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-outlined-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-outlined-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-outlined-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-outlined-disabled: var(--themes-disabled-light);
  --color-icon-button-betting-secondary-right-icon-text-default: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-text-hover: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-text-focus: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-text-active: var(--themes-action-primary-on-primary);
  --color-icon-button-betting-secondary-right-icon-text-disabled: var(--themes-disabled-light);
  --color-icon-results-winner: var(--themes-neutral-dark);
  --color-icon-results-servis: var(--themes-neutral-dark);
  --color-icon-results-default: var(--themes-neutral-dark);
  --color-icon-error-background: var(--themes-action-primary-main);
  --color-icon-error-line: var(--themes-action-primary-subtle);
  --color-icon-newsletter-box-default: var(--themes-neutral-main);
  --color-icon-checkbox-default-checked-normal-default: var(--color-white-1000);
  --color-icon-checkbox-default-checked-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-default-checked-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-default-checked-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-default-checked-inverted-default: var(--themes-brand-primary-strong);
  --color-icon-checkbox-default-checked-inverted-hover: var(--themes-brand-primary-strong);
  --color-icon-checkbox-default-checked-inverted-focus: var(--themes-brand-primary-strong);
  --color-icon-checkbox-default-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-default: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-default-all-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-default-all-inverted-default: var(--themes-brand-primary-strong);
  --color-icon-checkbox-default-all-inverted-hover: var(--themes-brand-primary-strong);
  --color-icon-checkbox-default-all-inverted-focus: var(--themes-brand-primary-strong);
  --color-icon-checkbox-default-all-inverted-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-default: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-error-checked-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-default: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-hover: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-focus: var(--color-white-1000);
  --color-icon-checkbox-error-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-default: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-hover: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-focus: var(--color-white-1000);
  --color-icon-checkbox-error-all-normal-disabled: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-default: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-hover: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-focus: var(--color-white-1000);
  --color-icon-checkbox-error-all-inverted-disabled: var(--color-white-1000);
  --color-icon-input-normal-value-default: var(--themes-neutral-main);
  --color-icon-input-normal-value-hover: var(--themes-neutral-dark);
  --color-icon-input-normal-value-focus: var(--themes-neutral-strong);
  --color-icon-input-normal-value-active: var(--themes-neutral-strong);
  --color-icon-input-normal-value-error: var(--themes-neutral-strong);
  --color-icon-input-normal-value-disabled: var(--themes-disabled-light);
  --color-icon-input-normal-prefix-default: var(--themes-neutral-dark);
  --color-icon-input-normal-prefix-hover: var(--themes-neutral-darker);
  --color-icon-input-normal-prefix-focus: var(--themes-neutral-strong);
  --color-icon-input-normal-prefix-active: var(--themes-neutral-strong);
  --color-icon-input-normal-prefix-error: var(--themes-neutral-strong);
  --color-icon-input-normal-prefix-disabled: var(--themes-disabled-light);
  --color-icon-input-normal-sufix-default: var(--themes-neutral-dark);
  --color-icon-input-normal-sufix-hover: var(--themes-neutral-darker);
  --color-icon-input-normal-sufix-focus: var(--themes-neutral-strong);
  --color-icon-input-normal-sufix-active: var(--themes-neutral-strong);
  --color-icon-input-normal-sufix-error: var(--themes-neutral-strong);
  --color-icon-input-normal-sufix-disabled: var(--themes-disabled-light);
  --color-icon-input-inverted-value-default: var(--themes-neutral-main);
  --color-icon-input-inverted-value-hover: var(--themes-neutral-dark);
  --color-icon-input-inverted-value-focus: var(--color-white-1000);
  --color-icon-input-inverted-value-active: var(--color-white-1000);
  --color-icon-input-inverted-value-error: var(--color-white-1000);
  --color-icon-input-inverted-value-disabled: var(--themes-disabled-darker);
  --color-icon-input-inverted-prefix-default: var(--themes-neutral-light);
  --color-icon-input-inverted-prefix-hover: var(--themes-neutral-light);
  --color-icon-input-inverted-prefix-focus: var(--themes-neutral-light);
  --color-icon-input-inverted-prefix-active: var(--themes-neutral-light);
  --color-icon-input-inverted-prefix-error: var(--themes-neutral-light);
  --color-icon-input-inverted-prefix-disabled: var(--themes-disabled-darker);
  --color-icon-input-inverted-sufix-default: var(--themes-neutral-light);
  --color-icon-input-inverted-sufix-hover: var(--themes-neutral-light);
  --color-icon-input-inverted-sufix-focus: var(--themes-neutral-light);
  --color-icon-input-inverted-sufix-active: var(--themes-neutral-light);
  --color-icon-input-inverted-sufix-error: var(--themes-neutral-light);
  --color-icon-input-inverted-sufix-disabled: var(--themes-disabled-darker);
  --color-icon-text-area-normal-default: var(--themes-neutral-light);
  --color-icon-text-area-normal-hover: var(--themes-neutral-dark);
  --color-icon-text-area-normal-focus: var(--themes-neutral-dark);
  --color-icon-text-area-normal-active: var(--themes-neutral-dark);
  --color-icon-text-area-normal-error: var(--themes-neutral-dark);
  --color-icon-text-area-normal-disabled: var(--themes-disabled-light);
  --color-icon-text-area-inverted-default: var(--themes-neutral-light);
  --color-icon-text-area-inverted-hover: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-focus: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-active: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-error: var(--themes-neutral-dark);
  --color-icon-text-area-inverted-disabled: var(--themes-disabled-light);
  --color-icon-tipsport-bet-default: var(--themes-neutral-main);
  --color-icon-info-wrapper-default: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-default: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-hover: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-focus: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-normal-disabled: var(--color-white-1000);
  --color-icon-radiobutton-default-checked-inverted-default: var(--themes-brand-primary-strong);
  --color-icon-radiobutton-default-checked-inverted-hover: var(--themes-brand-primary-strong);
  --color-icon-radiobutton-default-checked-inverted-focus: var(--themes-brand-primary-strong);
  --color-icon-radiobutton-default-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-default: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-hover: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-focus: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-normal-disabled: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-default: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-hover: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-focus: var(--color-white-1000);
  --color-icon-radiobutton-error-checked-inverted-disabled: var(--color-white-1000);
  --color-icon-tab-normal-default: var(--themes-neutral-main);
  --color-icon-tab-normal-hover: var(--themes-neutral-main);
  --color-icon-tab-inverted-default: var(--themes-neutral-main);
  --color-icon-tab-inverted-hover: var(--themes-neutral-main);
  --color-icon-top-match-podani: var(--themes-neutral-strong);
  --color-icon-top-event-winner: var(--themes-neutral-dark);
  --color-icon-top-event-servis: var(--themes-neutral-dark);
  --color-icon-top-event-default: var(--themes-neutral-dark);
  --color-icon-results-card-winner: var(--themes-neutral-dark);
  --color-icon-results-card-servis: var(--themes-neutral-dark);
  --color-icon-results-card-default: var(--themes-neutral-dark);
  --color-icon-commentary-box-yellow: var(--themes-neutral-dark);
  --color-icon-commentary-box-red: var(--themes-neutral-dark);
  --color-icon-commentary-box-dark: var(--themes-neutral-dark);
  --color-icon-commentary-box-light: var(--themes-neutral-dark);
  --color-icon-commentary-box-substitution-in: var(--themes-neutral-dark);
  --color-icon-commentary-box-substitution-out: var(--themes-neutral-dark);
  --color-icon-commentary-box-ball: var(--themes-neutral-dark);
  --color-icon-modal-success: var(--color-surface-succes-light);
  --color-icon-schedule-winner: var(--themes-neutral-dark);
  --color-icon-schedule-servis: var(--themes-neutral-dark);
  --color-icon-schedule-default: var(--themes-neutral-dark);
  --color-icon-live-standings-up: var(--themes-neutral-dark);
  --color-icon-live-standings-down: var(--themes-neutral-dark);
  --color-icon-live-standings-remains: var(--themes-neutral-dark);
  --color-icon-result-module-card-winner: var(--themes-neutral-dark);
  --color-icon-result-module-card-servis: var(--themes-neutral-dark);
  --color-icon-result-module-card-default: var(--themes-neutral-dark);
  --color-icon-survey-success: var(--color-surface-succes-light);
  --color-icon-title-section-default: var(--color-white-1000);
  --color-icon-videoplayer-default: var(--color-white-1000);
  --color-icon-segmented-controls-default: var(--themes-neutral-strong);
  --color-icon-segmented-controls-hover: var(--themes-neutral-strong);
  --color-icon-segmented-controls-focus: var(--themes-neutral-strong);
  --color-icon-segmented-controls-active: var(--themes-brand-primary-subtle);
  --color-icon-segmented-controls-disbaled: var(--themes-disabled-light);
  --color-icon-stepper-finished: var(--color-white-1000);
  --color-icon-stepper-hover: var(--color-white-1000);
  --color-icon-stepper-active: var(--themes-brand-primary-main);
  --spacing-spacer-half: var(--number-scale-4);
  --spacing-spacer-1: var(--number-scale-8);
  --spacing-spacer-2: var(--number-scale-16);
  --spacing-spacer-3: var(--number-scale-24);
  --spacing-spacer-4: var(--number-scale-32);
  --spacing-spacer-5: var(--number-scale-40);
  --spacing-spacer-6: var(--number-scale-48);
  --spacing-spacer-7: var(--number-scale-64);
  --spacing-button-radius: var(--number-scale-8);
  --spacing-icon-button-radius: var(--number-scale-4);
  --spacing-chip-radius: var(--number-scale-9999);
  --spacing-input-radius: var(--number-scale-4);
  --spacing-segmented-controls-radius: var(--number-scale-8);
  --spacing-avatar-radius: var(--number-scale-9999);
  --spacing-global-image-radius-global: var(--number-scale-32);
  --spacing-global-image-radius-1032x580: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-680x382: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-560x315: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-360x202: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-328x185: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-240x135: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-210x119: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-152x86: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-124x70: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-110x62: var(--spacing-global-image-radius-global);
  --spacing-global-image-radius-80x80: var(--spacing-global-image-radius-global);
  --spacing-status-radius: var(--number-scale-9999);
  --spacing-badge-pilled: var(--number-scale-9999);
  --spacing-badge-rounded: var(--number-scale-2);
  --spacing-tag-radius: var(--number-scale-2);
  --spacing-tooltip-radius: var(--number-scale-4);
  --spacing-breaking-news-radius: var(--number-scale-4);
  --spacing-flash-message-radius: var(--number-scale-12);
  --spacing-author-card-radius: var(--number-scale-16);
  --spacing-comments-card-radius: var(--number-scale-16);
  --spacing-modal-radius: var(--number-scale-12);
  --spacing-topics-radius: var(--number-scale-16);
  --spacing-brand-height-2xs: 16px;
  --spacing-brand-height-xs: 20px;
  --spacing-brand-height-sm: 24px;
  --spacing-brand-height-md: 32px;
  --spacing-brand-height-lg: 36px;
  --spacing-brand-height-xl: 40px;
  --spacing-brand-height-2xl: 48px;
  --spacing-brand-height-3xl: 52px;
  --spacing-brand-height-4xl: 64px;
  --spacing-brand-height-5xl: 80px;
  --spacing-header-height: 120px;
  --spacing-header-v-gap: 16px;
  --spacing-header-item-v-padding: 12px;
  --spacing-header-brand-height: var(--spacing-brand-height-2xl);
  --spacing-header-sticky-brand-height: var(--spacing-brand-height-md);
  --spacing-header-mobile-brand-height: var(--spacing-brand-height-sm);
  --spacing-header-bottom-left-radius: 0;
  --spacing-header-bottom-right-radius: 0;
  --spacing-header-top-left-radius: 0;
  --spacing-header-top-right-radius: 0;
  --spacing-page-content-left-h-padding-left: var(--number-scale-0);
  --spacing-page-content-left-h-padding-right: var(--number-scale-64);
  --spacing-premium-card-radius: 4px;
  --spacing-textlink-v-padding: 0;
  --spacing-textlink-h-padding: 0;
  --spacing-card-article-desktop-2xl-01-radius: 0;
  --spacing-article-cards-desktop-2xl-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-card-gap: 16px;
  --spacing-article-cards-desktop-2xl-v01-card-radius: 0;
  --spacing-article-cards-desktop-2xl-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-content-gap: 16px;
  --spacing-article-cards-desktop-2xl-v01-content-radius: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-gap: 0;
  --spacing-article-cards-desktop-2xl-v01-elements-radius: 0;
  --spacing-article-cards-desktop-2xl-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-2xl-v01-title-radius: 0;
  --spacing-article-cards-desktop-xl-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-xl-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-xl-v01-card-gap: 16px;
  --spacing-article-cards-desktop-xl-v01-card-radius: 0;
  --spacing-article-cards-desktop-xl-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-xl-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-xl-v01-content-gap: 20px;
  --spacing-article-cards-desktop-xl-v01-content-radius: 0;
  --spacing-article-cards-desktop-xl-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-xl-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-xl-v01-elements-gap: 12px;
  --spacing-article-cards-desktop-xl-v01-elements-radius: 0;
  --spacing-article-cards-desktop-xl-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-xl-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-xl-v01-title-radius: 0;
  --spacing-article-cards-desktop-xl-v03-card-v-padding: 0;
  --spacing-article-cards-desktop-xl-v03-card-h-padding: 0;
  --spacing-article-cards-desktop-xl-v03-card-gap: 16px;
  --spacing-article-cards-desktop-xl-v03-card-radius: 0;
  --spacing-article-cards-desktop-xl-v03-content-v-padding: 0;
  --spacing-article-cards-desktop-xl-v03-content-h-padding: 0;
  --spacing-article-cards-desktop-xl-v03-content-gap: -168px;
  --spacing-article-cards-desktop-xl-v03-content-radius: 0;
  --spacing-article-cards-desktop-xl-v03-elements-v-padding-top: 150px;
  --spacing-article-cards-desktop-xl-v03-elements-v-padding-bottom: 32px;
  --spacing-article-cards-desktop-xl-v03-elements-h-padding: 24px;
  --spacing-article-cards-desktop-xl-v03-elements-gap: 16px;
  --spacing-article-cards-desktop-xl-v03-elements-radius-top: 0;
  --spacing-article-cards-desktop-xl-v03-elements-radius-bottom: var(--spacing-global-image-radius-680x382);
  --spacing-article-cards-desktop-xl-v03-title-v-padding: 0;
  --spacing-article-cards-desktop-xl-v03-title-h-padding: 0;
  --spacing-article-cards-desktop-xl-v03-title-radius: 0;
  --spacing-article-cards-desktop-xl-v02-card-v-padding: 0;
  --spacing-article-cards-desktop-xl-v02-card-h-padding: 0;
  --spacing-article-cards-desktop-xl-v02-card-gap: 16px;
  --spacing-article-cards-desktop-xl-v02-card-radius: 0;
  --spacing-article-cards-desktop-xl-v02-content-v-padding: 0;
  --spacing-article-cards-desktop-xl-v02-content-h-padding: 0;
  --spacing-article-cards-desktop-xl-v02-content-gap: 20px;
  --spacing-article-cards-desktop-xl-v02-content-radius: 0;
  --spacing-article-cards-desktop-xl-v02-elements-v-padding: 0;
  --spacing-article-cards-desktop-xl-v02-elements-h-padding: 0;
  --spacing-article-cards-desktop-xl-v02-elements-gap: 12px;
  --spacing-article-cards-desktop-xl-v02-elements-radius: 0;
  --spacing-article-cards-desktop-xl-v02-title-v-padding: 0;
  --spacing-article-cards-desktop-xl-v02-title-h-padding: 0;
  --spacing-article-cards-desktop-xl-v02-title-radius: 0;
  --spacing-article-cards-desktop-lg-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-lg-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-lg-v01-card-gap: 16px;
  --spacing-article-cards-desktop-lg-v01-card-radius: 0;
  --spacing-article-cards-desktop-lg-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-lg-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-lg-v01-content-gap: 20px;
  --spacing-article-cards-desktop-lg-v01-content-radius: 0;
  --spacing-article-cards-desktop-lg-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-lg-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-lg-v01-elements-gap: 10px;
  --spacing-article-cards-desktop-lg-v01-elements-radius: 0;
  --spacing-article-cards-desktop-lg-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-lg-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-lg-v01-title-radius: 0;
  --spacing-article-cards-desktop-md-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-md-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-md-v01-card-gap: 16px;
  --spacing-article-cards-desktop-md-v01-card-radius: 0;
  --spacing-article-cards-desktop-md-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-md-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-md-v01-content-gap: 20px;
  --spacing-article-cards-desktop-md-v01-content-radius: 0;
  --spacing-article-cards-desktop-md-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-v01-elements-gap: 8px;
  --spacing-article-cards-desktop-md-v01-elements-radius: 0;
  --spacing-article-cards-desktop-md-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-md-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-md-v01-title-radius: 0;
  --spacing-article-cards-desktop-md-v02-card-v-padding: 0;
  --spacing-article-cards-desktop-md-v02-card-h-padding: 0;
  --spacing-article-cards-desktop-md-v02-card-gap: 16px;
  --spacing-article-cards-desktop-md-v02-card-radius: 0;
  --spacing-article-cards-desktop-md-v02-content-v-padding: 0;
  --spacing-article-cards-desktop-md-v02-content-h-padding: 0;
  --spacing-article-cards-desktop-md-v02-content-gap: 20px;
  --spacing-article-cards-desktop-md-v02-content-radius: 0;
  --spacing-article-cards-desktop-md-v02-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-v02-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-v02-elements-gap: 8px;
  --spacing-article-cards-desktop-md-v02-elements-radius: 0;
  --spacing-article-cards-desktop-md-v02-title-v-padding: 0;
  --spacing-article-cards-desktop-md-v02-title-h-padding: 0;
  --spacing-article-cards-desktop-md-v02-title-radius: 0;
  --spacing-article-cards-desktop-md-other-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-card-gap: 16px;
  --spacing-article-cards-desktop-md-other-v01-card-radius: 0;
  --spacing-article-cards-desktop-md-other-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-content-gap: 20px;
  --spacing-article-cards-desktop-md-other-v01-content-radius: 0;
  --spacing-article-cards-desktop-md-other-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-elements-gap: 8px;
  --spacing-article-cards-desktop-md-other-v01-elements-radius: 0;
  --spacing-article-cards-desktop-md-other-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v01-title-radius: 0;
  --spacing-article-cards-desktop-md-other-v02-card-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-card-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-card-gap: 16px;
  --spacing-article-cards-desktop-md-other-v02-card-radius: 0;
  --spacing-article-cards-desktop-md-other-v02-content-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-content-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-content-gap: 20px;
  --spacing-article-cards-desktop-md-other-v02-content-radius: 0;
  --spacing-article-cards-desktop-md-other-v02-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-elements-gap: 8px;
  --spacing-article-cards-desktop-md-other-v02-elements-radius: 0;
  --spacing-article-cards-desktop-md-other-v02-title-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-title-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v02-title-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-card-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-card-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-card-gap: 16px;
  --spacing-article-cards-desktop-md-other-v03-card-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-content-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-content-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-content-gap: 20px;
  --spacing-article-cards-desktop-md-other-v03-content-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-elements-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-elements-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-elements-gap: 8px;
  --spacing-article-cards-desktop-md-other-v03-elements-radius: 0;
  --spacing-article-cards-desktop-md-other-v03-title-v-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-title-h-padding: 0;
  --spacing-article-cards-desktop-md-other-v03-title-radius: 0;
  --spacing-article-cards-desktop-sm-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-card-gap: 16px;
  --spacing-article-cards-desktop-sm-v01-card-radius: 0;
  --spacing-article-cards-desktop-sm-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-content-gap: 8px;
  --spacing-article-cards-desktop-sm-v01-content-radius: 0;
  --spacing-article-cards-desktop-sm-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-elements-gap: 4px;
  --spacing-article-cards-desktop-sm-v01-elements-radius: 0;
  --spacing-article-cards-desktop-sm-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-sm-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-sm-v01-title-radius: 0;
  --spacing-article-cards-desktop-xs-v01-card-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-card-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-card-gap: 16px;
  --spacing-article-cards-desktop-xs-v01-card-radius: 0;
  --spacing-article-cards-desktop-xs-v01-content-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-content-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-content-gap: 20px;
  --spacing-article-cards-desktop-xs-v01-content-radius: 0;
  --spacing-article-cards-desktop-xs-v01-elements-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-elements-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-elements-gap: 4px;
  --spacing-article-cards-desktop-xs-v01-elements-radius: 0;
  --spacing-article-cards-desktop-xs-v01-title-v-padding: 0;
  --spacing-article-cards-desktop-xs-v01-title-h-padding: 0;
  --spacing-article-cards-desktop-xs-v01-title-radius: 0;
  --spacing-article-cards-mobile-xl-v01-card-v-padding: 0;
  --spacing-article-cards-mobile-xl-v01-card-h-padding: 0;
  --spacing-article-cards-mobile-xl-v01-card-gap: 12px;
  --spacing-article-cards-mobile-xl-v01-card-radius: 0;
  --spacing-article-cards-mobile-xl-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-xl-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-xl-v01-content-gap: 12px;
  --spacing-article-cards-mobile-xl-v01-content-radius: 0;
  --spacing-article-cards-mobile-xl-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-xl-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-xl-v01-elements-gap: 4px;
  --spacing-article-cards-mobile-xl-v01-elements-radius: 0;
  --spacing-article-cards-mobile-xl-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-xl-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-xl-v01-title-radius: 0;
  --spacing-article-cards-mobile-xl-v02-card-v-padding: 0;
  --spacing-article-cards-mobile-xl-v02-card-h-padding: 0;
  --spacing-article-cards-mobile-xl-v02-card-gap: 12px;
  --spacing-article-cards-mobile-xl-v02-card-radius: 0;
  --spacing-article-cards-mobile-xl-v02-content-v-padding: 0;
  --spacing-article-cards-mobile-xl-v02-content-h-padding: 0;
  --spacing-article-cards-mobile-xl-v02-content-gap: 12px;
  --spacing-article-cards-mobile-xl-v02-content-radius: 0;
  --spacing-article-cards-mobile-xl-v02-elements-v-padding: 0;
  --spacing-article-cards-mobile-xl-v02-elements-h-padding: 0;
  --spacing-article-cards-mobile-xl-v02-elements-gap: 4px;
  --spacing-article-cards-mobile-xl-v02-elements-radius: 0;
  --spacing-article-cards-mobile-xl-v02-title-v-padding: 0;
  --spacing-article-cards-mobile-xl-v02-title-h-padding: 0;
  --spacing-article-cards-mobile-xl-v02-title-radius: 0;
  --spacing-article-cards-mobile-xl-v03-card-v-padding: 0;
  --spacing-article-cards-mobile-xl-v03-card-h-padding: 0;
  --spacing-article-cards-mobile-xl-v03-card-gap: 0;
  --spacing-article-cards-mobile-xl-v03-card-radius: 0;
  --spacing-article-cards-mobile-xl-v03-content-v-padding: 0;
  --spacing-article-cards-mobile-xl-v03-content-h-padding: 0;
  --spacing-article-cards-mobile-xl-v03-content-gap: -214px;
  --spacing-article-cards-mobile-xl-v03-content-radius: var(--spacing-global-image-radius-328x185);
  --spacing-article-cards-mobile-xl-v03-elements-v-padding-top: 110px;
  --spacing-article-cards-mobile-xl-v03-elements-v-padding-bottom: 24px;
  --spacing-article-cards-mobile-xl-v03-elements-h-padding: 24px;
  --spacing-article-cards-mobile-xl-v03-elements-gap: 16px;
  --spacing-article-cards-mobile-xl-v03-elements-radius-bottom: var(--spacing-global-image-radius-328x185);
  --spacing-article-cards-mobile-xl-v03-title-v-padding: 0;
  --spacing-article-cards-mobile-xl-v03-title-h-padding: 0;
  --spacing-article-cards-mobile-xl-v03-title-radius: 0;
  --spacing-article-cards-mobile-lg-v01-card-v-padding: 0;
  --spacing-article-cards-mobile-lg-v01-card-h-padding: 0;
  --spacing-article-cards-mobile-lg-v01-card-gap: 12px;
  --spacing-article-cards-mobile-lg-v01-card-radius: 0;
  --spacing-article-cards-mobile-lg-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-lg-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-lg-v01-content-gap: 12px;
  --spacing-article-cards-mobile-lg-v01-content-radius: 0;
  --spacing-article-cards-mobile-lg-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-lg-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-lg-v01-elements-gap: 4px;
  --spacing-article-cards-mobile-lg-v01-elements-radius: 0;
  --spacing-article-cards-mobile-lg-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-lg-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-lg-v01-title-radius: 0;
  --spacing-article-cards-mobile-md-other-v01-card-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-card-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-card-gap: 12px;
  --spacing-article-cards-mobile-md-other-v01-card-radius: 0;
  --spacing-article-cards-mobile-md-other-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-content-gap: 12px;
  --spacing-article-cards-mobile-md-other-v01-content-radius: 0;
  --spacing-article-cards-mobile-md-other-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-elements-gap: 4px;
  --spacing-article-cards-mobile-md-other-v01-elements-radius: 0;
  --spacing-article-cards-mobile-md-other-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v01-title-radius: 0;
  --spacing-article-cards-mobile-md-other-v02-card-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-card-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-card-gap: 12px;
  --spacing-article-cards-mobile-md-other-v02-card-radius: 0;
  --spacing-article-cards-mobile-md-other-v02-content-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-content-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-content-gap: 12px;
  --spacing-article-cards-mobile-md-other-v02-content-radius: 0;
  --spacing-article-cards-mobile-md-other-v02-elements-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-elements-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-elements-gap: 4px;
  --spacing-article-cards-mobile-md-other-v02-elements-radius: 0;
  --spacing-article-cards-mobile-md-other-v02-title-v-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-title-h-padding: 0;
  --spacing-article-cards-mobile-md-other-v02-title-radius: 0;
  --spacing-article-cards-mobile-xs-v01-card-v-padding: 0;
  --spacing-article-cards-mobile-xs-v01-card-h-padding: 0;
  --spacing-article-cards-mobile-xs-v01-card-gap: 12px;
  --spacing-article-cards-mobile-xs-v01-card-radius: 0;
  --spacing-article-cards-mobile-xs-v01-content-v-padding: 0;
  --spacing-article-cards-mobile-xs-v01-content-h-padding: 0;
  --spacing-article-cards-mobile-xs-v01-content-gap: 12px;
  --spacing-article-cards-mobile-xs-v01-content-radius: 0;
  --spacing-article-cards-mobile-xs-v01-elements-v-padding: 0;
  --spacing-article-cards-mobile-xs-v01-elements-h-padding: 0;
  --spacing-article-cards-mobile-xs-v01-elements-gap: 4px;
  --spacing-article-cards-mobile-xs-v01-elements-radius: 0;
  --spacing-article-cards-mobile-xs-v01-title-v-padding: 0;
  --spacing-article-cards-mobile-xs-v01-title-h-padding: 0;
  --spacing-article-cards-mobile-xs-v01-title-radius: 0;
  --spacing-article-cards-mobile-m-other-radius: 0;
  --spacing-vote-strip-radius: var(--number-scale-0);
  --spacing-discussion-box-radius: var(--number-scale-0);
  --spacing-discussion-card-radius: var(--number-scale-0);
  --spacing-info-bar-video-v-gap: 8px;
  --spacing-info-bar-video-desktop-top-padding: 8px;
  --spacing-info-bar-video-desktop-bot-padding: 8px;
  --spacing-info-bar-video-mobile-top-padding: 4px;
  --spacing-info-bar-video-mobile-bot-padding: 4px;
  --spacing-gallery-box-radius: var(--number-scale-12);
  --spacing-gallery-box-v-gap: 8px;
  --spacing-result-card-radius: var(--number-scale-12);
  --spacing-schedule-radius: var(--number-scale-12);
  --properties-premium-brand-size-36: lg;
  --properties-global-image-play-button-style: solid;
  --properties-article-card-meta-section-purpose: text;
  --themes-brand-primary-subtle: var(--lide-a-zeme-blue-50);
  --themes-brand-primary-less-subtle: var(--lide-a-zeme-blue-100);
  --themes-brand-primary-lighter: var(--lide-a-zeme-blue-200);
  --themes-brand-primary-light: var(--lide-a-zeme-blue-400);
  --themes-brand-primary-main: var(--lide-a-zeme-blue-500);
  --themes-brand-primary-dark: var(--lide-a-zeme-blue-700);
  --themes-brand-primary-darker: var(--lide-a-zeme-blue-900);
  --themes-brand-primary-strong: var(--lide-a-zeme-blue-950);
  --themes-brand-primary-on-primary: var(--color-white-1000);
  --themes-brand-secondary-subtle: var(--lide-a-zeme-yellow-50);
  --themes-brand-secondary-lighter: var(--lide-a-zeme-yellow-100);
  --themes-brand-secondary-light: var(--lide-a-zeme-yellow-300);
  --themes-brand-secondary-main: var(--lide-a-zeme-yellow-500);
  --themes-brand-secondary-dark: var(--lide-a-zeme-yellow-700);
  --themes-brand-secondary-darker: var(--lide-a-zeme-yellow-900);
  --themes-brand-secondary-strong: var(--lide-a-zeme-yellow-950);
  --themes-brand-secondary-on-secondary: var(--color-black-1000);
  --themes-gradient-page-front: #fff;
  --themes-gradient-page-back: #fff;
  --themes-gradient-01-front: #fff;
  --themes-gradient-01-back: #fff;
  --themes-gradient-02-front: #fff;
  --themes-gradient-02-back: #fff;
  --themes-gradient-03-front: #fff;
  --themes-gradient-03-back: #fff;
  --themes-gradient-04-front: #fff;
  --themes-gradient-04-back: #fff;
  --themes-gradient-05-back: #fff;
  --themes-gradient-05-middle: #fff;
  --themes-gradient-05-front: #fff;
  --themes-gradient-06-front: #fff;
  --themes-gradient-06-back: #fff;
  --themes-gradient-07-front: #fff;
  --themes-gradient-07-back: #fff;
  --themes-accent-subtle: var(--color-gray-50);
  --themes-accent-lighter: var(--color-gray-100);
  --themes-accent-light: var(--color-gray-200);
  --themes-accent-main: var(--color-gray-300);
  --themes-accent-dark: var(--color-gray-400);
  --themes-accent-darker: var(--color-gray-500);
  --themes-accent-strong: var(--color-gray-600);
  --themes-accent-on-accent: var(--color-gray-950);
  --themes-accent-secondary-subtle: var(--lide-a-zeme-yellow-50);
  --themes-accent-secondary-lighter: var(--lide-a-zeme-yellow-100);
  --themes-accent-secondary-light: var(--lide-a-zeme-yellow-300);
  --themes-accent-secondary-main: var(--lide-a-zeme-yellow-500);
  --themes-accent-secondary-dark: var(--lide-a-zeme-yellow-700);
  --themes-accent-secondary-darker: var(--lide-a-zeme-yellow-900);
  --themes-accent-secondary-strong: var(--lide-a-zeme-yellow-950);
  --themes-accent-secondary-on-secondary: var(--color-black-1000);
  --themes-neutral-subtle: var(--color-gray-50);
  --themes-neutral-lighter: var(--color-gray-100);
  --themes-neutral-light: var(--color-gray-200);
  --themes-neutral-main: var(--color-gray-300);
  --themes-neutral-dark: var(--color-gray-400);
  --themes-neutral-darker: var(--color-gray-800);
  --themes-neutral-strong: var(--color-gray-950);
  --themes-neutral-on-neutral: var(--color-gray-950);
  --themes-action-primary-subtle: var(--lide-a-zeme-blue-50);
  --themes-action-primary-lighter: var(--lide-a-zeme-blue-200);
  --themes-action-primary-light: var(--lide-a-zeme-blue-300);
  --themes-action-primary-main: var(--lide-a-zeme-blue-500);
  --themes-action-primary-dark: var(--lide-a-zeme-blue-700);
  --themes-action-primary-darker: var(--lide-a-zeme-blue-900);
  --themes-action-primary-strong: var(--lide-a-zeme-blue-950);
  --themes-action-primary-on-primary: var(--color-white-1000);
  --themes-action-secondary-subtle: var(--color-gray-50);
  --themes-action-secondary-lighter: var(--color-gray-100);
  --themes-action-secondary-light: var(--color-gray-300);
  --themes-action-secondary-main: var(--color-gray-400);
  --themes-action-secondary-dark: var(--color-gray-700);
  --themes-action-secondary-darker: var(--color-gray-900);
  --themes-action-secondary-strong: var(--color-gray-950);
  --themes-action-secondary-on-secondary: var(--color-white-1000);
  --themes-action-inverted-subtle: var(--color-white-1000);
  --themes-action-inverted-lighter: var(--color-white-1000);
  --themes-action-inverted-light: var(--color-white-1000);
  --themes-action-inverted-main: var(--color-white-1000);
  --themes-action-inverted-dark: var(--color-white-1000);
  --themes-action-inverted-darker: var(--color-white-1000);
  --themes-action-inverted-strong: var(--color-white-1000);
  --themes-action-inverted-on-inverted: var(--color-white-1000);
  --themes-info-subtle: var(--color-blue-50);
  --themes-info-lighter: var(--color-blue-200);
  --themes-info-light: var(--color-blue-400);
  --themes-info-main: var(--color-blue-600);
  --themes-info-dark: var(--color-blue-800);
  --themes-info-darker: var(--color-blue-900);
  --themes-info-strong: var(--color-blue-950);
  --themes-info-on-info: var(--color-white-1000);
  --themes-success-subtle: var(--color-green-50);
  --themes-success-lighter: var(--color-green-200);
  --themes-success-light: var(--color-green-400);
  --themes-success-main: var(--color-green-600);
  --themes-success-dark: var(--color-green-700);
  --themes-success-darker: var(--color-green-900);
  --themes-success-strong: var(--color-green-950);
  --themes-success-on-success: var(--color-white-1000);
  --themes-warning-subtle: var(--color-yellow-50);
  --themes-warning-lighter: var(--color-yellow-200);
  --themes-warning-light: var(--color-yellow-300);
  --themes-warning-main: var(--color-yellow-500);
  --themes-warning-dark: var(--color-yellow-600);
  --themes-warning-darker: var(--color-yellow-800);
  --themes-warning-strong: var(--color-yellow-950);
  --themes-warning-on-warning: var(--color-gray-950);
  --themes-error-subtle: var(--color-red-50);
  --themes-error-lighter: var(--color-red-200);
  --themes-error-light: var(--color-red-400);
  --themes-error-main: var(--color-red-600);
  --themes-error-dark: var(--color-red-700);
  --themes-error-darker: var(--color-red-900);
  --themes-error-strong: var(--color-red-950);
  --themes-error-on-error: var(--color-white-1000);
  --themes-focus-subtle: var(--color-violet-50);
  --themes-focus-lighter: var(--color-violet-200);
  --themes-focus-light: var(--color-violet-400);
  --themes-focus-main: var(--color-violet-600);
  --themes-focus-dark: var(--color-violet-700);
  --themes-focus-darker: var(--color-violet-900);
  --themes-focus-strong: var(--color-violet-950);
  --themes-disabled-subtle: var(--color-gray-50);
  --themes-disabled-lighter: var(--color-gray-100);
  --themes-disabled-light: var(--color-gray-200);
  --themes-disabled-main: var(--color-gray-300);
  --themes-disabled-dark: var(--color-gray-400);
  --themes-disabled-darker: var(--color-gray-500);
  --themes-disabled-strong: var(--color-gray-600);
  --themes-shadows: #fff;
  --typography-desktop-heading-3xl-font-size: 42px;
  --typography-desktop-heading-3xl-line-height: 56px;
  --typography-desktop-heading-2xl-font-size: 38px;
  --typography-desktop-heading-2xl-line-height: 42px;
  --typography-desktop-heading-xl-font-size: 28px;
  --typography-desktop-heading-xl-line-height: 40px;
  --typography-desktop-heading-lg-font-size: 22px;
  --typography-desktop-heading-lg-line-height: 32px;
  --typography-desktop-heading-md-font-size: 19px;
  --typography-desktop-heading-md-line-height: 28px;
  --typography-desktop-heading-sm-font-size: 16px;
  --typography-desktop-heading-sm-line-height: 24px;
  --typography-desktop-heading-xs-font-size: 15px;
  --typography-desktop-heading-xs-line-height: 19px;
  --typography-desktop-heading-2xs-font-size: 14px;
  --typography-desktop-heading-2xs-line-height: 18px;
  --typography-desktop-inter-title-font-size: 28px;
  --typography-desktop-inter-title-line-height: 32px;
  --typography-desktop-section-title-font-size: 30px;
  --typography-desktop-section-title-line-height: 36px;
  --typography-desktop-subtitle-lg-font-size: 20px;
  --typography-desktop-subtitle-lg-line-height: 30px;
  --typography-desktop-subtitle-md-font-size: 18px;
  --typography-desktop-subtitle-md-line-height: 28px;
  --typography-desktop-body-lg-font-size: 18px;
  --typography-desktop-body-lg-line-height: 32px;
  --typography-desktop-body-md-font-size: 14px;
  --typography-desktop-body-md-line-height: 20px;
  --typography-desktop-body-sm-font-size: 13px;
  --typography-desktop-body-sm-line-height: 20px;
  --typography-desktop-body-xs-font-size: 12px;
  --typography-desktop-body-xs-line-height: 16px;
  --typography-desktop-button-lg-font-size: 16px;
  --typography-desktop-button-lg-line-height: 28px;
  --typography-desktop-button-md-font-size: 15px;
  --typography-desktop-button-md-line-height: 24px;
  --typography-desktop-button-sm-font-size: 14px;
  --typography-desktop-button-sm-line-height: 20px;
  --typography-desktop-button-xs-font-size: 12px;
  --typography-desktop-button-xs-line-height: 16px;
  --typography-desktop-menu-item-font-size: 16px;
  --typography-desktop-menu-item-line-height: 20px;
  --typography-desktop-label-font-size: 14px;
  --typography-desktop-label-line-height: 16px;
  --typography-desktop-helper-font-size: 12px;
  --typography-desktop-helper-line-height: 12px;
  --typography-mobile-heading-3xl-font-size: 34px;
  --typography-mobile-heading-3xl-line-height: 44px;
  --typography-mobile-heading-2xl-font-size: 32px;
  --typography-mobile-heading-2xl-line-height: 40px;
  --typography-mobile-heading-xl-font-size: 25px;
  --typography-mobile-heading-xl-line-height: 32px;
  --typography-mobile-heading-lg-font-size: 22px;
  --typography-mobile-heading-lg-line-height: 32px;
  --typography-mobile-heading-md-font-size: 19px;
  --typography-mobile-heading-md-line-height: 28px;
  --typography-mobile-heading-sm-font-size: 16px;
  --typography-mobile-heading-sm-line-height: 24px;
  --typography-mobile-heading-xs-font-size: 15px;
  --typography-mobile-heading-xs-line-height: 19px;
  --typography-mobile-heading-2xs-font-size: 14px;
  --typography-mobile-heading-2xs-line-height: 18px;
  --typography-mobile-inter-title-font-size: 22px;
  --typography-mobile-inter-title-line-height: 32px;
  --typography-mobile-section-title-font-size: 30px;
  --typography-mobile-section-title-line-height: 36px;
  --typography-mobile-subtitle-lg-font-size: 20px;
  --typography-mobile-subtitle-lg-line-height: 30px;
  --typography-mobile-subtitle-md-font-size: 18px;
  --typography-mobile-subtitle-md-line-height: 28px;
  --typography-mobile-body-lg-font-size: 18px;
  --typography-mobile-body-lg-line-height: 32px;
  --typography-mobile-body-md-font-size: 14px;
  --typography-mobile-body-md-line-height: 20px;
  --typography-mobile-body-sm-font-size: 13px;
  --typography-mobile-body-sm-line-height: 20px;
  --typography-mobile-body-xs-font-size: 12px;
  --typography-mobile-body-xs-line-height: 17px;
  --typography-mobile-button-lg-font-size: 16px;
  --typography-mobile-button-lg-line-height: 28px;
  --typography-mobile-button-md-font-size: 15px;
  --typography-mobile-button-md-line-height: 24px;
  --typography-mobile-button-sm-font-size: 14px;
  --typography-mobile-button-sm-line-height: 20px;
  --typography-mobile-button-xs-font-size: 12px;
  --typography-mobile-button-xs-line-height: 16px;
  --typography-mobile-menu-item-font-size: 16px;
  --typography-mobile-menu-item-line-height: 20px;
  --typography-mobile-label-font-size: 14px;
  --typography-mobile-label-line-height: 16px;
  --typography-mobile-helper-font-size: 12px;
  --typography-mobile-helper-line-height: 12px;
  --typography-mobile-table-font-size: 12px;
  --typography-mobile-table-line-height: 12px;
  --article-card-color-surface-content: var(--color-surface-default-contrast);
  --article-card-color-surface-bacground: #fff;
  --article-card-spacing-xl-d-v-gap: 20px;
  --article-card-spacing-xl-d-meta-h-gap: 0;
  --article-card-spacing-xl-d-meta-v-gap: 0;
  --article-card-spacing-xl-d-content-h-padding: 0;
  --article-card-spacing-xl-d-content-v-gap: 0;
  --article-card-spacing-xl-d-content-title-h-padding: 0;
  --article-card-spacing-xl-d-content-title-v-padding: 0;
  --article-card-spacing-xl-m-v-gap: 12px;
  --article-card-spacing-xl-m-content-h-padding: 0;
  --article-card-spacing-xl-m-content-v-gap: 0;
  --article-card-spacing-xl-m-content-title-h-padding: 0;
  --article-card-spacing-xl-m-content-title-v-padding: 0;
  --article-card-spacing-xl-m-meta-h-gap: 0;
  --article-card-spacing-xl-m-meta-v-gap: 0;
  --article-card-spacing-xxl-v-gap: 20px;
  --article-card-spacing-xxl-content-r-padding: 0;
  --article-card-spacing-xxl-content-t-padding: 0;
  --article-card-spacing-xxl-content-v-gap: 0;
  --article-card-spacing-xxl-content-title-h-padding: 0;
  --article-card-spacing-xxl-content-title-v-padding: 0;
  --article-card-spacing-xxl-meta-h-gap: 0;
  --article-card-spacing-xxl-meta-v-gap: 0;
  --article-card-spacing-xs-m-v-gap: 12px;
  --article-card-spacing-xs-m-content-h-padding: 0;
  --article-card-spacing-xs-m-content-v-gap: 0;
  --article-card-spacing-xs-m-content-title-h-padding: 0;
  --article-card-spacing-xs-m-content-title-v-padding: 0;
  --article-card-spacing-xs-m-meta-h-gap: 0;
  --article-card-spacing-xs-m-meta-v-gap: 0;
  --article-card-radius-article-card-xxl: var(--number-scale-32);
  --article-card-radius-article-card-xl: var(--number-scale-32);
  --article-card-radius-article-card-l: var(--number-scale-20);
  --article-card-radius-article-card-m: var(--number-scale-16);
  --color-surface-default-light: var(--themes-neutral-lighter);
  --color-surface-default-main: var(--themes-neutral-light);
  --color-surface-default-dark: var(--themes-neutral-main);
  --color-surface-default-slightly-darker: var(--themes-neutral-dark);
  --color-surface-default-darker: var(--themes-neutral-darker);
  --color-surface-default-strong: var(--themes-neutral-strong);
  --color-surface-default-hover: var(--themes-neutral-subtle);
  --color-surface-default-pressed: var(--themes-brand-primary-subtle);
  --color-surface-default-active: var(--themes-brand-primary-subtle);
  --color-surface-default-focus: var(--themes-brand-primary-subtle);
  --color-surface-default-disabled: var(--themes-neutral-subtle);
  --color-surface-default-overlay: var(--color-black-700);
  --color-surface-default-contrast: var(--color-white-1000);
  --color-surface-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-surface-brand-primary-less-subtle: var(--themes-brand-primary-less-subtle);
  --color-surface-brand-primary-light: var(--themes-brand-primary-light);
  --color-surface-brand-primary-main: var(--themes-brand-primary-main);
  --color-surface-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-surface-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-surface-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-surface-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-surface-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-surface-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-surface-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-surface-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-surface-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-surface-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-surface-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-surface-accent-subtle: var(--themes-accent-subtle);
  --color-surface-accent-light: var(--themes-accent-lighter);
  --color-surface-accent-main: var(--themes-accent-light);
  --color-surface-accent-dark: var(--themes-accent-main);
  --color-surface-accent-strong: var(--themes-accent-strong);
  --color-surface-accent-hover: var(--themes-accent-main);
  --color-surface-accent-pressed: var(--themes-accent-lighter);
  --color-surface-neutral-subtle: var(--themes-neutral-subtle);
  --color-surface-neutral-light: var(--themes-neutral-lighter);
  --color-surface-neutral-main: var(--themes-neutral-light);
  --color-surface-neutral-dark: var(--themes-neutral-main);
  --color-surface-neutral-strong: var(--themes-neutral-strong);
  --color-surface-neutral-hover: var(--themes-neutral-main);
  --color-surface-neutral-pressed: var(--themes-neutral-lighter);
  --color-surface-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-surface-action-primary-light: var(--themes-action-primary-light);
  --color-surface-action-primary-main: var(--themes-action-primary-main);
  --color-surface-action-primary-dark: var(--themes-action-primary-dark);
  --color-surface-action-primary-strong: var(--themes-action-primary-strong);
  --color-surface-action-primary-hover: var(--themes-action-primary-dark);
  --color-surface-action-primary-pressed: var(--themes-action-primary-light);
  --color-surface-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-surface-action-secondary-light: var(--themes-action-secondary-light);
  --color-surface-action-secondary-main: var(--themes-action-secondary-main);
  --color-surface-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-surface-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-surface-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-surface-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-surface-info-subtle: var(--themes-info-subtle);
  --color-surface-info-light: var(--themes-info-light);
  --color-surface-info-main: var(--themes-info-main);
  --color-surface-info-dark: var(--themes-info-dark);
  --color-surface-info-strong: var(--themes-info-strong);
  --color-surface-info-hover: var(--themes-info-dark);
  --color-surface-info-pressed: var(--themes-info-light);
  --color-surface-succes-subtle: var(--themes-success-subtle);
  --color-surface-succes-light: var(--themes-success-light);
  --color-surface-succes-main: var(--themes-success-main);
  --color-surface-succes-dark: var(--themes-success-dark);
  --color-surface-succes-strong: var(--themes-success-strong);
  --color-surface-succes-hover: var(--themes-success-dark);
  --color-surface-succes-pressed: var(--themes-success-light);
  --color-surface-warning-subtle: var(--themes-warning-subtle);
  --color-surface-warning-light: var(--themes-warning-light);
  --color-surface-warning-main: var(--themes-warning-main);
  --color-surface-warning-dark: var(--themes-warning-dark);
  --color-surface-warning-strong: var(--themes-warning-strong);
  --color-surface-warning-hover: var(--themes-warning-dark);
  --color-surface-warning-pressed: var(--themes-warning-light);
  --color-surface-error-subtle: var(--themes-error-subtle);
  --color-surface-error-light: var(--themes-error-light);
  --color-surface-error-main: var(--themes-error-main);
  --color-surface-error-dark: var(--themes-error-dark);
  --color-surface-error-strong: var(--themes-error-strong);
  --color-surface-error-hover: var(--themes-error-dark);
  --color-surface-error-pressed: var(--themes-error-light);
  --color-text-default-title-hover: var(--themes-action-primary-main);
  --color-text-default-subtitle-hover: var(--themes-action-secondary-main);
  --color-text-default-helper: var(--themes-neutral-dark);
  --color-text-default-caption: var(--themes-neutral-main);
  --color-text-default-placeholder: var(--themes-neutral-main);
  --color-text-default-disabled: var(--themes-neutral-light);
  --color-text-default-contrast: var(--color-white-1000);
  --color-text-link-primary-pressed: var(--themes-action-primary-light);
  --color-text-link-primary-visited: var(--themes-action-primary-main);
  --color-text-link-primary-contrast: var(--themes-action-primary-on-primary);
  --color-text-link-primary-contrast-hover: var(--themes-action-primary-light);
  --color-text-link-primary-contrast-pressed: var(--themes-action-primary-subtle);
  --color-text-link-primary-contrast-visited: var(--themes-action-primary-on-primary);
  --color-text-link-secondary-default: var(--themes-action-secondary-main);
  --color-text-link-secondary-hover: var(--themes-action-secondary-dark);
  --color-text-link-secondary-pressed: var(--themes-action-secondary-light);
  --color-text-link-secondary-visited: var(--themes-action-secondary-main);
  --color-text-link-secondary-contrast: var(--themes-action-secondary-on-secondary);
  --color-text-link-secondary-contrast-hover: var(--themes-action-secondary-light);
  --color-text-link-secondary-contrast-pressed: var(--themes-action-secondary-subtle);
  --color-text-link-secondary-contrast-visited: var(--themes-action-secondary-on-secondary);
  --color-text-link-neutral-pressed: var(--themes-neutral-main);
  --color-text-link-neutral-visited: var(--themes-neutral-strong);
  --color-text-link-neutral-contrast: var(--themes-neutral-subtle);
  --color-text-link-neutral-contrast-hover: var(--themes-neutral-light);
  --color-text-link-neutral-contrast-pressed: var(--themes-neutral-main);
  --color-text-link-neutral-contrast-visited: var(--themes-neutral-subtle);
  --color-text-link-menu-default: var(--themes-neutral-strong);
  --color-text-link-menu-hover: var(--themes-action-primary-main);
  --color-text-link-menu-active: var(--themes-neutral-strong);
  --color-text-link-menu-focus: var(--themes-neutral-strong);
  --color-text-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-text-brand-primary-light: var(--themes-brand-primary-light);
  --color-text-brand-primary-main: var(--themes-brand-primary-main);
  --color-text-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-text-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-text-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-text-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-text-brand-primary-on-primary: var(--themes-brand-primary-on-primary);
  --color-text-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-text-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-text-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-text-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-text-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-text-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-text-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-text-brand-secondary-on-secondary: var(--themes-brand-secondary-on-secondary);
  --color-text-accent-subtle: var(--themes-accent-subtle);
  --color-text-accent-light: var(--themes-accent-light);
  --color-text-accent-main: var(--themes-accent-dark);
  --color-text-accent-dark: var(--themes-accent-darker);
  --color-text-accent-strong: var(--themes-accent-strong);
  --color-text-accent-hover: var(--themes-accent-darker);
  --color-text-accent-pressed: var(--themes-accent-light);
  --color-text-accent-on-accent: var(--themes-accent-on-accent);
  --color-text-neutral-subtle: var(--themes-neutral-subtle);
  --color-text-neutral-light: var(--themes-neutral-main);
  --color-text-neutral-main: var(--themes-neutral-dark);
  --color-text-neutral-dark: var(--themes-neutral-darker);
  --color-text-neutral-strong: var(--themes-neutral-strong);
  --color-text-neutral-hover: var(--themes-neutral-darker);
  --color-text-neutral-pressed: var(--themes-neutral-main);
  --color-text-neutral-on-neutral: var(--color-white-1000);
  --color-text-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-text-action-primary-light: var(--themes-action-primary-light);
  --color-text-action-primary-main: var(--themes-action-primary-main);
  --color-text-action-primary-dark: var(--themes-action-primary-dark);
  --color-text-action-primary-strong: var(--themes-action-primary-strong);
  --color-text-action-primary-hover: var(--themes-action-primary-dark);
  --color-text-action-primary-pressed: var(--themes-action-primary-light);
  --color-text-action-primary-on-primary: var(--themes-action-primary-on-primary);
  --color-text-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-text-action-secondary-light: var(--themes-action-secondary-light);
  --color-text-action-secondary-main: var(--themes-action-secondary-main);
  --color-text-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-text-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-text-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-text-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-text-action-secondary-on-secondary: var(--themes-action-secondary-on-secondary);
  --color-text-info-subtle: var(--themes-info-subtle);
  --color-text-info-light: var(--themes-info-light);
  --color-text-info-main: var(--themes-info-main);
  --color-text-info-dark: var(--themes-info-dark);
  --color-text-info-strong: var(--themes-info-strong);
  --color-text-info-hover: var(--themes-info-dark);
  --color-text-info-pressed: var(--themes-info-light);
  --color-text-info-on-info: var(--themes-info-on-info);
  --color-text-succes-subtle: var(--themes-success-subtle);
  --color-text-succes-light: var(--themes-success-light);
  --color-text-succes-main: var(--themes-success-main);
  --color-text-succes-dark: var(--themes-success-dark);
  --color-text-succes-strong: var(--themes-success-strong);
  --color-text-succes-hover: var(--themes-success-dark);
  --color-text-succes-pressed: var(--themes-success-light);
  --color-text-succes-on-succes: var(--themes-success-on-success);
  --color-text-warning-subtle: var(--themes-warning-subtle);
  --color-text-warning-light: var(--themes-warning-light);
  --color-text-warning-main: var(--themes-warning-main);
  --color-text-warning-dark: var(--themes-warning-dark);
  --color-text-warning-strong: var(--themes-warning-strong);
  --color-text-warning-hover: var(--themes-warning-dark);
  --color-text-warning-pressed: var(--themes-warning-light);
  --color-text-warning-on-warning: var(--themes-warning-on-warning);
  --color-text-error-subtle: var(--themes-error-subtle);
  --color-text-error-light: var(--themes-error-light);
  --color-text-error-main: var(--themes-error-main);
  --color-text-error-dark: var(--themes-error-dark);
  --color-text-error-strong: var(--themes-error-strong);
  --color-text-error-hover: var(--themes-error-dark);
  --color-text-error-pressed: var(--themes-error-light);
  --color-text-error-on-error: var(--themes-error-on-error);
  --color-border-default-light: var(--themes-neutral-lighter);
  --color-border-default-main: var(--themes-neutral-light);
  --color-border-default-strong: var(--themes-neutral-strong);
  --color-border-default-hover: var(--themes-neutral-dark);
  --color-border-default-pressed: var(--color-icon-action-primary-subtle);
  --color-border-default-active: var(--color-icon-action-primary-subtle);
  --color-border-default-focus: var(--color-icon-action-primary-light);
  --color-border-default-disabled: var(--themes-neutral-light);
  --color-border-default-contrast: var(--color-white-1000);
  --color-border-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-border-brand-primary-light: var(--themes-brand-primary-light);
  --color-border-brand-primary-main: var(--themes-brand-primary-main);
  --color-border-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-border-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-border-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-border-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-border-brand-primary-on-primary: var(--themes-brand-primary-on-primary);
  --color-border-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-border-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-border-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-border-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-border-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-border-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-border-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-border-brand-secondary-on-secondary: var(--themes-brand-secondary-on-secondary);
  --color-border-accent-subtle: var(--themes-accent-subtle);
  --color-border-accent-light: var(--themes-accent-light);
  --color-border-accent-main: var(--themes-accent-main);
  --color-border-accent-dark: var(--themes-accent-dark);
  --color-border-accent-strong: var(--themes-accent-strong);
  --color-border-accent-hover: var(--themes-accent-dark);
  --color-border-accent-pressed: var(--themes-accent-light);
  --color-border-accent-on-accent: var(--themes-accent-on-accent);
  --color-border-neutral-subtle: var(--themes-neutral-subtle);
  --color-border-neutral-light: var(--themes-neutral-lighter);
  --color-border-neutral-main: var(--themes-neutral-light);
  --color-border-neutral-dark: var(--themes-neutral-main);
  --color-border-neutral-strong: var(--themes-neutral-strong);
  --color-border-neutral-hover: var(--themes-neutral-dark);
  --color-border-neutral-pressed: var(--themes-neutral-light);
  --color-border-neutral-on-neutral: var(--color-white-1000);
  --color-border-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-border-action-primary-light: var(--themes-action-primary-light);
  --color-border-action-primary-main: var(--themes-action-primary-main);
  --color-border-action-primary-dark: var(--themes-action-primary-dark);
  --color-border-action-primary-strong: var(--themes-action-primary-strong);
  --color-border-action-primary-hover: var(--themes-action-primary-dark);
  --color-border-action-primary-pressed: var(--themes-action-primary-light);
  --color-border-action-primary-on-primary: var(--themes-action-primary-on-primary);
  --color-border-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-border-action-secondary-light: var(--themes-action-secondary-light);
  --color-border-action-secondary-main: var(--themes-action-secondary-main);
  --color-border-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-border-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-border-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-border-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-border-action-secondary-on-secondary: var(--themes-action-secondary-on-secondary);
  --color-border-info-subtle: var(--themes-info-subtle);
  --color-border-info-light: var(--themes-info-light);
  --color-border-info-main: var(--themes-info-main);
  --color-border-info-dark: var(--themes-info-dark);
  --color-border-info-strong: var(--themes-info-strong);
  --color-border-info-hover: var(--themes-info-dark);
  --color-border-info-pressed: var(--themes-info-light);
  --color-border-info-on-info: var(--themes-info-on-info);
  --color-border-succes-subtle: var(--themes-success-subtle);
  --color-border-succes-light: var(--themes-success-light);
  --color-border-succes-main: var(--themes-success-main);
  --color-border-succes-dark: var(--themes-success-dark);
  --color-border-succes-strong: var(--themes-success-strong);
  --color-border-succes-hover: var(--themes-success-dark);
  --color-border-succes-pressed: var(--themes-success-light);
  --color-border-succes-on-succes: var(--themes-success-on-success);
  --color-border-warning-subtle: var(--themes-warning-subtle);
  --color-border-warning-light: var(--themes-warning-light);
  --color-border-warning-main: var(--themes-warning-main);
  --color-border-warning-dark: var(--themes-warning-dark);
  --color-border-warning-strong: var(--themes-warning-strong);
  --color-border-warning-hover: var(--themes-warning-dark);
  --color-border-warning-pressed: var(--themes-warning-light);
  --color-border-warning-on-warning: var(--themes-warning-on-warning);
  --color-border-error-subtle: var(--themes-error-subtle);
  --color-border-error-light: var(--themes-error-light);
  --color-border-error-main: var(--themes-error-main);
  --color-border-error-dark: var(--themes-error-dark);
  --color-border-error-strong: var(--themes-error-strong);
  --color-border-error-hover: var(--themes-error-dark);
  --color-border-error-pressed: var(--themes-error-light);
  --color-border-error-on-error: var(--themes-error-on-error);
  --color-icon-default-subtle: var(--themes-neutral-subtle);
  --color-icon-default-main: var(--color-icon-action-primary-main);
  --color-icon-default-hover: var(--color-icon-action-primary-hover);
  --color-icon-default-pressed: var(--color-icon-action-primary-pressed);
  --color-icon-default-active: var(--color-icon-action-primary-subtle);
  --color-icon-default-focus: var(--color-icon-action-primary-light);
  --color-icon-default-neutral: var(--themes-neutral-main);
  --color-icon-default-strong: var(--themes-neutral-strong);
  --color-icon-default-disabled: var(--themes-neutral-light);
  --color-icon-default-contrast: var(--color-white-1000);
  --color-icon-brand-primary-subtle: var(--themes-brand-primary-subtle);
  --color-icon-brand-primary-light: var(--themes-brand-primary-light);
  --color-icon-brand-primary-main: var(--themes-brand-primary-main);
  --color-icon-brand-primary-dark: var(--themes-brand-primary-dark);
  --color-icon-brand-primary-strong: var(--themes-brand-primary-strong);
  --color-icon-brand-primary-hover: var(--themes-brand-primary-dark);
  --color-icon-brand-primary-pressed: var(--themes-brand-primary-light);
  --color-icon-brand-primary-on-primary: var(--themes-brand-primary-on-primary);
  --color-icon-brand-secondary-subtle: var(--themes-brand-secondary-subtle);
  --color-icon-brand-secondary-light: var(--themes-brand-secondary-light);
  --color-icon-brand-secondary-main: var(--themes-brand-secondary-main);
  --color-icon-brand-secondary-dark: var(--themes-brand-secondary-dark);
  --color-icon-brand-secondary-strong: var(--themes-brand-secondary-strong);
  --color-icon-brand-secondary-hover: var(--themes-brand-secondary-dark);
  --color-icon-brand-secondary-pressed: var(--themes-brand-secondary-light);
  --color-icon-brand-secondary-on-secondary: var(--themes-brand-secondary-on-secondary);
  --color-icon-accent-subtle: var(--themes-accent-subtle);
  --color-icon-accent-light: var(--themes-accent-light);
  --color-icon-accent-main: var(--themes-accent-dark);
  --color-icon-accent-dark: var(--themes-accent-darker);
  --color-icon-accent-strong: var(--themes-accent-strong);
  --color-icon-accent-hover: var(--themes-accent-darker);
  --color-icon-accent-pressed: var(--themes-accent-light);
  --color-icon-accent-on-accent: var(--themes-accent-on-accent);
  --color-icon-neutral-subtle: var(--themes-neutral-subtle);
  --color-icon-neutral-light: var(--themes-neutral-main);
  --color-icon-neutral-main: var(--themes-neutral-dark);
  --color-icon-neutral-dark: var(--themes-neutral-darker);
  --color-icon-neutral-strong: var(--themes-neutral-strong);
  --color-icon-neutral-hover: var(--themes-neutral-darker);
  --color-icon-neutral-pressed: var(--themes-neutral-main);
  --color-icon-neutral-on-neutral: var(--color-white-1000);
  --color-icon-action-primary-subtle: var(--themes-action-primary-subtle);
  --color-icon-action-primary-light: var(--themes-action-primary-light);
  --color-icon-action-primary-main: var(--themes-action-primary-main);
  --color-icon-action-primary-dark: var(--themes-action-primary-dark);
  --color-icon-action-primary-strong: var(--themes-action-primary-strong);
  --color-icon-action-primary-hover: var(--themes-action-primary-dark);
  --color-icon-action-primary-pressed: var(--themes-action-primary-light);
  --color-icon-action-primary-on-primary: var(--themes-action-primary-on-primary);
  --color-icon-action-secondary-subtle: var(--themes-action-secondary-subtle);
  --color-icon-action-secondary-light: var(--themes-action-secondary-light);
  --color-icon-action-secondary-main: var(--themes-action-secondary-main);
  --color-icon-action-secondary-dark: var(--themes-action-secondary-dark);
  --color-icon-action-secondary-strong: var(--themes-action-secondary-strong);
  --color-icon-action-secondary-hover: var(--themes-action-secondary-dark);
  --color-icon-action-secondary-pressed: var(--themes-action-secondary-light);
  --color-icon-action-secondary-on-secondary: var(--themes-action-secondary-on-secondary);
  --color-icon-info-subtle: var(--themes-info-subtle);
  --color-icon-info-light: var(--themes-info-light);
  --color-icon-info-main: var(--themes-info-main);
  --color-icon-info-dark: var(--themes-info-dark);
  --color-icon-info-strong: var(--themes-info-strong);
  --color-icon-info-hover: var(--themes-info-dark);
  --color-icon-info-pressed: var(--themes-info-light);
  --color-icon-info-on-info: var(--themes-info-on-info);
  --color-icon-succes-subtle: var(--themes-success-subtle);
  --color-icon-succes-light: var(--themes-success-light);
  --color-icon-succes-main: var(--themes-success-main);
  --color-icon-succes-dark: var(--themes-success-dark);
  --color-icon-succes-strong: var(--themes-success-strong);
  --color-icon-succes-hover: var(--themes-success-dark);
  --color-icon-succes-pressed: var(--themes-success-light);
  --color-icon-succes-on-succes: var(--themes-success-on-success);
  --color-icon-warning-subtle: var(--themes-warning-subtle);
  --color-icon-warning-light: var(--themes-warning-light);
  --color-icon-warning-main: var(--themes-warning-main);
  --color-icon-warning-dark: var(--themes-warning-dark);
  --color-icon-warning-strong: var(--themes-warning-strong);
  --color-icon-warning-hover: var(--themes-warning-dark);
  --color-icon-warning-pressed: var(--themes-warning-light);
  --color-icon-warning-on-warning: var(--themes-warning-on-warning);
  --color-icon-error-subtle: var(--themes-error-subtle);
  --color-icon-error-light: var(--themes-error-light);
  --color-icon-error-main: var(--themes-error-main);
  --color-icon-error-dark: var(--themes-error-dark);
  --color-icon-error-strong: var(--themes-error-strong);
  --color-icon-error-hover: var(--themes-error-dark);
  --color-icon-error-pressed: var(--themes-error-light);
  --color-icon-error-on-error: var(--themes-error-on-error);
}

.error-page {
  text-align: center;
  margin: 0 auto var(--spacing-32);
  grid-column: lend / rend;
}

.error-page__top {
  margin: 0 auto var(--spacing-8);
  max-width: 270px;
  height: 72px;
  position: relative;
}

.error-page__decor-line {
  flex-shrink: 0;
  width: 269.912px;
  height: 71.607px;
  position: absolute;
  bottom: -3px;
  right: 24px;
}

.error-page__decor-line path {
  stroke: var(--color-border-error-subtle);
}

.error-page__number {
  color: var(--color-text-error-main);
  text-align: center;
  background-color: var(--white);
  font-size: 60px;
  display: inline-block;
  position: relative;
}

.error-page__title {
  color: var(--color-text-default-title);
  margin-bottom: var(--spacing-12);
}

.error-page__description {
  color: var(--color-text-default-subtitle);
  margin-bottom: var(--spacing-24);
}

.footer__themes {
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.footer__themes--top {
  padding-bottom: var(--spacing-16);
  border-bottom: 1px solid var(--color-border-default-light);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__themes--bottom .footer__theme-link {
  color: var(--color-text-action-primary-light);
  text-decoration: none;
  display: inline;
}

.footer__themes--bottom .footer__theme-link:hover {
  text-decoration: underline;
}

.footer__themes--bottom .footer__theme-link:not(:last-child):after {
  content: "|";
  padding: var(--spacing-4);
}

.footer__categories {
  color: var(--primary-contrast);
  gap: var(--spacing-48);
  flex-direction: column;
  order: 1;
  display: flex;
}

.footer__categories--wrapper {
  justify-content: space-between;
  display: flex;
}

.footer__categories--wrapper .footer__category {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.footer__categories--wrapper .footer__category-main {
  padding-bottom: var(--spacing-12);
  border-bottom: 1px solid var(--color-border-default-light);
  margin-bottom: var(--spacing-12);
}

.footer__categories--wrapper .footer__category a {
  color: var(--primary-contrast);
  text-decoration: none;
}

.footer__categories--wrapper .footer__category a:hover {
  text-decoration: underline;
}

.footer__contacts {
  gap: var(--spacing-40);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer__contacts-links {
  flex-wrap: wrap;
  display: flex;
}

.footer__contacts-links > a.footer__contacts-link {
  color: var(--primary-contrast);
  text-decoration: none;
}

.footer__contacts-links > a.footer__contacts-link:hover {
  text-decoration: underline;
}

.footer__contacts-links > a.footer__contacts-link:not(:last-child):after {
  content: "|";
  padding: var(--spacing-16);
}

.footer__applications-wrapper {
  display: flex;
}

.footer__applications {
  line-height: 0;
  display: flex;
}

.footer__social {
  gap: var(--spacing-12);
  display: flex;
}

.footer__social-link img {
  width: 32px;
  height: 32px;
  display: block;
}

.footer__logo img {
  max-width: 104px;
  display: block;
}

.footer__copyright {
  text-align: center;
  justify-content: center;
  gap: var(--spacing-4);
  color: var(--background-paper-contrast);
  flex-wrap: wrap;
  order: 3;
  display: flex;
}

.footer__copyright a {
  text-decoration: none;
}

.footer__copyright a:hover {
  text-decoration: underline;
}

.footer__copyright a, .footer__copyright span {
  color: var(--background-paper-contrast);
}

.footer__copyright a:not(:last-child):after, .footer__copyright span:not(:last-child):after {
  content: "|";
  margin-left: var(--spacing-4);
}

body {
  background-color: var(--background-default);
}

body.scrolling-disabled {
  height: 100%;
  overflow-y: hidden;
}

.container--main {
  grid-template-columns: auto 100% auto;
  grid-template-areas: "left-empty header-sticky right-empty"
                       "left-empty header-static right-empty"
                       "left-empty content right-empty"
                       "footer footer footer";
  gap: 0;
  display: grid;
}

.container--main > header {
  background-color: var(--other-common-white);
  z-index: 800;
  grid-area: header-sticky;
  grid-template-columns: auto minmax(0, 1080px) auto;
  grid-template-areas: "header-left header-center header-right";
  transition: all 3ms;
  display: grid;
  position: fixed;
  top: 0;
}

.container--main > header > .header-wrapper {
  grid-area: header-center;
}

.container--main > .header-static {
  background: var(--background-default);
  padding: var(--spacing-64) var(--spacing-24) 0 var(--spacing-24);
  grid-area: header-static;
  grid-template-columns: [lend] 1fr[cdivider] 1fr[rend];
  display: grid;
  position: relative;
}

.container--main > main {
  gap: var(--spacing-24);
  padding: var(--spacing-32) var(--spacing-24) var(--spacing-32) var(--spacing-24);
  background: var(--background-default);
  grid-area: content;
  grid-template-columns: [lend] 1fr[cdivider] 1fr[rend];
  display: grid;
  position: relative;
}

.container--main > footer {
  background: var(--color-surface-default-strong);
  grid-area: 4 / footer;
  grid-template-columns: auto minmax(0, 1080px) auto;
  grid-template-areas: "footer-left footer-center footer-right";
  display: grid;
}

.container--main > footer > .footer-wrapper {
  padding: var(--spacing-40) var(--spacing-16);
  gap: var(--spacing-64);
  flex-direction: column;
  grid-area: footer-center;
  display: flex;
}

.grid-113, .grid-233 {
  gap: var(--spacing-24);
  grid-column: lend / rend;
  grid-template-rows: auto;
  grid-auto-rows: min-content;
  display: grid;
}

header {
  box-shadow: 0 4px 20px #0000001a;
}

.header {
  height: var(--spacing-64);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16);
  row-gap: var(--spacing-12);
  padding: var(--spacing-12) var(--spacing-16) var(--spacing-20);
  background: var(--background-default);
  z-index: 4;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.header .flex-line-break {
  display: none;
}

@media (max-width: 350px) {
  header .header-wrapper .header {
    padding: var(--spacing-8) var(--spacing-8);
    gap: var(--spacing-8);
  }
}

.header-search {
  display: none;
}

.header-search .header-search__magnifier {
  cursor: pointer;
}

.header-socials {
  display: none;
}

.header-user {
  position: relative;
}

.header-user .header-user-icon__icon {
  width: 24px;
  height: 24px;
}

.header-user .header-user-icon__avatar {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.header-user .header-user__link {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.header-user .header-user__link > svg path:first-child {
  fill: var(--color-icon-default-strong);
}

.header-user .header-user__link:hover > svg path:first-child {
  fill: var(--color-icon-brand-primary-main);
}

.header-user .header-user__link .header-user-icon__avatar {
  margin: var(--spacing-8);
}

.header-user .header-user__text {
  text-decoration: none;
  display: inline-block;
}

.header-user .header-user__text .header-user__link {
  color: var(--color-text-default-body);
  text-decoration: none;
}

.header-user .header-user__text:hover {
  text-decoration: underline;
}

.header-user:hover .header-user__menu {
  display: flex;
}

.header-user .header-user__menu {
  right: -10px;
  top: calc(24px + 2 * var(--spacing-12));
  background: var(--color-surface-default-contrast);
  z-index: 3;
  border-radius: 6px;
  flex-direction: column;
  width: 260px;
  display: none;
  position: absolute;
  box-shadow: 0 4px 15px #0000001a;
}

.header-user .header-user__menu:before {
  content: "";
  border-color: transparent transparent var(--color-surface-default-contrast);
  border-style: solid;
  border-width: 10px;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: -20px;
  right: 20px;
}

.header-user .header-user__menu:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 20px;
  display: block;
  position: absolute;
  top: -20px;
}

.header-user .header-user__menu > a {
  color: var(--color-text-default-body);
  text-decoration: none;
}

.header-user .header-user__menu > a:hover {
  background: var(--color-surface-default-subtle);
}

.header-user .header-user__menu > a:last-child:hover {
  border-radius: 0 0 6px 6px;
}

.header-user .header-user__info {
  align-items: center;
  gap: var(--spacing-16);
  padding: var(--spacing-16) 0;
  margin: 0 var(--spacing-16);
  border-bottom: 1px solid var(--color-border-default-light);
  border-radius: 6px 6px 0 0;
  flex-wrap: wrap;
  display: flex;
}

.header-user .header-user__subscription {
  text-transform: uppercase;
}

.header-user .header-user__detail {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.header-user .header-user__settings {
  gap: var(--spacing-16);
  padding: var(--spacing-16);
  align-items: center;
  display: flex;
}

.header-user .header-user__discussion {
  gap: var(--spacing-16);
  padding: var(--spacing-16);
  display: flex;
}

.header-user .header-user__logout {
  gap: var(--spacing-16);
  padding: var(--spacing-16) 0;
  margin: 0 var(--spacing-16);
  border-top: 1px solid var(--color-border-default-light);
  display: flex;
}

.header-logo {
  height: var(--spacing-header-sticky-brand-height);
  justify-content: center;
  align-items: center;
  transition: all 2.5ms;
  display: flex;
}

.header-logo .header-logo__image {
  vertical-align: middle;
  height: var(--spacing-header-sticky-brand-height);
}

.header-controls {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.header-subscription {
  margin-right: var(--spacing-8);
}

.header-desktop-navigation {
  flex-grow: 1;
  display: flex;
}

.header-menu {
  width: 100%;
}

.header-menu__list {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.header-menu__item {
  padding-left: var(--spacing-16);
  padding-right: var(--spacing-16);
  font: var(--font-desktop-body1-bold);
  position: relative;
}

.header-menu__item:first-of-type {
  padding-left: 0;
}

.header-menu__link {
  color: var(--color-text-link-menu-default);
  font: var(--font-desktop-menu-item);
  flex-wrap: nowrap;
  align-items: center;
  width: max-content;
  text-decoration: none;
  display: flex;
}

.header-menu__link--active, .header-menu__link--active:hover {
  text-underline-offset: var(--spacing-4);
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.header-menu__link:hover {
  color: var(--color-text-link-menu-hover);
}

.header-menu__link:active {
  color: var(--color-text-link-menu-default);
}

.overflow-menu {
  padding-right: 0;
}

.overflow-menu__toggle {
  padding-right: var(--spacing-16);
  color: var(--color-text-link-menu-default);
  font: var(--font-desktop-menu-item);
  text-align: left;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  align-items: center;
  padding-left: 0;
  display: flex;
  position: relative;
}

.overflow-menu__label {
  padding-right: var(--spacing-8);
}

.overflow-menu__arrow {
  background: url("/dist/assets/default/icons/icon-chevron-down.svg") center / cover no-repeat;
  width: 16px;
  height: 16px;
  transition: transform .25s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.active .overflow-menu__arrow {
  transform: rotate(-180deg)translateY(50%);
}

.overflow-menu__list {
  z-index: 9000;
  padding: var(--spacing-8) 0;
  background-color: var(--color-surface-default-contrast);
  box-shadow: var(--shadow-m);
  border-radius: 4px;
  width: max-content;
  min-width: 130px;
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
}

.overflow-menu .header-menu__item {
  padding: 0;
}

.overflow-menu .header-menu__link {
  padding: var(--spacing-8) var(--spacing-16);
  font: var(--font-desktop-body2);
  width: 100%;
  display: block;
}

.overflow-menu .header-menu__link:not(.header-menu__link--active):hover {
  color: inherit;
  background-color: var(--color-surface-default-hover);
}

.overflow-menu .header-menu__link--active {
  color: var(--color-text-link-menu-default);
  background-color: var(--color-surface-brand-primary-subtle);
  text-decoration: none;
}

.overflow-menu.active .overflow-menu__list {
  display: block;
}

.mobile-navigation {
  padding-top: var(--spacing-16);
  grid-column: lend / rend;
  width: 100%;
  display: flex;
}

.header-menu-hamburger {
  padding: var(--spacing-8);
  align-items: center;
  padding-right: 0;
  display: flex;
  position: relative;
}

.header-menu-hamburger__icon {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 17px;
  display: flex;
}

.header-menu-hamburger__icon .hamburger-line {
  background: var(--color-icon-default-strong);
  pointer-events: none;
  transform-origin: 0;
  border-radius: 10px;
  width: 100%;
  height: 2px;
  transition: transform .4s ease-in-out;
}

.header-menu-hamburger__icon.opened .hamburger-line.line1 {
  transform-origin: 10%;
  transform: rotate(45deg);
}

.header-menu-hamburger__icon.opened .hamburger-line.line2 {
  transform: scaleY(0);
}

.header-menu-hamburger__icon.opened .hamburger-line.line3 {
  transform-origin: 10%;
  transform: rotate(-45deg);
}

.header-menu-hamburger:hover .header-menu-hamburger__icon .hamburger-line {
  background: var(--color-icon-action-primary-main);
}

.megamenu-wrapper {
  z-index: 810;
  background: var(--color-surface-default-contrast);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.megamenu-wrapper.opened {
  max-height: calc(100vh - var(--spacing-64));
  width: 1080px;
  display: grid;
  overflow: hidden auto;
}

.megamenu-close {
  grid-area: close;
}

.megamenu-stickers {
  align-items: flex-start;
  gap: var(--spacing-8);
  flex-wrap: wrap;
  grid-area: stickers;
  display: flex;
}

.megamenu-stickers__item > a, .megamenu-stickers__item > span {
  padding: var(--spacing-2) var(--spacing-12);
  border: 1px solid var(--other-outline-border);
  background: var(--background-default);
  color: var(--text-secondary);
  border-radius: 2px;
  text-decoration: none;
  display: inline-flex;
}

.megamenu-stickers__item > a:hover {
  color: var(--primary-dark);
  border-color: var(--primary-outlined-hover-background);
}

.megamenu-stickers__item > a:active {
  color: var(--primary-light);
  border-color: var(--primary-light);
}

.megamenu-stickers__item > a:focus {
  color: var(--primary-dark);
  border-color: var(--primary-light);
  background-color: var(--primary-outlined-hover-background);
}

.megamenu-search {
  grid-area: search;
}

.megamenu-search__form {
  position: relative;
}

.megamenu-search__input {
  padding: var(--spacing-12) var(--spacing-48) var(--spacing-12) var(--spacing-24);
  width: calc(100% - var(--spacing-48)  - var(--spacing-24)  - 2px);
  border: 1px solid var(--color-border-default-main);
  border-radius: var(--spacing-global-image-radius);
  background: var(--color-surface-default-subtle);
  font: var(--font-desktop-body2);
}

.megamenu-search__submit {
  top: var(--spacing-12);
  right: var(--spacing-16);
  cursor: pointer;
  background-color: #0000;
  border: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.megamenu-search__submit svg {
  width: 20px;
  height: 20px;
}

.megamenu-search__submit svg * {
  fill: var(--color-icon-neutral-main);
}

.megamenu-categories {
  grid-area: categories;
  align-items: flex-start;
  display: flex;
}

.megamenu-categories__item {
  flex-direction: column;
  display: flex;
}

.megamenu-categories__item-title {
  color: var(--text-primary);
}

.megamenu-categories__item-title > a {
  color: var(--text-primary);
  text-decoration: none;
}

.megamenu-categories__item-title > a:hover {
  text-decoration: underline;
}

.megamenu-categories .megamenu-subcategories {
  flex-direction: column;
  display: flex;
}

.megamenu-categories .megamenu-subcategories__item {
  color: var(--text-primary);
}

.megamenu-categories .megamenu-subcategories__item > a {
  color: var(--text-primary);
  text-decoration: none;
}

.megamenu-categories .megamenu-subcategories__item > a:hover {
  text-decoration: underline;
}

.megamenu-print {
  flex-flow: column;
  grid-area: print;
  place-self: flex-start start;
  align-items: center;
  width: 100%;
  display: flex;
}

.megamenu-print__title {
  color: var(--text-primary);
  text-align: center;
}

.megamenu-print__subtitle {
  color: var(--text-primary);
  line-height: 20px;
  text-decoration: underline;
}

.megamenu-print__subtitle > a {
  color: var(--text-primary);
}

.megamenu-print__covers {
  justify-content: center;
  display: flex;
}

.megamenu-print__button {
  text-align: center;
  text-decoration: none;
}

.megamenu-logo {
  justify-content: space-between;
  align-items: center;
  height: 40px;
  transition: all 2.5ms;
  display: flex;
}

.megamenu-logo .megamenu-logo__image {
  vertical-align: middle;
  height: 40px;
}

.megamenu-divider {
  background: var(--other-divider);
  border: 0;
  grid-area: divider;
  height: 1px;
  margin: 0;
}

.megamenu-applications {
  gap: var(--spacing-32);
  grid-area: applications;
  display: flex;
}

.megamenu-applications__wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.megamenu-applications__title {
  color: var(--color-text-default-title);
}

.megamenu-applications__subtitle, .megamenu-applications__subtitle > a {
  color: var(--color-text-default-body);
}

.megamenu-applications__subtitle > a:hover {
  color: var(--color-text-link-primary-default);
}

.megamenu-applications__download {
  gap: var(--spacing-8);
  align-items: center;
  display: flex;
}

.megamenu-applications__download a {
  line-height: 0;
  display: block;
}

.megamenu-social {
  grid-area: social;
  display: flex;
}

.megamenu-contact {
  flex-direction: column;
  grid-area: contact;
  display: flex;
}

.megamenu-contact__title, .megamenu-contact__email, .megamenu-contact__email > a {
  color: var(--text-primary);
}

.megamenu-backdrop--header {
  display: none;
}

.megamenu-backdrop {
  opacity: 0;
  pointer-events: none;
  background: #00000063;
  width: 100%;
  height: 0;
  transition: opacity .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.megamenu-backdrop--active {
  z-index: 1;
  opacity: 1;
  pointer-events: all;
  height: 100%;
}

.megamenu-newsletter {
  flex-direction: column;
  grid-area: newsletter;
  align-self: center;
  display: flex;
}

.megamenu-newsletter__title {
  color: var(--color-text-default-title);
}

.megamenu-newsletter__email {
  color: var(--color-text-default-body);
}

.megamenu-newsletter__email > a {
  color: var(--color-text-default-body);
  position: relative;
}

.megamenu-newsletter__email > a svg {
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 0;
  right: -22px;
}

.section--columns-233 {
  grid-column: lend / rend;
}

.section--columns-232 {
  grid-column: lend / rend;
}

.vp-player .vp-logo .vp-logo-bottom-left, .vp-player .vp-logo .vp-logo-bottom-right, .vp-player .vp-logo .vp-logo-top-left, .vp-player .vp-logo .vp-logo-top-right {
  width: auto;
}

.vp-player .vp-big-play-button .vp-icon-play:before {
  display: block;
}

.vp-player.vp-size-breakpoint-2 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-3 .vp-big-play-button .vp-icon-play:before {
  width: 30px;
}

.vp-player.vp-size-breakpoint-4 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-5 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-6 .vp-big-play-button .vp-icon-play:before {
  width: 60px;
}

.vp-player.vp-size-breakpoint-7 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-8 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-9 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-10 .vp-big-play-button .vp-icon-play:before {
  width: 90px;
}

.vp-player.vp-float-animation {
  z-index: 999 !important;
}

#vp-floating-container .vp-big-play-button .vp-icon-play:before {
  width: 30px;
}

.vp-player--loading {
  aspect-ratio: 16 / 9;
  color: #fff;
  background: #000;
  place-content: center;
  align-items: center;
  display: flex;
}

.vp-video-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}

.vp-video-wrapper .vp-video-wrapper--content .vp-display-container, .vp-video-wrapper .vp-video-wrapper--content .vp-reset, .vp-video-wrapper .vp-video-wrapper--content .vp-reset-text {
  vertical-align: middle;
}

.vp-video-wrapper .vp-skip {
  border: 1px solid #333;
  padding: .5em;
}

.vp-video-wrapper svg path {
  fill: #fff;
}

.vp-video-wrapper--content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vp-banner {
  width: auto !important;
  height: auto !important;
}

.vp-wrapper .vp-logo .vp-second-logo {
  width: auto;
  height: auto;
}

.vp-flag-floating .vp-wrapper {
  border: 5px solid #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px #00000040;
  max-width: 300px !important;
}

.vp-flag-floating .vp-wrapper .vp-overlays .vp-plugin-vast {
  display: none;
}

.vp-video-wrapper--poster {
  width: 100%;
  height: 100%;
}

.vp-video-access-dnd {
  background-size: 100%;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  inset: 0;
}

.vp-video-access-dnd--link {
  color: red;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
}

.vp-ended .vp-content-fade, .vp-prestart .vp-content-fade {
  background: none;
  opacity: 0 !important;
}

.online_report .report_item .media .vp-video-mobile-access-dnd a {
  float: none;
  width: auto;
  margin: auto;
}

.vp-video-access-dnd--link:hover {
  text-decoration: none;
}

.vp-video-access-dnd--link:after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  top: -5px;
  right: -25px;
}

.vp-video-access-dnd--overlayer {
  color: #fff;
  text-align: center;
  background: #000c;
  width: 100%;
  padding: 2% 0;
  position: absolute;
  bottom: 0;
}

.vp-video-access-dnd--logo {
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 20px;
  right: auto;
}

.vp-video-access-dnd--logo .logo {
  width: 27%;
  min-width: 90px;
  max-width: 170px;
  height: auto;
  padding: 20px;
}

.vp-video-access-dnd--button {
  color: #fff;
  background: red;
  border-radius: 2px;
  width: auto;
  margin: auto;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: table;
}

#article a.vp-video-access-dnd--button {
  text-decoration: none;
}

.vp-video-access-dnd--button:hover {
  color: #fff;
  text-decoration: underline;
}

.vp-video-access-dnd--overlayer-title {
  font-size: 20px;
  font-weight: 700;
}

.vp-video-access-dnd--overlayer-login {
  margin: 2%;
  font-size: 13px;
}

.vp-video-access-dnd--play {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 60px;
  height: 160px;
  position: absolute;
  top: 30%;
  left: calc(50% - 30px);
}

.vpplayer .vp-banner {
  width: auto;
  height: auto;
}

.vp-display-controls .vp-svg-icon-play path, .vp-display-controls .vp-svg-icon-puse path, .vp-display-controls .vp-svg-icon-replay path {
  display: none;
}

.vp-display-controls .vp-svg-icon-play, .vp-display-controls .vp-svg-icon-replay {
  background-repeat: no-repeat;
  background-size: contain;
}

.vp-video-mobile-access-dnd--overlayer {
  text-align: center;
  color: #fff;
  background: #000c;
  width: 100%;
  padding: 2% 0;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  bottom: 0;
  overflow: hidden;
}

.vp-video-mobile-access-dnd .vp-video-access-dnd--play {
  top: calc(50% - 28px);
}

.vp-mobile.vp-size-breakpoint-4 .vp-big-play-button .vp-icon-play:before, .vp-mobile.vp-size-breakpoint-5 .vp-big-play-button .vp-icon-play:before, .vp-mobile.vp-size-breakpoint-6 .vp-big-play-button .vp-icon-play:before {
  height: 120px;
}

.vp-overlay-video-locked-in-time {
  box-sizing: content-box;
  padding: 0 var(--spacing-48);
  width: calc(100% - 2 * var(--spacing-48));
  z-index: 20;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-16);
  visibility: hidden;
  background-size: contain;
  flex-direction: column;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  box-shadow: inset 0 0 0 2000px #000000b3;
}

.vp-overlay-video-locked-in-time__content {
  align-items: flex-start;
  gap: var(--spacing-32);
  text-align: left;
  flex-direction: column;
  display: flex;
}

.vp-overlay-video-locked-in-time__premium {
  align-items: center;
  gap: var(--spacing-16);
  display: flex;
}

.vp-overlay-video-locked-in-time__premium.logo-isport .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-reflex .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-e15 .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-auto .vp-overlay-video-locked-in-time__magazine {
  max-width: 110px;
  height: 40px;
}

.vp-overlay-video-locked-in-time__premium.logo-isport .vp-overlay-video-locked-in-time__magazine svg, .vp-overlay-video-locked-in-time__premium.logo-reflex .vp-overlay-video-locked-in-time__magazine svg, .vp-overlay-video-locked-in-time__premium.logo-e15 .vp-overlay-video-locked-in-time__magazine svg, .vp-overlay-video-locked-in-time__premium.logo-auto .vp-overlay-video-locked-in-time__magazine svg {
  width: 100%;
  height: 40px;
}

.vp-overlay-video-locked-in-time__premium.logo-premium .vp-overlay-video-locked-in-time__magazine {
  width: 150px;
  height: 20px;
}

.vp-overlay-video-locked-in-time__premium.logo-premium .vp-overlay-video-locked-in-time__divider, .vp-overlay-video-locked-in-time__premium.logo-premium .vp-overlay-video-locked-in-time__premium-logo {
  display: none;
}

.vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium-logo {
  line-height: 0;
}

.vp-overlay-video-locked-in-time__divider {
  height: var(--spacing-16);
  background: var(--other-divider);
  width: 1px;
}

.vp-overlay-video-locked-in-time__info {
  align-items: flex-start;
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.vp-overlay-video-locked-in-time__titles {
  align-items: flex-start;
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

.vp-overlay-video-locked-in-time__title {
  color: var(--background-paper-contrast);
  font: var(--font-desktop-h3);
}

.vp-overlay-video-locked-in-time__subtitle {
  color: var(--background-paper-contrast);
  font: var(--font-desktop-body1);
}

.vp-overlay-video-locked-in-time__button {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  border-radius: var(--spacing-button-radius);
  background: var(--color-surface-button-primary-solid-normal-contained-default);
  border: 2px solid var(--color-surface-button-primary-solid-normal-contained-default);
  color: var(--secondary-contrast);
  cursor: pointer;
  width: 150px;
  height: 52px;
  font-family: Lato, sans-serif;
  display: flex;
}

.vp-overlay-video-locked-in-time__button:hover {
  background: var(--color-surface-button-primary-solid-normal-contained-default);
  border: 2px solid var(--color-surface-button-primary-solid-normal-contained-default);
}

a.vp-overlay-video-locked-in-time__link {
  padding: var(--spacing-12) var(--spacing-24);
  letter-spacing: .18px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: var(--color-text-button-primary-solid-normal-contained-default) !important;
  text-decoration: none !important;
}

.vp-overlay-video-locked-in-time__login {
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.vp-overlay-video-locked-in-time__login-info {
  color: var(--background-paper-contrast);
  font: var(--font-desktop-body1);
}

.vp-overlay-video-locked-in-time__login-link {
  font-family: arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-decoration-line: underline;
  color: var(--secondary-contrast) !important;
}

.vp-overlay-video-locked-in-time__login-link:hover {
  text-decoration: none;
}

.animate-pulse-on-black-bg {
  animation: 2s infinite pulse-animation-on-black-bg;
}

@container (width <= 680px) {
  .vp-overlay-video-locked-in-time {
    padding: 0 var(--spacing-48);
    width: calc(100% - 2 * var(--spacing-48));
  }

  .vp-overlay-video-locked-in-time__content {
    gap: var(--spacing-24);
  }

  .vp-overlay-video-locked-in-time__info {
    gap: var(--spacing-16);
  }

  .vp-overlay-video-locked-in-time__titles {
    gap: 0;
  }

  a.vp-overlay-video-locked-in-time__link {
    padding: var(--spacing-8) var(--spacing-10);
    font: var(--font-desktop-button-medium);
  }

  .vp-overlay-video-locked-in-time__button {
    width: 103px;
    height: 40px;
  }
}

@container (width <= 560px) {
  .vp-overlay-video-locked-in-time {
    padding: 0 var(--spacing-24);
    width: calc(100% - 2 * var(--spacing-24));
  }

  .vp-overlay-video-locked-in-time__content {
    gap: var(--spacing-16);
  }

  .vp-overlay-video-locked-in-time__premium {
    display: none;
  }

  .vp-overlay-video-locked-in-time__info {
    gap: var(--spacing-8);
  }

  .vp-overlay-video-locked-in-time__titles {
    gap: 0;
  }

  .vp-overlay-video-locked-in-time__title {
    font: var(--font-desktop-h5);
  }

  .vp-overlay-video-locked-in-time__subtitle {
    font: var(--font-desktop-body2);
  }

  a.vp-overlay-video-locked-in-time__link {
    font-size: var(--typography-desktop-button-sm-font-size);
    line-height: var(--typography-desktop-button-sm-line-height);
  }

  .vp-overlay-video-locked-in-time__button {
    width: 81px;
    height: 32px;
  }

  .vp-overlay-video-locked-in-time__login-info {
    font: var(--font-desktop-body2);
  }

  .vp-overlay-video-locked-in-time__login-link {
    padding: var(--spacing-8);
    font: var(--font-desktop-body2);
  }
}

@container (width <= 328px) {
  .vp-overlay-video-locked-in-time {
    padding: 0 var(--spacing-16);
    width: calc(100% - 2 * var(--spacing-16));
  }

  .vp-overlay-video-locked-in-time__content {
    gap: var(--spacing-16);
  }

  .vp-overlay-video-locked-in-time__info {
    gap: var(--spacing-8);
  }
}

.avatar {
  border-radius: var(--spacing-avatar-radius);
  box-shadow: var(--shadow-m);
  background-color: var(--color-surface-avatar-background);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.avatar[href], .avatar[onclick], .avatar[role="button"] {
  cursor: pointer;
}

.avatar__icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatar svg {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.avatar path {
  fill: var(--color-icon-avatar-default);
}

.avatar__photo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.avatar--border {
  border: 2px solid var(--color-border-avatar-default);
}

.avatar--2xs {
  width: var(--avatar-size-2xs);
  height: var(--avatar-size-2xs);
  border-width: 1px;
}

.avatar--2xs .avatar__icon {
  width: var(--avatar-icon-size-2xs);
  height: var(--avatar-icon-size-2xs);
}

.avatar--xs {
  width: var(--avatar-size-xs);
  height: var(--avatar-size-xs);
  border-width: 1.5px;
}

.avatar--xs .avatar__icon {
  width: var(--avatar-icon-size-xs);
  height: var(--avatar-icon-size-xs);
}

.avatar--sm {
  width: var(--avatar-size-sm);
  height: var(--avatar-size-sm);
}

.avatar--sm .avatar__icon {
  width: var(--avatar-icon-size-sm);
  height: var(--avatar-icon-size-sm);
}

.avatar--md {
  width: var(--avatar-size-md);
  height: var(--avatar-size-md);
}

.avatar--md .avatar__icon {
  width: var(--avatar-icon-size-md);
  height: var(--avatar-icon-size-md);
}

.avatar--lg {
  width: var(--avatar-size-lg);
  height: var(--avatar-size-lg);
}

.avatar--lg .avatar__icon {
  width: var(--avatar-icon-size-lg);
  height: var(--avatar-icon-size-lg);
}

.avatar--xl {
  width: var(--avatar-size-xl);
  height: var(--avatar-size-xl);
}

.avatar--xl .avatar__icon {
  width: var(--avatar-icon-size-xl);
  height: var(--avatar-icon-size-xl);
}

.avatar--2xl {
  width: var(--avatar-size-2xl);
  height: var(--avatar-size-2xl);
}

.avatar--2xl .avatar__icon {
  width: var(--avatar-icon-size-2xl);
  height: var(--avatar-icon-size-2xl);
}

.avatar--3xl {
  width: var(--avatar-size-3xl);
  height: var(--avatar-size-3xl);
}

.avatar--3xl .avatar__icon {
  width: var(--avatar-icon-size-3xl);
  height: var(--avatar-icon-size-3xl);
}

.avatar--4xl {
  width: var(--avatar-size-4xl);
  height: var(--avatar-size-4xl);
}

.avatar--4xl .avatar__icon {
  width: var(--avatar-icon-size-4xl);
  height: var(--avatar-icon-size-4xl);
}

.badge-pill {
  padding: var(--spacing-2) var(--spacing-12);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);
  border-radius: var(--spacing-badge-pilled);
  border: 1px solid #0000;
  display: flex;
}

.badge-pill__icon {
  width: auto;
}

.badge-pill svg {
  width: inherit;
  height: inherit;
}

.badge-pill--sm {
  padding: var(--spacing-2) var(--spacing-4);
}

.badge-pill--sm .badge-pill__icon {
  height: 16px;
}

.badge-pill--md {
  padding: var(--spacing-2) var(--spacing-8);
}

.badge-pill--md .badge-pill__icon {
  height: 20px;
}

.badge-pill--lg {
  padding: var(--spacing-2) var(--spacing-12);
  height: 36px;
}

.badge-pill--lg .badge-pill__icon {
  height: 24px;
}

.badge-pill--solid.badge-pill--primary {
  border-color: var(--color-surface-badgepill-solid-primary);
  background-color: var(--color-surface-badgepill-solid-primary);
}

.badge-pill--solid.badge-pill--primary svg {
  fill: var(--color-icon-badgepill-solid-primary);
}

.badge-pill--solid.badge-pill--primary .badge-pill__label {
  color: var(--color-text-badgepill-solid-primary);
}

.badge-pill--solid.badge-pill--secondary {
  border-color: var(--color-surface-badgepill-solid-secondary);
  background-color: var(--color-surface-badgepill-solid-secondary);
}

.badge-pill--solid.badge-pill--secondary svg {
  fill: var(--color-icon-badgepill-solid-secondary);
}

.badge-pill--solid.badge-pill--secondary .badge-pill__label {
  color: var(--color-text-badgepill-solid-secondary);
}

.badge-pill--solid.badge-pill--info {
  border-color: var(--color-surface-badgepill-solid-info);
  background-color: var(--color-surface-badgepill-solid-info);
}

.badge-pill--solid.badge-pill--info svg {
  fill: var(--color-icon-badgepill-solid-info);
}

.badge-pill--solid.badge-pill--info .badge-pill__label {
  color: var(--color-text-badgepill-solid-info);
}

.badge-pill--solid.badge-pill--success {
  border-color: var(--color-surface-badgepill-solid-success);
  background-color: var(--color-surface-badgepill-solid-success);
}

.badge-pill--solid.badge-pill--success svg {
  fill: var(--color-icon-badgepill-solid-success);
}

.badge-pill--solid.badge-pill--success .badge-pill__label {
  color: var(--color-text-badgepill-solid-success);
}

.badge-pill--solid.badge-pill--warning {
  border-color: var(--color-surface-badgepill-solid-warning);
  background-color: var(--color-surface-badgepill-solid-warning);
}

.badge-pill--solid.badge-pill--warning svg {
  fill: var(--color-icon-badgepill-solid-warning);
}

.badge-pill--solid.badge-pill--warning .badge-pill__label {
  color: var(--color-text-badgepill-solid-warning);
}

.badge-pill--solid.badge-pill--error {
  border-color: var(--color-surface-badgepill-solid-error);
  background-color: var(--color-surface-badgepill-solid-error);
}

.badge-pill--solid.badge-pill--error svg {
  fill: var(--color-icon-badgepill-solid-error);
}

.badge-pill--solid.badge-pill--error .badge-pill__label {
  color: var(--color-text-badgepill-solid-error);
}

.badge-pill--solid.badge-pill--disabled {
  border-color: var(--color-surface-badgepill-solid-disabled);
  background-color: var(--color-surface-badgepill-solid-disabled);
}

.badge-pill--solid.badge-pill--disabled svg {
  fill: var(--color-icon-badgepill-solid-disabled);
}

.badge-pill--solid.badge-pill--disabled .badge-pill__label {
  color: var(--color-text-badgepill-solid-disabled);
}

.badge-pill--soft.badge-pill--primary {
  border-color: var(--color-surface-badgepill-soft-primary);
  background-color: var(--color-surface-badgepill-soft-primary);
}

.badge-pill--soft.badge-pill--primary svg {
  fill: var(--color-icon-badgepill-soft-primary);
}

.badge-pill--soft.badge-pill--primary .badge-pill__label {
  color: var(--color-text-badgepill-soft-primary);
}

.badge-pill--soft.badge-pill--secondary {
  border-color: var(--color-surface-badgepill-soft-secondary);
  background-color: var(--color-surface-badgepill-soft-secondary);
}

.badge-pill--soft.badge-pill--secondary svg {
  fill: var(--color-icon-badgepill-soft-secondary);
}

.badge-pill--soft.badge-pill--secondary .badge-pill__label {
  color: var(--color-text-badgepill-soft-secondary);
}

.badge-pill--soft.badge-pill--info {
  border-color: var(--color-surface-badgepill-soft-info);
  background-color: var(--color-surface-badgepill-soft-info);
}

.badge-pill--soft.badge-pill--info svg {
  fill: var(--color-icon-badgepill-soft-info);
}

.badge-pill--soft.badge-pill--info .badge-pill__label {
  color: var(--color-text-badgepill-soft-info);
}

.badge-pill--soft.badge-pill--success {
  border-color: var(--color-surface-badgepill-soft-success);
  background-color: var(--color-surface-badgepill-soft-success);
}

.badge-pill--soft.badge-pill--success svg {
  fill: var(--color-icon-badgepill-soft-success);
}

.badge-pill--soft.badge-pill--success .badge-pill__label {
  color: var(--color-text-badgepill-soft-success);
}

.badge-pill--soft.badge-pill--warning {
  border-color: var(--color-surface-badgepill-soft-warning);
  background-color: var(--color-surface-badgepill-soft-warning);
}

.badge-pill--soft.badge-pill--warning svg {
  fill: var(--color-icon-badgepill-soft-warning);
}

.badge-pill--soft.badge-pill--warning .badge-pill__label {
  color: var(--color-text-badgepill-soft-warning);
}

.badge-pill--soft.badge-pill--error {
  border-color: var(--color-surface-badgepill-soft-error);
  background-color: var(--color-surface-badgepill-soft-error);
}

.badge-pill--soft.badge-pill--error svg {
  fill: var(--color-icon-badgepill-soft-error);
}

.badge-pill--soft.badge-pill--error .badge-pill__label {
  color: var(--color-text-badgepill-soft-error);
}

.badge-pill--soft.badge-pill--disabled {
  border-color: var(--color-surface-badgepill-soft-disabled);
  background-color: var(--color-surface-badgepill-soft-disabled);
}

.badge-pill--soft.badge-pill--disabled svg {
  fill: var(--color-icon-badgepill-soft-disabled);
}

.badge-pill--soft.badge-pill--disabled .badge-pill__label {
  color: var(--color-text-badgepill-soft-disabled);
}

.badge-pill--outlined {
  background-color: #fff;
}

.badge-pill--outlined.badge-pill--primary {
  border-color: var(--color-border-badgepill-outlined-primary);
}

.badge-pill--outlined.badge-pill--primary svg {
  fill: var(--color-icon-badgepill-outlined-primary);
}

.badge-pill--outlined.badge-pill--primary .badge-pill__label {
  color: var(--color-text-badgepill-outlined-primary);
}

.badge-pill--outlined.badge-pill--secondary {
  border-color: var(--color-border-badgepill-outlined-secondary);
}

.badge-pill--outlined.badge-pill--secondary svg {
  fill: var(--color-icon-badgepill-outlined-secondary);
}

.badge-pill--outlined.badge-pill--secondary .badge-pill__label {
  color: var(--color-text-badgepill-outlined-secondary);
}

.badge-pill--outlined.badge-pill--info {
  border-color: var(--color-border-badgepill-outlined-info);
}

.badge-pill--outlined.badge-pill--info svg {
  fill: var(--color-icon-badgepill-outlined-info);
}

.badge-pill--outlined.badge-pill--info .badge-pill__label {
  color: var(--color-text-badgepill-outlined-info);
}

.badge-pill--outlined.badge-pill--success {
  border-color: var(--color-border-badgepill-outlined-success);
}

.badge-pill--outlined.badge-pill--success svg {
  fill: var(--color-icon-badgepill-outlined-success);
}

.badge-pill--outlined.badge-pill--success .badge-pill__label {
  color: var(--color-text-badgepill-outlined-success);
}

.badge-pill--outlined.badge-pill--warning {
  border-color: var(--color-border-badgepill-outlined-warning);
}

.badge-pill--outlined.badge-pill--warning svg {
  fill: var(--color-icon-badgepill-outlined-warning);
}

.badge-pill--outlined.badge-pill--warning .badge-pill__label {
  color: var(--color-text-badgepill-outlined-warning);
}

.badge-pill--outlined.badge-pill--error {
  border-color: var(--color-border-badgepill-outlined-error);
}

.badge-pill--outlined.badge-pill--error svg {
  fill: var(--color-icon-badgepill-outlined-error);
}

.badge-pill--outlined.badge-pill--error .badge-pill__label {
  color: var(--color-text-badgepill-outlined-error);
}

.badge-pill--outlined.badge-pill--disabled {
  border-color: var(--color-border-badgepill-outlined-disabled);
}

.badge-pill--outlined.badge-pill--disabled svg {
  fill: var(--color-icon-badgepill-outlined-disabled);
}

.badge-pill--outlined.badge-pill--disabled .badge-pill__label {
  color: var(--color-text-badgepill-outlined-disabled);
}

.header-link {
  align-items: center;
  gap: var(--spacing-4);
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  transition: color .3s, background-color .3s;
  display: inline-flex;
}

.header-link .header-link__text {
  position: relative;
}

.header-link .header-link__text:hover {
  text-decoration: none;
}

.header-link .header-link__text:after {
  content: "";
  width: 0;
  height: 2px;
  transition: width .2s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header-link__icon {
  line-height: 1;
}

.header-link svg {
  width: auto;
  height: 1em;
  display: block;
}

.header-link:active .header-link__text:after, .header-link:focus .header-link__text:after, .header-link[data-is-selected] .header-link__text:after {
  width: 100%;
}

.header-link.header-link--solid.header-link--normal .header-link__text {
  color: var(--color-text-header-link-solid-normal-default);
}

.header-link.header-link--solid.header-link--normal .header-link__icon path {
  fill: var(--color-text-header-link-solid-normal-default);
}

.header-link.header-link--solid.header-link--normal:hover .header-link__text {
  color: var(--color-text-header-link-solid-normal-hover);
}

.header-link.header-link--solid.header-link--normal:hover .header-link__text:after {
  background-color: var(--color-text-header-link-solid-normal-hover);
}

.header-link.header-link--solid.header-link--normal:hover .header-link__icon path {
  fill: var(--color-text-header-link-solid-normal-hover);
}

.header-link.header-link--solid.header-link--normal[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--normal[data-is-selected] .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--normal[data-is-selected] .header-link__icon path {
  fill: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--normal:focus, .header-link.header-link--solid.header-link--normal:active {
  background-color: var(--color-surface-text-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--normal:focus .header-link__text, .header-link.header-link--solid.header-link--normal:active .header-link__text {
  color: var(--color-text-header-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--normal:focus .header-link__text:after, .header-link.header-link--solid.header-link--normal:active .header-link__text:after {
  background-color: var(--color-text-header-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--normal:focus .header-link__icon path, .header-link.header-link--solid.header-link--normal:active .header-link__icon path {
  fill: var(--color-text-header-link-solid-normal-focus);
}

.header-link.header-link--solid.header-link--inverted .header-link__text {
  color: var(--color-text-header-link-solid-inverted-default);
}

.header-link.header-link--solid.header-link--inverted .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-default);
}

.header-link.header-link--solid.header-link--inverted .header-link__icon path {
  fill: var(--color-text-header-link-solid-inverted-default);
}

.header-link.header-link--solid.header-link--inverted:hover .header-link__text {
  color: var(--color-text-header-link-solid-inverted-hover);
}

.header-link.header-link--solid.header-link--inverted:hover .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-hover);
}

.header-link.header-link--solid.header-link--inverted:hover .header-link__icon path {
  fill: var(--color-text-header-link-solid-inverted-hover);
}

.header-link.header-link--solid.header-link--inverted[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--inverted[data-is-selected] .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--inverted[data-is-selected] .header-link__icon path {
  fill: var(--color-text-header-link-solid-inverted-active);
}

.header-link.header-link--solid.header-link--inverted:focus, .header-link.header-link--solid.header-link--inverted:active {
  background-color: var(--color-surface-text-link-solid-inverted-focus);
}

.header-link.header-link--solid.header-link--inverted:focus .header-link__text, .header-link.header-link--solid.header-link--inverted:active .header-link__text {
  color: var(--color-text-header-link-solid-inverted-focus);
}

.header-link.header-link--solid.header-link--inverted:focus .header-link__text:after, .header-link.header-link--solid.header-link--inverted:active .header-link__text:after {
  background-color: var(--color-text-header-link-solid-inverted-focus);
}

.header-link.header-link--solid.header-link--inverted:focus .header-link__icon path, .header-link.header-link--solid.header-link--inverted:active .header-link__icon path {
  fill: var(--color-text-header-link-solid-inverted-focus);
}

.header-link.header-link--gradient.header-link--normal .header-link__text {
  color: var(--color-text-header-link-gradient-normal-default);
  text-decoration: none;
}

.header-link.header-link--gradient.header-link--normal .header-link__text:after {
  background-color: var(--themes-gradient-02-front);
  background-image: var(--gradient-2);
}

.header-link.header-link--gradient.header-link--normal .header-link__icon path {
  fill: var(--color-text-header-link-gradient-normal-default);
}

.header-link.header-link--gradient.header-link--normal:hover .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.header-link.header-link--gradient.header-link--normal:hover .header-link__icon path {
  fill: var(--themes-gradient-02-back);
}

.header-link.header-link--gradient.header-link--normal[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-gradient-normal-active);
  background: none;
  background-clip: unset;
}

.header-link.header-link--gradient.header-link--normal[data-is-selected] .header-link__icon path {
  fill: var(--color-text-header-link-gradient-normal-active);
}

.header-link.header-link--gradient.header-link--normal:focus .header-link__text, .header-link.header-link--gradient.header-link--normal:active .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.header-link.header-link--gradient.header-link--normal:focus .header-link__icon path, .header-link.header-link--gradient.header-link--normal:active .header-link__icon path {
  fill: var(--themes-gradient-02-back);
}

.header-link.header-link--gradient.header-link--inverted .header-link__text {
  color: var(--color-text-header-link-gradient-inverted-default);
  text-decoration: none;
}

.header-link.header-link--gradient.header-link--inverted .header-link__text:after {
  background-color: var(--themes-gradient-02-front);
  background-image: var(--gradient-2);
}

.header-link.header-link--gradient.header-link--inverted .header-link__icon path {
  fill: var(--color-text-header-link-gradient-inverted-default);
}

.header-link.header-link--gradient.header-link--inverted:hover .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.header-link.header-link--gradient.header-link--inverted:hover .header-link__icon path {
  fill: var(--themes-gradient-02-back);
}

.header-link.header-link--gradient.header-link--inverted[data-is-selected] .header-link__text {
  color: var(--color-text-header-link-gradient-inverted-active);
  background: none;
  background-clip: unset;
}

.header-link.header-link--gradient.header-link--inverted[data-is-selected] .header-link__icon path {
  fill: var(--color-text-header-link-gradient-inverted-active);
}

.header-link.header-link--gradient.header-link--inverted:focus .header-link__text, .header-link.header-link--gradient.header-link--inverted:active .header-link__text {
  background-color: var(--themes-gradient-02-back);
  background-image: var(--gradient-2);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.header-link.header-link--gradient.header-link--inverted:focus .header-link__icon path, .header-link.header-link--gradient.header-link--inverted:active .header-link__icon path {
  fill: var(--themes-gradient-02-back);
}

.chip {
  vertical-align: top;
  border-radius: var(--spacing-chip-radius);
  color: inherit;
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.chip__icon {
  margin-left: var(--spacing-4);
  justify-content: center;
  align-items: center;
  display: flex;
}

.chip__icon svg {
  width: 20px;
  height: 20px;
}

.chip__icon path {
  fill: var(--color-icon-neutral-dark);
}

.chip__avatar {
  margin: calc(var(--spacing-2) * -1) calc(var(--spacing-1) * -1);
}

.chip__label {
  padding: var(--spacing-2) var(--spacing-8);
  color: var(--color-text-neutral-dark);
}

.chip__close {
  margin-right: var(--spacing-4);
  justify-content: center;
  align-items: center;
  display: flex;
}

.chip__close svg {
  width: 16px;
  height: 16px;
}

.chip__close path, .chip:hover .chip__icon path {
  fill: var(--color-icon-neutral-main);
}

.chip:hover .chip__label {
  color: var(--color-text-neutral-main);
  text-decoration: none;
}

.chip.is-active {
  pointer-events: none;
}

.chip.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.chip.is-disabled .chip__icon path {
  fill: var(--color-icon-default-disabled);
}

.chip.is-disabled .chip__avatar {
  opacity: .5;
}

.chip.is-disabled .chip__label {
  color: var(--color-text-default-disabled);
}

.chip.is-disabled .chip__close path {
  fill: var(--color-icon-default-disabled);
}

.chip--fill {
  background-color: var(--color-surface-neutral-subtle);
}

.chip--fill.is-disabled {
  background-color: var(--color-surface-default-disabled);
}

.chip--fill.is-active {
  background-color: var(--color-surface-default-slightly-darker);
}

.chip--fill.is-active .chip__icon path {
  fill: var(--color-icon-default-contrast);
}

.chip--fill.is-active .chip__label {
  color: var(--color-text-default-contrast);
}

.chip--fill.is-active .chip__close path {
  fill: var(--color-icon-default-subtle);
}

.chip--fill.chip--primary:hover, .chip--fill.chip--primary.is-active {
  background-color: var(--color-surface-action-primary-subtle);
}

.chip--fill.chip--primary.is-active .chip__icon path {
  fill: var(--color-icon-action-primary-main);
}

.chip--fill.chip--primary.is-active .chip__label {
  color: var(--color-text-action-primary-main);
}

.chip--fill.chip--primary.is-active .chip__close path {
  fill: var(--color-icon-action-primary-main);
}

.chip--outlined {
  border-color: var(--color-border-neutral-dark);
  background-color: var(--color-surface-default-white);
}

.chip--outlined:hover {
  border-color: var(--color-border-default-dark);
}

.chip--outlined.is-disabled {
  border-color: var(--color-border-default-disabled);
}

.chip--outlined.is-active {
  border-color: var(--color-border-default-dark);
  background-color: var(--color-surface-default-darker);
}

.chip--outlined.is-active .chip__icon path {
  fill: var(--color-icon-default-contrast);
}

.chip--outlined.is-active .chip__label {
  color: var(--color-text-default-contrast);
}

.chip--outlined.is-active .chip__close path {
  fill: var(--color-icon-default-subtle);
}

.chip--outlined.chip--primary:hover {
  border-color: var(--color-border-action-primary-light);
}

.chip--outlined.chip--primary:hover .chip__icon path {
  fill: var(--color-icon-action-primary-main);
}

.chip--outlined.chip--primary:hover .chip__label {
  color: var(--color-text-action-primary-main);
}

.chip--outlined.chip--primary:hover .chip__close path {
  fill: var(--color-icon-action-primary-light);
}

.chip--outlined.chip--primary.is-active {
  border-color: var(--color-border-action-primary-light);
  background-color: var(--color-surface-action-primary-main);
}

.chip--md {
  padding: var(--spacing-6);
}

.chip--sm {
  padding: var(--spacing-1) var(--spacing-4);
}

.divider {
  background-color: var(--color-border-divider-label);
  margin: 0;
  margin-bottom: var(--spacing-16);
  width: 100%;
  height: 1px;
}

.divider--no-bottom-margin {
  margin-bottom: 0;
}

.divider--label, .divider--chip {
  text-align: center;
  grid-gap: 20px;
  background-color: #0000;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  align-items: center;
  height: auto;
  display: grid;
}

.divider--label:before, .divider--label:after, .divider--chip:before, .divider--chip:after {
  content: "";
  background-color: var(--color-border-divider-label);
  width: 100%;
  height: 1px;
}

.divider--label .divider__text {
  color: var(--color-text-divider-label);
}

.divider--section {
  background-color: var(--color-border-divider-section);
  height: 4px;
}

.divider--vertical {
  width: 1px;
  height: 100%;
  min-height: 1rem;
  margin: 0;
}

.divider--vertical.divider--chip, .divider--vertical.divider--label {
  grid-template-rows: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: auto;
  grid-auto-flow: row dense;
  width: auto;
  height: 100%;
  display: inline-grid;
}

.divider--vertical.divider--chip:before, .divider--vertical.divider--chip:after, .divider--vertical.divider--label:before, .divider--vertical.divider--label:after {
  width: 1px;
  height: 100%;
  margin: 0 auto;
}

.divider--vertical.divider--section {
  width: 4px;
}

.icon-button {
  box-sizing: border-box;
  cursor: pointer;
  --svg-fill-color: transparent;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 51%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.icon-button > svg path {
  fill: var(--svg-fill-color);
}

.icon-button:before {
  z-index: -1;
  border-radius: inherit;
  position: absolute;
}

.icon-button--xxs {
  width: 16px;
  height: 16px;
}

.icon-button--xxs svg {
  width: 12px;
  height: 12px;
}

.icon-button--xs {
  width: 24px;
  height: 24px;
}

.icon-button--xs svg {
  width: 16px;
  height: 16px;
}

.icon-button--sm {
  width: 32px;
  height: 32px;
}

.icon-button--sm svg {
  width: 20px;
  height: 20px;
}

.icon-button--md {
  width: 40px;
  height: 40px;
}

.icon-button--md svg {
  width: 24px;
  height: 24px;
}

.icon-button--lg {
  width: 48px;
  height: 48px;
}

.icon-button--lg svg {
  width: 24px;
  height: 24px;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained {
  background-color: var(--color-surface-icon-button-primary-gradient-normal-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-default);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained:hover {
  background: var(--gradient-2);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-hover);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained:focus {
  border-color: var(--color-border-icon-button-primary-gradient-normal-contained-focus);
  background-color: var(--color-surface-icon-button-primary-gradient-normal-contained-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-focus);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-primary-gradient-normal-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-primary-gradient-normal-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-contained-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined {
  border-color: var(--color-border-icon-button-primary-gradient-normal-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:hover {
  --svg-fill-color: url("#gradient-3");
  background-color: #0000;
  border-color: #0000;
  position: relative;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:hover:before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(90deg, var(--themes-gradient-03-front) 0%, var(--themes-gradient-03-back) 100%) border-box;
  border: 1px solid #0000;
  position: absolute;
  inset: -1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:focus {
  border-color: var(--color-border-icon-button-primary-gradient-normal-outlined-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-focus);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-primary-gradient-normal-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-primary-gradient-normal-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-outlined-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text:hover {
  --svg-fill-color: url("#gradient-3");
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text:focus {
  border-color: var(--color-border-icon-button-primary-gradient-normal-text-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-focus);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--normal.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-normal-text-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained {
  background-color: var(--color-surface-icon-button-primary-gradient-inverted-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-default);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained:hover {
  background: var(--gradient-2);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-hover);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained:focus {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-contained-focus);
  background-color: var(--color-surface-icon-button-primary-gradient-inverted-contained-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-focus);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-primary-gradient-inverted-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-primary-gradient-inverted-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-contained-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:hover {
  --svg-fill-color: url("#gradient-3");
  background-color: #0000;
  border-color: #0000;
  position: relative;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:hover:before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(90deg, var(--themes-gradient-03-front) 0%, var(--themes-gradient-03-back) 100%) border-box;
  border: 1px solid #0000;
  position: absolute;
  inset: -1px;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:focus {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-outlined-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-focus);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-outlined-disabled);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text:hover {
  --svg-fill-color: url("#gradient-3");
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text:focus {
  border-color: var(--color-border-icon-button-primary-gradient-inverted-text-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-focus);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-active);
}

.icon-button.icon-button--primary.icon-button--gradient.icon-button--inverted.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-primary-gradient-inverted-text-disabled);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--contained {
  background-color: var(--color-surface-icon-button-primary-solid-normal-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-contained-default);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--contained:hover {
  background-color: var(--color-surface-icon-button-primary-solid-normal-contained-hover);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-contained-hover);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--contained:focus {
  border-color: var(--color-border-icon-button-primary-solid-normal-contained-focus);
  background-color: var(--color-surface-icon-button-primary-solid-normal-contained-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-contained-focus);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-primary-solid-normal-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-contained-active);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-primary-solid-normal-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-contained-disabled);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--outlined {
  border-color: var(--color-border-icon-button-primary-solid-normal-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--outlined:hover {
  background-color: var(--color-surface-icon-button-primary-solid-normal-outlined-hover);
  border-color: var(--color-border-icon-button-primary-solid-normal-outlined-hover);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-outlined-hover);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--outlined:focus {
  border-color: var(--color-border-icon-button-primary-solid-normal-outlined-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-outlined-focus);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-primary-solid-normal-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-outlined-active);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-primary-solid-normal-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-outlined-disabled);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-text-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--text:hover {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-text-hover);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--text:focus {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-text-focus);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-text-active);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--normal.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-normal-text-disabled);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--contained {
  background-color: var(--color-surface-icon-button-primary-solid-inverted-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-contained-default);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--contained:hover {
  background-color: var(--color-surface-icon-button-primary-solid-inverted-contained-hover);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-contained-hover);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--contained:focus {
  border-color: var(--color-border-icon-button-primary-solid-inverted-contained-focus);
  background-color: var(--color-surface-icon-button-primary-solid-inverted-contained-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-contained-focus);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-primary-solid-inverted-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-contained-active);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-primary-solid-inverted-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-contained-disabled);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--outlined {
  border-color: var(--color-border-icon-button-primary-solid-inverted-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--outlined:hover {
  background-color: var(--color-surface-icon-button-primary-solid-inverted-outlined-hover);
  border-color: var(--color-border-icon-button-primary-solid-inverted-outlined-hover);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-outlined-hover);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--outlined:focus {
  border-color: var(--color-border-icon-button-primary-solid-inverted-outlined-focus);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-outlined-focus);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-primary-solid-inverted-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-outlined-active);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-primary-solid-inverted-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-outlined-disabled);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-text-default);
  background-color: #0000;
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--text:hover {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-text-hover);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--text:focus {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-text-focus);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-text-active);
}

.icon-button.icon-button--primary.icon-button--solid.icon-button--inverted.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-primary-solid-inverted-text-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-default);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained:hover {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-hover);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained:focus {
  border-color: var(--color-border-icon-button-secondary-solid-normal-contained-focus);
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-focus);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-focus);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-active);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-contained-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined {
  border-color: var(--color-border-icon-button-secondary-solid-normal-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined:hover {
  background-color: var(--color-surface-icon-button-secondary-solid-normal-outlined-hover);
  border-color: var(--color-border-icon-button-secondary-solid-normal-outlined-hover);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined:focus {
  border-color: var(--color-border-icon-button-secondary-solid-normal-outlined-focus);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-focus);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-secondary-solid-normal-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-active);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-secondary-solid-normal-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-outlined-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-default);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text:hover {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text:focus {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-focus);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-active);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--normal.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-normal-text-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--contained {
  background-color: var(--color-surface-icon-button-secondary-solid-inverted-contained-default);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-contained-default);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--contained:hover {
  background-color: var(--color-surface-icon-button-secondary-solid-inverted-contained-hover);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-contained-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--contained:focus {
  border-color: var(--color-border-icon-button-secondary-solid-inverted-contained-focus);
  background-color: var(--color-surface-icon-button-secondary-solid-inverted-contained-focus);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-contained-focus);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--contained:active {
  background-color: var(--color-surface-icon-button-secondary-solid-inverted-contained-active);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-contained-active);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--contained[disabled] {
  background-color: var(--color-surface-icon-button-secondary-solid-inverted-contained-disabled);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-contained-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--outlined {
  border-color: var(--color-border-icon-button-secondary-solid-inverted-outlined-default);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-outlined-default);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--outlined:hover {
  background-color: var(--color-surface-icon-button-secondary-solid-inverted-outlined-hover);
  border-color: var(--color-border-icon-button-secondary-solid-inverted-outlined-hover);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-outlined-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--outlined:focus {
  border-color: var(--color-border-icon-button-secondary-solid-inverted-outlined-focus);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-outlined-focus);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--outlined:active {
  border-color: var(--color-border-icon-button-secondary-solid-inverted-outlined-active);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-outlined-active);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--outlined[disabled] {
  border-color: var(--color-border-icon-button-secondary-solid-inverted-outlined-disabled);
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-outlined-disabled);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--text {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-text-default);
  background-color: #0000;
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--text:hover {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-text-hover);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--text:focus {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-text-focus);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--text:active {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-text-active);
}

.icon-button.icon-button--secondary.icon-button--solid.icon-button--inverted.icon-button--text[disabled] {
  --svg-fill-color: var(--color-icon-icon-button-secondary-solid-inverted-text-disabled);
}

.label-link {
  align-items: center;
  height: 20px;
  text-decoration: none;
  display: inline-flex;
}

.label-link:not([href]) {
  pointer-events: none;
}

.label-link.label-link--text.label-link--solid {
  color: var(--color-text-label-link-solid-text-default);
}

.label-link.label-link--text.label-link--solid[href]:hover {
  color: var(--color-text-label-link-solid-text-hover);
}

.label-link.label-link--text.label-link--solid[href]:hover:not(:active) {
  text-decoration: underline;
}

.label-link.label-link--text.label-link--solid:focus {
  color: var(--color-text-label-link-solid-text-focus);
  background-color: var(--color-surface-label-link-text-solid-focus);
  border-radius: 4px;
}

.label-link.label-link--text.label-link--solid:active .label-link__text {
  color: var(--color-text-label-link-solid-text-active);
  text-decoration: none;
}

.label-link.label-link--text.label-link--solid:not([href]) {
  pointer-events: none;
}

.label-link.label-link--text.label-link--gradient .label-link__text {
  background: var(--gradient-1);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.label-link.label-link--text.label-link--gradient:hover .label-link__text, .label-link.label-link--text.label-link--gradient:focus .label-link__text, .label-link.label-link--text.label-link--gradient:active .label-link__text {
  background: none;
  background-clip: unset;
}

.label-link.label-link--text.label-link--gradient[href]:hover .label-link__text {
  color: var(--color-text-label-link-gradient-text-hover);
}

.label-link.label-link--text.label-link--gradient:focus {
  background-color: var(--color-surface-label-link-text-gradient-focus);
}

.label-link.label-link--text.label-link--gradient:focus .label-link__text {
  color: var(--color-text-label-link-gradient-text-focus);
}

.label-link.label-link--text.label-link--gradient:active .label-link__text {
  color: var(--color-text-label-link-gradient-text-active);
  text-decoration: none;
}

.label-link.label-link--contained {
  padding: 0 var(--spacing-8);
  border-radius: 80px;
}

.label-link.label-link--contained .label-link__text {
  text-decoration: none;
}

.label-link.label-link--contained.label-link--solid {
  color: var(--color-text-label-link-solid-contained-default);
  background-color: var(--color-surface-label-link-contained-solid-default);
}

.label-link.label-link--contained.label-link--solid[href]:hover {
  color: var(--color-text-label-link-solid-contained-hover);
  background-color: var(--color-surface-label-link-contained-solid-hover);
}

.label-link.label-link--contained.label-link--solid:focus {
  color: var(--color-text-label-link-solid-contained-focus);
  background-color: var(--color-surface-label-link-contained-solid-focus);
  border-radius: 4px;
}

.label-link.label-link--contained.label-link--solid:active {
  color: var(--color-text-label-link-solid-contained-active);
  background-color: var(--color-surface-label-link-contained-solid-active);
  text-decoration: none;
}

.label-link.label-link--contained.label-link--gradient {
  color: var(--color-text-label-link-gradient-contained-default);
  background: var(--gradient-1);
}

.label-link.label-link--contained.label-link--gradient[href]:hover {
  background: var(--color-surface-label-link-contained-gradient-hover);
}

.label-link.label-link--contained.label-link--gradient:focus {
  color: var(--color-text-label-link-gradient-contained-focus);
  background: var(--color-surface-label-link-contained-gradient-focus);
  border-radius: 4px;
}

.label-link.label-link--contained.label-link--gradient:active {
  color: var(--color-text-label-link-gradient-contained-default);
  background: var(--gradient-1);
  text-decoration: none;
}

.social-icon__wrapper {
  width: 30px;
  height: 30px;
  display: block;
}

.social-icon__wrapper svg {
  width: 100%;
  height: 100%;
}

.social-icon__wrapper svg:hover .circle {
  fill: var(--color-icon-brand-primary-main);
  transition: fill 2ms;
}

.social-icon[class$="-light"] .social-icon__wrapper svg .symbol {
  fill: var(--color-icon-default-strong);
}

.social-icon[class$="-light"] .social-icon__wrapper svg:hover .symbol {
  fill: var(--color-icon-brand-primary-main);
  transition: fill 2ms;
}

.social-icon[class$="-light"] .social-icon__wrapper svg:hover .circle {
  fill: inherit;
}

.text-link {
  align-items: center;
  gap: var(--spacing-4);
  text-decoration: none;
  transition: color .3s, background-color .3s;
  display: inline-flex;
}

.text-link:focus {
  border-radius: var(--spacing-global-image-radius);
}

.text-link__icon {
  align-items: center;
  display: flex;
}

.text-link svg {
  width: auto;
  height: 1em;
}

.text-link[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.text-link--stand-alone .text-link__text, .text-link--stand-alone[disabled] .text-link__text, .text-link--hyperlink:hover .text-link__text {
  text-decoration: none;
}

.text-link--stand-alone:hover .text-link__text, .text-link--stand-alone:visited .text-link__text, .text-link--stand-alone:focus .text-link__text, .text-link--hyperlink .text-link__text, .text-link--hyperlink:visited .text-link__text, .text-link--hyperlink:focus .text-link__text, .text-link--hyperlink[disabled] .text-link__text {
  text-decoration: underline;
}

.text-link--stand-alone.text-link--primary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-default);
}

.text-link--stand-alone.text-link--primary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-default);
}

.text-link--stand-alone.text-link--primary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-hover);
}

.text-link--stand-alone.text-link--primary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-hover);
}

.text-link--stand-alone.text-link--primary.text-link--normal:focus {
  background-color: var(--color-surface-text-link-stand-alone-primary-solid-normal-focus);
}

.text-link--stand-alone.text-link--primary.text-link--normal:focus .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-focus);
}

.text-link--stand-alone.text-link--primary.text-link--normal:focus .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-focus);
}

.text-link--stand-alone.text-link--primary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--primary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--primary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-default);
}

.text-link--stand-alone.text-link--primary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-default);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:focus {
  background-color: var(--color-surface-text-link-stand-alone-primary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:focus .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--primary.text-link--inverted:focus .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--primary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-primary-solid-inverted-disabled);
}

.text-link--stand-alone.text-link--primary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-primary-solid-inverted-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-default);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:focus {
  background-color: var(--color-surface-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:focus .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--normal:focus .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-normal-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-default);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-default);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-hover);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:focus {
  background-color: var(--color-surface-text-link-stand-alone-secondary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:focus .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted:focus .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-focus);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-stand-alone-secondary-solid-inverted-disabled);
}

.text-link--stand-alone.text-link--secondary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-stand-alone-secondary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
}

.text-link--hyperlink.text-link--primary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
}

.text-link--hyperlink.text-link--primary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-hover);
}

.text-link--hyperlink.text-link--primary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-hover);
}

.text-link--hyperlink.text-link--primary.text-link--normal:focus {
  background-color: var(--color-surface-text-link-hyperlink-primary-solid-normal-focus);
}

.text-link--hyperlink.text-link--primary.text-link--normal:focus .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-focus);
}

.text-link--hyperlink.text-link--primary.text-link--normal:focus .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-focus);
}

.text-link--hyperlink.text-link--primary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--normal .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary.text-link--normal .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-default);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:focus {
  background-color: var(--color-surface-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:focus .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--normal:focus .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--normal[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--normal[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-normal-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-default);
}

.text-link--hyperlink.text-link--primary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-default);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:focus {
  background-color: var(--color-surface-text-link-hyperlink-primary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:focus .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--primary.text-link--inverted:focus .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--primary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-primary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--primary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-primary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-default);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-default);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:hover .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:hover .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-hover);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:focus {
  background-color: var(--color-surface-text-link-hyperlink-secondary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:focus .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted:focus .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-focus);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted[disabled] .text-link__text {
  color: var(--color-text-text-link-hyperlink-secondary-solid-inverted-disabled);
}

.text-link--hyperlink.text-link--secondary.text-link--inverted[disabled] .text-link__icon path {
  fill: var(--color-text-text-link-hyperlink-secondary-solid-inverted-disabled);
}

.typography--underline {
  text-decoration: underline;
}

.typography--uppercase {
  text-transform: uppercase;
}

a > .typography:hover {
  text-decoration: underline;
}

a > .typography--underline:hover {
  text-decoration: none;
}

.premium-brand {
  width: auto;
}

.premium-brand svg {
  width: inherit;
  height: inherit;
}

.premium-brand--2xs {
  height: var(--spacing-brand-height-2xs);
}

.premium-brand--xs {
  height: var(--spacing-brand-height-xs);
}

.premium-brand--sm {
  height: var(--spacing-brand-height-sm);
}

.premium-brand--md {
  height: var(--spacing-brand-height-md);
}

.premium-brand--lg {
  height: var(--spacing-brand-height-lg);
}

.premium-brand--xl {
  height: var(--spacing-brand-height-xl);
}

.premium-brand--2xl {
  height: var(--spacing-brand-height-2xl);
}

.premium-brand--3xl {
  height: var(--spacing-brand-height-3xl);
}

.premium-brand--4xl {
  height: var(--spacing-brand-height-4xl);
}

.premium-brand--5xl {
  height: var(--spacing-brand-height-5xl);
}

.accordion {
  flex-flow: column;
  display: flex;
}

.accordion__item {
  border-bottom: 1px solid var(--color-border-divider-label);
  list-style-type: none;
}

.accordion__question {
  padding: var(--spacing-16) var(--spacing-32) var(--spacing-16) 0;
  cursor: pointer;
  color: var(--color-text-accordion-title-default);
  position: relative;
}

.accordion__question * {
  color: inherit;
}

.accordion__question:hover {
  color: var(--color-text-accordion-title-hover);
}

.accordion__question:hover * {
  color: inherit;
}

.accordion__icon {
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  transition: all .4s;
  display: flex;
  position: absolute;
  top: 26px;
  right: 8px;
}

.item--opened .accordion__icon {
  transform-origin: center;
  transition: all .4s;
  transform: rotate(180deg);
}

.accordion__icon svg {
  width: inherit;
  height: inherit;
}

.accordion__answer {
  color: var(--color-text-accordion-body-default);
  display: none;
}

.item--opened .accordion__answer {
  padding-bottom: var(--spacing-16);
  display: block;
}

.accordion__answer * {
  color: inherit;
  font: var(--font-desktop-body2);
}

.accordion__answer :last-child {
  margin-bottom: 0;
}

.accordion__answer p {
  margin-bottom: var(--spacing-16);
}

.accordion__answer ol, .accordion__answer ul {
  margin: 0 0 var(--spacing-16) var(--spacing-16);
}

.accordion__answer a:hover {
  text-decoration: none;
}

.accordion__answer h3, .accordion__answer h4 {
  font: var(--font-desktop-body2-bold);
  margin-bottom: var(--spacing-4);
}

.meta {
  gap: var(--spacing-8);
  font: var(--font-desktop-meta-regular);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.meta__authors, .meta__date, .meta__time {
  color: var(--color-text-default-helper);
  font: inherit;
}

.meta__date + .meta__time:before {
  content: "•";
  margin-right: var(--spacing-4);
}

.meta__partner {
  color: var(--color-text-default-helper);
}

.meta--theme-dark {
  color: var(--text-secondary);
}

.category {
  font: var(--font-desktop-meta-bold);
  color: var(--color-text-link-primary-default);
}

.category__link {
  color: inherit;
  text-decoration: none;
}

.article-card__forum a {
  align-items: center;
  gap: var(--spacing-4);
  font: var(--font-desktop-meta-regular);
  color: var(--secondary-main);
  text-decoration: none;
  display: flex;
}

.article-card__forum a:hover {
  text-decoration: underline;
}

.article-card__forum a:hover .article-card__forum-icon {
  background-color: #e7e7e7;
}

.article-card__forum a:hover .article-card__forum-icon svg path {
  fill: #191919;
}

.article-card__forum-icon {
  padding: var(--spacing-4);
  border-radius: 50%;
  line-height: 0;
}

.article-card {
  flex-direction: column;
  display: flex;
}

.article-card__container {
  display: flex;
}

.article-card__image {
  max-width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.article-card__image picture {
  background-color: var(--other-filled-background);
  background-image: url("/dist/assets/default/gallery/spin-loader.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  position: relative;
}

.article-card__content {
  width: 100%;
  position: relative;
}

.article-card__content-inner {
  align-items: flex-start;
  gap: var(--spacing-6);
  flex-direction: column;
  display: flex;
  position: relative;
}

.article-card__rating .rating-bar__icons svg {
  width: 20px;
  height: 20px;
}

.article-card__title {
  color: var(--color-text-default-title);
}

.article-card__title-link {
  font: inherit;
  color: inherit;
  text-decoration: none;
}

.article-card__title-link:hover {
  text-decoration: underline;
}

.article-card__description {
  font: var(--typography-desktop-body-md-font-size) / var(--typography-desktop-body-md-line-height) var(--body-md-font-family);
}

.article-card__bottom {
  gap: var(--spacing-4) var(--spacing-16);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 1080px) {
  .article-card--desktop-landscape-xxl {
    gap: var(--spacing-16);
  }

  .article-card--desktop-landscape-xxl .article-card__container {
    gap: var(--spacing-16) var(--spacing-20);
  }

  .article-card--desktop-landscape-xxl .article-card__image {
    flex: 0 0 560px;
  }

  .article-card--desktop-landscape-xxl .article-card__content {
    gap: var(--spacing-12);
  }

  .article-card--desktop-landscape-xxl .article-card__title {
    font: var(--font-desktop-h2);
  }

  .article-card--desktop-landscape-xxl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--desktop-portrait-xl, .article-card--desktop-landscape-xl {
    gap: var(--spacing-16);
  }

  .article-card--desktop-portrait-xl .article-card__container, .article-card--desktop-landscape-xl .article-card__container {
    gap: var(--spacing-16) var(--spacing-20);
  }

  .article-card--desktop-portrait-xl .article-card__content, .article-card--desktop-landscape-xl .article-card__content {
    gap: var(--spacing-12);
  }

  .article-card--desktop-portrait-xl .article-card__title, .article-card--desktop-landscape-xl .article-card__title {
    font: var(--font-desktop-h1);
  }

  .article-card--desktop-portrait-xl .article-card__rating .rating-bar__icons svg, .article-card--desktop-landscape-xl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--desktop-portrait-lg, .article-card--desktop-landscape-lg {
    gap: var(--spacing-16);
  }

  .article-card--desktop-portrait-lg .article-card__container, .article-card--desktop-landscape-lg .article-card__container {
    gap: var(--spacing-16) var(--spacing-20);
  }

  .article-card--desktop-portrait-lg .article-card__content, .article-card--desktop-landscape-lg .article-card__content {
    gap: var(--spacing-12);
  }

  .article-card--desktop-portrait-lg .article-card__title, .article-card--desktop-landscape-lg .article-card__title {
    font: var(--font-desktop-h2);
  }

  .article-card--desktop-landscape-lg .article-card__image {
    flex: 0 0 560px;
  }

  .article-card--desktop-portrait-md, .article-card--desktop-landscape-md {
    gap: var(--spacing-16);
  }

  .article-card--desktop-portrait-md .article-card__container, .article-card--desktop-landscape-md .article-card__container {
    gap: var(--spacing-12) var(--spacing-20);
  }

  .article-card--desktop-portrait-md .article-card__title, .article-card--desktop-landscape-md .article-card__title {
    font: var(--font-desktop-h3);
  }

  .article-card--desktop-landscape-md .article-card__image {
    flex: 0 0 210px;
  }

  .article-card--desktop-portrait-xs, .article-card--desktop-landscape-xs {
    gap: var(--spacing-12);
  }

  .article-card--desktop-portrait-xs .article-card__container, .article-card--desktop-landscape-xs .article-card__container {
    gap: var(--spacing-12) var(--spacing-20);
  }

  .article-card--desktop-portrait-xs .article-card__title, .article-card--desktop-landscape-xs .article-card__title {
    font: var(--font-desktop-h5);
  }

  .article-card--desktop-landscape-xs .article-card__image {
    flex: 0 0 152px;
    max-width: 50%;
  }

  .article-card--desktop-landscape-xl .article-card__container, .article-card--desktop-landscape-lg .article-card__container, .article-card--desktop-landscape-md .article-card__container, .article-card--desktop-landscape-sm .article-card__container, .article-card--desktop-landscape-xs .article-card__container, .article-card--desktop-landscape-xxs .article-card__container {
    flex-direction: row;
  }

  .article-card--desktop-landscape-xl .article-card__container--reverse, .article-card--desktop-landscape-lg .article-card__container--reverse, .article-card--desktop-landscape-md .article-card__container--reverse, .article-card--desktop-landscape-sm .article-card__container--reverse, .article-card--desktop-landscape-xs .article-card__container--reverse, .article-card--desktop-landscape-xxs .article-card__container--reverse {
    flex-direction: row-reverse;
  }

  .article-card--desktop-portrait-xl .article-card__container, .article-card--desktop-portrait-lg .article-card__container, .article-card--desktop-portrait-md .article-card__container, .article-card--desktop-portrait-sm .article-card__container, .article-card--desktop-portrait-xs .article-card__container, .article-card--desktop-portrait-xxs .article-card__container {
    flex-direction: column;
  }

  .article-card--desktop-portrait-xl .article-card__container--reverse, .article-card--desktop-portrait-lg .article-card__container--reverse, .article-card--desktop-portrait-md .article-card__container--reverse, .article-card--desktop-portrait-sm .article-card__container--reverse, .article-card--desktop-portrait-xs .article-card__container--reverse, .article-card--desktop-portrait-xxs .article-card__container--reverse {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1079px) {
  .article-card--tablet-landscape-xxl {
    gap: var(--spacing-16);
  }

  .article-card--tablet-landscape-xxl .article-card__container {
    gap: var(--spacing-16) var(--spacing-20);
  }

  .article-card--tablet-landscape-xxl .article-card__image {
    flex: 0 0 560px;
    max-width: 55%;
  }

  .article-card--tablet-landscape-xxl .article-card__content {
    gap: var(--spacing-12);
  }

  .article-card--tablet-landscape-xxl .article-card__title {
    font: var(--font-desktop-h2);
  }

  .article-card--tablet-landscape-xxl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--tablet-portrait-xl {
    gap: var(--spacing-16);
  }

  .article-card--tablet-portrait-xl .article-card__container {
    gap: var(--spacing-16) var(--spacing-20);
  }

  .article-card--tablet-portrait-xl .article-card__content {
    gap: var(--spacing-12);
  }

  .article-card--tablet-portrait-xl .article-card__title {
    font: var(--font-desktop-h1);
  }

  .article-card--tablet-portrait-xl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--tablet-portrait-lg, .article-card--tablet-landscape-lg {
    gap: var(--spacing-16);
  }

  .article-card--tablet-portrait-lg .article-card__container, .article-card--tablet-landscape-lg .article-card__container {
    gap: var(--spacing-16) var(--spacing-20);
  }

  .article-card--tablet-portrait-lg .article-card__content, .article-card--tablet-landscape-lg .article-card__content {
    gap: var(--spacing-12);
  }

  .article-card--tablet-portrait-lg .article-card__title, .article-card--tablet-landscape-lg .article-card__title {
    font: var(--font-desktop-h2);
  }

  .article-card--tablet-landscape-lg .article-card__image {
    flex: 0 0 328px;
  }

  .article-card--tablet-portrait-md, .article-card--tablet-landscape-md {
    gap: var(--spacing-12);
  }

  .article-card--tablet-portrait-md .article-card__container, .article-card--tablet-landscape-md .article-card__container {
    gap: var(--spacing-12) var(--spacing-20);
  }

  .article-card--tablet-portrait-md .article-card__title, .article-card--tablet-landscape-md .article-card__title {
    font: var(--font-desktop-h3);
  }

  .article-card--tablet-landscape-md .article-card__image {
    flex: 0 210px;
  }

  .article-card--tablet-portrait-xs, .article-card--tablet-landscape-xs {
    gap: var(--spacing-12);
  }

  .article-card--tablet-portrait-xs .article-card__container, .article-card--tablet-landscape-xs .article-card__container {
    gap: var(--spacing-12) var(--spacing-20);
  }

  .article-card--tablet-portrait-xs .article-card__title, .article-card--tablet-landscape-xs .article-card__title {
    font: var(--font-desktop-h5);
  }

  .article-card--tablet-landscape-xs .article-card__image {
    flex: 0 0 152px;
    max-width: 50%;
  }

  .article-card--tablet-landscape-xl .article-card__container, .article-card--tablet-landscape-lg .article-card__container, .article-card--tablet-landscape-md .article-card__container, .article-card--tablet-landscape-sm .article-card__container, .article-card--tablet-landscape-xs .article-card__container, .article-card--tablet-landscape-xxs .article-card__container {
    flex-direction: row;
  }

  .article-card--tablet-landscape-xl .article-card__container--reverse, .article-card--tablet-landscape-lg .article-card__container--reverse, .article-card--tablet-landscape-md .article-card__container--reverse, .article-card--tablet-landscape-sm .article-card__container--reverse, .article-card--tablet-landscape-xs .article-card__container--reverse, .article-card--tablet-landscape-xxs .article-card__container--reverse {
    flex-direction: row-reverse;
  }

  .article-card--tablet-portrait-xl .article-card__container, .article-card--tablet-portrait-lg .article-card__container, .article-card--tablet-portrait-md .article-card__container, .article-card--tablet-portrait-sm .article-card__container, .article-card--tablet-portrait-xs .article-card__container, .article-card--tablet-portrait-xxs .article-card__container {
    flex-direction: column;
  }

  .article-card--tablet-portrait-xl .article-card__container--reverse, .article-card--tablet-portrait-lg .article-card__container--reverse, .article-card--tablet-portrait-md .article-card__container--reverse, .article-card--tablet-portrait-sm .article-card__container--reverse, .article-card--tablet-portrait-xs .article-card__container--reverse, .article-card--tablet-portrait-xxs .article-card__container--reverse {
    flex-direction: column-reverse;
  }
}

.article-card--theme-light .article-card__divider {
  border-bottom: 1px solid var(--other-divider);
  margin-bottom: 0;
}

.article-card--theme-light .article-card__title {
  color: var(--color-text-default-title);
}

.article-card--theme-light .article-card__authors, .article-card--theme-light .article-card__date, .article-card--theme-light .article-card__time, .article-card--theme-light .article-card__category-name {
  color: var(--color-text-default-helper);
}

.article-card--theme-light .article-card__category-link {
  color: var(--color-text-link-primary-default);
}

.article-card--theme-dark .article-card__title, .article-card--theme-dark .article-card__description {
  color: var(--primary-contrast);
}

.article-card--theme-dark .article-card__category-name {
  color: var(--text-secondary);
}

.article-card--theme-dark .article-card__category-link {
  color: var(--primary-main);
}

.article-card--promoted {
  border: 1px solid var(--grey-200);
  background: var(--grey-50);
  padding: var(--spacing-8);
  margin: calc(-1 * var(--spacing-8));
  position: relative;
}

.article-card--promoted .article-card__divider, div.article-card--hidden {
  display: none;
}

.author {
  gap: var(--spacing-16);
  margin-top: var(--spacing-12);
  flex-direction: row;
  display: flex;
}

.author__info {
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

.author__name--link {
  text-underline-offset: 4px;
}

.author__photos {
  display: inline-flex;
  position: relative;
}

.author__photo:not(:first-child) {
  margin-left: -16px;
}

.author__date-and-category {
  gap: var(--spacing-16);
  font: initial;
  display: flex;
}

.badge--pill {
  align-items: center;
  gap: var(--spacing-4);
  padding: var(--spacing-2) var(--spacing-8);
  border-radius: 2px;
  flex-grow: 0;
  display: inline-flex;
}

.badge--pill .badge__text {
  color: var(--primary-contrast);
  font: var(--font-desktop-meta-bold);
  text-transform: uppercase;
  white-space: nowrap;
}

.badge--fill-primary {
  background-color: var(--primary-main);
}

.badge--outlined-primary {
  border: 1px solid var(--color-surface-brand-primary-light);
}

.badge--outlined-primary .badge__text {
  color: var(--color-surface-brand-primary-light);
}

.badge--outlined-primary-dark {
  border: 1px solid var(--primary-dark);
}

.badge--outlined-primary-dark .badge__text {
  color: var(--primary-dark);
}

.badge--success {
  background-color: #f3faf3;
}

.badge--success .badge__text {
  color: #388e3b;
}

.author-card--default {
  padding: var(--spacing-24);
  background: var(--color-surface-default-subtle);
  border-radius: var(--spacing-author-card-radius);
  min-height: 110px;
}

.author-card--default-big {
  grid-row: auto / span 2;
}

.author-card--default-big .author-card__image {
  margin-bottom: var(--spacing-24);
}

.author-card--default-small .author-card__image {
  margin-right: var(--spacing-16);
}

.author-card--default-big .author-card__name-wrapper {
  width: 100%;
}

.author-card--default-small .author-card__name-wrapper {
  align-items: flex-start;
  width: calc(100% - 76px);
}

.author-card--default-big .author-card__name {
  flex: calc(100% - 24px);
}

.author-card--default-small .author-card__name {
  flex: calc(100% - 100px);
}

.author-card__name-wrapper--only-name {
  height: 100%;
  align-items: center !important;
}

.author-card__name-wrapper--only-name > h2, .author-card__name-wrapper--only-name > span {
  align-items: center;
  display: flex;
}

.author-card--default-small .author-card__role {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  width: calc(100% - 76px);
  line-height: 1.43;
  display: -webkit-box;
  overflow: hidden;
}

.author-card--author-detail {
  border-bottom: 1px solid var(--other-divider);
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto;
  display: grid;
}

.author-card--author-detail .author-card__image {
  margin-right: var(--spacing-16);
  grid-row: 2 span;
}

.author-card--author-detail .author-card__role {
  color: var(--color-text-author-card-detail-subtitle);
}

.author-card--author-detail .author-card__mail-link {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
  overflow-wrap: anywhere;
  margin-top: var(--spacing-4);
  grid-area: 2 / 3;
}

.author-card--author-detail .author-card__description {
  margin-top: var(--spacing-24);
  grid-column: 3 span;
}

.author-card__image {
  float: left;
}

.author-card__name-wrapper {
  color: initial;
  -webkit-text-decoration: initial;
  text-decoration: initial;
  flex-direction: row;
  display: flex;
}

.author-card__arrow-wrapper {
  flex: 0 24px;
  min-width: 24px;
  margin-left: auto;
}

.author-card__arrow-wrapper svg {
  width: 100%;
  height: 100%;
}

.author-card__role {
  margin-top: var(--spacing-2);
  color: var(--color-text-author-card-list-subtitle);
  align-self: center;
  width: 100%;
}

.breadcrumb {
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  display: inline-flex;
}

.breadcrumb li {
  font: var(--font-desktop-meta-regular);
}

.breadcrumb li:not(:last-child):after {
  content: "";
  padding: 0 var(--spacing-12);
  background: url("/dist/assets/default/icons/icon-breadcrumb-arrow-right.svg") center / 5px 9px no-repeat;
  width: 5px;
  height: 9px;
  display: inline-block;
  position: relative;
}

.breadcrumb li a {
  color: var(--text-primary);
  text-decoration: none;
}

.breadcrumb li a:visited, .breadcrumb li a:active, .breadcrumb li a:hover {
  color: var(--text-primary);
  text-decoration: underline;
}

.breadcrumb--simple li:before {
  content: "";
  padding: 0 var(--spacing-12);
  color: var(--text-primary);
  background: url("/dist/assets/default/icons/icon-breadcrumb-arrow-right.svg") center / 5px 9px no-repeat;
  transform: rotate(180deg);
}

[data-load-more-seo-wrapper] {
  text-decoration: none;
}

.button {
  cursor: pointer;
  border: 2px solid var(--color-border-button-primary-solid-normal-outlined-default);
  font: var(--font-desktop-button-large);
  text-align: center;
  padding: var(--spacing-12) var(--spacing-24);
  border-radius: var(--spacing-button-radius);
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.button--lg {
  gap: var(--spacing-8);
}

.button--lg svg {
  width: 24px;
  height: 24px;
}

.button--md {
  padding: var(--spacing-6) var(--spacing-16);
  gap: var(--spacing-8);
  font: var(--font-desktop-button-medium);
}

.button--md svg {
  width: 20px;
  height: 20px;
}

.button--sm {
  padding: var(--spacing-4) var(--spacing-12);
  gap: var(--spacing-4);
  font: var(--font-desktop-button-small);
}

.button--sm svg {
  width: 16px;
  height: 16px;
}

.button--contained {
  background-color: var(--color-surface-button-primary-solid-normal-contained-default);
  border-color: var(--color-surface-button-primary-solid-normal-contained-default);
  color: var(--color-text-button-primary-solid-normal-contained-default);
}

.button--contained--md {
  color: var(--white);
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: var(--spacing-24);
  letter-spacing: .32px;
  word-wrap: break-word;
  background: var(--red);
  border: none;
}

.button--contained:hover {
  border-color: var(--color-surface-button-primary-solid-normal-contained-hover);
  background-color: var(--color-surface-button-primary-solid-normal-contained-hover);
}

.button--contained:focus {
  background-color: var(--color-surface-button-primary-solid-normal-contained-focus);
  border: 2px solid var(--color-surface-button-primary-solid-normal-contained-focus);
}

.button--contained:active {
  border-color: var(--color-surface-button-primary-solid-normal-contained-active);
  background-color: var(--color-surface-button-primary-solid-normal-contained-active);
}

.button--contained:disabled {
  color: var(--color-text-button-primary-solid-normal-contained-disabled);
  background-color: var(--color-surface-button-primary-solid-normal-contained-disabled);
  border-color: var(--color-surface-button-primary-solid-normal-contained-disabled);
}

.button--contained:disabled svg path {
  fill: var(--color-icon-button-primary-solid-normal-contained-disabled) !important;
}

.button--contained:not(.button--button-social-login) svg path {
  fill: var(--color-icon-button-primary-solid-normal-contained-default) !important;
}

.button--outlined {
  color: var(--color-text-button-primary-solid-normal-outlined-default);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-default);
  border-color: var(--color-border-button-primary-solid-normal-outlined-default);
}

.button--outlined:hover {
  color: var(--color-text-button-primary-solid-normal-outlined-hover);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-hover);
  border-color: var(--color-border-button-primary-solid-normal-outlined-hover);
}

.button--outlined:focus {
  color: var(--color-text-button-primary-solid-normal-outlined-focus);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-focus);
  border-color: var(--color-border-button-primary-solid-normal-outlined-focus);
}

.button--outlined:active {
  color: var(--color-text-button-primary-solid-normal-outlined-active);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-active);
  border-color: var(--color-border-button-primary-solid-normal-outlined-active);
}

.button--outlined:disabled {
  color: var(--color-text-button-primary-solid-normal-outlined-disabled);
  background-color: var(--color-surface-button-primary-solid-normal-outlined-disabled);
  border-color: var(--color-border-button-primary-solid-normal-outlined-disabled);
}

.button--outlined:disabled svg path {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-disabled);
}

.button--text {
  color: var(--primary-main);
  background-color: initial;
  border: none;
}

.button--text--sm {
  height: var(--spacing-32);
  padding: var(--spacing-8);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);
  background: none;
  border: none;
  flex-shrink: 0;
  display: inline-flex;
}

.button--text:hover {
  color: var(--primary-dark);
}

.button--text:focus {
  color: var(--primary-main);
}

.button--text:active {
  color: var(--primary-light);
}

.button--text:disabled {
  color: var(--text-disabled);
}

.button--text:disabled svg path {
  fill: var(--text-disabled);
}

.button__link {
  color: inherit;
  text-decoration: none;
}

.button svg {
  position: relative;
}

.button--icon {
  min-width: initial;
  max-width: initial;
  padding: var(--spacing-12);
}

.button--contained.button--secondary {
  color: var(--color-text-button-secondary-solid-normal-contained-default);
  border-color: var(--color-border-button-secondary-solid-normal-contained-default);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-default);
}

.button--contained.button--secondary svg {
  fill: var(--color-icon-button-secondary-solid-normal-contained-default);
}

.button--contained.button--secondary:hover {
  color: var(--color-text-button-secondary-solid-normal-contained-hover);
  border-color: var(--color-surface-button-secondary-solid-normal-contained-hover);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-hover);
}

.button--contained.button--secondary:hover svg {
  fill: var(--color-icon-button-secondary-solid-normal-contained-hover);
}

.button--contained.button--secondary:focus {
  color: var(--color-text-button-secondary-solid-normal-contained-focus);
  border-color: var(--color-surface-button-secondary-solid-normal-contained-focus);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-focus);
}

.button--contained.button--secondary:focus svg {
  fill: var(--color-icon-button-secondary-solid-normal-contained-focus);
}

.button--contained.button--secondary:active {
  color: var(--color-text-button-secondary-solid-normal-contained-active);
  border-color: var(--color-surface-button-secondary-solid-normal-contained-active);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-active);
}

.button--contained.button--secondary:active svg {
  fill: var(--color-icon-button-secondary-solid-normal-contained-active);
}

.button--contained.button--secondary:disabled {
  color: var(--color-text-button-secondary-solid-normal-contained-disabled);
  border-color: var(--color-surface-button-secondary-solid-normal-contained-disabled);
  background-color: var(--color-surface-button-secondary-solid-normal-contained-disabled);
}

.button--contained.button--secondary:disabled svg {
  fill: var(--color-icon-button-secondary-solid-normal-contained-disabled);
}

.button--contained.button--inverted {
  color: var(--color-text-button-primary-solid-inverted-contained-default);
  border-color: var(--color-surface-button-primary-solid-inverted-contained-default);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-default);
}

.button--contained.button--inverted svg {
  fill: var(--color-icon-button-primary-solid-inverted-contained-default);
}

.button--contained.button--inverted:hover {
  color: var(--color-text-button-primary-solid-inverted-contained-hover);
  border-color: var(--color-surface-button-primary-solid-inverted-contained-hover);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-hover);
}

.button--contained.button--inverted:hover svg {
  fill: var(--color-icon-button-primary-solid-inverted-contained-hover);
}

.button--contained.button--inverted:focus {
  color: var(--color-text-button-primary-solid-inverted-contained-focus);
  border-color: var(--color-surface-button-primary-solid-inverted-contained-focus);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-focus);
}

.button--contained.button--inverted:focus svg {
  fill: var(--color-icon-button-primary-solid-inverted-contained-focus);
}

.button--contained.button--inverted:active {
  color: var(--color-text-button-primary-solid-inverted-contained-active);
  border-color: var(--color-surface-button-primary-solid-inverted-contained-active);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-active);
}

.button--contained.button--inverted:active svg {
  fill: var(--color-icon-button-primary-solid-inverted-contained-active);
}

.button--contained.button--inverted:disabled {
  color: var(--color-text-button-primary-solid-inverted-contained-disabled);
  border-color: var(--color-surface-button-primary-solid-inverted-contained-disabled);
  background-color: var(--color-surface-button-primary-solid-inverted-contained-disabled);
}

.button--contained.button--inverted:disabled svg {
  fill: var(--color-icon-button-primary-solid-inverted-contained-disabled);
}

.button--outlined.button--secondary {
  color: var(--color-text-button-secondary-solid-normal-outlined-default);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-default);
  border-color: var(--color-border-button-secondary-solid-normal-outlined-default);
}

.button--outlined.button--secondary svg {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-default);
}

.button--outlined.button--secondary:hover {
  color: var(--color-text-button-secondary-solid-normal-outlined-hover);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-hover);
  border-color: var(--color-border-button-secondary-solid-normal-outlined-hover);
}

.button--outlined.button--secondary:hover svg {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-hover);
}

.button--outlined.button--secondary:focus {
  color: var(--color-text-button-secondary-solid-normal-outlined-focus);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-focus);
  border-color: var(--color-border-button-secondary-solid-normal-outlined-focus);
}

.button--outlined.button--secondary:focus svg {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-focus);
}

.button--outlined.button--secondary:active {
  color: var(--color-text-button-secondary-solid-normal-outlined-active);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-active);
  border-color: var(--color-border-button-secondary-solid-normal-outlined-active);
}

.button--outlined.button--secondary:active svg {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-active);
}

.button--outlined.button--secondary:disabled {
  color: var(--color-text-button-secondary-solid-normal-outlined-disabled);
  background-color: var(--color-surface-button-secondary-solid-normal-outlined-disabled);
  border-color: var(--color-border-button-secondary-solid-normal-outlined-disabled);
}

.button--outlined.button--secondary:disabled svg {
  fill: var(--color-icon-button-secondary-solid-normal-outlined-disabled);
}

.button--outlined.button--inverted {
  color: var(--color-text-button-primary-solid-inverted-outlined-default);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-default);
  border-color: var(--color-border-button-primary-solid-inverted-outlined-default);
}

.button--outlined.button--inverted svg {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-default);
}

.button--outlined.button--inverted:hover {
  color: var(--color-text-button-primary-solid-inverted-outlined-hover);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-hover);
  border-color: var(--color-border-button-primary-solid-inverted-outlined-hover);
}

.button--outlined.button--inverted:hover svg {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-hover);
}

.button--outlined.button--inverted:focus {
  color: var(--color-text-button-primary-solid-inverted-outlined-focus);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-focus);
  border-color: var(--color-border-button-primary-solid-inverted-outlined-focus);
}

.button--outlined.button--inverted:focus svg {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-focus);
}

.button--outlined.button--inverted:active {
  color: var(--color-text-button-primary-solid-inverted-outlined-active);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-active);
  border-color: var(--color-border-button-primary-solid-inverted-outlined-active);
}

.button--outlined.button--inverted:active svg {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-active);
}

.button--outlined.button--inverted:disabled {
  color: var(--color-text-button-primary-solid-inverted-outlined-disabled);
  background-color: var(--color-surface-button-primary-solid-inverted-outlined-disabled);
  border-color: var(--color-border-button-primary-solid-inverted-outlined-disabled);
}

.button--outlined.button--inverted:disabled svg {
  fill: var(--color-icon-button-primary-solid-inverted-outlined-disabled);
}

.button--button-social-login {
  border-color: var(--color-border-default-light);
  color: var(--color-text-default-body);
  background-color: var(--color-surface-button-social-login-default);
}

.button--button-social-login:hover {
  border-color: var(--color-border-button-social-login-hover);
  background-color: var(--color-surface-button-social-login-hover);
}

.button--button-social-login:focus-visible {
  border-color: var(--color-border-button-social-login-focus);
  background-color: var(--color-surface-default-contrast);
}

.button--button-social-login:active {
  border-color: var(--color-border-button-social-login-active);
  background-color: var(--color-surface-button-social-login-active);
}

.button--button-social-login[disabled] {
  color: var(--color-text-button-social-login-disabled);
  border-color: var(--color-border-button-social-login-disabled);
  background-color: var(--color-surface-button-social-login-disabled);
}

.button--button-social-login[disabled] .button__icon svg {
  filter: grayscale();
  opacity: .4;
}

.button--pending {
  cursor: wait;
  border: none;
  border-radius: .5rem;
  outline: none;
  width: 220px;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
}

.button--pending:before {
  content: "";
  background: repeating-linear-gradient(60deg, transparent, transparent .75rem, var(--grey-50) .75rem, var(--grey-50) 1.5rem);
  width: 150%;
  height: 100%;
  animation: 1s linear infinite load;
  position: absolute;
  top: 0;
  left: 0;
}

.button--pending span {
  width: 100%;
  display: block;
  position: relative;
}

@keyframes load {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1.75rem);
  }
}

.button--shaked {
  perspective: 1000px;
  animation: .82s cubic-bezier(.36, .07, .19, .97) both shake;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.load-more-btn:focus {
  background-color: var(--primary-main);
  color: var(--primary-contrast);
  border: 2px solid var(--primary-main);
}

.category-card {
  flex-direction: column;
  display: flex;
  position: relative;
}

.category-card__image {
  aspect-ratio: 328 / 184;
  width: 100%;
}

.category-card__image picture {
  aspect-ratio: 328 / 184;
  justify-content: center;
  align-items: center;
  display: flex;
}

.category-card__image img {
  width: auto;
  max-width: 100%;
}

.category-card__card-link {
  padding: var(--spacing-24);
  flex-direction: column;
  flex: auto;
  text-decoration: none;
  display: flex;
}

.category-card__card-link button {
  align-self: flex-start;
  margin-top: auto;
}

a:hover .category-card__title, a:hover .category-card__description {
  text-decoration: none;
}

.category-card__description {
  margin-bottom: var(--spacing-32);
}

.category-card .partner-promo {
  top: var(--spacing-10);
  left: var(--spacing-10);
  position: absolute;
}

.category-card--dark {
  background-color: var(--color-surface-default-strong);
}

.category-card--dark .category-card__title, .category-card--dark .category-card__description {
  color: var(--color-text-default-inverted);
}

[data-collapser-target] {
  transition: max-height .15s ease-out, visibility .3s, opacity .3s;
  position: relative;
  overflow: hidden;
}

[data-collapser-target]:after {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--color-surface-default-contrast) 100%);
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

[data-collapser-target][aria-expanded="true"] {
  max-height: 100%;
  transition: max-height .15s ease-in, visibility .3s, opacity .3s;
}

[data-collapser-target][aria-expanded="true"]:after {
  visibility: hidden;
  opacity: 0;
  display: none;
}

[data-collapser-target].hide-target:after {
  display: none;
}

[data-collapser]:not(.button) {
  cursor: pointer;
  color: var(--color-text-action-primary-main);
  margin-top: var(--spacing-16);
  margin-bottom: var(--spacing-16);
  background: none;
  border: 0;
  align-items: center;
  text-decoration: none;
  display: flex;
}

[data-collapser]:not([data-collapser-type="toggle"]).hide-target, [data-collapser][data-collapser-type="toggle"]:not([aria-expanded="true"]).hide-target {
  display: none;
}

[data-collapser-arrow] {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

[data-collapser-arrow] path {
  fill: var(--color-text-action-primary-main);
}

[data-collapser-arrow][aria-expanded="true"] {
  transform: rotate(180deg);
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity .2s;
}

.loading-placeholder:not(.loading-placeholder--loaded, .loading-placeholder--error) {
  background-color: #f8f8f8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.loading-placeholder--loaded, .loading-placeholder--error):after {
  content: " ";
  filter: opacity(.5);
  background-color: #f8f8f8;
  background-image: linear-gradient(to right, #f8f8f8 0%, #eee 33%, #f8f8f8 67%);
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  animation: 1s linear infinite gradient-animation;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.loading-placeholder--loaded, .loading-placeholder--error) img {
  text-indent: -9999px;
  color: #0000;
  position: absolute;
}

.loading-placeholder--loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder--loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder--error {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.loading-placeholder--error:before {
  content: "";
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.datetime, .datetime__date, .datetime__time {
  color: var(--color-text-article-meta-normal-data);
}

.wiki-form {
  padding: var(--spacing-8) var(--spacing-12);
  margin: var(--spacing-16) 0;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
  background: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  flex-direction: row;
  width: 100%;
  height: 48px;
  display: flex;
}

.wiki-form__input {
  padding: var(--spacing-8) var(--spacing-4);
  font-size: var(--font-size-16);
  border: none;
  width: 90%;
  height: 16px;
}

.wiki-form__input:focus {
  outline: none;
}

.wiki-form__button {
  cursor: pointer;
  padding: var(--spacing-4);
  background: none;
  border: none;
}

.wiki-form__button svg {
  width: 32px;
  height: 32px;
}

.link-list {
  align-items: center;
  gap: 0 var(--spacing-12);
  flex-wrap: wrap;
  display: flex;
}

.link-list__item {
  color: var(--color-text-default-body);
}

.link-list__link {
  color: inherit;
}

.link-list__link:hover {
  text-decoration: none;
}

.list {
  gap: var(--spacing-12) 0;
  counter-reset: order;
  color: var(--color-text-list-default);
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.list--inverted {
  color: var(--color-text-list-inverted);
}

.list--circle .list__item, .list--number .list__item, .list--icon .list__item {
  gap: var(--spacing-4);
}

.list--circle .list__item:before {
  content: "•";
  width: 1em;
  display: inline-block;
}

.list--icon svg {
  flex: none;
}

.list--icon.list--inverted svg * {
  fill: var(--color-text-list-inverted);
}

.list--icon-checker path {
  fill: var(--color-text-list-default);
}

.list--number .list__item:before {
  content: counter(order) ".";
  width: 1em;
  display: inline-block;
}

.list--no-bullet .list__item:before {
  content: none;
  display: none;
}

.list__item {
  counter-increment: order;
  color: inherit;
  align-items: flex-start;
  display: flex;
}

.list__item-text {
  color: inherit;
  flex: 1;
}

.list__link {
  color: inherit;
}

.list__link:hover {
  text-decoration: none;
}

.load-more-wrapper, .uncover-wrapper {
  text-align: center;
  padding-bottom: var(--spacing-16);
  position: relative;
}

.load-more-wrapper > a, .uncover-wrapper > a {
  text-decoration: none;
}

.load-more-wrapper--error, .uncover-wrapper--error {
  padding: var(--spacing-12);
}

.load-more-wrapper--gradient, .uncover-wrapper--gradient {
  text-align: center;
  padding-bottom: var(--spacing-16);
  position: relative;
}

.load-more-wrapper--gradient:before, .uncover-wrapper--gradient:before {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--background-default), var(--background-default) 140%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
}

.load-more-wrapper--hidden, .uncover-wrapper--hidden, .visually-hidden {
  display: none;
}

.load-more-disabled {
  gap: var(--spacing-12);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.load-more-disabled__icon {
  line-height: 0;
}

.load-more-disabled__text {
  gap: var(--spacing-4);
  flex-direction: column;
  display: flex;
}

.load-more-disabled__title {
  text-align: center;
}

.load-more-disabled__subtitle {
  color: var(--text-secondary);
  text-align: center;
}

.pagination {
  margin: var(--spacing-32) 0;
  position: relative;
}

.pagination > ul.pagination__wrapper {
  justify-content: center;
  gap: var(--spacing-8);
  flex-direction: row;
  width: 100%;
  margin: 0;
  display: flex;
}

.pagination__item {
  border: 2px var(--color-border-pagination-inactive) solid;
  font: var(--font-desktop-button-medium);
  text-align: center;
  color: var(--color-text-pagination-inactive);
  background-color: var(--color-surface-pagination-inactive);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  transition: all .4s ease-in-out;
  display: block;
}

.pagination__item:hover {
  border-color: var(--color-border-pagination-hover);
  background-color: var(--color-surface-pagination-hover);
}

.pagination__item:focus {
  background-color: var(--color-surface-pagination-focus);
  border-color: var(--color-border-pagination-focus);
  color: var(--color-text-pagination-focus);
}

.pagination .pagination__link {
  color: var(--color-text-pagination-inactive);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.pagination--active {
  border-color: var(--color-border-pagination-active);
  pointer-events: none;
  background-color: var(--color-surface-pagination-active);
}

.pagination--active .pagination__link {
  color: var(--color-text-pagination-active);
}

.pagination__arrow {
  fill: var(--color-icon-button-primary-contained-main);
}

.pagination__arrow-button {
  background-color: var(--color-surface-button-primary-contained-main);
  border: 2px var(--color-surface-button-primary-contained-main) solid;
  text-align: center;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  transition: all .4s ease-in-out;
  display: block;
  position: absolute;
}

.pagination__arrow-button:hover {
  background-color: var(--color-surface-action-primary-dark);
  border-color: var(--color-surface-action-primary-dark);
}

.pagination__arrow-button:focus {
  background-color: var(--color-surface-action-primary-dark);
  border-color: var(--color-border-default-focus);
}

.pagination__arrow-button .pagination__link {
  line-height: 40px;
}

.pagination__arrow-button:first-child {
  left: 0;
}

.pagination__arrow-button:last-child {
  right: 0;
}

.pagination--disabled {
  pointer-events: none;
  background-color: var(--color-surface-default-disabled);
  border-color: var(--color-surface-default-disabled);
  border-radius: 4px;
}

.pagination--disabled .pagination__arrow {
  fill: var(--color-text-action-secondary-on-secondary);
}

.partner-promo {
  flex-flow: row;
  align-items: center;
  display: flex;
}

.partner-promo__logo {
  flex-flow: column;
  justify-content: center;
  max-width: 144px;
  height: 48px;
  display: flex;
}

.partner-promo__logo img {
  width: auto;
  max-width: 144px;
  max-height: 48px;
}

.partner-promo__name {
  color: var(--color-text-neutral-light);
  font: var(--font-desktop-meta-bold);
}

.partner-promo__name a {
  color: var(--color-surface-brand-primary-main);
  text-decoration: underline;
}

.partner-promo__name a:hover {
  text-decoration: none;
}

.partner-promo__link + .partner-promo__name {
  margin-left: var(--spacing-20);
}

.partner-promo--theme-dark .partner-promo__name {
  color: var(--text-disabled);
}

.promobox {
  padding: var(--spacing-16);
  border: 1px solid var(--color-border-default-main);
}

@media (max-width: 575.999px) {
  .promobox {
    padding-bottom: var(--spacing-4);
  }
}

.promobox__main-article + .promobox__bottom-articles {
  margin-top: var(--spacing-16);
  padding-top: var(--spacing-16);
  position: relative;
}

.promobox__main-article + .promobox__bottom-articles:after {
  content: "";
  background-color: var(--color-border-default-main);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.promobox--horizontal {
  gap: var(--spacing-32);
  border: none;
  display: flex;
}

.promobox--horizontal .promobox__left-article {
  flex: 1 0 0;
  display: flex;
}

.promobox--horizontal .promobox__right-articles {
  gap: var(--spacing-32);
  flex-direction: column;
  flex: 1 0 0;
  display: flex;
}

.promobox--horizontal .promobox__right-articles .article-card__title {
  font: var(--font-desktop-h4);
}

.svg-loader {
  margin: 0 auto;
  display: block;
}

.chevron_right:after {
  content: "";
  background: url("/dist/assets/default/chevron_right.svg") center no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.enquiry-bars {
  gap: var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.enquiry-bars__title {
  border-top: var(--spacing-4) solid var(--color-border-divider-section);
  padding-top: var(--spacing-8);
  font: var(--font-desktop-body2-bold);
  color: var(--color-text-survey-meta);
}

.enquiry-bars__question {
  font: var(--font-desktop-h2);
  color: var(--color-text-survey-title);
}

.enquiry-bars__answers {
  gap: var(--spacing-8);
  flex-direction: column;
  display: flex;
}

.enquiry-bars-answers__answer {
  font: var(--font-desktop-body1);
  border-radius: var(--spacing-vote-strip-radius);
  border-left: var(--spacing-4) solid var(--color-border-vote-strip-default);
  background: var(--color-surface-vote-strip-default);
  position: relative;
}

.enquiry-bars-answers__answer--hover:hover {
  border-style: solid;
  border-width: 1px 1px 1px 4px;
  border-color: var(--color-border-vote-strip-hover);
  background: var(--color-surface-vote-strip-hover);
  cursor: pointer;
}

.enquiry-bars-answers__answer--hover:hover .enquiry-bars-answer__statement {
  padding: 7px 15px 7px 16px;
}

.enquiry-bars-answer__indicator {
  background-color: var(--color-surface-vote-strip-answer);
  width: 0;
  height: 100%;
  transition: width 1s linear;
  position: absolute;
}

.enquiry-bars-answer__statement {
  padding: var(--spacing-8) var(--spacing-16);
  justify-content: space-between;
  display: flex;
  position: relative;
}

.enquiry-bars-answer__statement--selected {
  font: var(--font-desktop-body1-bold);
}

.enquiry-bars__result-notice {
  padding: var(--spacing-24) var(--spacing-32);
  background-color: var(--color-surface-survey-background);
  gap: var(--spacing-24);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.enquiry-bars__result-notice .button {
  width: max-content;
  max-height: fit-content;
}

.enquiry-bars-result-notice__text {
  text-align: center;
  font: var(--font-desktop-body1-bold);
  color: var(--color-text-survey-body);
}

.enquiry-bars-result-notice__link {
  color: var(--white);
  width: 114px;
  text-decoration: none;
  display: block;
}

.enquiry-bars-result-notice__link:hover {
  text-decoration: underline;
}

.enquiry-bars__footer {
  border-bottom: 1px solid var(--color-border-divider-default);
  padding-bottom: var(--spacing-4);
  justify-content: space-between;
  display: flex;
}

.enquiry-bars-footer__vote-notice {
  color: var(--color-text-survey-confirm);
  font: var(--font-desktop-body1-bold);
}

.enquiry-bars-footer__total-votes {
  text-align: right;
  color: var(--color-text-survey-meta);
  font: var(--font-desktop-body1);
  margin-left: auto;
}

.print__cover {
  flex-direction: column;
  display: flex;
}

.print__cover > a {
  text-decoration: none;
  display: flex;
}

.promo-subscription {
  padding: var(--spacing-40) var(--spacing-40);
  border-radius: var(--spacing-4);
  border-top: 3px solid var(--color-border-brand-primary-main, #df0f19);
  background: var(--color-surface-neutral-strong, #262626);
  flex-direction: column;
  flex: 1 0 0;
  grid-area: print;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 300px;
  display: flex;
}

.promo-subscription__header {
  justify-content: center;
  align-items: center;
  display: flex;
}

.promo-subscription__benefits {
  color: var(--color-text-default-contrast, #fff);
  padding-top: var(--spacing-16);
}

.promo-subscription__benefits--title {
  font: var(--font-desktop-subtitle1-bold);
}

.promo-subscription__benefits--subtitle {
  font: var(--font-desktop-body2);
}

.promo-subscription__benefits--content {
  font: var(--font-desktop-helper-text);
  color: var(--colors-accent-light);
  display: block;
}

ul.promo-subscription__benefits-list {
  padding-top: var(--spacing-16);
  list-style-image: url("/dist/assets/default/icons/icon-checker-small.svg");
  list-style-position: outside;
}

ul.promo-subscription__benefits-list > li {
  padding: var(--spacing-8) 0 var(--spacing-4);
}

.promo-subscription__call-to-action {
  padding-top: var(--spacing-16);
}

.premium-card {
  border-radius: var(--spacing-premium-card-radius);
  padding: var(--spacing-48) var(--spacing-32);
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  max-width: 329px;
  display: flex;
  position: relative;
}

.premium-card--normal {
  border: 2px solid var(--color-border-premium-card-normal);
}

.premium-card__badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.premium-card__header {
  text-align: center;
  margin-bottom: var(--spacing-32);
  flex-shrink: 0;
}

.premium-card__content {
  flex-grow: 1;
  flex-shrink: 0;
}

.premium-card__benefits {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-12);
}

.premium-card__benefits-divider, .premium-card__benefits-title {
  margin-bottom: var(--spacing-8);
}

.premium-card__footer {
  justify-content: center;
  align-items: flex-end;
  margin-top: auto;
  display: flex;
}

.premium-card__cta {
  min-width: 204px;
}

.premium-card--normal.premium-card--normal {
  background: var(--color-surface-premium-card-normal-normal);
}

.premium-card--normal.premium-card--normal .premium-card__title, .premium-card--normal.premium-card--normal .premium-card__price, .premium-card--normal.premium-card--normal .premium-card__interval {
  color: var(--color-text-premium-card-normal-title);
}

.premium-card--normal.premium-card--normal .premium-card__benefits-title {
  color: var(--color-text-premium-card-normal-body);
}

.premium-card--normal.premium-card--normal .premium-card__price-discount {
  color: var(--color-text-premium-card-normal-subtitle);
  text-decoration: line-through;
}

.premium-card--normal.premium-card--normal .premium-card__price-info {
  color: var(--color-text-premium-card-normal-subtitle);
}

.premium-card--normal.premium-card--normal .premium-card__tarif-info svg {
  padding-top: var(--spacing-4);
}

.premium-card--normal.premium-card--best {
  background: var(--color-surface-premium-card-normal-best);
}

.premium-card--normal.premium-card--best .premium-card__title, .premium-card--normal.premium-card--best .premium-card__price, .premium-card--normal.premium-card--best .premium-card__interval {
  color: var(--color-text-premium-card-normal-title);
}

.premium-card--normal.premium-card--best .premium-card__benefits-title {
  color: var(--color-text-premium-card-normal-body);
}

.premium-card--normal.premium-card--best .premium-card__price-discount {
  color: var(--color-text-premium-card-normal-subtitle);
  text-decoration: line-through;
}

.premium-card--normal.premium-card--best .premium-card__price-info {
  color: var(--color-text-premium-card-normal-subtitle);
}

.premium-card--normal.premium-card--best .premium-card__tarif-info svg {
  padding-top: var(--spacing-4);
}

.premium-card--normal.premium-card--vip {
  background: var(--color-surface-premium-card-normal-VIP);
}

.premium-card--normal.premium-card--vip .premium-card__title, .premium-card--normal.premium-card--vip .premium-card__price, .premium-card--normal.premium-card--vip .premium-card__interval {
  color: var(--color-text-premium-card-normal-title);
}

.premium-card--normal.premium-card--vip .premium-card__benefits-title {
  color: var(--color-text-premium-card-normal-body);
}

.premium-card--normal.premium-card--vip .premium-card__price-discount {
  color: var(--color-text-premium-card-normal-subtitle);
  text-decoration: line-through;
}

.premium-card--normal.premium-card--vip .premium-card__price-info {
  color: var(--color-text-premium-card-normal-subtitle);
}

.premium-card--normal.premium-card--vip .premium-card__tarif-info svg {
  padding-top: var(--spacing-4);
}

.premium-card--inverted.premium-card--normal {
  background: var(--color-surface-premium-card-inverted-normal);
}

.premium-card--inverted.premium-card--normal .premium-card__title, .premium-card--inverted.premium-card--normal .premium-card__price, .premium-card--inverted.premium-card--normal .premium-card__interval {
  color: var(--color-text-premium-card-inverted-title);
}

.premium-card--inverted.premium-card--normal .premium-card__benefits-title {
  color: var(--color-text-premium-card-inverted-body);
}

.premium-card--inverted.premium-card--normal .premium-card__price-discount {
  color: var(--color-text-premium-card-inverted-subtitle);
  text-decoration: line-through;
}

.premium-card--inverted.premium-card--normal .premium-card__price-info {
  color: var(--color-text-premium-card-inverted-subtitle);
}

.premium-card--inverted.premium-card--normal .premium-card__tarif-info svg {
  padding-top: var(--spacing-4);
}

.premium-card--inverted.premium-card--best {
  background: var(--color-surface-premium-card-inverted-best);
}

.premium-card--inverted.premium-card--best .premium-card__title, .premium-card--inverted.premium-card--best .premium-card__price, .premium-card--inverted.premium-card--best .premium-card__interval {
  color: var(--color-text-premium-card-inverted-title);
}

.premium-card--inverted.premium-card--best .premium-card__benefits-title {
  color: var(--color-text-premium-card-inverted-body);
}

.premium-card--inverted.premium-card--best .premium-card__price-discount {
  color: var(--color-text-premium-card-inverted-subtitle);
  text-decoration: line-through;
}

.premium-card--inverted.premium-card--best .premium-card__price-info {
  color: var(--color-text-premium-card-inverted-subtitle);
}

.premium-card--inverted.premium-card--best .premium-card__tarif-info svg {
  padding-top: var(--spacing-4);
}

.premium-card--inverted.premium-card--vip {
  background: var(--color-surface-premium-card-inverted-VIP);
}

.premium-card--inverted.premium-card--vip .premium-card__title, .premium-card--inverted.premium-card--vip .premium-card__price, .premium-card--inverted.premium-card--vip .premium-card__interval {
  color: var(--color-text-premium-card-inverted-title);
}

.premium-card--inverted.premium-card--vip .premium-card__benefits-title {
  color: var(--color-text-premium-card-inverted-body);
}

.premium-card--inverted.premium-card--vip .premium-card__price-discount {
  color: var(--color-text-premium-card-inverted-subtitle);
  text-decoration: line-through;
}

.premium-card--inverted.premium-card--vip .premium-card__price-info {
  color: var(--color-text-premium-card-inverted-subtitle);
}

.premium-card--inverted.premium-card--vip .premium-card__tarif-info svg {
  padding-top: var(--spacing-4);
}

.scroll-menu .is-mouse-drag {
  -webkit-user-select: none;
  user-select: none;
  cursor: ew-resize;
}

.scroll-menu__item {
  text-decoration: none;
  display: block;
}

.scroll-menu__item:hover {
  color: var(--primary-main);
  text-decoration: none;
}

.scroll-menu__item:hover .typography {
  text-decoration: none;
}

.scroll-menu--theme-light .scroll-menu__item {
  color: var(--text-primary);
}

.scroll-menu--theme-light .scroll-menu__item:hover {
  color: var(--primary-dark);
}

.scroll-menu--theme-dark .scroll-menu__item {
  color: var(--color-text-default-contrast);
}

.scroll-menu--theme-dark .scroll-menu__item:hover {
  color: var(--primary-main);
}

.social-icon-list, .social-share {
  gap: var(--spacing-12);
  flex-direction: row;
  display: flex;
}

.social-media {
  gap: var(--spacing-12);
  flex-flow: wrap;
  display: flex;
}

.social-media:empty {
  display: none;
}

.premium-sticker {
  background-image: url("/dist/assets/default/premium-sticker-black.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 88px;
  height: 20px;
}

.swiper {
  position: relative;
  container: swiper / inline-size;
}

.swiper__container {
  position: relative;
}

.swiper__container.end-fade:after {
  content: "";
  pointer-events: none;
  width: 120px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
}

.swiper__list {
  gap: var(--spacing-24);
  white-space: nowrap;
  scroll-snap-type: x proximity;
  transition: all .3s ease-in-out;
  display: flex;
  overflow: auto;
}

.swiper__item {
  white-space: normal;
  scroll-snap-align: start;
  flex-shrink: 0;
}

.swiper__navigation {
  padding-top: var(--spacing-12);
}

@media (min-width: 576px) {
  .swiper__navigation {
    display: none;
  }
}

.swiper--article .swiper__item {
  width: calc((100% - var(--spacing-24)) / 1.5);
}

@media (min-width: 480px) {
  .swiper--article .swiper__item {
    width: 50%;
  }
}

@media (min-width: 576px) {
  .swiper--article .swiper__item {
    flex-shrink: 1;
  }
}

.swiper--article .swiper__item:nth-of-type(5), .swiper--article .swiper__item:nth-of-type(6) {
  display: block;
}

@media (min-width: 576px) {
  .swiper--article .swiper__item:nth-of-type(5), .swiper--article .swiper__item:nth-of-type(6) {
    display: none;
  }
}

@container swiper (width >= 768px) {
  .swiper--article .swiper__item:nth-of-type(5) {
    display: block;
  }
}

@container swiper (width >= 850px) {
  .swiper--article .swiper__item:nth-of-type(6) {
    display: block;
  }
}

.swiper--mobile-navigation {
  width: 100%;
}

.swiper--mobile-navigation .swiper__list {
  gap: var(--spacing-8);
}

.swiper--filter-navigation {
  width: 100%;
}

.swiper--filter-navigation .swiper__list {
  gap: var(--spacing-16);
}

.swiper--theme-white .swiper__container.end-fade:after {
  background: linear-gradient(90deg, #0000 0%, #ffffff80 50%, #fff 100%);
}

.swiper--theme-light .swiper__container.end-fade:after {
  background: linear-gradient(90deg, transparent 0%, var(--action-disabled-background) 100%);
}

.swiper--theme-dark .swiper__container.end-fade:after {
  background: linear-gradient(90deg, transparent 0%, var(--color-surface-neutral-strong) 100%);
}

.swiper-navigation {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.swiper-navigation__item {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.swiper-navigation__item:before {
  content: "";
  pointer-events: none;
  border-radius: 2px;
  display: block;
}

.swiper-navigation--barred .swiper-navigation__item {
  width: 40px;
  height: 30px;
}

.swiper-navigation--barred .swiper-navigation__item:before {
  background-color: var(--color-surface-neutral-main);
  width: 100%;
  height: 6px;
}

.swiper-navigation--barred .swiper-navigation__item.is-active:before {
  background-color: var(--color-surface-brand-primary-main);
}

.topic {
  padding: var(--spacing-32) var(--spacing-24);
  background-color: var(--surface-default-disabled);
  container: topic / inline-size;
}

.topic__image {
  margin-bottom: var(--spacing-24);
  overflow: hidden;
}

.topic__image img {
  object-fit: cover;
  width: 100%;
  height: 128px;
  display: block;
}

.topic .scroll-menu__item {
  padding-top: 6px;
}

.topic__top {
  justify-content: space-between;
  gap: var(--spacing-16);
  margin-bottom: var(--spacing-24);
  align-items: center;
  display: flex;
}

.topic__heading {
  align-items: flex-start;
  gap: var(--spacing-8) var(--spacing-24);
  flex-direction: column;
  display: flex;
}

@container topic (width >= 680px) {
  .topic__heading {
    flex-direction: row;
  }
}

.topic__badge {
  padding-bottom: var(--spacing-4);
  flex: none;
}

.topic__link {
  color: inherit;
  text-decoration: none;
}

.topic__link .typography {
  font: var(--font-desktop-h2);
}

.topic__unimenu {
  padding-bottom: var(--spacing-12);
  padding-top: var(--spacing-12);
  margin-top: calc(-1 * var(--spacing-24));
  margin-bottom: var(--spacing-24);
  position: relative;
}

.topic__unimenu:after {
  content: "";
  background-color: var(--secondary-outlined-resting-border);
  opacity: .3;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.topic__partner {
  max-width: 230px;
}

.topic__main-article {
  margin-bottom: var(--spacing-24);
}

.topic__top-articles {
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-24);
  margin-top: var(--spacing-24);
  display: flex;
}

.topic__top-articles__item {
  width: 50%;
}

@container topic (width >= 768px) {
  .topic__top-articles__item {
    width: 33.3%;
  }
}

.topic__top-articles__item:nth-of-type(3) {
  display: none;
}

@container topic (width >= 768px) {
  .topic__top-articles__item:nth-of-type(3) {
    display: block;
  }
}

.topic__custom-article html, .topic__custom-article body, .topic__custom-article .font-body {
  font: var(--font-mobile-body1);
  color: var(--text-primary);
}

.topic__custom-article h1, .topic__custom-article .heading-1 {
  font: var(--font-mobile-h1);
}

.topic__custom-article h2, .topic__custom-article .heading-2 {
  font: var(--font-mobile-h2);
}

.topic__custom-article h3, .topic__custom-article .heading-3 {
  font: var(--font-mobile-h3);
}

.topic__custom-article h4, .topic__custom-article .heading-4 {
  font: var(--font-mobile-h4);
}

.topic__custom-article h5, .topic__custom-article .heading-5 {
  font: var(--font-mobile-h5);
}

.topic__custom-article .button {
  text-decoration: none;
}

.topic__custom-article em, .topic__custom-article em strong {
  font-style: italic;
}

.topic__custom-article sub, .topic__custom-article sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.topic__custom-article sup {
  top: -.5em;
}

.topic__custom-article sub {
  bottom: -.25em;
}

.topic__custom-article ul {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: disc;
}

.topic__custom-article ul ul {
  margin: 0 0 0 var(--spacing-24);
  list-style-type: circle;
}

.topic__custom-article ol {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: decimal;
}

.topic__custom-article ol ol {
  margin: 0 0 0 var(--spacing-24);
}

.topic__custom-article hr {
  border: 0;
  border-top: 1px solid var(--other-divider);
}

.topic__custom-article blockquote:not(.tiktok-embed) {
  background-color: var(--other-divider);
  border-left: 5px solid var(--other-backdrop-overlay);
  padding: var(--spacing-20);
}

.topic__custom-article-body .uncover-wrapper:before {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--action-disabled-background), var(--action-disabled-background) 140%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
}

.topic__bottom-articles {
  margin-bottom: var(--spacing-24);
  padding-top: var(--spacing-24);
  position: relative;
}

.topic__bottom-articles:after {
  content: "";
  background-color: var(--secondary-outlined-resting-border);
  opacity: .3;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.topic__cta {
  padding-top: var(--spacing-8);
  justify-content: center;
  display: flex;
}

.topic--miniSpecialDetailClanku {
  padding: var(--spacing-16);
}

.topic--miniSpecialDetailClanku .topic__top {
  padding-bottom: 0;
}

.topic--theme-light .scroll-menu__item {
  color: var(--text-primary);
}

.topic--theme-dark {
  background-color: var(--color-surface-neutral-strong);
}

.topic--theme-dark .topic__name {
  color: var(--color-text-default-contrast);
}

.topic--theme-dark .topic__bottom-articles:after, .topic--theme-dark .topic__unimenu:after {
  background-color: var(--color-border-divider-default);
}

.topic--theme-dark .topic__custom-article, .topic--theme-dark .topic__custom-article a {
  color: var(--color-text-default-contrast);
}

.topic--theme-dark .topic__custom-article a.category__link {
  color: var(--primary-main);
}

.topic--theme-dark .topic__custom-article-body .uncover-wrapper:before {
  content: "";
  background: linear-gradient(180deg, transparent 0%, var(--surface-neutral-darker), var(--surface-neutral-darker) 140%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
}

.topic--theme-dark .category .label-link.label-link--contained.label-link--solid {
  color: var(--color-text-label-link-solid-text-default);
}

.topic--theme-dark .category .label-link.label-link--contained.label-link--solid:hover {
  text-decoration: underline;
}

.topic--topicInHP {
  padding: var(--spacing-20) 0 var(--spacing-24);
  border-top: 4px solid var(--text-primary);
  background-color: var(--background-default);
}

.topic--topicInHP .topic__heading {
  text-transform: uppercase;
  gap: var(--spacing-12);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.topic--topicInHP .topic__heading .topic__icon {
  line-height: 0;
}

.topic--topicInHP .topic__top-articles {
  flex-direction: column;
}

.topic--topicInHP .topic__top-articles .topic__top-articles__item {
  width: 100%;
}

.topic--topicInHP .topic__top-articles .topic__top-articles__item:nth-child(2) .article-card__description {
  display: none;
}

.topic--topicInHP .topic__top-articles .article-card__image {
  flex: 0 0 304px;
  display: block;
}

.topic--topicInHP2x2 {
  padding: var(--spacing-20) 0 var(--spacing-24);
  border-top: 4px solid var(--text-primary);
  background-color: var(--background-default);
}

.topic--topicInHP2x2 .topic__heading {
  text-transform: uppercase;
  gap: var(--spacing-12);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.topic--topicInHP2x2 .topic__heading .topic__icon {
  line-height: 0;
}

.topic--topicInHP2x2 .topic__top-articles {
  gap: var(--spacing-24);
  flex-flow: wrap;
}

.topic--topicInHP2x2 .topic__top-articles .topic__top-articles__item {
  width: calc(50% - var(--spacing-24) / 2);
}

.topic--topicInHP2x2 .topic__top-articles .topic__top-articles__item:nth-child(3) {
  display: block;
}

.topic--topicInHP2x2 .topic__top-articles .topic__top-articles__item .article-card__image {
  display: block;
}

.topic--theme-finexpert {
  border-top-color: var(--blue-400);
  padding: var(--spacing-20) var(--spacing-24) var(--spacing-24);
  background-color: #eff8ff;
}

.topic--theme-finexpert .topic__heading, .topic--theme-finexpert .partner-promo__name, .topic--theme-finexpert .topic__unimenu:after {
  color: var(--blue-400);
}

.topic--theme-finexpert .scroll-menu__item {
  color: var(--text-primary);
}

.table-wrapper {
  margin-bottom: var(--spacing-20);
  padding-bottom: var(--spacing-12);
  max-width: 100%;
  overflow-x: auto;
}

.table-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-wrapper::-webkit-scrollbar:horizontal {
  border-radius: var(--spacing-8);
  background-color: var(--action-disabled-background);
  height: 4px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  border-radius: var(--spacing-8);
  background-color: var(--action-disabled);
  cursor: pointer;
  height: 4px;
}

.table-wrapper .scroll-shadow:before {
  content: "";
  background: linear-gradient(to right, #1919190a, #0000);
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
}

table {
  table-layout: fixed;
  border-collapse: separate;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  transition: all .2s ease-in;
  overflow: hidden;
}

th, td {
  vertical-align: middle;
  color: var(--text-primary);
}

th:first-child, td:first-child {
  z-index: 1;
  backface-visibility: hidden;
  position: sticky;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

th a, td a {
  color: var(--primary-main);
}

th a:hover, td a:hover {
  text-decoration: none;
}

thead {
  background-color: var(--action-hover);
  text-align: left;
}

thead th, thead td {
  padding: var(--spacing-12) var(--spacing-8);
  font: var(--font-desktop-body2-bold);
  background-color: var(--action-hover);
  border-bottom: 1px solid var(--primary-light);
}

tbody th, tbody td {
  padding: var(--spacing-20) var(--spacing-8);
  background: var(--secondary-contrast);
  border-bottom: 1px solid var(--other-divider);
  font: var(--font-desktop-body2);
}

tbody th *, tbody td * {
  font: inherit;
}

tbody th strong, tbody td strong {
  font: var(--font-desktop-body2-bold);
}

tbody tr:first-of-type td, tbody tr:first-of-type th {
  border-top: 1px solid var(--other-divider);
}

thead + tbody tr:first-of-type td, thead + tbody tr:first-of-type th {
  border-top: 0;
}

.tabs-and-links {
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-48);
  flex-direction: column;
  grid-column: lend / rend;
  display: flex;
}

.tabs-and-links .swiper__list {
  gap: var(--spacing-8);
}

.tabs-and-links__tabs {
  gap: var(--spacing-8);
  width: 100%;
  display: flex;
}

.tabs-and-links__tab {
  background: var(--info-contrast);
  padding: var(--spacing-4) var(--spacing-8);
  color: var(--secondary-main);
  gap: var(--spacing-8);
  cursor: pointer;
  border: 1px solid var(--secondary-light);
  border-radius: 100px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
}

.tabs-and-links__tab svg {
  width: 20px;
  height: 20px;
}

.tabs-and-links__tab svg path {
  fill: var(--secondary-main);
}

.tabs-and-links__tab--active {
  background: var(--primary-main);
  color: var(--primary-contrast);
  border: 1px solid var(--primary-main);
}

.tabs-and-links__tab--active svg path {
  fill: var(--primary-contrast);
}

.tabs-and-links__tab:hover {
  background-color: var(--grey-300);
  color: var(--secondary-main);
  border: 1px solid var(--secondary-light);
}

.tabs-and-links__tab:hover svg path {
  fill: var(--secondary-main);
}

.tabs-and-links__tab-text {
  margin-top: var(--spacing-4);
}

.tabs-and-links__links {
  gap: var(--spacing-16);
  flex-flow: wrap;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.tabs-and-links__link {
  flex-basis: calc(50% - var(--spacing-24));
}

.tabs-and-links__link a {
  color: var(--black);
  gap: var(--spacing-4);
  background-color: var(--info-alert-background);
  padding: var(--spacing-16);
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.tabs-and-links__link a:hover {
  background-color: var(--info-states-outlined-hover-background);
}

.tabs-and-links__link--hidden {
  display: none;
}

.tabs-and-links__link-title {
  font: var(--font-mobile-body1-bold);
  -webkit-text-decoration-color: var(--black);
  text-decoration-color: var(--black);
  max-width: 90%;
  text-decoration: underline;
}

.tabs-and-links__link-title:hover, .tabs-and-links__link-title:focus, .tabs-and-links__link-title:active, .tabs-and-links__link-title:visited {
  color: var(--black);
}

.tabs-and-links__link-tabs {
  gap: var(--spacing-4);
  flex-direction: row;
  display: flex;
}

.tabs-and-links__link-tab {
  padding: var(--spacing-2) var(--spacing-8);
  background: var(--info-contrast);
  padding: var(--spacing-4) var(--spacing-8);
  color: var(--secondary-main);
  border-radius: 100px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.tabs-and-links__link-arrow {
  top: 0;
  bottom: 0;
  right: var(--spacing-16);
  width: 20px;
  height: 20px;
  margin: auto 0;
  position: absolute;
}

.tabs-and-links__link-arrow svg path {
  fill: var(--text-primary);
}

.article-detail__content-body .tabs-and-links .tabs-and-links__links {
  margin: 0;
}

.article-detail__content-body .tabs-and-links li.tabs-and-links__link {
  list-style-type: none;
}

.article-detail__content-body .tabs-and-links li.tabs-and-links__link a {
  color: var(--black);
  text-decoration: none;
}

.article-detail__content-body .tabs-and-links li.tabs-and-links__link a .tabs-and-links__link-title {
  text-decoration: underline;
}

* {
  --tag-gradient-active-normal-bg: var(--gradient-1);
  --tag-gradient-active-inverted-bg: var(--gradient-2);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal {
  border: 1px solid var(--color-border-tag-solid-normal-default);
  background: var(--color-surface-tag-solid-normal-default);
  color: var(--color-text-tag-solid-normal-default);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal:hover {
  border: 1px solid var(--color-border-tag-solid-normal-hover);
  background: var(--color-surface-tag-solid-normal-hover);
  color: var(--color-text-tag-solid-normal-hover);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal:focus {
  border: 1px solid var(--color-border-tag-solid-normal-focus);
  background: var(--color-surface-tag-solid-normal-focus);
  color: var(--color-text-tag-solid-normal-focus);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal:active {
  background: var(--color-surface-tag-solid-normal-active);
  color: var(--color-text-tag-solid-normal-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--normal[disabled] {
  border: 1px solid var(--color-border-tag-solid-normal-disabled);
  background: var(--color-surface-tag-solid-normal-disabled);
  color: var(--color-text-tag-solid-normal-disabled);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal {
  border: 1px solid var(--color-border-tag-gradient-normal-default);
  background: var(--color-surface-tag-gradient-normal-default);
  color: var(--color-text-tag-gradient-normal-default);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:hover {
  background: var(--color-surface-tag-gradient-normal-hover);
  color: var(--color-text-tag-gradient-normal-hover);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:focus {
  background: var(--color-surface-tag-gradient-normal-focus);
  color: var(--color-text-tag-gradient-normal-focus);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:active {
  background: var(--tag-gradient-active-normal-bg);
  color: var(--color-text-tag-gradient-normal-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:active span {
  background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal[disabled] {
  border: 1px solid var(--color-border-tag-gradient-normal-disabled);
  background: var(--color-surface-tag-gradient-normal-disabled);
  color: var(--color-text-tag-gradient-normal-disabled);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted {
  border: 1px solid var(--color-border-tag-solid-inverted-default);
  background: var(--color-surface-tag-solid-inverted-default);
  color: var(--color-text-tag-solid-inverted-default);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted:hover {
  border: 1px solid var(--color-border-tag-solid-inverted-hover);
  background: var(--color-surface-tag-solid-inverted-hover);
  color: var(--color-text-tag-solid-inverted-hover);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted:focus {
  border: 1px solid var(--color-border-tag-solid-inverted-focus);
  background: var(--color-surface-tag-solid-inverted-focus);
  color: var(--color-text-tag-solid-inverted-focus);
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted:active {
  background: var(--color-surface-tag-solid-inverted-active);
  color: var(--color-text-tag-solid-inverted-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--solid.tag-wrapper--inverted[disabled] {
  border: 1px solid var(--color-border-tag-solid-inverted-disabled);
  background: var(--color-surface-tag-solid-inverted-disabled);
  color: var(--color-text-tag-solid-inverted-disabled);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted {
  border: 1px solid var(--color-border-tag-gradient-inverted-default);
  background: var(--color-surface-tag-gradient-inverted-default);
  color: var(--color-text-tag-gradient-inverted-default);
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:hover {
  background: var(--color-surface-tag-gradient-inverted-hover);
  color: var(--color-text-tag-gradient-inverted-hover);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:focus {
  background: var(--color-surface-tag-gradient-inverted-focus);
  color: var(--color-text-tag-gradient-inverted-focus);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:active {
  background: var(--tag-gradient-active-inverted-bg);
  color: var(--color-text-tag-gradient-inverted-active);
  border-color: #0000;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:active span {
  background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
}

.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted[disabled] {
  border: 1px solid var(--color-border-tag-gradient-inverted-disabled);
  background: var(--color-surface-tag-gradient-inverted-disabled);
  color: var(--color-text-tag-gradient-inverted-disabled);
}

.tag-wrapper {
  padding: var(--spacing-2) var(--spacing-12);
  border-radius: var(--spacing-tag-radius);
  border: 1px solid var(--color-border-tag-default-default);
  background: var(--color-surface-tag-primary-default);
  outline: none;
  text-decoration: none;
  transition: color .2s, background-color .2s, border-color .2s;
  display: inline-flex;
  position: relative;
}

.tag-wrapper:before {
  border-radius: calc(var(--spacing-tag-radius)  + 1px);
}

.tag-wrapper > .tag {
  text-decoration: none;
  position: relative;
}

.tag-wrapper > .tag:hover {
  text-decoration: none;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:before {
  content: "";
  z-index: -1;
  border-radius: calc(var(--spacing-tag-radius)  + 1px);
  background: var(--gradient-1);
  opacity: 0;
  margin: -2px;
  transition: opacity .2s;
  position: absolute;
  inset: 0;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:hover:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:hover > .tag {
  background: var(--gradient-1);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:focus:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--normal:focus > .tag {
  background: var(--gradient-1);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:before {
  content: "";
  z-index: -1;
  border-radius: calc(var(--spacing-tag-radius)  + 1px);
  background: var(--gradient-2);
  opacity: 0;
  margin: -2px;
  transition: opacity .2s;
  position: absolute;
  inset: 0;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:hover:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:hover > .tag {
  background: var(--gradient-2);
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:focus:before {
  opacity: 1;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:focus > .tag {
  background: var(--gradient-2);
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: none;
  border-color: #0000 !important;
}

.tag-wrapper.tag-wrapper.tag-wrapper--gradient.tag-wrapper--inverted:active > .tag {
  border-color: #0000;
}

.tag-list {
  margin-top: var(--spacing-32);
  gap: var(--spacing-8);
  flex-wrap: wrap;
  display: flex;
}

.tag-list__item {
  display: inline-flex;
}

.rating-bar {
  gap: var(--spacing-4);
  align-items: center;
  display: flex;
}

.rating-bar__icons {
  display: flex;
}

.rating-bar__icons svg path {
  fill: var(--primary-main);
}

.rating-bar__label {
  font: var(--font-desktop-body1-bold);
  color: var(--text-primary);
}

.rating-bar__hint {
  font: var(--font-desktop-meta-regular);
  color: var(--text-secondary);
}

.rating-bar--md .rating-bar__icons svg {
  width: 16px;
  height: 16px;
}

.rating-bar--lg .rating-bar__icons svg {
  width: 20px;
  height: 20px;
}

.rating-bar--xl .rating-bar__icons svg {
  width: 24px;
  height: 24px;
}

.select {
  visibility: hidden;
  opacity: 0;
}

.choices {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.choices:focus {
  outline: none;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
  background-color: var(--color-surface-default-disabled);
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices__inner {
  vertical-align: top;
  background-color: var(--color-surface-default-contrast);
  padding: var(--spacing-8) var(--spacing-12);
  border: 1px solid var(--color-text-default-body);
  border-radius: var(--spacing-input-radius);
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  padding-right: var(--spacing-24);
  width: 100%;
  display: inline-block;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__item {
  font: var(--font-mobile-body2);
  color: var(--color-text-default-body);
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .5;
}

.choices__input {
  vertical-align: baseline;
  background-color: var(--color-surface-default-contrast);
  font: var(--font-mobile-body2);
  color: var(--color-text-default-body);
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-4) 0 var(--spacing-4) var(--spacing-2);
  border: 0;
  border-radius: 0;
  max-width: 100%;
  display: inline-block;
}

.choices__input:focus {
  outline: 0;
}

.choices__input::-webkit-search-decoration {
  display: none;
}

.choices__input::-webkit-search-cancel-button {
  display: none;
}

.choices__input::-webkit-search-results-button {
  display: none;
}

.choices__input::-webkit-search-results-decoration {
  display: none;
}

.choices__input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.choices__input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

.choices__placeholder {
  color: var(--color-text-default-placeholder);
  font: var(--font-mobile-body2);
  text-wrap: nowrap;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"]:after {
  content: "";
  right: var(--spacing-12);
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M13.2291%207.50004L9.9958%2010.7334L6.76247%207.50004C6.43747%207.17504%205.91247%207.17504%205.58747%207.50004C5.26247%207.82504%205.26247%208.35004%205.58747%208.67504L9.41247%2012.5C9.73747%2012.825%2010.2625%2012.825%2010.5875%2012.5L14.4125%208.67504C14.7375%208.35004%2014.7375%207.82504%2014.4125%207.50004C14.0875%207.18337%2013.5541%207.17504%2013.2291%207.50004Z%22%20fill%3D%22%23DF0F19%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.choices[data-type*="select-one"].is-open:after {
  transform: translateY(-50%)rotate(180deg);
}

.choices[data-type*="select-one"] .choices__input {
  padding: var(--spacing-8) var(--spacing-12);
  border-bottom: 1px solid var(--color-border-default-subtle);
  background-color: var(--color-surface-default-contrast);
  width: 100%;
  margin: 0;
  display: block;
}

.choices__list[aria-expanded], .choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  background-color: var(--color-surface-default-contrast);
  margin-top: var(--spacing-4);
  border-radius: var(--spacing-input-radius);
  will-change: visibility;
  box-shadow: var(--shadow-m);
  width: 100%;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.is-active.choices__list[aria-expanded], .is-active.choices__list--dropdown {
  visibility: visible;
}

.is-flipped .choices__list[aria-expanded], .is-flipped .choices__list--dropdown {
  border-radius: .25rem .25rem 0 0;
  margin-top: 0;
  margin-bottom: -1px;
  top: auto;
  bottom: 100%;
}

.choices__list[aria-expanded] .choices__list, .choices__list--dropdown .choices__list {
  margin: var(--spacing-8) 0;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  max-height: 216px;
  position: relative;
  overflow: auto;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar {
  width: 14px;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar {
  width: 14px;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar-track {
  background-clip: padding-box;
  background-color: var(--color-surface-default-disabled);
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar-track {
  background-clip: padding-box;
  background-color: var(--color-surface-default-disabled);
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar-thumb {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar-thumb {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-radius: 6px;
}

.choices__list[aria-expanded] .choices__list::-webkit-scrollbar-thumb:hover {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
}

.choices__list--dropdown .choices__list::-webkit-scrollbar-thumb:hover {
  background: var(--color-surface-default-main);
  background-clip: padding-box;
}

.choices__list[aria-expanded] .choices__item, .choices__list--dropdown .choices__item {
  padding: var(--spacing-8) var(--spacing-16);
  color: var(--color-text-default-body);
  font: var(--font-desktop-body2);
  position: relative;
}

.choices__list[aria-expanded] .choices__item:hover, .choices__list--dropdown .choices__item:hover {
  background-color: var(--color-surface-default-hover);
}

.choices__list[aria-expanded] .choices__placeholder, .choices__list--dropdown .choices__placeholder {
  display: none;
}

.registration {
  background-color: var(--color-surface-registration-default-light);
  box-shadow: var(--shadow-l);
}

.registration__top, .registration__bottom {
  padding: var(--spacing-32) var(--spacing-24) var(--spacing-40);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.registration__top {
  border: 1px solid var(--color-border-registration-subtle);
  gap: var(--spacing-32);
  border-bottom: none;
}

.registration__bottom {
  border: 1px solid var(--color-border-registration-lighter);
  background-color: var(--color-surface-registration-default-subtle);
}

.registration__header {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.registration__perex {
  text-align: center;
  text-wrap: balanced;
  max-width: 655px;
}

.registration__benefits {
  align-items: center;
}

.registration__benefits svg path {
  fill: var(--color-icon-list-brand);
}

.registration__icon {
  margin-bottom: var(--spacing-12);
  background-color: var(--color-surface-registration-lock-background);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  display: flex;
}

.registration__login {
  align-items: center;
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.registration__button {
  width: 296px;
  max-width: 100%;
}

.registration__form {
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.ad-branding-wrapper {
  z-index: -1;
  position: absolute;
  top: 55px;
}

.ad-bottom {
  grid-column: 1 / -1;
  grid-template-columns: auto minmax(0, 1080px) auto;
}

.ad-category--bottom {
  grid-column: 1 / -1;
}

.ad-category--left-column {
  grid-column: 1 / 3;
}

.ad-position--desktop, .ad-position--tablet, .ad-position--mobile {
  display: none;
}

.ad-list-322, .ad-list-353 {
  grid-column: 1 / -1;
}

.ad-list-325 {
  grid-column: 1 / -1;
}

:root, :before {
  --ad-top-56: 56px;
  --ad-top-82: 82px;
}

.editorial-promo {
  position: relative;
}

.editorial-promo__agree, .editorial-promo__noads {
  position: absolute;
}

.editorial-promo--position-311 {
  width: 328px;
  height: 600px;
}

.editorial-promo--position-311 .editorial-promo__agree, .editorial-promo--position-311 .editorial-promo__noads {
  width: 196px;
  left: 66px;
}

.editorial-promo--position-311 .editorial-promo__agree {
  bottom: 86px;
}

.editorial-promo--position-311 .editorial-promo__noads {
  bottom: 34px;
}

.editorial-promo--position-322-desktop {
  width: 680px;
  height: 300px;
}

.editorial-promo--position-322-desktop .editorial-promo__agree, .editorial-promo--position-322-desktop .editorial-promo__noads {
  width: 165px;
  left: 100px;
}

.editorial-promo--position-322-desktop .editorial-promo__agree {
  bottom: 86px;
}

.editorial-promo--position-322-desktop .editorial-promo__noads {
  bottom: 42px;
}

.editorial-promo--position-322-mobile {
  width: 330px;
  height: 300px;
}

.editorial-promo--position-322-mobile .editorial-promo__agree, .editorial-promo--position-322-mobile .editorial-promo__noads {
  width: 145px;
  left: 21px;
}

.editorial-promo--position-322-mobile .editorial-promo__agree {
  bottom: 105px;
}

.editorial-promo--position-322-mobile .editorial-promo__noads {
  bottom: 61px;
}

.editorial-promo__noads {
  color: var(--primary-contrast);
  border-color: var(--primary-contrast);
  background: none;
}

.editorial-promo__noads:hover {
  color: var(--primary-contrast);
  border-color: var(--primary-contrast);
  background: #fafafa33;
}

.editorial-promo--magazine-e15:not(.editorial-promo--position-311) .editorial-promo__noads {
  color: #264961;
  background: none;
  border-color: #264961;
}

.editorial-promo--magazine-e15:not(.editorial-promo--position-311) .editorial-promo__noads:hover {
  color: #264961;
  background: #26496133;
  border-color: #264961;
}

.editorial-promo--magazine-zeny.editorial-promo--position-311 .editorial-promo__agree {
  bottom: 66px;
}

.editorial-promo--magazine-zeny.editorial-promo--position-322-mobile .editorial-promo__agree {
  bottom: 74px;
  left: 23px;
}

.editorial-promo--magazine-zeny.editorial-promo--position-322-desktop .editorial-promo__agree {
  bottom: 76px;
}

.miniplayer {
  display: none;
}

.modal {
  z-index: 10;
  background-color: #191919b3;
  width: 100%;
  height: 100%;
  padding-top: 140px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal__wrapper {
  background-color: var(--background-default);
  padding: var(--spacing-24);
  border: 1px solid var(--other-outline-border);
  gap: var(--spacing-24);
  color: var(--text-primary);
  border-radius: 12px;
  flex-direction: column;
  width: 688px;
  margin: auto;
  display: flex;
  position: relative;
}

.modal__header {
  border-bottom: 1px solid var(--other-divider);
  padding-bottom: var(--spacing-16);
  width: 100%;
  margin: auto;
}

.modal__title {
  font: var(--font-desktop-h3);
}

.modal__close {
  color: var(--other-common-black);
  top: var(--spacing-24);
  right: var(--spacing-24);
  width: 24px;
  height: 24px;
  font-size: 28px;
  position: absolute;
}

.modal__close:hover, .modal__close:focus {
  cursor: pointer;
  text-decoration: none;
}

.modal__body {
  width: 100%;
  margin: auto;
  position: relative;
}

.modal__footer {
  gap: var(--spacing-24);
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  display: flex;
}

.box__headline {
  align-items: center;
  gap: var(--spacing-12);
  color: var(--text-primary);
  font: var(--font-desktop-section-title);
  letter-spacing: .03em;
  background: none;
  padding: 0;
  display: flex;
}

.box__headline a:after {
  display: none;
}

.wiki-search__title {
  margin-bottom: var(--spacing-16);
  display: block;
}

.wiki-search__description {
  padding-top: var(--spacing-4);
  margin-bottom: var(--spacing-32);
}

.wiki-search__description > strong {
  font-weight: 700;
}

.wiki-search__paging {
  margin-top: var(--spacing-32);
  justify-content: space-between;
  display: flex;
}

.author-card-list {
  width: 100%;
}

.author-card-list__bottom {
  margin-top: var(--spacing-24);
  justify-content: center;
  display: flex;
}

.category-card-list {
  gap: var(--spacing-32) var(--spacing-24);
  grid-column: lend / rend;
  grid-template-columns: 1fr;
  width: 100%;
  display: grid;
}

.category-info {
  grid-column: lend / rend;
}

.category-info:empty {
  display: none;
}

.category-info picture, .category-info img {
  max-width: 100%;
}

.category-info__description html, .category-info__description body, .category-info__description .font-body {
  font: var(--font-mobile-body1);
  color: var(--text-primary);
}

.category-info__description h1, .category-info__description .heading-1 {
  font: var(--font-mobile-h1);
}

.category-info__description h2, .category-info__description .heading-2 {
  font: var(--font-mobile-h2);
}

.category-info__description h3, .category-info__description .heading-3 {
  font: var(--font-mobile-h3);
}

.category-info__description h4, .category-info__description .heading-4 {
  font: var(--font-mobile-h4);
}

.category-info__description h5, .category-info__description .heading-5 {
  font: var(--font-mobile-h5);
}

.category-info__description .button {
  text-decoration: none;
}

.category-info__description em, .category-info__description em strong {
  font-style: italic;
}

.category-info__description sub, .category-info__description sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.category-info__description sup {
  top: -.5em;
}

.category-info__description sub {
  bottom: -.25em;
}

.category-info__description ul {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: disc;
}

.category-info__description ul ul {
  margin: 0 0 0 var(--spacing-24);
  list-style-type: circle;
}

.category-info__description ol {
  margin: var(--spacing-20) 0 var(--spacing-20) var(--spacing-24);
  list-style-type: decimal;
}

.category-info__description ol ol {
  margin: 0 0 0 var(--spacing-24);
}

.category-info__description hr {
  border: 0;
  border-top: 1px solid var(--other-divider);
}

.category-info__description blockquote:not(.tiktok-embed) {
  background-color: var(--other-divider);
  border-left: 5px solid var(--other-backdrop-overlay);
  padding: var(--spacing-20);
}

.seo-box {
  gap: var(--spacing-16);
  padding: var(--spacing-24);
  border-top: 1px solid var(--other-common-black);
  border-bottom: 1px solid var(--color-border-divider-default);
  flex-flow: column;
  grid-column: lend / rend;
  display: flex;
}

.seo-box p {
  font: var(--font-desktop-body1);
}

.seo-box a:not(.seo-box__tag, .seo-box__title) {
  color: var(--color-text-text-link-hyperlink-primary-solid-normal-default);
}

.seo-box a:not(.seo-box__tag, .seo-box__title):hover {
  text-decoration: none;
}

.seo-box h3 {
  font: var(--typography-desktop-heading-md-font-size) / var(--typography-desktop-heading-md-line-height) var(--heading-md-font-family);
}

.seo-box h4 {
  font: var(--typography-desktop-body-sm-font-size) / var(--typography-desktop-body-sm-line-height) var(--body-sm-bold-font-family);
  color: var(--color-text-text-link-stand-alone-primary-solid-normal-default);
}

.seo-box__tag {
  margin-right: var(--spacing-8);
  margin-bottom: var(--spacing-8);
  text-decoration: none;
  display: inline-flex;
}

.seo-box .seo-box__tag a {
  color: var(--color-text-secondary);
}

.seo-box__link {
  position: relative;
}

.seo-box__link + .seo-box__link {
  margin-left: var(--spacing-20);
}

.seo-box__link + .seo-box__link:before {
  height: var(--spacing-28);
  left: calc(-1 * var(--spacing-12));
  content: "•";
  color: var(--color-text-default-meta);
  position: absolute;
}

.seo-box__title {
  font: var(--font-desktop-h4);
  color: var(--color-text-primary);
}

.seo-box__body {
  gap: var(--spacing-16);
  flex-flow: column;
  display: flex;
}

.seo-box__team-link {
  gap: var(--spacing-8);
  align-items: center;
  margin-bottom: 14px;
  margin-right: 14px;
  display: inline-flex;
}

.profile-user {
  flex-direction: column;
  grid-column: lend / rend;
  max-width: 680px;
  margin: 0 auto;
  display: flex;
}

.profile-user a {
  color: var(--text-primary);
}

.profile-user a:hover {
  text-decoration: none;
}

.profile-user__title {
  justify-content: space-between;
  display: flex;
}

.profile-user__title h1.title-hidden {
  visibility: hidden;
}

.profile-user__title span {
  align-self: center;
}

.profile-user__name-email {
  text-align: right;
}

.profile-user__subscription {
  justify-content: space-between;
  display: flex;
}

.profile-user__subscription p {
  flex: 0 0 150px;
}

.profile-user__subscription ~ .profile-user__subscription {
  padding-top: var(--spacing-12);
  border-top: none;
  margin-top: 0;
}

.profile-user__cancel-reccuring, .profile-user__cancel-newsletter {
  padding-top: var(--spacing-12);
  justify-content: space-between;
  display: flex;
}

.profile-user__cancel-reccuring strong, .profile-user__cancel-newsletter strong {
  text-align: right;
  color: var(--green);
  position: relative;
}

.profile-user__cancel-reccuring strong a, .profile-user__cancel-newsletter strong a {
  color: var(--text-primary);
  font-weight: normal;
}

.profile-user__cancel-reccuring strong span, .profile-user__cancel-newsletter strong span {
  color: var(--primary-main);
  cursor: pointer;
}

.profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
  background: var(--primary-contrast);
  border: 1px solid var(--grey-300);
  padding: var(--spacing-12);
  color: var(--text-primary);
  text-align: center;
  z-index: 2;
  width: 160px;
  line-height: 17px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

.profile-user__remaining-days {
  padding-top: var(--spacing-12);
  text-align: right;
}

.profile-user__remaining-days strong {
  color: var(--green);
}

.profile-user__subscription, .profile-user__setting-links, .profile-user__condition-links, .profile-user__order, .profile-user__benefits, .profile-user__activate {
  border-top: 1px solid var(--grey-300);
  padding-top: var(--spacing-32);
  margin-top: var(--spacing-32);
  line-height: 35px;
}

.profile-user__voucher {
  gap: var(--spacing-16);
  margin: var(--spacing-16) 0;
  flex-direction: column;
  display: flex;
}

.profile-user__condition-prefix {
  margin-top: var(--spacing-24);
}

.profile-user__condition-links a {
  padding-left: var(--spacing-20);
  position: relative;
}

.profile-user__condition-links a:before {
  content: "";
  background: var(--grey-300);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.profile-user__order .error-message {
  color: var(--red);
}

.profile-user__form-centered {
  justify-content: center;
  display: flex;
}

.profile-user .activation-steps {
  margin: var(--spacing-32) auto 0;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  display: flex;
}

.profile-user .activation-steps__item {
  padding: var(--spacing-8) var(--spacing-20) var(--spacing-8);
  background: var(--grey-200);
  color: var(--text-primary);
  width: calc(50% - 30px);
  margin-right: 30px;
  position: relative;
}

.profile-user .activation-steps__item:last-of-type {
  text-indent: 25px;
  left: -30px;
}

.profile-user .activation-steps__item:before {
  content: "";
  border: 35px solid #0000;
  border-left: 30px solid var(--grey-200);
  z-index: 1;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.profile-user .activation-steps__item.active-step {
  color: var(--red);
  background: var(--grey-50);
}

.profile-user .activation-steps__item.active-step:before {
  border-left: 30px solid var(--grey-50);
}

.loader {
  border: 16px solid var(--primary-outlined-hover-background);
  border-top: 16px solid var(--primary-main);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-review__field {
  margin: var(--spacing-8) 0;
}

.form-review__field input {
  display: none;
}

.form-review__field label {
  background-color: var(--grey-100);
  cursor: pointer;
  padding: 14px 16px 10px 42px;
  display: block;
  position: relative;
}

.form-review__field label:after {
  content: "";
  border: solid 1px var(--grey-300);
  background-color: var(--white);
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 11px;
  left: 12px;
}

.form-review__field label[for="textarea"] {
  display: none;
}

.form-review__field input:checked + label {
  background-color: var(--grey-200);
}

.form-review__field input:checked + label:after {
  content: "✔";
  font-size: var(--font-size-16);
  text-align: center;
  color: var(--primary-main);
  background-color: var(--pink);
  border-color: var(--primary-main);
}

.form-review__field textarea {
  padding: var(--spacing-12);
  box-sizing: border-box;
  border: solid 1px var(--grey-100);
  width: 100%;
  height: 130px;
  min-height: 100px;
  display: none;
}

.form-review__field textarea:focus-visible {
  border: solid 1px var(--grey-200);
  outline: none;
}

.form-review__field input[id="choice8"]:checked ~ textarea {
  display: block;
}

.discount-code__info {
  color: var(--primary-main);
}

.form-order__field {
  justify-content: space-between;
  display: flex;
}

.form-order__field p {
  white-space: nowrap;
  flex: 0 0 150px;
}

.form-order__field.discount-code {
  justify-content: left;
  gap: var(--spacing-12);
}

.form-order__info-text {
  background-color: var(--grey-100);
  padding: var(--spacing-8) var(--spacing-24);
}

.form-order__payment-choose input, .form-order__payment-choose label, .form-order__agreement input, .form-order__agreement label {
  cursor: pointer;
}

.form-order__icons span {
  margin: 0 0 0 var(--spacing-8);
  background-repeat: no-repeat;
  background-size: cover;
  height: 23px;
  display: inline-block;
}

.form-order__icons span.master-card {
  background-image: url("/dist/assets/default/icons/icon-premium-master-card.svg");
  width: 30px;
}

.form-order__icons span.visa {
  background-image: url("/dist/assets/default/icons/icon-premium-visa.svg");
  background-position: 0 -3px;
  width: 39px;
}

.form-order__icons span.gopay {
  background-image: url("/dist/assets/default/icons/icon-premium-gopay.svg");
  width: 50px;
}

.form-order__icons span.google-pay {
  background-image: url("/dist/assets/default/icons/icon-google-pay.svg");
  background-position: -7px -11px;
  background-size: 60px 45px;
  width: 44px;
}

.form-order__icons span.apple-pay {
  background-image: url("/dist/assets/default/icons/icon-apple-pay.svg");
  width: 36px;
}

.premium-odmeny {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-32);
  flex-direction: column;
  width: 100%;
  display: inline-flex;
}

.block__header {
  padding-left: var(--spacing-24);
  padding-right: var(--spacing-24);
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
}

.block__body {
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.block__body-content {
  padding: var(--spacing-24);
  border-radius: var(--spacing-15);
  border: 1px var(--grey-200) solid;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
  box-shadow: 0 2px 4px -2px #19191912;
}

.block__body-content__top {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
}

.block__body-content .premium-offer {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex: 1 1 0;
  display: flex;
}

.block__body-content .premium-offer__text {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  display: flex;
}

.block__body-content .premium-offer__title {
  color: var(--grey-900);
  font-size: var(--font-size-22);
  font-weight: 400;
  line-height: var(--spacing-28);
  word-wrap: break-word;
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.block__body-content__center {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
}

.block__body-content__right {
  color: var(--grey-600);
  font: var(--font-desktop-meta-regular);
}

.thanks {
  gap: var(--spacing-8);
  grid-column: 1 / 3;
  display: flex;
}

.thanks__content {
  flex: 1;
}

.thanks__title {
  margin-bottom: var(--spacing-32);
}

.thanks__text {
  margin-bottom: var(--spacing-32);
}

.thanks__greetings {
  margin-bottom: var(--spacing-8);
}

.thanks__image-wrapper {
  flex: none;
  width: 252px;
}

.thanks__image-wrapper.lideazeme {
  align-items: center;
  display: flex;
}

.newsletters {
  text-align: center;
  grid-column: lend / rend;
}

.newsletters__title {
  text-align: center;
  grid-column: lend / rend;
  max-width: 720px;
  margin: 0 auto;
}

.newsletters__title h1 {
  font: var(--font-desktop-h1);
  margin-bottom: var(--spacing-32);
}

.newsletters__title p {
  font: var(--font-desktop-body1-bold);
}

.newsletters__choose-all {
  font: var(--font-desktop-body1);
  margin-bottom: var(--spacing-32);
  cursor: pointer;
  padding-left: var(--spacing-32);
  display: inline-block;
  position: relative;
}

.newsletters__choose-all:after {
  content: "";
  background: url("/dist/assets/default/icons/icon-checkbox.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
}

.newsletters__choose-all.selected:after {
  background: url("/dist/assets/default/icons/icon-checkbox-checked.svg") center no-repeat;
}

.newsletters__popup-label {
  display: block;
}

.newsletters__email-input {
  padding: var(--spacing-12) var(--spacing-16);
  border: 1px solid var(--grey-300);
  border-radius: var(--spacing-input-radius);
  width: 260px;
}

.newsletters__email-input--red {
  border-color: var(--color-border-error-main);
}

.newsletter__detail {
  padding: var(--spacing-24) var(--spacing-32) var(--spacing-24) var(--spacing-32);
  border: 2px solid var(--grey-200);
  margin: 0 auto var(--spacing-16);
  text-align: left;
  max-width: 720px;
  position: relative;
  box-shadow: 0 12px 16px -4px #19191914, 0 4px 6px -2px #1919190a;
}

.newsletter__detail--title {
  font: var(--font-desktop-h2);
  color: var(--primary-main);
}

.newsletter__detail--date {
  font: var(--font-desktop-body1);
  color: var(--grey-600);
  margin-top: var(--spacing-12);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.newsletter__detail--date svg {
  padding-right: var(--spacing-8);
  display: inline-block;
  position: relative;
  top: -1px;
}

.newsletter__detail--description {
  font: var(--font-desktop-body1);
  margin-top: var(--spacing-20);
}

.newsletter__detail--note {
  font: var(--font-desktop-body1);
  color: var(--grey-600);
  margin-top: var(--spacing-20);
}

.newsletter__detail--signup {
  font: var(--font-desktop-body1);
  cursor: pointer;
  padding-right: var(--spacing-32);
  top: 30px;
  right: var(--spacing-32);
  position: absolute;
}

.newsletter__detail--signup:after {
  content: "";
  background: url("/dist/assets/default/icons/icon-checkbox.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 2px;
  right: 150px;
}

.newsletter__detail--signup.selected:after {
  background: url("/dist/assets/default/icons/icon-checkbox-checked.svg") center no-repeat;
}

.newsletters > .newsletter__detail--last-child {
  margin: 0 auto;
}

.newsletters__bar {
  z-index: 1;
  background: var(--grey-100);
  padding: var(--spacing-8) 0;
  text-align: left;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 8px #00000040;
}

.newsletters__bar--content {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
  gap: var(--spacing-8);
  flex-direction: row;
  place-content: center space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
}

.newsletters__bar--title {
  font: var(--font-desktop-h3);
}

.newsletters__bar--summary {
  margin-top: var(--spacing-8);
}

.newsletters__bar--summary span {
  padding-left: var(--spacing-32);
  text-wrap: nowrap;
  margin-right: 20px;
  position: relative;
}

.newsletters__bar--summary span:before {
  content: "";
  background: url("/dist/assets/default/icons/icon-checked-green.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
}

.newsletters__bar--summary strong {
  text-wrap: nowrap;
}

.newsletters__bar--agreement {
  font: var(--font-desktop-body2);
}

.newsletters__bar--agreement a {
  color: #000;
}

.newsletters__bar--agreement a:hover {
  text-decoration: none;
}

.newsletters__bar--agreement.notification-agreement {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.newsletters__bar--agreement.notification-agreement .checkbox-wrapper {
  display: inline-block;
}

.newsletters__bar--agreement.notification-agreement .checkbox-wrapper:before {
  content: "";
  background: url("/dist/assets/default/icons/icon-checkbox.svg") center no-repeat;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  display: block;
  position: relative;
  top: 5px;
}

.newsletters__bar--agreement.notification-agreement .checkbox-wrapper.selected:before {
  background: url("/dist/assets/default/icons/icon-checkbox-checked-red.svg") center no-repeat;
}

.newsletters__bar--button {
  justify-content: end;
  display: flex;
}

.newsletters__bar--success {
  margin: var(--spacing-16) 0 var(--spacing-24);
}

.newsletters__bar--your-choice {
  margin-right: var(--spacing-12);
}

.newsletter__popup-final-step-close {
  float: right;
  padding: 0 0 var(--spacing-8) var(--spacing-8);
  cursor: pointer;
}

.newsletter__popup-form {
  margin-top: var(--spacing-32);
  gap: var(--spacing-16);
  flex-direction: column;
  display: flex;
}

.newsletter__popup-final-step-overlay {
  z-index: 1000;
  background: #191919b3;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
}

.notification-agreement--red {
  border: 1px solid var(--color-border-error-main);
}

.newsletter__popup-final-step {
  font: var(--font-desktop-body2);
  border-radius: var(--spacing-12);
  border: 1px solid var(--light-other-outline-border);
  background-color: #fff;
  max-width: 688px;
  min-height: 250px;
  padding: 24px;
  box-shadow: 0 4px 8px #0000001a;
}

.newsletter-popup__list-of-newsletters > span {
  margin-right: var(--spacing-20);
  padding-left: var(--spacing-32);
  white-space: nowrap;
  padding-top: 2px;
  line-height: 24px;
  position: relative;
}

.newsletter-popup__list-of-newsletters > span:before {
  content: "";
  background: url("/dist/assets/default/icons/icon-checked-green.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.newsletter__popup-email-format-info {
  font: var(--font-desktop-body2);
  color: var(--color-text-error-main);
  margin-top: var(--spacing-12);
}

.newsletter-popup__title--success {
  padding-left: var(--spacing-32);
  margin-top: var(--spacing-24);
  text-align: center;
  position: relative;
}

.newsletter-popup__title--success:before {
  content: "";
  top: var(--spacing-6);
  background: url("/dist/assets/default/icons/icon-checked-green.svg") center no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  left: 0;
}

.newsletter-poupup__form-sent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
}

.newsletter__error-info-souhlas-se-zpracovanim {
  font: var(--font-desktop-body2);
  color: var(--color-text-error-main);
}

.list-articles-wrapper--vertical, .list-articles-wrapper--horizontal {
  grid-column: 1 / 3;
  display: flex;
}

.list-articles-wrapper--vertical:empty, .list-articles-wrapper--horizontal:empty {
  display: none;
}

.list-articles-wrapper--vertical {
  gap: var(--spacing-32);
  flex-direction: column;
}

.list-articles-wrapper--horizontal {
  flex-direction: row;
}

.list-articles-wrapper--border-top {
  border-top: 4px solid var(--text-primary);
  padding-top: var(--spacing-20);
}

.list-articles-wrapper--with-padding {
  padding: var(--spacing-24);
}

.list-articles-wrapper--theme-light {
  background-color: var(--surface-default-disabled);
}

.list-articles__category-button {
  justify-content: center;
  display: flex;
}

.list-articles__timeline {
  gap: var(--spacing-24);
  display: flex;
}

.list-articles__timeline .article-card__image {
  flex: 0 0 154px;
}

.list-articles__timeline-time {
  color: var(--text-secondary);
}

.topic-banner {
  gap: var(--spacing-24);
  padding: var(--spacing-16) var(--spacing-32);
  background-color: var(--text-primary);
  opacity: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  transition: transform .3s ease-out;
  display: flex;
  position: absolute;
  transform: translateY(-100%);
}

.topic-banner--fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear .3s, opacity .3s, transform .3s;
  transform: translateY(-100%);
}

.topic-banner--fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility linear, opacity .3s;
  transform: translateY(0);
}

.topic-banner__label {
  color: var(--primary-contrast);
  background-color: var(--primary-main);
  padding: var(--spacing-4) var(--spacing-12);
  font: var(--font-mobile-meta-bold);
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2px;
  order: 1;
  max-height: 28px;
}

.topic-banner__title {
  cursor: pointer;
  color: var(--primary-contrast);
  font: var(--font-mobile-h3);
  order: 2;
  max-width: 600px;
}

.topic-banner__arrow {
  cursor: pointer;
  order: 3;
  width: 40px;
  height: 40px;
  margin: auto 0;
  padding: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 42px;
}

.topic-banner__arrow svg {
  width: 20px;
  height: 20px;
}

.topic-banner__arrow svg path {
  fill: var(--primary-contrast);
}

.ig-box {
  background-color: var(--secondary-main);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 576.001px) {
  .ig-box {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.ig-box__logo {
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  display: flex;
}

.ig-box__posts {
  padding-bottom: var(--spacing-32);
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 120px);
  min-height: 360px;
  display: grid;
}

@media (min-width: 576.001px) {
  .ig-box__posts {
    grid-template-rows: repeat(2, 120px);
    grid-template-columns: repeat(3, 1fr);
    min-height: 240px;
    padding-bottom: 0;
  }
}

.ig-logo {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  display: flex;
}

.ig-logo__image {
  max-width: 120px;
  display: block;
}

.ig-post {
  width: 120px;
  height: 120px;
  display: block;
  overflow: hidden;
}

.ig-post__image {
  object-fit: cover;
  width: 100%;
  height: auto;
  display: block;
  transform: translate3d(0, 0, 1px);
}

.premium-header {
  text-align: center;
  padding-bottom: var(--spacing-24);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.premium-header__brand {
  margin-bottom: var(--spacing-24);
}

.premium-header__title {
  margin-bottom: var(--spacing-16);
}

.premium-header__link {
  color: var(--color-text-action-primary-main);
  text-decoration: none;
}

.premium-header__link:hover {
  text-decoration: underline;
}

.premium-offer {
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-24);
  width: 100%;
  margin: auto;
  display: flex;
}

.premium-extras {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-48);
  padding: var(--spacing-64) 0;
  flex-direction: column;
  display: flex;
}

.premium-extras__title {
  text-align: center;
}

.premium-extras__list {
  gap: var(--spacing-48);
  flex-direction: column;
  display: flex;
}

@media (min-width: 480.001px) {
  .premium-extras__list {
    flex-direction: row;
  }
}

.premium-extras__item {
  align-items: center;
  gap: var(--spacing-8);
  flex-direction: column;
  max-width: 124px;
  display: flex;
}

.premium-extras__icon {
  width: 32px;
  height: 32px;
}

.premium-extras__link {
  color: var(--color-text-default-body);
  text-align: center;
  text-decoration: underline;
}

.premium-extras__link:hover {
  text-decoration: none;
}

.premium-print {
  background-color: var(--color-surface-default-subtle);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-48);
  margin-left: calc(var(--spacing-24) * -1);
  margin-right: calc(var(--spacing-24) * -1);
  padding: var(--spacing-64) var(--spacing-24);
  flex-direction: column;
  display: flex;
}

.premium-print__title {
  text-align: center;
}

.premium-print__content {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-24);
  flex-direction: column;
  max-width: 600px;
  display: flex;
}

@media (min-width: 900.001px) {
  .premium-print__content {
    flex-direction: row;
    max-width: 992px;
  }
}

.premium-print__posters {
  gap: var(--spacing-12);
  display: flex;
}

.premium-print__poster {
  width: 128px;
  display: none;
}

.premium-print__poster:nth-of-type(-n+2) {
  display: block;
}

@media (min-width: 500.001px) {
  .premium-print__poster:nth-of-type(-n+3) {
    display: block;
  }
}

@media (min-width: 768.001px) {
  .premium-print__poster {
    display: block;
  }
}

.premium-print__image {
  width: 100%;
  height: auto;
  display: block;
}

.premium-print__text {
  text-align: center;
  flex: 1;
}

@media (min-width: 900.001px) {
  .premium-print__text {
    text-align: left;
  }
}

.premium-print__link {
  color: var(--color-text-link-primary-default);
}

.premium-print__link:hover {
  text-decoration: none;
}

.premium-faq {
  justify-content: center;
  align-items: center;
  gap: var(--spacing-32);
  padding: var(--spacing-32) 0;
  flex-direction: column;
  display: flex;
}

.premium-faq__title {
  color: var(--color-text-default-title);
}

.premium-faq__accordion {
  width: 100%;
  max-width: 820px;
}

.list-articles-wrapper--2x2 {
  gap: var(--spacing-24);
  flex-wrap: wrap;
}

.list-articles-wrapper--2x2 .box__headline {
  width: 100%;
}

.list-articles-wrapper--2x2 .article-card {
  width: calc(50% - var(--spacing-24) / 2);
}

@supports (-webkit-touch-callout: none) and (not (translate: none)) {
  .header-menu-hamburger__icon {
    margin-right: var(--spacing-16);
  }

  .header-menu-hamburger {
    margin-left: var(--spacing-20);
  }

  .header-menu__item:not(:last-child) {
    margin-right: var(--spacing-24);
  }

  .header-search {
    margin-right: var(--spacing-20);
  }

  .megamenu-subcategories {
    margin-bottom: var(--spacing-32);
  }

  .megamenu-stickers__item {
    margin-right: var(--spacing-8);
    margin-bottom: var(--spacing-8);
  }

  .box__headline {
    margin-bottom: var(--spacing-32);
  }

  .article-detail__social-icon {
    margin-right: var(--spacing-12);
  }

  .article-detail-tags li, .tag {
    margin-right: var(--spacing-8);
    margin-bottom: var(--spacing-8);
  }

  .megamenu-social__networks a:not(:last-child) {
    margin-right: var(--spacing-8);
  }

  .megamenu-applications__wrapper, .megamenu-applications__download-appstore {
    margin-right: var(--spacing-12);
  }

  .online-report__item .online-report__item-header, .online-report__item .online-report__content {
    margin: var(--spacing-16) 0;
  }

  .online-report__item .online-report__item-header .online-report__item-header-item {
    margin-right: var(--spacing-16);
  }

  .online-report__item .online-report__author-name {
    margin-bottom: var(--spacing-4);
  }
}

.gtm-highlight__track {
  outline-offset: -4px;
  outline: 4px solid red;
  padding: 3px;
}

.gtm-highlight__track--disabled {
  outline-offset: -2px;
  outline: 2px solid orange;
  padding: 3px;
}

.gtm-highlight__track-additional-info {
  color: #fff;
  z-index: 9999;
  opacity: .8;
  background-color: green;
  padding: 3px;
  font-size: .8em;
  position: absolute;
}

.gtm-highlight__track--green {
  outline-color: green;
}

.gtm-highlight__scroll-to {
  color: red;
  border: 4px solid red;
  padding: 3px;
}

.gtm-highlight__gallery {
  border: 4px solid red;
}

.gtm-highlight__track-source {
  color: #ff0;
}

.list-article-comments {
  border-top: 4px solid var(--color-border-brand-primary-main);
  padding-top: var(--spacing-24);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.list-article-comments__next-link {
  color: var(--color-text-action-secondary-main);
  font: var(--font-desktop-button-small);
  text-decoration: none;
}

.list-article-comments__next-link path {
  fill: var(--color-text-action-secondary-main);
}

.list-article-comments__next-link:hover {
  color: #4f4f4f;
  text-decoration: none;
}

.list-article-author {
  margin-bottom: var(--spacing-24);
  grid-column: 1 / 3;
}

.list-article-author .article-author {
  margin-top: var(--spacing-16);
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
  color: var(--primary-dark);
  background-color: var(--color-surface-accent-subtle);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.list-article-author .article-author:before {
  min-height: var(--spacing-68);
  background-color: #fff;
  width: 100%;
  display: block;
}

.list-article-author .article-author__image {
  z-index: 0;
}

.list-article-author .article-author__image img, .list-article-author .article-author__image svg, .list-article-author .article-author__image picture {
  z-index: 5;
  background-color: var(--white);
  border-radius: 100px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  display: flex;
  box-shadow: 0 0 4px -2px #19191912, 0 0 8px 2px #1919191a;
}

.list-article-author .article-author .article-author__title {
  text-align: center;
  color: var(--color-text-default-title);
  padding: 0 var(--spacing-24);
  gap: var(--spacing-16);
  font: var(--font-desktop-h4);
}

.list-article-author .article-author .article-author__title a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.list-article-author .article-author .article-author__title a:hover {
  text-decoration: underline;
}

.list-article-author .article-author__name {
  text-align: center;
  color: var(--color-text-link-primary-default);
  font: var(--font-desktop-h5);
  text-decoration: none;
}

.list-article-author .article-author__read-more {
  color: var(--color-text-link-primary-default);
  text-align: center;
  font: var(--font-desktop-meta-bold);
  justify-content: center;
  gap: var(--spacing-2);
  padding-bottom: var(--spacing-8);
  align-items: center;
  min-width: 100px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.list-article-author .article-author__arrow-right {
  justify-content: center;
  align-items: center;
  display: flex;
}

.list-article-author .article-author__arrow-right:hover {
  text-decoration: none;
}

.list-article-author .article-author__arrow-right path {
  fill: var(--color-text-link-primary-default);
}

.list-article-author .article-author__top-wrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.list-article-author .article-author__white-line {
  z-index: 0;
  background-color: #fff;
  width: 100%;
  min-height: 56px;
  position: absolute;
}

.list-article-author a.article-author__name:hover {
  text-decoration: underline;
}

.list-article-author-total-2 {
  align-items: center;
  gap: var(--spacing-24);
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  cursor: default;
  display: flex;
  overflow: auto hidden;
}

.list-article-author-total-2::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.megamenu-wrapper {
  font-family: var(--heroic-condensed);
  background: var(--background-paper);
}

a.tag, span.tag {
  border-radius: var(--spacing-24);
  background: var(--other-common-white);
  color: var(--text-secondary);
}

a.tag:hover {
  color: var(--primary-light);
  border-color: var(--primary-light);
}

.megamenu-search__form:hover .megamenu-search__submit svg {
  width: 24px;
  height: 24px;
}

.megamenu-search__form:hover .megamenu-search__submit svg * {
  fill: var(--blue-400);
}

.megamenu-search__input {
  border: 2px solid var(--other-outline-border);
}

.megamenu-search__input:focus, .megamenu-search__input:focus-within, .megamenu-search__input:focus-visible {
  border: 2px solid var(--blue-400);
  outline: none;
}

.megamenu-categories__item, .megamenu-categories__item-title, .megamenu-categories__item-title > a, .megamenu-categories .megamenu-subcategories, .megamenu-categories .megamenu-subcategories__item, .megamenu-categories .megamenu-subcategories__item > a {
  color: var(--primary-contrast);
}

.megamenu-print {
  border-radius: var(--spacing-global-image-radius);
  border-top: 3px solid var(--color-border-brand-primary-main);
  background: var(--themes-neutral-darker);
  padding: var(--spacing-32) var(--spacing-16);
  gap: var(--spacing-16);
}

.megamenu-print__title {
  color: var(--primary-contrast);
}

.megamenu-print__button {
  text-transform: uppercase;
}

.megamenu-contact__title, .megamenu-contact__email, .megamenu-contact__email > a, .megamenu-newsletter__title, .megamenu-newsletter__email, .megamenu-newsletter__email > a {
  color: var(--primary-contrast);
}

.megamenu-newsletter__email > a svg * {
  fill: var(--primary-contrast);
}

.megamenu-newsletter__email > a:hover {
  color: var(--primary-contrast);
}

.megamenu-newsletter__email > a:hover svg * {
  fill: var(--primary-contrast);
}

.megamenu-social__item svg .symbol {
  fill: var(--color-icon-icon-button-primary-solid-inverted-text-default);
}

.megamenu-social__item:hover svg .symbol {
  fill: var(--color-icon-icon-button-primary-solid-inverted-text-hover);
}

.megamenu-social__item:active, .megamenu-social__item:focus {
  border-color: var(--color-border-icon-button-primary-solid-inverted-text-focus);
}

.megamenu-social__item:active svg .symbol, .megamenu-social__item:focus svg .symbol {
  fill: var(--color-icon-icon-button-primary-solid-inverted-text-focus);
}

.footer__themes--bottom .footer__theme-link {
  color: var(--primary-light);
}

.article-detail .article-detail__content-body .enquiry-bars-answer__indicator {
  background-color: #def5fd;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .article-card--desktop-portrait-xl .article-card__image img, .article-card--desktop-landscape-xl .article-card__image img, .article-card--desktop-portrait-xl .article-card__image picture, .article-card--desktop-landscape-xl .article-card__image picture {
    border-radius: 30px;
  }

  .article-card--desktop-portrait-lg .article-card__image img, .article-card--desktop-landscape-lg .article-card__image img, .article-card--desktop-portrait-lg .article-card__image picture, .article-card--desktop-landscape-lg .article-card__image picture {
    border-radius: 20px;
  }

  .article-card--desktop-portrait-md .article-card__image img, .article-card--desktop-landscape-md .article-card__image img, .article-card--desktop-portrait-md .article-card__image picture, .article-card--desktop-landscape-md .article-card__image picture {
    border-radius: 15px;
  }
}

.button {
  text-transform: uppercase;
}

